import React from 'react';
import { withStyles, Theme } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import { injectIntl } from 'react-intl';
//@ts-ignore
import CreateButton from 'hcla-web-frontend-primitives/app/components/primitives/Buttons/CreateButton';
//@ts-ignore
import MultiUserSelectChip from 'hcla-web-frontend-primitives/app/components/primitives/Chips/MultiUserSelectChip';
//@ts-ignore
import InCardTwoColumnTableWrapper from 'hcla-web-frontend-primitives/app/components/primitives/Table/InCardTwoColumnTableWrapper';
import { DetailsSettingsPropType } from '../../Types';
import Validators from 'hcla-web-frontend-primitives/app/Utils/Validators';

const styles = (theme: Theme) => ({
  hint: {
    fontSize: '0.8rem',
    lineHeight: '1rem',
  },
  root: {
    flexGrow: 1,
  },
  inCardContainer: {
    border: '1px solid #ccc',
    borderRadius: theme.spacing(1) / 2,
    padding: theme.spacing(1) * 2,
  },
  buttonWrapper: {
    margin: theme.spacing(1),
    display: 'inline-flex',
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});

interface Props {
  details: DetailsSettingsPropType,
  onRequestSave: (event: any, data: any) => any,
  isSaving: boolean,
  classes: { [key: string]: any },
  intl: any
}

interface State {
  details: any
}

class ModelSettingsGeneral extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      details: {
        ...props.details,
        title: {
          text: props.details.title,
          hasError: !Validators.isTitleValid(props.details.title)
        },
        description: {
          text: props.details.description,
          hasError: !Validators.isDescriptionValid(props.details.description)
        }
      },
    };
  }

  handleChange = (name: string) => (event: any) => {
    const text = event.target.value;
    const hasError = () => {
      if (name === 'title') return !Validators.isTitleValid(text);
      if (name === 'description') return !Validators.isDescriptionValid(text);
    }
    this.setState(prevState => ({
      details: {
        ...prevState.details,
        [name]: {
          text,
          hasError: hasError()
        },
      },
    }));
  };

  //MODIFIED TO HANDLE THE STATE
  handleMultiChange =  () => async (event: any) => {
    console.log("EVENT IN SETTING",event)
    var updatingDetails = {...this.state.details}
    updatingDetails.assigned_users = event;
    console.log("UPDATING DETAILS",updatingDetails)
    await this.setState({details:updatingDetails})
    console.log("UPDATED DETAILS",this.state.details)
  };

  handleSubmit = (event: any) => {
    const { onRequestSave } = this.props;
    const data: any = {};

    if (this.state.details.title !== this.props.details.title) {
      console.log(this.props, 'jc', this.state)
      data.title = this.state.details.title.text;
    }

    if (this.state.details.description !== this.props.details.description) {
      data.description = this.state.details.description.text;
    }

    if (this.state.details.assigned_users !== this.props.details.assigned_users) {
      console.log("GGGGGGGGGGGGGGGGGGGGGGGGGG");
      data.assigned_users = this.state.details.assigned_users;
      console.log(data.assigned_users)
    }

    onRequestSave(event, data).then(async (updated: any) => {
      await this.setState({ details: updated.data });
    });

  };

  isModelStale = () => {
    return (this.props.details.title !== this.state.details.title.text)
      || (this.props.details.description !== this.state.details.description.text)
      || (this.props.details.assigned_users !== this.state.details.assigned_users);
  };

  render() {
    const { classes, isSaving, intl } = this.props;
    const { details } = this.state;
    const {
      title, description, assigned_users,
    } = details;
    console.log(title, description, assigned_users, this.state.details, 138)
    return (
      <div className={classes.root}>
        <div className={classes.inCardContainer}>
          <InCardTwoColumnTableWrapper>
            <table>
              <tbody>
                <tr>
                  <td>
                    <Typography variant="body1">{intl.formatMessage({ id: 'modelTitle' })}</Typography>
                  </td>
                  <td>
                    <TextField
                      id="title"
                      value={title.text}
                      onChange={this.handleChange('title')}
                      fullWidth
                      error={!Validators.isEmpty(title.text) ? title.hasError : false}
                      helperText={!Validators.isEmpty(title.text) && title.hasError ? Validators.ERROR_DESCRIPTIONS.TITLE : undefined}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <Typography variant="body1">{intl.formatMessage({ id: 'description' })}</Typography>
                  </td>
                  <td>
                    <TextField
                      id="description"
                      value={description.text}
                      onChange={this.handleChange('description')}
                      multiline
                      fullWidth
                      error={description.hasError}
                      helperText={description.hasError ? Validators.ERROR_DESCRIPTIONS.DESCRIPTION : undefined}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <Typography variant="body1">{intl.formatMessage({ id: 'mainContent.newModel.assignedUsers' })}</Typography>
                    <Typography className={classes.hint} color="textSecondary">
                      {intl.formatMessage({ id: 'mainContent.newModel.assignedUsersDesc' })}
                    </Typography>
                  </td>
                  <td>
                    <MultiUserSelectChip
                      handleUpdate={this.handleMultiChange()}
                      selected={assigned_users}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </InCardTwoColumnTableWrapper>
          <div className={classes.buttonWrapper}>
            <CreateButton
              onClick={(e: any) => this.handleSubmit(e)}
              variant="outlined"
              size="large"
              disabled={!this.isModelStale() || isSaving || title.hasError || description.hasError}
            >
              {intl.formatMessage({ id: 'save' })}
            </CreateButton>
            {isSaving && <CircularProgress size={24} className={classes.buttonProgress} />}
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(injectIntl(ModelSettingsGeneral));
