import React from 'react';
import { Link } from 'react-router-dom';
import { withStyles, Theme } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import ArrowDown from '@material-ui/icons/ArrowDropDown';
import Fab from '@material-ui/core/Fab';
import Menu from '@material-ui/core/Menu';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import { injectIntl } from 'react-intl';

const styles = (theme: Theme) => ({
  root: {
    textAlign: 'left',
    paddingLeft: '16px',
    paddingRight: '16px',
    marginBottom: '0px',
  },
  button: {
    height: '2rem',
    boxShadow: 'none',
    width: '3.6rem',
    borderRadius: '0.3rem',
    marginRight: theme.spacing(1),
    backgroundColor: 'rgba(0,0,0,0.22)',
  },
  addButton: {
    color: theme.palette.secondary.dark,
    backgroundColor: 'rgba(255,255,255,0.88)',
    borderRadius: '0.3rem',
    width: '1rem',
    height: '1rem',
  },
  downButton: {
    width: '1.2rem',
    height: '1.2rem',
  },
  dialog: {
    overflow: 'unset',
  },
});

interface Props {
  classes: { [key: string]: any },
  intl: { [key: string]: any }
}

interface State {
  anchorEl: any
}

class CreateMenuWithFAB extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      anchorEl: null,
    };
  }

  handleClick = (event: any) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { classes, intl } = this.props;
    const { anchorEl } = this.state;
    return (
      <div>
        <Fab
          color="secondary"
          aria-label="Create"
          aria-haspopup="true"
          aria-owns={anchorEl ? 'create-new-navmenu' : null}
          onClick={this.handleClick}
          className={classes.button}
        >
          <AddIcon className={classes.addButton} />
          <ArrowDown />
        </Fab>
        <Menu
          id="create-new-navmenu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          <Typography className={classes.root} variant="caption" noWrap>{intl.formatMessage({ id: 'create' })}</Typography>
          <Link to="/model/new">
            <MenuItem>
              {intl.formatMessage({ id: 'newModel' })}
            </MenuItem>
          </Link>
        </Menu>
      </div>
    );
  }
}

export default withStyles(styles)(injectIntl(CreateMenuWithFAB));
