import React from 'react';
import { HCLTreeMxGraphViewJSON } from 'hcl-web-editor/app/components/Model/SingleHCLModelView/SingleTreeView/MxGraphViews/HCLTreeMxGraphView';
import { EventTreeMxGraphJSON } from 'hcl-web-editor/app/mxGraph/HCLTreeMxGraph/EventTreeMxGraph';
import HCLTreeMxGraphEditorView from "./HCLTreeMxGraphEditorView";
import {HCLTreeMxGraphViewProps} from "hcl-web-editor/app/components/Model/SingleHCLModelView/SingleTreeView/MxGraphViews/HCLTreeMxGraphViewProps";
import {PreferencesConsumer} from 'hcla-web-frontend-primitives/app/config/PreferencesProvider';

export interface EventTreeMxGraphViewJSON extends HCLTreeMxGraphViewJSON {
  tree_data: EventTreeMxGraphJSON;
  hide: any;
  add: any;
}

export interface EventTreeMxGraphViewProps extends HCLTreeMxGraphViewProps {
  modelId: number;
  data: EventTreeMxGraphViewJSON;
}

const EventTreeMxGraphEditorView = (props: EventTreeMxGraphViewProps) => {
  return (
    <HCLTreeMxGraphEditorView {...props} />
  );
};

export default EventTreeMxGraphEditorView;
