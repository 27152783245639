import React, {useEffect, useState} from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { injectIntl } from 'react-intl';
import { Switch, Route } from 'react-router-dom';
import PhoenixApiManager from "../../../../../../Api/PhoenixApiManager";

// @ts-ignore
import ViewNoMatch from 'hcla-web-frontend-primitives/app/components/primitives/Error/ViewNoMatch';

import CFMs from '../../CFM/CFMs';
import CFMsForHFE from '../../CFM/CFMsForHFE';
import CFMForHFERouter from '../../CFM/SingleCFMView/CFMForHFERouter';
import EditCFM from '../../CFM/SingleCFMView/EditCFM';
import { HFEType } from "../../../../Types";
import HumanFailureEventEditorView from "./HumanFailureEventEditorView";
import PIFs from '../../PIF/PIFs';
import PIFForCFMRouter from '../../PIF/SinglePIFView/PIFForCFMRouter';
import PIFsForCFM from '../../PIF/PIFsforCFM';
import QuantifyHFE from './QuantifyHFE';
import ExpertModeHFEEditor from './ExpertModeHFEEditor';
import { Description } from '@material-ui/icons';

interface Props {
  match: { [key: string]: any },
  location: { [key: string]: any },
  depth: number,
  progress: any,
  intl: any,
  hfeItems: HFEType[],
  disableEditing: boolean,
  onResponseUpdated: () => void,
  restartHFEreloadCounter: any;
}

const HFERouter = (props: Props) => {
  const { depth, match: { params: { hfeName, id, cfid } }, progress, intl, hfeItems, disableEditing, onResponseUpdated, restartHFEreloadCounter } = props;
  const [cfname, setCfname] = useState('');

  useEffect(() => {
    if(restartHFEreloadCounter) restartHFEreloadCounter();
		PhoenixApiManager.getCfFunctionDetails(cfid)
		.then(response => response.json())
		.then(data => {
			setCfname(data.title)
		})
		.catch(err => console.error(err));
	}, []);

  const eventData = {
    "house_events": [
        "Primary Information Not Available",
        "Secondary Information Not Available",
        "Flag of Following Own Knowledge during Primary Information Gathering",
        "Flag of Following Procedure during Primary Information Gathering",
        "Flag of Following Own Knowledge during Secondary Information Gathering",
        "Flag of Following Procedure during Secondary Information Gathering",
        "Flag of Following Own Knowledge",
        "Flag of Following Procedure"
    ],
    "basic_events": [
        "Procedures In Primary Information Source",
        "Primary Instrument In MCR Not Available",
        "Primary Instrumentation Failure",
        "Primary Instrument Out of MCR Not Available",
        "Primary Communication Instrumentation Failure",
        "Incomplete or Incorrect Procedure Guidance 1",
        "Incomplete or Incorrect Procedure Guidance 2",
        "Procedures In Secondary Information Source",
        "Secondary Instrumentation Failure",
        "Secondary Instrument In MCR Not Available",
        "Secondary Instrument Out of MCR Not Available",
        "Secondary Communication Instrumentation Failure",
        "The Time Required for Completing the Task is Higher than the Available Time for the Crew",
        "The equipment in which the action should be performed is inaccessible",
        "Insufficient Workforce Available for Taking the Action"
    ],
   cfms : {
      "CFM - Information Miscommunicated 1": {
        "label": {
          "name": "Information Miscommunicated - Crew Member In Primary Information Source",
          "description": "This covers the case where there is a missed or incorrect transfer of information between crew\nmembers (i.e. the receiver and sender). The sender may not communicate the necessary\ninformation or may pass along incorrect information in the form of wrong, incomplete,\nimprecise or ambiguous information to the receiver. Also, the crew member may direct the\ninformation to the wrong person, present it in the wrong format (e.g. inappropriate use of\nterms or phonetics) or at the wrong time (i.e. either too early or too late). The receiver may\nmishear or misunderstand the information transmitted and does not repeat back to the sender\n(for an opportunity for confirmation). Also, the crew member may repeat the misunderstood\ninformation to the sender, but the sender does not correct it. As an example, during a plant\nupset condition, the control room crew may not pass on the required information to the field\ncrew on time. This may cause a delay in returning the plant to a stable state."
        },
      },
      "CFM - Decision to Stop Gathering Data 1": {
        "label": {
          "name": "Decision to Stop Gathering Data - Failed to Collect Primary Active Information during Procedure Information Gathering",
          "description": ""
        },

      },
      "CFM - Collected Data Discounted 1": {
        "label": {
          "name": "Collected Data Discounted - Failed to Collect Primary Active Information during Procedure Information Gathering",
          "description": "This is a situation where the crew understands the need for and has obtained correct\ninformation (either personally or communicated by another crew member) but decides to\ndiscard it (i.e. there is no intent to use or include it in the assessment of the plant state). In this\ncase, the data is first gathered and later discounted as opposed to the CFM “Data Not\nObtained” where the information has not been gathered at all. For example, the crew may\nobtain some information initially but on a second thought, they may decide to give it up\nbecause they assume it is not relevant to the current situation they are encountering."
        },
      },
      "CFM - Data Not Checked with Appropriate Frequency 1": {
        "label": {
          "name": "Data Not Checked with Appropriate Frequency - Failed to Collect Active Primary Information during Knowledge Information Gathering",
          "description": "This is the case where the crew is not adequately implementing the monitoring strategy for\ndata collection. For example, the crew may have the task of monitoring a parameter and the\ninstruction to initiate some kind of response is dependent on a critical value of that parameter\nwhose value is changing and is expected to keep changing rather than remain static. They may\nmiss checking this critical parameter value and hence, fail to initiate the expected response in\na timely manner.\nFigure 6 shows the human failure event fault tree with expanded information-gathering phase\ncrew failure modes for primary information. A similar one exists for the secondary information."
        },
      },
      "CFM - Decision to Stop Gathering Data 2": {
        "label": {
          "name": "Decision to Stop Gathering Data - Failed to Collect Active Primary Information during Knowledge Information Gathering",
          "description": "This is a situation where the crew has been collecting information and at some point\ndetermines that they do not require any more data based on their confidence in the\nassessment of the plant status (i.e. they have collected enough information to enable them\nobtain a true picture of the plant state and no additional data is needed). Hence, there is no\nmotivation to continue the data collection process since the goal has been accomplished. As an example, the crew may be gathering data with regards to a certain situation in the plant.\nThey get interrupted by other persons or information and thereafter, they decide to stop\ncollecting the data."
        },
      },
      "CFM - Collected Data Discounted 2": {
        "label": {
          "name": "Collected Data Discounted - Failed to Collect Active Primary Information during Knowledge Information Gathering",
          "description": "This is a situation where the crew understands the need for and has obtained correct\ninformation (either personally or communicated by another crew member) but decides to\ndiscard it (i.e. there is no intent to use or include it in the assessment of the plant state). In this\ncase, the data is first gathered and later discounted as opposed to the CFM “Data Not\nObtained” where the information has not been gathered at all. For example, the crew may\nobtain some information initially but on a second thought, they may decide to give it up\nbecause they assume it is not relevant to the current situation they are encountering."
        },

      },
      "CFM - Data Not Checked with Appropriate Frequency 2": {
        "label": {
          "name": "Data Not Checked with Appropriate Frequency - Failed to Collect Active Primary Information during Knowledge Information Gathering",
          "description": "This is the case where the crew is not adequately implementing the monitoring strategy for\ndata collection. For example, the crew may have the task of monitoring a parameter and the\ninstruction to initiate some kind of response is dependent on a critical value of that parameter\nwhose value is changing and is expected to keep changing rather than remain static. They may\nmiss checking this critical parameter value and hence, fail to initiate the expected response in\na timely manner.\nFigure 6 shows the human failure event fault tree with expanded information-gathering phase\ncrew failure modes for primary information. A similar one exists for the secondary information."
        },
      },
      "CFM - Data Incorrectly Processed 1": {
        "label": {
          "name": "Data Incorrectly Processed - Failed to Collect Active Primary Information during Knowledge Information Gathering",
          "description": "This is a situation where the crew misinterprets or is slow in interpreting plant parameters /\ninformation read from the indicator or received from other crew members. As an example,\nduring the loss of seal cooling event, a crew member may check the status of the charging\npump and conclude that the charging flow is normal because the charging pump is still running.\nHowever, the failed (open) release valve may have caused no charging flow to the seals but\nthe crew member may not realize it. He/ she may believe that the charging flow is normal."
        },

      },
      "CFM - Key Alarm Not Responded To Intentionally 1": {
        "label": {
          "name": "Key Alarm Not Responded To Intentionally - Failed to Collect Passive Primary Information during Knowledge Information Gathering",
          "description": ""
        },
      },
      "CFM - Data Not Checked with Appropriate Frequency 3": {
        "label": {
          "name": "Data Not Checked with Appropriate Frequency - Failure to Collect Active Primary Information",
          "description": "This is the case where the crew is not adequately implementing the monitoring strategy for\ndata collection. For example, the crew may have the task of monitoring a parameter and the\ninstruction to initiate some kind of response is dependent on a critical value of that parameter\nwhose value is changing and is expected to keep changing rather than remain static. They may\nmiss checking this critical parameter value and hence, fail to initiate the expected response in\na timely manner.\nFigure 6 shows the human failure event fault tree with expanded information-gathering phase\ncrew failure modes for primary information. A similar one exists for the secondary information."
        },

      },
      "CFM - Wrong Data Source Attended To 1": {
        "label": {
          "name": "Wrong Data Source Attended To - Failure to Collect Active Primary Information",
          "description": "This is a situation where the crew is aware of the need to obtain information and the correct\ninformation is available, but they unintentionally try to collect this information from the wrong\nsource. For example, the required reading should be obtained from indicator “A”, but it was\nobtained from “B”."
        },

      },
      "CFM - Reading Error 1": {
        "label": {
          "name": "Reading Error - Failure to Collect Active Primary Information",
          "description": "This CFM is applicable to a situation where the crew tries to read a procedure or indicator but\nsomehow makes a mistake. It includes mistakes in reading procedure steps, detecting and/or\nreading the values of parameters from some form of display like an indicator, and mistakes in\ndetermining the status of equipment based on indications on the control panel. This is a case\nwhere everything is put in place correctly, but the crew still makes a simple mistake. It is more\nof an “eye” error. For example, during an upset plant condition, the crew may misread a\nprocedure step “turn valve A 3 times clockwise” as “turn valve A 2 times clockwise”, an “on”\nindicator pump light for “off” or a “closed” valve indicator for “open”."
        },
      },
      "CFM - Key Alarm Not Responded To Unintentionally 1": {
        "label": {
          "name": "Key Alarm Not Responded To Unintentionally - Failure to Collect Passive Primary Information",
          "description": ""
        },

      },
      "Secondary Instrumentation Failure": {
        "label": {
          "name": "Secondary Instrumentation Failure - Crew Member In Secondary Information Source",
          "description": ""
        },
  
      },

      "CFM - Information Miscommunicated 2": {
        "label": {
          "name": "Information Miscommunicated - Crew Member In Secondary Information Source",
          "description": "This covers the case where there is a missed or incorrect transfer of information between crew\nmembers (i.e. the receiver and sender). The sender may not communicate the necessary\ninformation or may pass along incorrect information in the form of wrong, incomplete,\nimprecise or ambiguous information to the receiver. Also, the crew member may direct the\ninformation to the wrong person, present it in the wrong format (e.g. inappropriate use of\nterms or phonetics) or at the wrong time (i.e. either too early or too late). The receiver may\nmishear or misunderstand the information transmitted and does not repeat back to the sender\n(for an opportunity for confirmation). Also, the crew member may repeat the misunderstood\ninformation to the sender, but the sender does not correct it. As an example, during a plant\nupset condition, the control room crew may not pass on the required information to the field\ncrew on time. This may cause a delay in returning the plant to a stable state."
        },
      },

      "CFM - Decision to Stop Gathering Data 3": {
        "label": {
          "name": "Decision to Stop Gathering Data - Failed to Collect Secondary Active Information during Procedure Information Gathering",
          "description": ""
        },
      },
      "CFM - Collected Data Discounted 3": {
        "label": {
          "name": "Collected Data Discounted - Failed to Collect Secondary Active Information during Procedure Information Gathering",
          "description": "This is a situation where the crew understands the need for and has obtained correct\ninformation (either personally or communicated by another crew member) but decides to\ndiscard it (i.e. there is no intent to use or include it in the assessment of the plant state). In this\ncase, the data is first gathered and later discounted as opposed to the CFM “Data Not\nObtained” where the information has not been gathered at all. For example, the crew may\nobtain some information initially but on a second thought, they may decide to give it up\nbecause they assume it is not relevant to the current situation they are encountering."
        },
      },
      "CFM - Data Not Checked with Appropriate Frequency 4": {
        "label": {
          "name": "Data Not Checked with Appropriate Frequency - Failed to Collect Secondary Active Information during Procedure Information Gathering",
          "description": "This is the case where the crew is not adequately implementing the monitoring strategy for\ndata collection. For example, the crew may have the task of monitoring a parameter and the\ninstruction to initiate some kind of response is dependent on a critical value of that parameter\nwhose value is changing and is expected to keep changing rather than remain static. They may\nmiss checking this critical parameter value and hence, fail to initiate the expected response in\na timely manner.\nFigure 6 shows the human failure event fault tree with expanded information-gathering phase\ncrew failure modes for primary information. A similar one exists for the secondary information."
        },

      },
      "CFM - Decision to Stop Gathering Data 4": {
        "label": {
          "name": "Decision to Stop Gathering Data - Failed to Collect Active Secondary Information during Knowledge Information Gathering",
          "description": ""
        },

      },
      "CFM - Data Not Checked with Appropriate Frequency 5": {
        "label": {
          "name": "Data Not Checked with Appropriate Frequency - Failed to Collect Active Secondary Information during Knowledge Information Gathering",
          "description": "This is the case where the crew is not adequately implementing the monitoring strategy for\ndata collection. For example, the crew may have the task of monitoring a parameter and the\ninstruction to initiate some kind of response is dependent on a critical value of that parameter\nwhose value is changing and is expected to keep changing rather than remain static. They may\nmiss checking this critical parameter value and hence, fail to initiate the expected response in\na timely manner.\nFigure 6 shows the human failure event fault tree with expanded information-gathering phase\ncrew failure modes for primary information. A similar one exists for the secondary information."
        },

      },
      "CFM - Data Incorrectly Processed 2": {
        "label": {
          "name": "Data Incorrectly Processed - Failed to Collect Active Secondary Information during Knowledge Information Gathering",
          "description": "This is a situation where the crew misinterprets or is slow in interpreting plant parameters /\ninformation read from the indicator or received from other crew members. As an example,\nduring the loss of seal cooling event, a crew member may check the status of the charging\npump and conclude that the charging flow is normal because the charging pump is still running.\nHowever, the failed (open) release valve may have caused no charging flow to the seals but\nthe crew member may not realize it. He/ she may believe that the charging flow is normal."
        },

      },
      "CFM - Collected Data Discounted 4": {
        "label": {
          "name": "Collected Data Discounted - Failed to Collect Active Secondary Information during Knowledge Information Gathering",
          "description": "This is a situation where the crew understands the need for and has obtained correct\ninformation (either personally or communicated by another crew member) but decides to\ndiscard it (i.e. there is no intent to use or include it in the assessment of the plant state). In this\ncase, the data is first gathered and later discounted as opposed to the CFM “Data Not\nObtained” where the information has not been gathered at all. For example, the crew may\nobtain some information initially but on a second thought, they may decide to give it up\nbecause they assume it is not relevant to the current situation they are encountering."
        },
      },
      "CFM - Key Alarm Not Responded To Intentionally 2": {
        "label": {
          "name": "Key Alarm Not Responded To Intentionally - Failed to Collect Passive Secondary Information during Knowledge Information Gathering",
          "description": "This is a case where the crew intentionally or unintentionally fails to respond to a key alarm. A\nkey alarm is one for which response is expected to be immediate and the crew is adequately\ntrained. It includes failure to detect, notice or understand the alarm. It is assumed that the alarm is the most important cue that is adequate for a correct assessment of the plant\ncondition, and the expected response should lead to a successful outcome. A key alarm is typically expected to initiate an immediate response which may include working through a\nprocedure. This CFM also includes not perceiving, dismissing and misperceiving the key alarm.\nFor example, the crew may receive an alarm, but may be busy carrying out some other task which they believe is more important than responding to the alarm. Hence, they intentionally\nignore it. Also, they may not receive the alarm because of the man-machine interface, noisy\nenvironment, or high work load and therefore, they don’t respond to it unintentionally."
        },
      },
      "CFM - Data Not Checked with Appropriate Frequency 6": {
        "label": {
          "name": "Data Not Checked with Appropriate Frequency - Failure to Collect Active Secondary Information",
          "description": "This is the case where the crew is not adequately implementing the monitoring strategy for\ndata collection. For example, the crew may have the task of monitoring a parameter and the\ninstruction to initiate some kind of response is dependent on a critical value of that parameter\nwhose value is changing and is expected to keep changing rather than remain static. They may\nmiss checking this critical parameter value and hence, fail to initiate the expected response in\na timely manner.\nFigure 6 shows the human failure event fault tree with expanded information-gathering phase\ncrew failure modes for primary information. A similar one exists for the secondary information."
        },

      },
      "CFM - Wrong Data Source Attended To 2": {
        "label": {
          "name": "Wrong Data Source Attended To - Failure to Collect Active Secondary Information",
          "description": "This is a situation where the crew is aware of the need to obtain information and the correct\ninformation is available, but they unintentionally try to collect this information from the wrong\nsource. For example, the required reading should be obtained from indicator “A”, but it was\nobtained from “B”."
        },

      },
      "CFM - Reading Error 2": {
        "label": {
          "name": "Reading Error - Failure to Collect Active Secondary Information",
          "description": "This CFM is applicable to a situation where the crew tries to read a procedure or indicator but\nsomehow makes a mistake. It includes mistakes in reading procedure steps, detecting and/or\nreading the values of parameters from some form of display like an indicator, and mistakes in\ndetermining the status of equipment based on indications on the control panel. This is a case\nwhere everything is put in place correctly, but the crew still makes a simple mistake. It is more\nof an “eye” error. For example, during an upset plant condition, the crew may misread a\nprocedure step “turn valve A 3 times clockwise” as “turn valve A 2 times clockwise”, an “on”\nindicator pump light for “off” or a “closed” valve indicator for “open”."
        },
  
      },
      "CFM - Key Alarm Not Responded To Unintentionally 2": {
        "label": {
          "name": "Key Alarm Not Responded To Unintentionally - Failure to Collect Passive Secondary Information",
          "description": "This is a case where the crew intentionally or unintentionally fails to respond to a key alarm. A\nkey alarm is one for which response is expected to be immediate and the crew is adequately\ntrained. It includes failure to detect, notice or understand the alarm. It is assumed that the alarm is the most important cue that is adequate for a correct assessment of the plant\ncondition, and the expected response should lead to a successful outcome. A key alarm is typically expected to initiate an immediate response which may include working through a\nprocedure. This CFM also includes not perceiving, dismissing and misperceiving the key alarm.\nFor example, the crew may receive an alarm, but may be busy carrying out some other task which they believe is more important than responding to the alarm. Hence, they intentionally\nignore it. Also, they may not receive the alarm because of the man-machine interface, noisy\nenvironment, or high work load and therefore, they don’t respond to it unintentionally."
        },

      },
      "CFM - Action on Wrong Component 1": {
        "label": {
          "name": "Action on Wrong Component - Failure in Taking the Correct Action Given Correct Decision",
          "description": "This CFM covers a situation where the wrong component or system is chosen to be\nmanipulated, implying that the intent is to perform the right action however; it is carried out\non the wrong component. For example, the crew was supposed to start pump A but mistakenly\nstarted pump B"
        },

      },
      "CFM - Incorrect Timing 1": {
        "label": {
          "name": "Incorrect Timing - Failure in Taking the Correct Action Given Correct Decision",
          "description": "This applies to the situation where the crew is in the process of performing an action and they\ncomplete it prematurely, spend too much time on it or forget to take the required actions. It\nis an honest delay on the crew’s part and not a deliberate attempt to slow down the desired action. As an example, the crew may be in the process of starting a pump and they become distracted by an alarm or a call to attend to another issue. They may return to complete the\npump start-up or may totally forget altogether."
        },

      },
      "CFM - Incorrect Operation of Component 1": {
        "label": {
          "name": "Incorrect Operation of Component - Failure in Taking the Correct Action Given Correct Decision",
          "description": "This is a case where the right component or object is selected but it is manipulated or\ncontrolled wrongly. It includes performing actions out of sequence (e.g. skipping operation\nsteps or reversing steps in the action when the ordering matters), and the placement of a\ncomponent in the wrong position. This CFM also includes the failure to use alternative actions\nin instances when a change in situation has made it almost impossible to perform the original\naction, and dynamic manual control problems. In all these instances, the component is not\noperated properly, and it may lead to inaccurate results being obtained. For example, while\ntrying to close a valve, the crew turned the wheel 5 times in the clockwise direction while they\nwere supposed to turn it 5 times in the anti-clockwise direction."
        },

      },
      "CFM - Inappropriate Strategy Chosen by Following Procedure 1": {
        "label": {
          "name": "Inappropriate Strategy Chosen by Following Procedure - Deviate from Procedure",
          "description": "This is the case where the crew having made a correct assessment of the plant condition,\ndecide to take a different course of action from the expected “normal” one (i.e. they made an\nincorrect choice). It is assumed that the expected or normal course of action is the guaranteed\nsuccess path and that the alternate action may result in success or failure depending on the\ncontext. The crew’s decision to choose an alternate path may be as a result of their failure to\nconsider all options, familiarity with the chosen path or lack of clarity of the expected course\nof action. For example, when the crew notices that the safety injection system (SIS) set point\nis about to be met and they do not find any significant event such as loss of coolant accident\n(LOCA) or steam generator tube rupture (SGTR), they may manually bypass the SIS (which is\nnot an appropriate strategy at this point) to minimize the consequence due to the occurrence\nof safety injection (SI)."
        },

      },
      "CFM - Decision to Delay Action by Following Procedure 1": {
        "label": {
          "name": "Decision to Delay Action by Following Procedure - Error in Action Decision by Following Procedure",
          "description": ""
        },
 
      },
      "CFM - Failure to Adapt Written Procedure to the Situation 1": {
        "label": {
          "name": "Failure to Adapt Written Procedure to the Situation - Error in Situational Assessment Error in Action Decision by Following Procedure",
          "description": ""
        },
 
      },
      "CFM - Plant State Misdiagnosed by Following Procedure 1": {
        "label": {
          "name": "Plant State Misdiagnosed by Following Procedure - Error in Situational Assessment Error in Action Decision by Following Procedure",
          "description": "This CFM applies to a situation where the crew conducts a wrong assessment of the equipment\ncondition. This may be because of their lack of awareness of the current condition of the equipment, for instance."
        },

      },
      "CFM - Inappropriate Transfer to a Different Procedure 1": {
        "label": {
          "name": "Inappropriate Transfer to a Different Procedure - Deviate from Procedure",
          "description": ""
        },

      },
      "CFM - Procedure Misinterpreted 1": {
        "label": {
          "name": "Procedure Misinterpreted - Wrong Decision",
          "description": "This applies to the situation where a procedure is incorrectly understood and, a decision is\nmade based on the crew’s misinterpretation, leading to an incorrect response to the current\nplant condition. It is also applicable to a situation where the crew has difficulty in following or\nusing the procedure. This may be due to reasons such as the complex nature of the procedure\nlogic, the ambiguity of the procedure steps, or the complicated structure of the procedure. As\nan example, the crew did not understand some of the steps in the procedure they were using\nwhile restoring a pumping system. This led to a complete shutdown of the pumping system\nand other auxiliary systems that it supports."
        },
      },
      "CFM - Procedure Step Omitted Intentionally 1": {
        "label": {
          "name": "Procedure Step Omitted Intentionally - Wrong Decision",
          "description": "This is the case where the crew is working through a procedure and they skip or postpone a\nstep or sub-step. When the crew skips a step, it implies that the crew has decided to rely on\ntheir knowledge, (i.e., mental reasoning) instead of following the procedure step by step.\nHence, they have no intention of completing it. This could be due to their lack of confidence in\nthe procedure or the belief that skipping the step will still lead them to the expected result;\nthereby saving them some time which could be used to carry out other task. When they\npostpone a step, there is an intent or plan to complete it at a later time. The crew may decide\nto postpone the procedure step because they believe that doing so will still lead them to the\nexpected outcome while spending less time on the entire procedure. Also, they may have\nsomething more important to do and they skip the step with the intent of returning to it later while believing that the expected response will still be achieved. As an example, while\nperforming a task, the crew omitted a procedure step because they believed it wasn't relevant."
        },

      },
      "CFM - Failure to Adapt Own Knowledge to the Situation 1": {
        "label": {
          "name": "Failure to Adapt Own Knowledge to the Situation - Error in Situational Assessment by Following Own Knowledge",
          "description": "This is applicable to a situation where the crew fails to adapt procedures to the situation at\nhand. It also covers cases where they fail to take proactive action, anticipate the required\nactions, re-evaluate or revise their response as the situation changes, or correctly balance\ncompeting priorities. This may be due to the ambiguity of the procedure or lack of understanding of the procedure as it applies to the particular situation. For example, the crew\ndid not revise their response due to a changing situation, hence they kept following the initial\nprocedure which was no longer applicable at that point in time."
        },
  
      },
      "CFM - Plant State Misdiagnosed by Following Own Knowledge 1": {
        "label": {
          "name": "Plant State Misdiagnosed by Following Own Knowledge - Error in Situational Assessment by Following Own Knowledge",
          "description": "This CFM applies to a situation where the crew conducts a wrong assessment of the plant\ncondition. This may be because of their lack of awareness of the current condition of the plant,\nincorrect understanding of the plant condition or failure to revise their initial concept of the\nplant condition (which was false). As an example, during a steam generator tube rupture (SGTR) event, the crew may notice that the steam generator (SG) is almost solid and decide to\ntrip the auxiliary feed water (AFW) pumps when they should not."
        },
  
      },
      "CFM - Inappropriate Strategy Chosen by Following Own Knowledge 1": {
        "label": {
          "name": "Inappropriate Strategy Chosen by Following Own Knowledge - Error in Action Decision by Following Own Knowledge",
          "description": "This is the case where the crew having made a correct assessment of the plant condition,\ndecide to take a different course of action from the expected “normal” one (i.e. they made an\nincorrect choice). It is assumed that the expected or normal course of action is the guaranteed\nsuccess path and that the alternate action may result in success or failure depending on the\ncontext. The crew’s decision to choose an alternate path may be as a result of their failure to\nconsider all options, familiarity with the chosen path or lack of clarity of the expected course\nof action. For example, when the crew notices that the safety injection system (SIS) set point\nis about to be met and they do not find any significant event such as loss of coolant accident\n(LOCA) or steam generator tube rupture (SGTR), they may manually bypass the SIS (which is\nnot an appropriate strategy at this point) to minimize the consequence due to the occurrence\nof safety injection (SI)."
        },
  
      },
      "CFM - Decision to Delay Action by Following Own Knowledge 1": {
        "label": {
          "name": "Decision to Delay Action by Following Own Knowledge - Error in Action Decision by Following Own Knowledge",
          "description": "This is applicable to the situation where the crew having conducted a correct assessment of\nthe plant state, decide not to implement the action or delay making decision (because they are\nwaiting for more information) to the extent that the response is unsuccessful even when it is\nfinally completed. As an example, during the loss of heat sink event, the crew may postpone\nthe required feed and bleed operation, even when the criteria to perform it had been met."
        },
      },
    }
}

const BP_D_Data = {
  "house_events": [],
  "basic_events": [
      "Failure of Detectors and Indicators",
      "Failure of Plant computer system",
      "Failure of Alarm Display",
      "Failure of Display Panel",
      "Failure of Monitor",
      "Failure of Monitor Processor",
      "Failure of Trasmitter 1",
      "Failure of Trasmitter 2",
      "Failure of Trasmitter 3"
  ],
  "cfms": {}
}

const BP_G_Data = {
  "house_events": [],
  "basic_events": [
    "Failure in continous run 1",
    "Failure in continous run 2",
    "Failure in continous run 3",
    "Failure in continous run 4",
    "Failure to start 1",
    "Failure to start 2",
    "Failure to start 3",
    "Failure to start 4"
],
  "cfms": {}
}

  return (
    
    <React.Fragment>
      <BreadcrumbsItem to={`/model/${id}/main-analysis/hfe/`}>
        {cfname}
      </BreadcrumbsItem>
      <Switch>
        <Route
          exact
          path="/model/:id/main-analysis/:cfid/hfe/:hfeName/cfm/:cfmName/pif/"
          render={prop => (
            <PIFsForCFM
              {...prop}
              depth={depth + 3}
              detailsProgress={progress.pif.details}
              hfe={hfeItems.find(hfe => prop.match.params.hfeName === hfe.name)}
            />
          )}
        />
        <Route
          exact
          path="/model/:id/main-analysis/:cfid/hfe/:hfeName/cfm/:cfmName/pif/:pifName/"
          render={prop => (
            <PIFForCFMRouter
              {...prop}
              depth={depth + 4}
              hfe={hfeItems.find(hfe => prop.match.params.hfeName === hfe.name)}
            />
          )}
        />
        <Route
          exact
          path="/model/:id/main-analysis/:cfid/hfe/:hfeName/cfm/:cfmName/edit/"
          render={prop => (
            <EditCFM
              {...prop}
              depth={depth + 3}
            />
          )}
        />
        <Route
          exact
          path="/model/:id/main-analysis/:cfid/hfe/:hfeName/cfm/:cfmName/quantify/"
          render={prop => (
            <CFMForHFERouter
              {...prop}
              depth={depth + 3}
              hfe={hfeItems.find(hfe => prop.match.params.hfeName === hfe.name)}
            />
          )}
        />
        <Route
          exact
          path="/model/:id/main-analysis/:cfid/hfe/:hfeName/cfm/"
          render={prop => (
            <CFMsForHFE
              {...prop}
              depth={depth + 1}
              detailsProgress={progress.cfm.details}
            />
          )}
        />
        <Route
          exact
          path="/model/:id/main-analysis/:cfid/hfe/:hfeName/cfm/:cfmName/"
          render={prop => {
            return (<CFMForHFERouter
              {...prop}
              depth={depth + 2}
              hfe={hfeItems}
              hfeItems={hfeItems}
            />)
          }}
        />
        <Route
          exact
          path="/model/:id/main-analysis/:cfid/hfe/cfm/pif/"
          render={prop => (
            <PIFs
              {...prop}
              depth={depth + 1}
              detailsProgress={progress.pif.details}
            />
          )}
        />
        <Route
          exact
          path="/model/:id/main-analysis/:cfid/hfe/cfm/"
          render={prop => (
            <CFMs
              detailsProgress={progress.cfm.details}
              {...prop}
            />
          )}
        />
        <Route
          exact
          path="/model/:id/main-analysis/:cfid/hfe/:hfeName/edit/"
          render={prop => (
            <HumanFailureEventEditorView
              {...prop}
              hfe={hfeItems.find(hfe => prop.match.params.hfeName == hfe.name)}
              disableEditing={disableEditing}
              onResponseUpdated={onResponseUpdated}
            />
          )}
        />
          <Route
            exact
            path="/model/:id/main-analysis/:cfid/hfe/:hfeName/expertedit/"
            render={prop => {
              // Extract hfeName from the route parameters
              const { hfeName } = prop.match.params;

              // Determine the data to pass based on hfeName
              const data = hfeName === 'BP_D1' || hfeName === 'BP_D2' ? BP_D_Data : hfeName === "BP_G" || hfeName === 'BP_GRec' ? BP_G_Data : eventData;

              return (
                <ExpertModeHFEEditor
                  {...prop}
                  data={data}
                  onResponseUpdated={onResponseUpdated}
                  hfe={hfeItems.find(hfe => hfeName === hfe.name)}
                />
              );
            }}
          />
        <Route
          exact
          path="/model/:id/main-analysis/:cfid/hfe/:hfeName/quantify/"
          render={prop => (
            <QuantifyHFE
              {...prop}
              depth={depth + 1}
              hfe={hfeItems.find(hfe => prop.match.params.hfeName === hfe.name)}
            />
          )}
        />
        <Route
          render={prop => (
            <ViewNoMatch
              {...prop}
              message={intl.formatMessage({ id: '404.pageNotFound' })}
            />
          )}
        />
      </Switch>
    </React.Fragment>
  );
};

export default injectIntl(HFERouter);
