import React from 'react';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { withStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

//@ts-ignore
import ApiManager from 'hcla-web-frontend-primitives/app/Api/ApiManager.ts';
//@ts-ignore
import InCardTextFieldWrapper from 'hcla-web-frontend-primitives/app/components/primitives/TextField/InCardTextFieldWrapper';
//@ts-ignore
import CreateButton from 'hcla-web-frontend-primitives/app/components/primitives/Buttons/CreateButton';
//@ts-ignore
import SortButton from 'hcla-web-frontend-primitives/app/Utils/SortButton';
import ListView from 'hcla-web-frontend-primitives/app/components/primitives/List/ListView';


import ModelEntry from './ModelEntry/ModelEntry';

const styles = (theme: Theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  containerLeft: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
  textField: {
    background: theme.palette.type === 'dark' ? 'rgba(0,0,0,0.22)' : 'rgba(255,255,255,0.88)',
    borderRadius: '4px',
    padding: '0px 4px',
  },
  input: {
    padding: '2px 4px',
  },
  listRoot: {
    width: '100%',
    maxWidth: 'unset',
  },
});

interface Props {
  classes: any,
  items: any,
  itemTotal?: number | null,
  paginationEnabled?: boolean | null,
  updateList: any,
  isLoading: boolean,
  title: any,
  filterInput: string,
  handleFilterChange: any,
  handleFilterSubmit: any,
  handleSortChange: any,
  intl: any
}

const defaultProps: Partial<Props> = {
  itemTotal: 0,
  paginationEnabled: false,
  updateList: () => {}
};

const ModelListView = (props: Props) => {
  const {
    classes,
    items,
    itemTotal,
    paginationEnabled,
    updateList,
    filterInput,
    handleFilterChange,
    handleFilterSubmit,
    handleSortChange,
    intl,
    ...others
  } = props;

  const getHeaderView = () => {
    return (
      <div className={classes.header}>
        <div className={classes.containerLeft}>
          <SortButton handleSortChange={handleSortChange} />
          <InCardTextFieldWrapper>
            <form noValidate onSubmit={handleFilterSubmit}>
              <TextField
                placeholder={intl.formatMessage({ id: 'filterByName' })}
                value={filterInput}
                onChange={handleFilterChange}
              />
            </form>
          </InCardTextFieldWrapper>
        </div>
        <Link to={`${ApiManager.MODEL_PATH}/new`}>
          <CreateButton variant="outlined" size="small">
            {intl.formatMessage({ id: 'newModel' })}
          </CreateButton>
        </Link>
      </div>
    );
  };

  return (
    <ListView
      header={getHeaderView()}
      items={items.map((item: any) => (<ModelEntry key={item.id} detail={item} />))}
      itemTotal={itemTotal}
      updateList={updateList}
      paginationEnabled={paginationEnabled}
      rootClassOverride={classes.listRoot}
      defaultText={intl.formatMessage({ id: 'noModels' })}
      {...others}
    />
  );
};

ModelListView.defaultProps = defaultProps;

export default withStyles(styles)(injectIntl(ModelListView));
