// import "./styles.css";
import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import BasicEventAccordion from "./BasicEventAccordion";
import GroupAccordion from "./GroupAccordion";
import PhoenixApiManager from "../../../../../Api/PhoenixApiManager"
import MasterButton from "./MasterButton"

const payloadData = {
  // pif: "Stress",
  // options: {
  //   "1": {
  //     "Ops C and D are in procedure": {
  //       "CFM 1": {}
  //     },
  //     "Ops B and E open valve": {
  //       "CFM 2": true,
  //       "CFM 2": {}
  //     }
  //   },
  //   "2": {
  //     "Ops C and D are in procedure": {
  //       "CFM 1": {}
  //     },
  //     "Ops B and E open valve": {
  //       "CFM 2": {},
  //       "CFM 2": {},
  //       "CFM 2": {},
  //       "CFM 2": {}
  //     }
  //   }
  // },
  // cf_map: {
  //   "1": "CF1: Judgement of situation"
  // },
  // dependency_groups: [
  //   {
  //     "1": {
  //       BP_H: {
  //         "CFM - Action on Wrong Component 1": "HSI",
  //         "CFM - Information Miscommunicated 1": "HSI"
  //       },
  //       BP_C: {
  //         "CFM - Data Not Checked with Appropriate Frequency 1": "HSI"
  //       }
  //     },
  //     "3": {
  //       BP_A: {
  //         "CFM - Action on Wrong Component 1": "HSI"
  //       }
  //     }
  //   }
  // ]
};


export default function App(props) {
  const {
    activePif,
    removePif,    
    id 
  } = props;

  const [isAdding, setIsAdding] = useState(false);
  const [groups, setGroups] = useState([
    // {
    // "  1": {
    //     BP_H: {
    //       "CFM - Action on Wrong Component 1": "HSI",
    //       "CFM - Information Miscommunicated 1": "HSI"
    //     },
    //     BP_C: {
    //       "CFM - Data Not Checked with Appropriate Frequency 1": "HSI"
    //     }
    //   },
    //   "3": {
    //     BP_A: {
    //       "CFM - Action on Wrong Component 1": "HSI"
    //     }
    //   }
    // }
  ]);

  const [focusedGroup, setFocusedGroup] = useState(0);

  const handleNewGroup = () => {
    const newGroup = [...groups, {}];
    setGroups(newGroup);
    setFocusedGroup(newGroup.length - 1);
    console.log(groups);
  };

  const focusOnGroup = (key) => {
    if (key !== focusedGroup) return setFocusedGroup(key);
    setFocusedGroup(null);
  };

  const [data, setData] = useState({
    // pif: "Stress",
    // options: {
    //   "1": {
    //     "Ops C and D are in procedure": {
    //       "CFM 1": {}
    //     },
    //     "Ops B and E open valve": {
    //       "CFM 2": {},
    //       "CFM 2": {}
    //     }
    //   },
    //   "2": {
    //     "Ops C and D are in procedure": {
    //       "CFM 1": {}
    //     }
    //   }
    // },
    // cf_map: {
    //   "1": "CF1: Judgement of situation"
    // },
    // dependency_groups: [
    //   {
    //     "1": {
    //       BP_H: {
    //         "CFM - Action on Wrong Component 1": "HSI",
    //         "CFM - Information Miscommunicated 1": "HSI"
    //       },
    //       BP_C: {
    //         "CFM - Data Not Checked with Appropriate Frequency 1": "HSI"
    //       }
    //     },
    //     "3": {
    //       BP_A: {
    //         "CFM - Action on Wrong Component 1": "HSI"
    //       }
    //     }
    //   }
    // ]
   });

  const submitGroupNumbers = () => {
    // setData({...data })
    console.log(data);
  };

  const postGetOptions = () => {
    PhoenixApiManager.getOptionsForGroups(id, activePif)
    .then((res)=> {
      console.log(res);
      setData(res.data);
    })
  }

  const postGetGroups = () => {
    PhoenixApiManager.getGroups(id, activePif)
    .then((res)=>{
      setGroups(res.data.dependency_groups)
    })
  }

  const addGroup = (payload) => {
    PhoenixApiManager.addToGroup(id, activePif, payload)
    .then((response)=>{
      // setGroups([...groups, {...payload }]);
      postGetOptions();
      postGetGroups();

        console.log(response)
      }
      )

    console.log(payload, 'this is payload');
  };


  // here we will use to send empty array for PUT request
  const deleteGroups = () => {
    const emptyGroup = {
      "dependency_groups": []
  }
    PhoenixApiManager.addToGroup(id, activePif, emptyGroup)
    .then((response)=>{
      // setGroups([...groups, {...payload }]);
      postGetOptions();
      postGetGroups();

        console.log(response)
      }
      )
  };




  useEffect(() => {
    // make an api call here to get the payload from the top
    PhoenixApiManager.getOptionsForGroups(id, activePif)
    .then((res)=> {
      console.log(res);
      setData(res.data);
    })
    // setData(payloadData);
  }, [activePif]);


  useEffect(()=>{
    PhoenixApiManager.getGroups(id, activePif)
    .then((res)=>{
      setGroups(res.data.dependency_groups)
    })
  },[activePif])

  console.log('dep data', data)

  if(Object.keys(data).length !== 0) {


  return (
    <>
      <Typography variant="h4">{activePif}</Typography>
      <Button
      onClick={()=>removePif()}
      variant="contained"
      color="secondary"
      size="large"
      >
        Return to Selection
      </Button>
      <div style={{ justifyContent: "space-between" }}>
        <div
          style={{
            display: "flex",
            margin: "0 auto",
            justifyContent: "center"
          }}
        >
          <div style={{ width: "40%" }}>
            {Object.keys(data.options).map((basicEvent) => (
              <BasicEventAccordion
                title={basicEvent}
                basicEvent={data.options[basicEvent]}
                focusedGroup={focusedGroup}
                addGroup={addGroup}
                groups={groups}
                labelMap={data.label_map}
              />
            ))}
          </div>
          <div
            style={{
              width: "60%",
              display: "flex",
              flexDirection: "column",
              paddingLeft: "20px",
              // justifyContent: "center"
            }}
          >
            <Button
              color="primary"
              variant="contained"
              style={{ width: "90%", margin: "0 auto", alignSelf: "center" }}
              onClick={handleNewGroup}
            >
              Add Group
            </Button>
            {groups.map((group, groupIndex) => (
              <GroupAccordion
                cfs={group}
                groupIndex={groupIndex}
                expanded={focusedGroup === groupIndex}
                focusOnGroup={focusOnGroup}
                labelMap={data.label_map}
              />
            ))}
            {focusedGroup !== null && (
          <Typography>
            You are currently adding to Group: {focusedGroup}
          </Typography>
          
        )}
      <MasterButton
      onClick={()=>deleteGroups()}
      variant="contained"
      color="error"
      size="medium"
      >
        Clear Groups
      </MasterButton>
          </div>
        </div>
        {/* {focusedGroup !== null && (
          <Typography>
            You are currently adding to Group: {focusedGroup}
          </Typography>
        )} */}
      </div>
    </>
  );
        }
  
  else {
    return null
  }

  return (
    <div style={{ display: "flex" }}>
      <div>
        <SimpleAccordion />
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          ></AccordionSummary>
        </Accordion>
      </div>
      <div>
        <Button style={{ width: "100%" }} variant="contained" color="primary">
          Add Group
        </Button>
        <SimpleAccordion />
      </div>
    </div>
  );

  return (
    <div className="App">
      <h1>{data.pif}</h1>
      <div style={{ display: "flex" }}>
        {Object.keys(data.dependencies).map((dependency) => {
          //dependency === 1
          const currentDependency = data.dependencies[dependency];
          // console.log(currentDependency);
          return (
            <div>
              {Object.keys(currentDependency).map((current) => {
                //current === "ops C and D"
                // console.log("this is ",current);
                return (
                  <div style={{ margin: "0 8px" }}>
                    <p style={{ border: "1px solid black" }}>{current}</p>
                    {Object.keys(currentDependency[current]).map((critical) => {
                      //critical === CFM1
                      // console.log(current, critical);
                      return (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "90%",
                            margin: "0 auto",
                            border: "solid black 1px",
                            borderRadius: "5px"
                          }}
                        >
                          <p>{critical}</p>
                          <input
                            name="value"
                            value={
                              data.dependencies[dependency][current][critical][
                                "value"
                              ] || ""
                            }
                            type="numeric"
                            style={{
                              height: "30px",
                              width: "30px",
                              borderRadius: "15px"
                            }}
                            onChange={(e) => {
                              const b = {
                                ...data,
                                dependencies: {
                                  ...data.dependencies,
                                  [dependency]: {
                                    ...data.dependencies[dependency],
                                    [current]: {
                                      ...data.dependencies[dependency][current],
                                      [critical]: {
                                        value: event.target.value
                                      }
                                    }
                                  }
                                }
                              };
                              // console.log(b);
                              setData(b);
                            }}
                          ></input>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          );
          // return <></>;
        })}
      </div>
      <button onClick={submitGroupNumbers}>Save</button>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  }
}));

function SimpleAccordion() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Accordion 1</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            malesuada lacus ex, sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Accordion 2</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            malesuada lacus ex, sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion disabled>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography className={classes.heading}>
            Disabled Accordion
          </Typography>
        </AccordionSummary>
      </Accordion>
    </div>
  );
}
