import {PhoenixFaultTreeMxGraph} from "../../../PhoenixTreeMxGraphView";
import BasicEventVertexValue
	from "hcl-web-editor/app/mxGraph/HCLTreeNode/FaultTreeNode/BasicEventNode/BasicEventVertexValue";
import PhoenixApiManager from "../../../../../../Api/PhoenixApiManager";
import BasicEventVertex from "hcl-web-editor/app/mxGraph/HCLTreeNode/FaultTreeNode/BasicEventNode/BasicEventVertex";
import {mxCell} from "mxgraph-js";
import FaultTreeTransferGateVertex
	from "hcl-web-editor/app/mxGraph/HCLTreeNode/FaultTreeNode/FaultTreeTransferGateNode/FaultTreeTransferGateVertex";
import ANDGateVertex from "hcl-web-editor/app/mxGraph/HCLTreeNode/FaultTreeNode/GateNode/ANDGateNode/ANDGateVertex";
import ORGateVertex from "hcl-web-editor/app/mxGraph/HCLTreeNode/FaultTreeNode/GateNode/ORGateNode/ORGateVertex";
import HouseEventVertex from "hcl-web-editor/app/mxGraph/HCLTreeNode/FaultTreeNode/HouseEventNode/HouseEventVertex";
import HouseEventVertexValue
	from "hcl-web-editor/app/mxGraph/HCLTreeNode/FaultTreeNode/HouseEventNode/HouseEventVertexValue";

class HumanFailureEventTraveler {

	// data structure to keep track of nodes visited
	private visited: {[key: string]: boolean};

	// data structure to keep track of where to go next
	private travelPlan: any[];

	// list of actions to perform for undo
  private undoHistory: any[]; // [selection, undoAction]

	// nodes that are saved
	private savedNodes: {
		basic_events: { [key: string]: any },
		house_events: { [key: string]: any },
		excluded_gates: string[],
		cfms: string[]
	};

	// adding feasabilty node here for the Time basic event

	private timeNode: {
		basic_events: { [key: string]: any },

	};

	private graph: PhoenixFaultTreeMxGraph;
	private root: any;
	private hfeName: any;
	private modelId: string;
	private displayInsufficientTimeDialog: boolean;

	constructor(savedNodes: any, graph: PhoenixFaultTreeMxGraph, hfeName: string, modelId: string, timeNode: any) {
		this.savedNodes = savedNodes;
		this.graph = graph;
		this.hfeName = hfeName;
		this.modelId = modelId;
		this.timeNode = timeNode;

		this.init();
	};

	clearSavedNodes() {
		this.savedNodes.basic_events = {};
		this.savedNodes.house_events = {};
		this.savedNodes.excluded_gates = [];
		this.savedNodes.cfms = [];
	}



	rules_for_PrimaryInformationNotAvailible = ["Procedures In Primary Information Source", "Primary Instrument In MCR Not Available", "Primary Instrumentation Failure", "Primary Instrument Out of MCR Not Available", "Primary Communication Instrumentation Failure", "CFM - Information Miscommunicated 1", "Incomplete or Incorrect Procedure Guidance 1", "CFM - Decision to Stop Gathering Data 1", "CFM - Collected Data Discounted 1", "CFM - Data Not Checked with Appropriate Frequency 1", "CFM - Decision to Stop Gathering Data 2", "CFM - Collected Data Discounted 2", "CFM - Data Not Checked with Appropriate Frequency 2", "CFM - Data Incorrectly Processed 1", "CFM - Key Alarm Not Responded To Intentionally 1", "CFM - Data Not Checked with Appropriate Frequency 3", "CFM - Wrong Data Source Attended To 1", "CFM - Reading Error 1", "CFM - Key Alarm Not Responded To Unintentionally 1"]

	rules_Secondary_Information_Not_Available = ["Procedures In Secondary Information Source", "Secondary Instrumentation Failure", "Secondary Instrument In MCR Not Available", "Secondary Instrument Out of MCR Not Available", "Secondary Communication Instrumentation Failure", "CFM - Information Miscommunicated 2", "CFM - Decision to Stop Gathering Data 3", "CFM - Collected Data Discounted 3", "CFM - Data Not Checked with Appropriate Frequency 4", "Incomplete or Incorrect Procedure Guidance 2", "CFM - Decision to Stop Gathering Data 4", "CFM - Data Not Checked with Appropriate Frequency 5", "CFM - Data Incorrectly Processed 2", "CFM - Collected Data Discounted 4", "CFM - Key Alarm Not Responded To Intentionally 2", "CFM - Data Not Checked with Appropriate Frequency 6", "CFM - Wrong Data Source Attended To 2", "CFM - Reading Error 2", "CFM - Key Alarm Not Responded To Unintentionally 2"]

	rules_Flag_of_Following_Own_Knowledge = ["CFM - Procedure Misinterpreted 1", "CFM - Procedure Step Omitted Intentionally 1", "CFM - Inappropriate Transfer to a Different Procedure 1", "CFM - Inappropriate Strategy Chosen by Following Procedure 1", "CFM - Decision to Delay Action by Following Procedure 1", "CFM - Failure to Adapt Written Procedure to the Situation 1", "CFM - Plant State Misdiagnosed by Following Procedure 1", "CFM - Procedure Step Omitted Intentionally 1"]


	rules_Flag_of_Following_Procedure = ["CFM - Failure to Adapt Own Knowledge to the Situation 1", "CFM - Plant State Misdiagnosed by Following Own Knowledge 1", "CFM - Inappropriate Strategy Chosen by Following Own Knowledge 1", "CFM - Decision to Delay Action by Following Own Knowledge 1"]


	rules_Flag_of_Following_Own_Knowledge_during_Primary_Information_Gathering = ["Incomplete or Incorrect Procedure Guidance 1", "CFM - Decision to Stop Gathering Data 1", "CFM - Collected Data Discounted 1", "CFM - Data Not Checked with Appropriate Frequency 1"]

	rules_Flag_of_Following_Procedure_during_Primary_Information_Gathering = ["CFM - Decision to Stop Gathering Data 2", "CFM - Collected Data Discounted 2", "CFM - Data Not Checked with Appropriate Frequency 2", "CFM - Data Incorrectly Processed 1", "CFM - Key Alarm Not Responded To Intentionally 1"]

	rules_Flag_of_Following_Own_Knowledge_during_Secondary_Information_Gathering = ["Incomplete or Incorrect Procedure Guidance 2","CFM - Decision to Stop Gathering Data 3", "CFM - Collected Data Discounted 3", "CFM - Data Not Checked with Appropriate Frequency 4"]



	rules_Flag_of_Following_Procedure_during_Secondary_Information_Gathering = ["CFM - Decision to Stop Gathering Data 4", "CFM - Data Not Checked with Appropriate Frequency 5", "CFM - Data Incorrectly Processed 2", "CFM - Collected Data Discounted 4", "CFM - Key Alarm Not Responded To Intentionally 2"]

	left_over_cfm = ["CFM - Action on Wrong Component 1", "CFM - Incorrect Timing 1", "CFM - Incorrect Operation of Component 1", "Insufficient Workforce Available for Taking the Action", "The equipment in which the action should be performed is inaccessible", "The Time Required for Completing the Task is Higher than the Available Time for the Crew"]

	clearHouseEvents() {
	
		// Check if there are any house events
		if(Object.keys(this.savedNodes.house_events).length > 0) {

			// check rules for house event trigger, and see if we need to remove any: 
			
					// if any of these CFMs are in this.savedNode.cfms then remove it: 


					let cfmsKeysExistInRules, basicEventExistInRules; // Define them once at the top
					let doublecheckcfmexist, doublecheckbasiceventexist;

					if(this.savedNodes.house_events["Primary Information Not Available"] && (this.savedNodes.cfms || this.savedNodes.basic_events)) {
						cfmsKeysExistInRules = (this.savedNodes.cfms || []).some(key => this.rules_for_PrimaryInformationNotAvailible.includes(key));
						basicEventExistInRules = Object.keys(this.savedNodes.basic_events || {}).some(key => this.rules_for_PrimaryInformationNotAvailible.includes(key));
						(basicEventExistInRules || cfmsKeysExistInRules) ? delete this.savedNodes.house_events["Primary Information Not Available"] : null;

						doublecheckcfmexist = (this.savedNodes.cfms || []).some(key => this.rules_Secondary_Information_Not_Available.includes(key));
						doublecheckbasiceventexist = Object.keys(this.savedNodes.basic_events || {}).some(key => this.rules_Secondary_Information_Not_Available.includes(key));
						(doublecheckcfmexist || doublecheckbasiceventexist) ? null : delete this.savedNodes.house_events["Primary Information Not Available"]
					}
					
					if(this.savedNodes.house_events["Secondary Information Not Available"] && (this.savedNodes.cfms || this.savedNodes.basic_events)){

						cfmsKeysExistInRules = (this.savedNodes.cfms || []).some(key => this.rules_Secondary_Information_Not_Available.includes(key));
						basicEventExistInRules = Object.keys(this.savedNodes.basic_events || {}).some(key => this.rules_Secondary_Information_Not_Available.includes(key));
						cfmsKeysExistInRules || basicEventExistInRules ? delete this.savedNodes.house_events["Secondary Information Not Available"] : null;

						doublecheckcfmexist = (this.savedNodes.cfms || []).some(key => this.rules_for_PrimaryInformationNotAvailible.includes(key));
						doublecheckbasiceventexist = Object.keys(this.savedNodes.basic_events || {}).some(key => this.rules_for_PrimaryInformationNotAvailible.includes(key));
						(doublecheckcfmexist || doublecheckbasiceventexist) ? null : delete this.savedNodes.house_events["Secondary Information Not Available"]
					}

					if(this.savedNodes.house_events["Flag of Following Own Knowledge"] && (this.savedNodes.cfms || this.savedNodes.basic_events)) {
						let cfmsKeysExistInRules = (this.savedNodes.cfms || []).some(item => this.rules_Flag_of_Following_Own_Knowledge.includes(item));
						basicEventExistInRules = Object.keys(this.savedNodes.basic_events || {}).some(key => this.rules_Flag_of_Following_Own_Knowledge.includes(key));
						(cfmsKeysExistInRules || basicEventExistInRules) ? delete this.savedNodes.house_events["Flag of Following Own Knowledge"] : null

						doublecheckcfmexist = (this.savedNodes.cfms || []).some(key => this.rules_Flag_of_Following_Procedure.includes(key));
						doublecheckbasiceventexist = Object.keys(this.savedNodes.basic_events || {}).some(key => this.rules_Flag_of_Following_Procedure.includes(key));
						(doublecheckcfmexist || doublecheckbasiceventexist) ? null : delete this.savedNodes.house_events["Flag of Following Own Knowledge"]
						
					}

					if(this.savedNodes.house_events["Flag of Following Procedure"] && (this.savedNodes.cfms || this.savedNodes.basic_events)){
						let cfmsKeysExistInRules = (this.savedNodes.cfms || []).some(key => this.rules_Flag_of_Following_Procedure.includes(key));
						basicEventExistInRules = Object.keys(this.savedNodes.basic_events || {}).some(key => this.rules_Flag_of_Following_Procedure.includes(key));
						(cfmsKeysExistInRules || basicEventExistInRules) ? delete this.savedNodes.house_events["Flag of Following Procedure"] : null

						doublecheckcfmexist = (this.savedNodes.cfms || []).some(key => this.rules_Flag_of_Following_Own_Knowledge.includes(key));
						doublecheckbasiceventexist = Object.keys(this.savedNodes.basic_events || {}).some(key => this.rules_Flag_of_Following_Own_Knowledge.includes(key));
						(doublecheckcfmexist || doublecheckbasiceventexist) ? null : delete this.savedNodes.house_events["Flag of Following Procedure"]
					}

					if(this.savedNodes.house_events["Flag of Following Own Knowledge during Primary Information Gathering"] && (this.savedNodes.cfms || this.savedNodes.basic_events)){
						let cfmsKeysExistInRules = (this.savedNodes.cfms || []).some(key => this.rules_Flag_of_Following_Own_Knowledge_during_Primary_Information_Gathering.includes(key));
						basicEventExistInRules = Object.keys(this.savedNodes.basic_events || {}).some(key => this.rules_Flag_of_Following_Own_Knowledge_during_Primary_Information_Gathering.includes(key));
						(cfmsKeysExistInRules || basicEventExistInRules) ? delete this.savedNodes.house_events["Flag of Following Own Knowledge during Primary Information Gathering"] : null


						doublecheckcfmexist = (this.savedNodes.cfms || []).some(key => this.rules_Flag_of_Following_Procedure_during_Primary_Information_Gathering.includes(key));
						doublecheckbasiceventexist = Object.keys(this.savedNodes.basic_events || {}).some(key => this.rules_Flag_of_Following_Procedure_during_Primary_Information_Gathering.includes(key));
						(doublecheckcfmexist || doublecheckbasiceventexist) ? null : delete this.savedNodes.house_events["Flag of Following Own Knowledge during Primary Information Gathering"]
					}

					if(this.savedNodes.house_events["Flag of Following Procedure during Primary Information Gathering"] && (this.savedNodes.cfms || this.savedNodes.basic_events)){
						let cfmsKeysExistInRules = (this.savedNodes.cfms || []).some(key => this.rules_Flag_of_Following_Procedure_during_Primary_Information_Gathering.includes(key));
						basicEventExistInRules = Object.keys(this.savedNodes.basic_events || {}).some(key => this.rules_Flag_of_Following_Procedure_during_Primary_Information_Gathering.includes(key));
						(cfmsKeysExistInRules || basicEventExistInRules) ? delete this.savedNodes.house_events["Flag of Following Procedure during Primary Information Gathering"] : null

						doublecheckcfmexist = (this.savedNodes.cfms || []).some(key => this.rules_Flag_of_Following_Own_Knowledge_during_Primary_Information_Gathering.includes(key));
						doublecheckbasiceventexist = Object.keys(this.savedNodes.basic_events || {}).some(key => this.rules_Flag_of_Following_Own_Knowledge_during_Primary_Information_Gathering.includes(key));
						(doublecheckcfmexist || doublecheckbasiceventexist) ? null : delete this.savedNodes.house_events["Flag of Following Procedure during Primary Information Gathering"]
					}

					if(this.savedNodes.house_events["Flag of Following Own Knowledge during Secondary Information Gathering"] && (this.savedNodes.cfms || this.savedNodes.basic_events)){
						let cfmsKeysExistInRules = (this.savedNodes.cfms || []).some(key => this.rules_Flag_of_Following_Own_Knowledge_during_Secondary_Information_Gathering.includes(key));
						basicEventExistInRules = Object.keys(this.savedNodes.basic_events || {}).some(key => this.rules_Flag_of_Following_Own_Knowledge_during_Secondary_Information_Gathering.includes(key));
						(cfmsKeysExistInRules || basicEventExistInRules) ? delete this.savedNodes.house_events["Flag of Following Own Knowledge during Secondary Information Gathering"] : null



						doublecheckcfmexist = (this.savedNodes.cfms || []).some(key => this.rules_Flag_of_Following_Procedure_during_Secondary_Information_Gathering.includes(key));
						doublecheckbasiceventexist = Object.keys(this.savedNodes.basic_events || {}).some(key => this.rules_Flag_of_Following_Procedure_during_Secondary_Information_Gathering.includes(key));
						(doublecheckcfmexist || doublecheckbasiceventexist) ? null : delete this.savedNodes.house_events["Flag of Following Own Knowledge during Secondary Information Gathering"]
					}

					if(this.savedNodes.house_events["Flag of Following Procedure during Secondary Information Gathering"] && (this.savedNodes.cfms || this.savedNodes.basic_events)){
						let cfmsKeysExistInRules = (this.savedNodes.cfms || []).some(key => this.rules_Flag_of_Following_Procedure_during_Secondary_Information_Gathering.includes(key));
						basicEventExistInRules = Object.keys(this.savedNodes.basic_events || {}).some(key => this.rules_Flag_of_Following_Procedure_during_Secondary_Information_Gathering.includes(key));
						(cfmsKeysExistInRules || basicEventExistInRules) ? delete this.savedNodes.house_events["Flag of Following Procedure during Secondary Information Gathering"] : null



						doublecheckcfmexist = (this.savedNodes.cfms || []).some(key => this.rules_Flag_of_Following_Own_Knowledge_during_Secondary_Information_Gathering.includes(key));
						doublecheckbasiceventexist = Object.keys(this.savedNodes.basic_events || {}).some(key => this.rules_Flag_of_Following_Own_Knowledge_during_Secondary_Information_Gathering.includes(key));
						(doublecheckcfmexist || doublecheckbasiceventexist) ? null : delete this.savedNodes.house_events["Flag of Following Procedure during Secondary Information Gathering"]
					}
						// Check if at least one value from left_over_cfm is present in this.savedNodes.house_events
						const isValuePresentcfm = this.left_over_cfm.some(value => this.savedNodes.cfms.hasOwnProperty(value));
						const isValuePresentbe = this.left_over_cfm.some(value => Object.keys(this.savedNodes.basic_events || {}).hasOwnProperty(value));



						if (isValuePresentcfm || isValuePresentbe) {
						// At least one value from left_over_cfm is present in this.savedNodes.house_events
						// Compare the arrays and find the elements in this.savedNodes.cfms that are not in left_over_cfm
						const differentElements = this.savedNodes.cfms.filter(element => !this.left_over_cfm.includes(element));

						if (differentElements.length === 0) {
							// There are no different elements in this.savedNodes.cfms
							console.log("No different elements:", differentElements);

							this.savedNodes.house_events = {};
						}
						} else {
						// No value from left_over_cfm is present in this.savedNodes.house_events
						console.log("No value from left_over_cfm is present in this.savedNodes.house_events.");
						}

					if(Object.keys(this.savedNodes.house_events).length && this.savedNodes.cfms.length == 0 && Object.keys(this.savedNodes.house_events).length == 0) {
						this.savedNodes.house_events = {}
					}
		
	} else {
			console.log("No house events present in savedNodes");
				}
	}
	

	init() {
		this.travelPlan = [];
		this.undoHistory = [];
		this.visited = {};

		const verticesMap = this.graph.getVerticesMap();
		this.root = verticesMap[this.graph.getRootKey()][0];
		// console.log('amg',this.travelPlan.push(...this.root.getChildren()))
		this.travelPlan.push(...this.root.getChildren());

	};


	getRoot = () => {
		return this.root;
	};

	getNode = (key: string) => {
		if(key == "wPBAi37o3") return null
		const vertices = this.graph.getVerticesMap()[key];
		if (vertices) return vertices[0];

		//Fallback for old models
		const findByLabelName = Object.values(this.graph.getVerticesMap()).find(x => x[0].value.label.name === key);
		// console.log(findByLabelName, 'get node function')
		if (findByLabelName) return findByLabelName[0];
		else return null;
	};
	//updated code to handle Basic events with BBN
	setBasicEvent = (key: string, value: { failure_probability: number }) => {
		console.log("this is the key in basic event", key);
		if ((!(key.includes("CFM")) || key.split(" ").length == 1)) {
		  console.log("I am in the set basic event", this.savedNodes);
		  this.savedNodes.basic_events[key] = value;
		  const be = this.getNode(key);
	
		  (be.getValue() as BasicEventVertexValue)
			.getExpression()
			.setValue(value.failure_probability);
		  this.graph.getSelectionModel().addCell(be);
		  this.graph.rerender(be);
		} else {
		  this.setCFM(key);
		}
	  };
	
	  setBasicEventWithExpressionLink = (key: string) => {

		const be = this.getNode(key);
	  (be.getValue() as BasicEventVertexValue).getExpression();
		(be.getValue() as BasicEventVertexValue).getExpression();
		this.graph.getSelectionModel().addCell(be);
		this.graph.rerender(be);
	  };
	
	unsetBasicEvent = (key: string) => {
		if (!this.isBasicEventSet(key)) return;
		const be = this.getNode(key);
		delete this.savedNodes.basic_events[key];
		this.graph.getSelectionModel().removeCell(be);
	};

	isBasicEventSet = (key: string) => {
		return Boolean(this.savedNodes.basic_events[key]);
	};

	setCFM = (key: string) => {
		this.savedNodes.cfms.push(key);
		const cfm = this.getNode(key);
		this.graph.getSelectionModel().addCell(cfm);
		this.graph.rerender(cfm);
	};

	unsetCFM = (key: string) => {
		if (!this.isCFMSet(key)) return;
		const cfm = this.getNode(key);
		this.savedNodes.cfms = this.savedNodes.cfms.filter(cfm => cfm !== key);
		this.graph.getSelectionModel().removeCell(cfm);
	};

	isCFMSet = (key: string) => {
		return Boolean(this.savedNodes.cfms.includes(key));
	};

	savedNodesAreEmpty = () => {
		const {basic_events, house_events, excluded_gates, cfms} = this.savedNodes;
		console.log(this.savedNodes, 'savednodes in function', Object.keys(basic_events).length === 0
		&& Object.keys(house_events).length === 0
		&& excluded_gates.length === 0
		&& cfms.length === 0)
		try {
			return Object.keys(basic_events).length === 0
				&& Object.keys(house_events).length === 0
				&& excluded_gates.length === 0
				&& cfms.length === 0;
		} catch (e) {
			return true;
		}
		return false;
	};


	save = () => {
	
		const payload = {
			name: this.hfeName,
			nodes: this.savedNodes,
		};
		if(this.timeNode){
			payload.nodes.basic_events['wPBAi37o3'] = {"failure_probability": Number(this.timeNode)}
		}
		if (!this.savedNodesAreEmpty()) {
			return PhoenixApiManager.postHFEForModelWithId(this.modelId, JSON.stringify(payload))
				.then(response => response.status === 201 && response.json())
				.then((data) => this.savedNodes = data.nodes);
		}
	};


	newSave = () => {
		const payload = {
			name: this.hfeName,
			nodes: this.savedNodes,
		};

		if (!this.savedNodesAreEmpty()) {
			return PhoenixApiManager.postHFEForModelWithId(this.modelId, JSON.stringify(payload))
				.then(response => response.status === 201 && response.json())
				.then((data) => this.savedNodes = data.nodes);
		}
	};

	getSummary = () => {

		this.clearHouseEvents()
		const savedCFMs = this.savedNodes.cfms.map((key: string) => this.getNode(key).value.label.name);
		const savedHouseEvents = Object.keys(this.savedNodes.house_events).map((key: any) => this.getNode(key).value.label.name);
		const savedBasicEvents = Object.keys(this.savedNodes.basic_events).map((key: any) => this.getNode(key)?.value?.label?.name);
		
		return {
			"CFMs": savedCFMs,
			"House Events": savedHouseEvents,
			"Basic Events": savedBasicEvents
		};
	};

	getDisplayInsufficientTimeDialog = () => {
		return this.displayInsufficientTimeDialog;
	};

	setDisplayInsufficientTimeDialog = (show: boolean) => {
		this.displayInsufficientTimeDialog = show;
	};

	getCurrentSelection = () => {
		const currentSelection = this.travelPlan[0];
		if (currentSelection && !Array.isArray(currentSelection)) {
			const isParentTheRoot = this.travelPlan[0].edges[0].source.key === this.root.key;
			const isBasicEvent = this.travelPlan[0] instanceof BasicEventVertex;
			this.setDisplayInsufficientTimeDialog(isParentTheRoot && isBasicEvent);
		}

		return currentSelection;
	};

	addPlan = (children: mxCell[]) => {
		const toggleables: (BasicEventVertex | FaultTreeTransferGateVertex)[] = []; // Push Basic Events/CFMs here
		const nonToggleables: (ANDGateVertex | ORGateVertex)[] = []; // Placeholder for next queue (children)

		children.map(child => {
			if (child instanceof BasicEventVertex || child instanceof FaultTreeTransferGateVertex) toggleables.push(child);
			else nonToggleables.push(child);
		});

		this.travelPlan.unshift(...nonToggleables);
		if (toggleables.length > 0) this.travelPlan.unshift(toggleables);

		return [toggleables, nonToggleables];

	};

	next = (answer: boolean) => {

		const currentSelection = this.getCurrentSelection();

		const isAndGate = currentSelection instanceof ANDGateVertex;
		const isOrGate = currentSelection instanceof ORGateVertex;

		const currentPlan = this.travelPlan.shift();

		let toggleables = [];
		let nontoggleables = [];
		let childrenHouseEvents: HouseEventVertex[] = [];

		// answer is YES
		if (answer) {
			if (isAndGate || isOrGate) {
				// keep track of the houseEvents
				const hasSomeHouseEvents = currentSelection.getChildren().some((child: any) => child instanceof HouseEventVertex);
				if (isOrGate && hasSomeHouseEvents) {
					// Filter out House Events
					const filteredChildren = currentSelection.getChildren().filter((child: any) => !(child instanceof HouseEventVertex));
					// here we will add the children and later on check the rules to see if we need to filter them out

					childrenHouseEvents = currentSelection.getChildren().filter((child: any) => child instanceof HouseEventVertex);
					if (childrenHouseEvents.length > 0) {
						childrenHouseEvents.forEach((houseEvent: HouseEventVertex) => {
							const key = houseEvent.getKey();
							const value = (houseEvent.getValue() as HouseEventVertexValue).getConstant().getValue();
							this.savedNodes.house_events[key] = {constant: value};
						});
					}

					[toggleables, nontoggleables] = this.addPlan(filteredChildren);
				} else {
          [toggleables, nontoggleables] = this.addPlan(currentSelection.getChildren());
				}
			} 
		}
		// answer is NO
		else {
			if (isOrGate) {

				// keep track of the house events 
				childrenHouseEvents = currentSelection.getChildren().filter((child: any) => child instanceof HouseEventVertex);
				// If Immediate Children has a House Event
				// select all and skip rest of children
				if (childrenHouseEvents.length > 0) {
					childrenHouseEvents.forEach((houseEvent: HouseEventVertex) => {
						const key = houseEvent.getKey();
						const value = (houseEvent.getValue() as HouseEventVertexValue).getConstant().getValue();
						this.savedNodes.house_events[key] = {constant: value};
					});
				}
			}

			// recursively find all basic events and cfms
			// and remove them from saved nodes
			this.removeBasicEventsAndCFMsFromSavedNodes(currentSelection);

		}

		// update visited
		(Array.isArray(currentSelection) ? currentSelection : [currentSelection]).forEach(selection => {
			this.visited[selection.getKey()] = true;
		});

		// update undoHistory
		this.undoHistory.push([currentSelection, () => {
      if (toggleables.length > 0) this.travelPlan.splice(0, 1);
      this.travelPlan.splice(0, nontoggleables.length);
		  this.travelPlan.unshift(currentPlan);
			if (childrenHouseEvents.length > 0) {
				childrenHouseEvents.forEach((houseEvent: HouseEventVertex) => {
					const key = houseEvent.getKey();
					delete this.savedNodes.house_events[key];
				});
			}
			(Array.isArray(currentSelection) ? currentSelection : [currentSelection]).forEach(selection => {
				delete this.visited[selection.getKey()];
			});
    }]);

	};

	removeBasicEventsAndCFMsFromSavedNodes = (cell: ANDGateVertex | ORGateVertex) => {
		cell.getChildren().forEach(child => {

			if (child instanceof HouseEventVertex) return;

			if (child instanceof BasicEventVertex) {
				this.unsetBasicEvent(child.getKey());
				return;
			}

			if (child instanceof FaultTreeTransferGateVertex) {
				this.unsetCFM(child.getKey());
				return;
			}

			this.removeBasicEventsAndCFMsFromSavedNodes(child as ANDGateVertex | ORGateVertex);

		});
	};

	// only undo travel plan and house event values
	// basic event and cfm values are preserved
	undo = () => {

	  if (this.isUndoHistoryEmpty()) {
	    console.error("there is nothing to undo");
	    return false;
    }

	  const [_, action] = this.undoHistory.pop();

	  action();

	  return true;

  };

	// perform undo until current selection's key is key
	undoUntilKey(key: string) {

		if (!this.visited[key]) {
			console.error("Error: can't undo to a node that was not visited");
			return false;
		}

		while (this.undoHistory.length > 0) {
			const [topUndoSelection, _] = this.undoHistory[this.undoHistory.length - 1];
			this.undo();

			// topUndoSelection is a list of basic events or transfer gates
			if (Array.isArray(topUndoSelection) &&
				topUndoSelection.some(selection => selection.getKey() === key)) return;

			// topUndoSelection is a gate
			if (!Array.isArray(topUndoSelection) && topUndoSelection.getKey() === key) return;

		}

		return true;

	};

	isUndoHistoryEmpty = () => {
		return this.undoHistory.length === 0;
	};

	isVisited = (key: string) => {
		return Boolean(this.visited[key]);
	};

}

export default HumanFailureEventTraveler;
