import {
  mxGraphLayout as MxGraphLayout,
  mxGeometry as MxGeometry,
  mxConstants as MxConstants,
  mxEdgeStyle as MxEdgeStyle,
  mxStyleRegistry as MxStyleRegistry,
  mxPoint as MxPoint,
} from 'mxgraph-js';

class Phoenix2ScenarioLayout extends MxGraphLayout {
  constructor(graph) {
    super(graph);
    this.applyVertexLayout(graph);
  }

  applyVertexLayout(graph) {
    const vertices = graph.getVertices();

    const startX = 0;
    const startY = 0;
    const gapX = 200;
    const gapY = 150;

    const determineCoordinates = (positions) => {
      const coordinates = {};

      const edgeCaseVerticesCount = {
        BP_G: 0,
        ES_3: 0,
        BP_D2: 0,
        BP_GRec: 0,
        BP_J: 0,
        EF_8_0: 0,
      };

      const verticesHash = vertices.reduce((obj, curr) => {
        if (curr.key) {
          obj[curr.key] = curr;
          if (curr.key.includes('ES_3')) {
            edgeCaseVerticesCount['ES_3']++;
          }
          if (curr.key.includes('BG_G')) {
            edgeCaseVerticesCount['BP_G']++;
          }
          if (curr.key.includes('BG_GRec')) {
            edgeCaseVerticesCount['BP_GRec']++;
          }
          if (curr.key.includes('BP_K')) {
            edgeCaseVerticesCount['BP_K']++;
          }
          if (curr.key.includes('BP_J')) {
            edgeCaseVerticesCount['BP_J']++;
          }
          if (curr.key.includes('EF_8_0')) {
            edgeCaseVerticesCount['EF_8_0']++;
          }
          if (edgeCaseVerticesCount.hasOwnProperty(curr.key)) {
            edgeCaseVerticesCount[curr.key]++;
          }
        }
        return obj;
      }, {});

      if (Object.keys(verticesHash).length === 1) {
        return {
          'Initiating Event': [0, 0],
        };
      }

      const assignCoordinates = (vertexKey, coordinates, x, y) => {
        //source properties
        const { key, edges } = verticesHash[vertexKey];

        let offsetY = 0;
        coordinates[key] = [x, y + offsetY];

        const connections = edges.reduce(
          (coll, { value, source, target }) => {
            const targetIsBP = target.key.includes('BP');
            const targetIsESEF =
              target.key.includes('ES') || target.key.includes('EF');

            if (targetIsBP && source.key === vertexKey) {
              if (value === 'success') {
                coll.successBPs.push(target);
              } else {
                coll.failureBPs.push(target);
              }
            }

            if (targetIsESEF && source.key === vertexKey) {
              if (value === 'success') {
                coll.successESEFs.push(target);
              } else {
                coll.failureESEFs.push(target);
              }
            }

            return coll;
          },
          {
            successBPs: [],
            failureBPs: [],
            successESEFs: [],
            failureESEFs: [],
          }
        );

        const successBPCount = connections.successBPs.length;
        const successsESEFsCount = connections.successESEFs.length;
        const failureESEFsCount = connections.failureESEFs.length;
        const failureBPsCount = connections.failureBPs.length;

        const outgoingEdgesCount =
          successBPCount +
          successsESEFsCount +
          failureESEFsCount +
          failureBPsCount;

        if (!outgoingEdgesCount) {
          return 0;
        }

        const handleEdgeCases = () => {
          if (verticesHash['EF_2']) {
            if (vertexKey === 'BP_D2') {
              y += 1;
            }
            if (
              vertexKey === 'BP_G' &&
              !edgeCaseVerticesCount['BP_D2']
            ) {
              y += 1;
            }
          }
          if (vertexKey.includes('BP_I')) {
            const loop = Number(vertexKey[vertexKey.length - 1]);
            if (
              edgeCaseVerticesCount['ES_3'] &&
              loop <= edgeCaseVerticesCount['ES_3']
            ) {
              y += 1;
            }
          }
          if (vertexKey.includes('BP_G')) {
            if (edgeCaseVerticesCount['BP_GRec']) {
              y += 1;
            }
          }
          if (vertexKey.includes('BP_GRec')) {
            if (
              edgeCaseVerticesCount['BP_G'] &&
              edgeCaseVerticesCount['EF_8_0']
            ) {
              y += 2;
            }
          }
          if (vertexKey.includes('BP_HRec')) {
            if (edgeCaseVerticesCount['BP_J']) {
              y += 1;
            }
          }
        };

        handleEdgeCases();

        connections.successBPs.forEach((branchPoint) => {
          assignCoordinates(branchPoint.key, coordinates, x + 1, y);
        });

        connections.successESEFs.forEach((branchPoint) => {
          assignCoordinates(branchPoint.key, coordinates, x + 1, y);
        });

        if (connections.failureESEFs.length > 0) {
          offsetY += 1;
        }

        connections.failureESEFs.forEach((branchPoint) => {
          offsetY += assignCoordinates(
            branchPoint.key,
            coordinates,
            x,
            y + 1
          );
        });

        if (connections.failureBPs.length > 0) {
          offsetY += 1;
        }

        connections.failureBPs.forEach((branchPoint) => {
          offsetY += assignCoordinates(
            branchPoint.key,
            coordinates,
            x,
            y + offsetY
          );
        });

        return offsetY;
      };

      assignCoordinates('Initiating Event', coordinates, 0, 0, {});
      return coordinates;
    };

    const determineGeometry = (coordinates) => {
      const initiatingEvent = {
        x: vertices[0].geometry.startX,
        y: vertices[0].geometry.startY,
      };

      vertices.forEach((vertex) => {
        if (coordinates[vertex.key]) {
          if (vertex.key === 'Initiating Event') {
            vertex.geometry.x = startX - 14;
            vertex.geometry.y = startY - 20;
          } else {
            vertex.geometry.x =
              startX + coordinates[vertex.key][0] * gapX;
            vertex.geometry.y =
              startY + coordinates[vertex.key][1] * gapY;
          }
        }
      });
    };

    const determineLabelGeometry = () => {
      vertices.forEach((vertex) => {
        const outgoingEdges = vertex.getOutgoingEdges();
        outgoingEdges.forEach((edge) => {
          edge.geometry.relative = true;

          if (edge.value === 'success') {
            edge.geometry.offset = new MxPoint(25, 0);
          }

          if (edge.value === 'failure') {
            edge.geometry.offset = new MxPoint(5, -20);
          }
        });
      });
    };

    determineGeometry(determineCoordinates({}));
    determineLabelGeometry();
  }
}

export default Phoenix2ScenarioLayout;
