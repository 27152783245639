import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { BreadcrumbsProvider } from 'react-breadcrumbs-dynamic';

import { SharedSnackbarProvider } from 'hcla-web-frontend-primitives/app/components/primitives/Snackbar/SharedSnackbar.context';
import { SharedIntlProvider } from 'hcla-web-frontend-primitives/app/components/primitives/Intl/SharedIntlProvider';
import getMessageObj from 'hcla-web-frontend-primitives/app/components/primitives/Intl/messages';
import enPrimitiveMessages from 'hcla-web-frontend-primitives/app/translations/en.json';
import jaPrimitiveMessages from 'hcla-web-frontend-primitives/app/translations/ja.json';
import PreferencesProvider from "hcla-web-frontend-primitives/app/config/PreferencesProvider";

import enHCLMessages from 'hcl-web-editor/app/translations/en.json';
import jaHCLMessages from 'hcl-web-editor/app/translations/ja.json';

import './css/index.css';
import ApiProvider from "hcla-web-frontend-primitives/app/components/primitives/Context/ApiContext";
import ThemeSettingsProvider from 'hcla-web-frontend-primitives/app/config/ThemeSettingsProvider';
import PhoenixApiManager from "./Api/PhoenixApiManager";
import Root from './Root';

import enMessages from './translations/en.json';
import jaMessages from './translations/ja.json';

import AmberTheme from './config/themes/AmberTheme';

const render = (Component: React.FunctionComponent<{}> | JSX.IntrinsicAttributes) => {
  ReactDOM.render(
    <ApiProvider
      ApiManagerClass={PhoenixApiManager}
    >
      <PreferencesProvider>
        <ThemeSettingsProvider
          themeOptions={[AmberTheme]}
        >
          <CssBaseline />
          <SharedIntlProvider
            messages={getMessageObj(
              {
                ...enMessages,
                ...enHCLMessages,
                ...enPrimitiveMessages,
              }, {
                ...jaMessages,
                ...jaHCLMessages,
                ...jaPrimitiveMessages,
              },
            )}
          >
            <BreadcrumbsProvider>
              <SharedSnackbarProvider>
                <Component />
              </SharedSnackbarProvider>
            </BreadcrumbsProvider>
          </SharedIntlProvider>
        </ThemeSettingsProvider>
      </PreferencesProvider>
    </ApiProvider>,
    document.getElementById('root'),
  );
};

render(Root);
