import React from 'react';
import { withStyles, Theme } from '@material-ui/core/styles';

import ListView from 'hcla-web-frontend-primitives/app/components/primitives/List/ListView';
import { injectIntl } from 'react-intl';
import CFMEntry from './CFMEntry/CFMEntry';
import {CFMDetailsProgress, HFEType} from '../../../Types';
import Typography from '@material-ui/core/Typography';

const styles = (theme: Theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  containerLeft: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
  textField: {
    background: theme.palette.type === 'dark' ? 'rgba(0,0,0,0.22)' : 'rgba(255,255,255,0.88)',
    borderRadius: '4px',
    padding: '0px 4px',
  },
  input: {
    padding: '2px 4px',
  },
  listRoot: {
    width: '100%',
    maxWidth: 'unset',
    marginBottom: theme.spacing(1) * 2,
  },
});

interface Props {
  classes: { [key: string]: any },
  intl: { [key: string]: any}
  items: HFEType[],
  isLoading: boolean,
  title: any,
  filterInput: string,
  handleFilterChange: (e: any) => void,
  handleFilterSubmit: (e: any) => void,
  handleSortChange: (e: any) => void,
  match: { [key:string]: any },
  location: { [key:string]: any },
  depth: number,
  detailsProgress: CFMDetailsProgress[],
}
const CFMListView = (props: Props) => {
  const {
    depth, classes, items, match: { params: { id } }, intl, detailsProgress, ...others
  } = props;
  const getProgress = (hfeName: string, cfmName: string) => {
    return detailsProgress.find(item => item.hfe === hfeName && item.name === cfmName);
  };
  return (
    <React.Fragment>
      {items.map(item => (
        <ListView
          {...others}
          title={<Typography variant="h5">{item.label.name}</Typography>}
          items={(Object.values(item.cfms).map(cfm => (
            <CFMEntry
              key={cfm.name}
              cfmName={cfm.name}
              cfmLabel={cfm.label.name}
              hfeName={item.name}
              hfeLabel={item.label.name}
              modelId={id}
              progress={getProgress(item.name, cfm.name)}
            />
          )))}
          rootClassOverride={classes.listRoot}
          defaultText={intl.formatMessage({ id: 'analysis.cfmListView.defaultText' })}
        />
      ))}
    </React.Fragment>
  );
};

export default withStyles(styles)(injectIntl(CFMListView));
