import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { withStyles, Theme } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Typography from '@material-ui/core/Typography';
import { injectIntl } from 'react-intl';

import ApiManager from 'hcla-web-frontend-primitives/app/Api/ApiManager';

import CircularProgressIcon from '../../../../../primitives/Progress/CircularProgressIcon';
import GreenButton from '../../../../../primitives/Button/GreenButton';
import { EntryProgress, EntryDefaultProgress } from '../../../../Types';
import HFEChip from '../../../../../primitives/Chip/HFEChip';
//@ts-ignore
import PrimaryButton from "hcla-web-frontend-primitives/app/components/primitives/Buttons/PrimaryButton";

const styles = (theme: Theme) => ({
  root: {
    padding: `${theme.spacing(1) * 2}px`,
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginRight: `${theme.spacing(1)}px`,
    },
    '& :first-child': {
      marginLeft: 'unset',
    },
  },
  actionsContainer: {
    '& > *': {
      marginRight: `${theme.spacing(1) * 2}px`,
    },
  },
  textBox: {
    paddingRight: '500px',
  },
  secondaryTypographyProps: {
    overflow: 'hidden',
    whiteSpace: 'normal',
    textOverflow: 'ellipsis',
    '& > *': {
      margin: `${theme.spacing(1) / 4}px`,
    },
  },
  hfeLink: {
    maxWidth: '100%'
  }
});

interface Props {
  classes: { [key: string]: any },
  intl: { [key: string]: any },
  modelId: number,
  cfmName: string,
  hfeName: string,
  hfeLabel: string,
  cfmLabel: string,
  progress: EntryProgress,
}

const defaultProps: Partial<Props> = {
  progress: EntryDefaultProgress,
};

const CFMEntry = (props: Props) => {
  const {
    classes, cfmName, hfeName, cfmLabel, hfeLabel, modelId, intl, progress,
  } = props;
  const primaryText = (
    <Typography noWrap variant="h6" color="textPrimary">{cfmLabel}</Typography>
  );

  const hfePathBasename = `${ApiManager.MODEL_PATH}/${modelId}/main-analysis/hfe/${hfeName}/`;
  const cfmPathBasename = `${hfePathBasename}cfm/${cfmName}/`;

  const hfeLink = itemProps => <Link to={{ pathname: hfePathBasename }} {...itemProps} />;
  const cfmLink = itemProps => <Link to={{ pathname: cfmPathBasename }} {...itemProps} />;

  const secondaryText = (
    <div className={classes.secondaryTypographyProps}>
      <HFEChip name="HFE" link={hfeLink} label={hfeLabel} className={classes.hfeLink} />
    </div>
  );

  const pif = itemProps => (<Link to={{ pathname: `${cfmPathBasename}pif/` }} {...itemProps} />);
  const quantify = itemProps => (<Link to={{ pathname: `${cfmPathBasename}quantify/` }} {...itemProps} />);

  return (
    <ListItem
      button
      component={cfmLink}
      alignItems="center"
      className={classes.root}
      divider
    >
      <ListItemAvatar>
        <CircularProgressIcon progress={progress} checkmark />
      </ListItemAvatar>
      <ListItemText
        disableTypography
        className={classes.textBox}
        primary={primaryText}
        secondary={secondaryText}
      />
      <ListItemSecondaryAction className={classes.actionsContainer}>
        <GreenButton
          tooltipTitle={intl.formatMessage({ id: 'quantify' })}
          component={quantify}
        >
          {intl.formatMessage({ id: 'quantify' })}
        </GreenButton>
        <PrimaryButton
          tooltipTitle={intl.formatMessage({ id: 'analysis.pif' })}
          component={pif}
          color="secondary"
        >
          {intl.formatMessage({ id: 'analysis.pif' })}
        </PrimaryButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

CFMEntry.defaultProps = defaultProps;

export default withStyles(styles)(withRouter(injectIntl(CFMEntry)));
