import React, { FunctionComponent, useEffect, useState } from "react";
import PhoenixApiManager from "../../../../../Api/PhoenixApiManager";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import InboxIcon from "@material-ui/icons/Inbox";
import DraftsIcon from "@material-ui/icons/Drafts";
import Dependency from './Dependency';

type DependencyPifSelectionProps = {
  details: any;
  location: any;
  id: number;
  template: number;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
  })
);

const DependencyPifSelection: FunctionComponent<DependencyPifSelectionProps> = (
  props
) => {
  const {
    details,
    location,
    match: {
      params: { id },
    },
  } = props;

  const [pifs, setPifs] = useState([]);
  const classes = useStyles();
  const [selectedIndex, setSelectedIndex] = useState(1);
  const [activePif, setActivePif] = useState(null);

  const removePif = () => {
    setActivePif(null)
  }

  useEffect(() => {
    PhoenixApiManager.openMainModel(id).then((res) => {
      const templateID = res.data.template;
      PhoenixApiManager.getTemplateTree(templateID).then((res) => {
        setPifs(Object.keys(res.data.questionnaire.pif));
        console.log(Object.keys(res.data.questionnaire.pif));
      });
    });
  }, []);

  console.log(pifs, "dep");

  if(!activePif){

   return (
    <>
      {pifs.map((pif) => (
        <div className={classes.root}>
          <List component="nav" aria-label="main mailbox folders">
            <ListItem
              button
              selected={selectedIndex === 0}
              onClick={()=> setActivePif(pif)}
            >
              {pif}
            </ListItem>
          </List>
        </div>
      ))}
    </>
  );
}
else {
  return <Dependency 
            activePif={activePif}
            removePif={removePif}
            id={id}
          />
}

};

export default DependencyPifSelection;
