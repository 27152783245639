import React, {FunctionComponent} from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import PageAuth from './components/Auth/PageAuth';
import MainContainer from './components/NavBar/MainContainer';

const Root: FunctionComponent<{}> = (props) => {

  return (
    <Router basename={process.env.APP_BASEPATH || '/'}>
      <Switch>
        <Route exact path="/auth" render={(rProps) => <PageAuth {...rProps} />} />
        <Route path="/" render={(rProps) => <MainContainer {...rProps} />} />
      </Switch>
    </Router>
  );
};

export default Root;
