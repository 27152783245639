import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import Button from '@material-ui/core/Button/Button';

const ButtonWithTooltip = (props) => {
  const {
    classes, tooltipTitle, children, ...others
  } = props;
  return (
    <Tooltip title={tooltipTitle}>
      <Button
        size="large"
        variant="outlined"
        className={classes.root}
        aria-label={tooltipTitle}
        {...others}
      >
        {children}
      </Button>
    </Tooltip>
  );
};

ButtonWithTooltip.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  children: PropTypes.instanceOf(Object).isRequired,
  tooltipTitle: PropTypes.string.isRequired,
};

export default ButtonWithTooltip;
