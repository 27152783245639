//This file is for havig the case in terms of getting quantification view for single
//CF
//the main quantification is creating a list of cf related to the crm
//In this file, all the ids are refered as the cf id
import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import {InjectedIntlProps, injectIntl} from 'react-intl';
import {RouteComponentProps} from "react-router";

import {QuantificationResultJSON} from "hcl-web-editor/app/components/Model/Quantification/QuantificationResultJSON";
import StatsTable, { StatsData } from "hcl-web-editor/app/components/Model/QuantificationResult/Chips/StatsTable";

import PhoenixApiManager from '../../../../Api/PhoenixApiManager';
import QuantificationCard from './QuantificationCard';
import {PhoenixRouteParams} from "../../../NavBar/MainContainer";

const percentile = require("percentile");

const mean = (nums: number[]): number => {
  return nums.reduce((a, b) => (a + b)) / nums.length;
};

export const computeHEPFromData = (data: QuantificationResultJSON, failureKey='SQ_F.0') => {
  let accumErrorP5 = 0.0;
  let accumErrorP50 = 0.0;
  const accumErrorP50Arr = []
  let accumErrorP95 = 0.0;
  let accumMean = 0.0;
  let uncertaintyDetected = false;
  try {
    const { result_data: { sequences } } = data;
    Object.entries(sequences).forEach(([endstateKey, endstateObj]) => {
      const keyToCheck = endstateKey.split('.')[0];
      if (endstateKey.includes(failureKey) && !['SQ_EF_4', 'SQ_EF_7'].some(substring => keyToCheck.startsWith(substring))) {
        if (Array.isArray(endstateObj.result)) {
          uncertaintyDetected = true;
          const p5 = percentile(5, endstateObj.result);
          const p50 = percentile(50, endstateObj.result);
          const avg = mean(endstateObj.result);
          accumMean += avg;
          const p95 = percentile(95, endstateObj.result);
          accumErrorP5 += p5;
          accumErrorP50 += p50;
          accumErrorP95 += p95;
        } else {
          accumErrorP50 += endstateObj.result;
          accumErrorP50Arr.push(1 - endstateObj.result);
        }
      }
    });
  } catch (e) {
    return null;
  }
  let dataArr: StatsData[];
  if (uncertaintyDetected) {
    dataArr = [
      {
        title: 'Mean',
        text: accumMean,
        label: (accumMean).toExponential(3),
      },
      {
        title: 'Median',
        text: accumErrorP50,
        label: (accumErrorP50).toExponential(3),
      },
      {
        title: '5th',
        text: accumErrorP5,
        label: (accumErrorP5).toExponential(3),
      },
      {
        title: '95th',
        text: accumErrorP95,
        label: (accumErrorP95).toExponential(3),
      },
    ];
  } else {
    const value = 1 - accumErrorP50Arr.reduce(
      (acc, curr)=> acc*(curr)
      ,1)
    dataArr = [
      {
        title: 'Best Estimate',
        text: value,
        label: (value).toExponential(3),
      },
    ];
  }
  // return <StatsTable data={dataArr} />
  return 'Total HEP = ' + dataArr[0].label
};

interface QuantificationViewProps extends RouteComponentProps<PhoenixRouteParams>, InjectedIntlProps {}

interface QuantificationViewState {
  trimmedCRT: {
    id: string;
    loading: boolean;
  };
  quantificationResult: {
    data: any;
    loading: boolean;
    cfName:any;
  };
  cfName:any;
  
}

class SingleCfQuantificationView extends React.Component<QuantificationViewProps, QuantificationViewState> {
  constructor(props: QuantificationViewProps) {
    super(props);
    this.state = {
      trimmedCRT: {
        id: null,
        loading: true
      },
      quantificationResult: {
        data: {},
        loading: false
      },
      cfName: null
    };
  }

  componentDidMount() {
    this.fetchTrimmedCRTId();
    this.getCfName();
  }


  getCfName=()=>{
    const { match: { params: { cfid } } } = this.props;
    PhoenixApiManager.getCfFunctionDetails(cfid)
    .then(response=>response.status===200 && response.json())
    .then((data)=>{
      this.setState({cfName:data.title})
    })
  };


  fetchTrimmedCRTId = () => {
    const { match: { params: { id,cfid } } } = this.props;
    PhoenixApiManager.getPhoenixModelWithId(cfid)
      .then(response => response.status === 200 && response.json())
      .then((data) => {
        const treeId = data.model_data.crt.trimmed_crt_id;
        this.setState({
          trimmedCRT: {
            id: treeId,
            loading: false
          },
        });
      });
  };

  quantifyCRT = (configuration: any) => {
    const { match: { params: { id,cfid } } } = this.props;
    this.setState({
      quantificationResult: {
        data: {},
        loading: true
      },
    }, () => {
      const { trimmedCRT } = this.state;
      PhoenixApiManager.quantifyPhoenixTreeForModelWithId(trimmedCRT.id, cfid, configuration)
        .then(response => response.status === 201 && response.json())
        .then((data) => {
          this.setState({
            quantificationResult: {
              data,
              loading: false,
              //@ts-ignore
              loaded: true,
            },
          });
        });
    });
  };


  render() {

    const { match: { params: { id,cfid } }, intl } = this.props;
    const { quantificationResult, trimmedCRT, cfName } = this.state;

    return (
      <React.Fragment>
        <BreadcrumbsItem to={`/model/${id}/${cfid}/detailed-quantification`}>
          {intl.formatMessage({ id: 'quantification' })}
        </BreadcrumbsItem>
        {/* <h1>{this.state.cfName}</h1> */}
        <QuantificationCard
          loading={quantificationResult.loading}
          data={quantificationResult.data}
          onRequestQuantify={(configurations: any) => this.quantifyCRT(configurations)}
          treeId={trimmedCRT.id && trimmedCRT.id}
          quantifyDisabled={!trimmedCRT.id}
          cfid={cfid}
          title={cfName}
          computeErrorFunc={(data, failureKey) => computeHEPFromData(data, 'EF_')}
        />
      </React.Fragment>
    );
  }
}

export default injectIntl(SingleCfQuantificationView);
