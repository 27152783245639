import React from 'react';

import PhoenixApiManager from '../../../../../../Api/PhoenixApiManager';

import PIFViewer from './PIFViewer';
import PhoenixModelContext from '../../../../PhoenixModelContext';
import {HFEType} from "../../../../Types";

interface Props {
  match: { [key: string]: any },
  onResponseUpdated: () => void,
  hfe: HFEType
}

interface State {
  template: {
    pif: { [key: string]: any },
    loading: boolean,
    loaded: boolean,
  },
  pifData: {
    score: any,
    questions: { [key: string]: any },
    override: boolean,
    expression?: { [key: string]: any },
    loading: boolean,
  },
}

class SinglePIFView extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      template: {
        pif: {},
        loading: true,
        loaded: false,
      },
      hfe: {

      },
      data: {
        
      },
      pifData: {
        score: 0,
        questions: {},
        override: false,
        loading: true,
      },
    };
  }

  componentDidMount() {
    this.loadData();
    this.fetchModel();
    this.getHFE()

  }

  getHFE=()=>{
    const {
      match: {
        params: {
          id, hfeName, cfmName, pifName, hfe, cfid
        },
      },
    } = this.props;
    PhoenixApiManager.getHFEsForModelWithId(cfid)
      .then(response => response.status === 200 && response.json())
      .then(data =>{
        data.forEach(currentHfe=> {
            if(currentHfe.cfms[cfmName]){
              this.setState({...this.state, hfe:currentHfe})
            }
        })
      })
  }

  loadData = () => {
    const {
      match: {
        params: {
          id, hfeName, cfmName, pifName, hfe, cfid
        },
      },
    } = this.props;
    PhoenixApiManager.getTemplateForModelWithId(cfid)
      .then(response => response.status === 200 && response.json())
      .then(data => this.setState({
        template: {
          pif: data.questionnaire.pif,
          loading: false,
          loaded: true,
        },

      }));

 

    PhoenixApiManager.getPIFForModelWithIdAndHFEAndCFM(cfid, hfeName, cfmName, pifName)
      .then(response => response.status === 200 && response.json())
      .then(data => this.setState({
        pifData: {
          score: data.score,
          questions: data.questions,
          override: data.override,
          expression: data.expression,
          loading: false,
        },
      }));
  };

  fetchModel = () => {
    const {
      match: {
        params: { id,cfid }
      },
      intl
    } = this.props;
    return PhoenixApiManager.openMainModel(id, {
      autoHideDuration: 1000
    })
      .then(response => (response.status === 200 ? response : false,
          this.setState({ ...this.state, data: response})))
      // .then((data) =>
      //   this.setState({
      //     details: data,
      //     isLoading: false,
      //     loaded: data !== false
      //   })
      // );
  };

  render() {
    // const { hfe } = this.props;
    // console.log("116", hfe)
    const { template, pifData, hfe, pifData: { loading } } = this.state;
    if (loading || template.loading || Object.keys(hfe).length==0 ) {
      return null;
    }

    return (
      <PhoenixModelContext.Consumer>
        {(value) => {
              const {
                match: {
                  params: {
                   cfid
                  },
                },
              } = this.props;
          const { refresh } = value;
          // const hfeKeys = Object.keys(value.allCFs.hfe.model_data.hfe);
          const hfeKeys = value.allCFs

          for(let key of hfeKeys) {
            if (key.crt_model == cfid) {
              
            }
          }
          const nextHFEIndex = hfeKeys.findIndex((name) => name === hfe.name) + 1;
          const nextHFEName = hfeKeys[nextHFEIndex];
          return (
            <PIFViewer
              pifData={pifData}
              pifTemplate={template.pif}
              loading={template.loading}
              {...this.props}
              onResponseUpdated={() => {
                refresh();
                this.loadData();
              }}
              nextHFEName={nextHFEName}
              hfe={hfe}
              cfid={cfid}
            />
          );
        }}
      </PhoenixModelContext.Consumer>
    );
  }
}

export default SinglePIFView;
