import CustomGraphLayout from "./CustomGraphLayout";

const positions = {
  null: [0, 0],
  BP_A: [1, 2],
  BP_B: [0, 3],
  BP_C: [1, 3],
  DP_1: [0, 1],
  DP_2: [0, 2],
  EF_1: [2, 4],
  EF_2: [2, 5],
  EF_3: [2, 6],
  EF_4: [2, 7],
  EF_5: [2, 9],
  ES_1: [2, 2],
  ES_2: [2, 3],
  ES_3: [2, 8]
};

const repeated = {
  DP_3: [0, 4],
  DP_4: [1, 5],
  BP_D: [0, 5],
  BP_E: [1, 6],
  DP_5: [0, 7],
  BP_F: [1, 7],
  BP_G: [0, 8],
  BP_H: [1, 8],
  DP_6: [0, 9],
};

const positions2 = {
  DP_3_1: [0, 13 - 3],
  DP_4_1: [1, 14 - 3],
  BP_D_1: [0, 14 - 3],
  BP_E_1: [1, 15 - 3],
  DP_5_1: [0, 16 - 3],
  BP_F_1: [1, 16 - 3],
  BP_G_1: [0, 17 - 3],
  BP_H_1: [1, 17 - 3],
  DP_6_1: [0, 18 - 3],
  // BP_E_1: {},
  // BP_F_1: {},
  // BP_G_1: {},
  // BP_H_1: {},
  // DP_3_1: {},
  // DP_4_1: {},
  // DP_5_1: {},
  // DP_6_1: {}
};

const edges = {
  "DP_1->DP_3": CustomGraphLayout.EDGE_STYLE.LEFT_DOWN_RIGHT,
  "DP_6->DP_3": CustomGraphLayout.EDGE_STYLE.LEFT_UP_RIGHT,
  "BP_A->BP_B": CustomGraphLayout.EDGE_STYLE.STRAIGHT,
  "BP_C->EF_1": CustomGraphLayout.EDGE_STYLE.DOWN_RIGHT,
  "DP_3->DP_4": CustomGraphLayout.EDGE_STYLE.STRAIGHT,
  "BP_E->DP_5": CustomGraphLayout.EDGE_STYLE.STRAIGHT,
  "BP_F->BP_G": CustomGraphLayout.EDGE_STYLE.STRAIGHT,
  "BP_H->DP_6": CustomGraphLayout.EDGE_STYLE.STRAIGHT,


  "DP_6->DP_3_1": CustomGraphLayout.EDGE_STYLE.STRAIGHT,
  "DP_6_1->DP_3_1": CustomGraphLayout.EDGE_STYLE.LEFT_UP_RIGHT,
  "BP_A_1->BP_B_1": CustomGraphLayout.EDGE_STYLE.STRAIGHT,
  "BP_C_1->EF_1": CustomGraphLayout.EDGE_STYLE.DOWN_RIGHT,
  "DP_3_1->DP_4_1": CustomGraphLayout.EDGE_STYLE.STRAIGHT,
  "BP_E_1->DP_5_1": CustomGraphLayout.EDGE_STYLE.STRAIGHT,
  "BP_F_1->BP_G_1": CustomGraphLayout.EDGE_STYLE.STRAIGHT,
  "BP_H_1->DP_6_1": CustomGraphLayout.EDGE_STYLE.STRAIGHT,
};

class DesignBasisScenarioLayout extends CustomGraphLayout {
  constructor(graph) {
    super(graph, { ...positions, ...repeated, ...positions2}, edges);
    this.applyEdgeLabelLayout(graph);
  }

  // TODO: Figure out a better way to layout edge labels
  applyEdgeLabelLayout(graph) {
    const vertices = graph.getVertices();

    vertices.forEach(vertex => {
      if (vertex.key === "DP_1") {
        vertex.getOutgoingEdges().forEach(e => {
          if (e.value === "yes") {
            e.geometry.relative = true;
            e.geometry.x = -1;
            e.geometry.y = -16;
          }
          else {
            e.geometry.relative = true;
            e.geometry.x = -0.5;
            e.geometry.y = 72;
          }
        })
      }
      else if (vertex.key === "DP_2" || vertex.key === "DP_4") {
        vertex.getOutgoingEdges().forEach(e => {
          if (e.value === "yes") {
            e.geometry.relative = true;
            e.geometry.x = -1;
            e.geometry.y = -16;
          }
          else {
            e.geometry.relative = true;
            e.geometry.x = 1;
            e.geometry.y = 16;
          }
        })
      }
      else if (vertex.key === "DP_3") {
        vertex.getOutgoingEdges().forEach(e => {
          if (e.value === "yes") {
            e.geometry.relative = true;
            e.geometry.x = -1;
            e.geometry.y = -16;
          }
          else {
            e.geometry.relative = true;
            e.geometry.x = 0.2;
            e.geometry.y = 112;
          }
        })
      }
      else if (vertex.key === "DP_5") {
        vertex.getOutgoingEdges().forEach(e => {
          if (e.value === "yes") {
            e.geometry.relative = true;
            e.geometry.x = 1;
            e.geometry.y = 16;
          }
          else {
            e.geometry.relative = true;
            e.geometry.x = -1;
            e.geometry.y = -16;
          }
        })
      }
      else if (vertex.key === "DP_6") {
        vertex.getOutgoingEdges().forEach(e => {
          if (e.value === "yes") {
            e.geometry.relative = true;
            e.geometry.x = -0.035;
            e.geometry.y = -168;
          }
          else {
            e.geometry.relative = true;
            e.geometry.x = 1;
            e.geometry.y = -16;
          }
        })
      }
      else if (vertex.key === "BP_A") {
        vertex.getOutgoingEdges().forEach(e => {
          if (e.value === "success") {
            e.geometry.relative = true;
            e.geometry.x = 1;
            e.geometry.y = 16;
          } else {
            e.geometry.relative = true;
            e.geometry.x = -1;
            e.geometry.y = 24;
          }
        })
      }
      else if (vertex.key === "BP_B") {
        vertex.getOutgoingEdges().forEach(e => {
          if (e.value === "success") {
            e.geometry.relative = true;
            e.geometry.x = 1;
            e.geometry.y = -16;
          }
          else {
            e.geometry.relative = true;
            e.geometry.x = -1;
            e.geometry.y = -24;
          }
        })
      }
      else if (vertex.key === "BP_C") {
        vertex.getOutgoingEdges().forEach(e => {
          if (e.value === "success") {
            e.geometry.relative = true;
            e.geometry.x = 1;
            e.geometry.y = -16;
          }
          else {
            e.geometry.relative = true;
            e.geometry.x = -1;
            e.geometry.y = 48;
          }
        })
      }
      else if (vertex.key === "BP_D") {
        vertex.getOutgoingEdges().forEach(e => {
          if (e.value === "success") {
            e.geometry.relative = true;
            e.geometry.x = -1;
            e.geometry.y = -32;
          }
          else {
            e.geometry.relative = true;
            e.geometry.x = 1;
            e.geometry.y = 16;
          }
        })
      }
      else if (vertex.key === "BP_E" || vertex.key === "BP_F") {
        vertex.getOutgoingEdges().forEach(e => {
          if (e.value === "success") {
            e.geometry.relative = true;
            e.geometry.x = -1;
            e.geometry.y = -24;
          }
          else {
            e.geometry.relative = true;
            e.geometry.x = 1;
            e.geometry.y = 16;
          }
        })
      }
      else if (vertex.key === "BP_H") {
        vertex.getOutgoingEdges().forEach(e => {
          if (e.value === "success") {
            e.geometry.relative = true;
            e.geometry.x = 1;
            e.geometry.y = 16;
          }
          else {
            e.geometry.relative = true;
            e.geometry.x = -1;
            e.geometry.y = -24;
          }
        })
      }
      else if (vertex.key === "BP_G") {
        vertex.getOutgoingEdges().forEach(e => {
          if (e.value === "success") {
            e.geometry.relative = true;
            e.geometry.x = 1;
            e.geometry.y = 16;
          }
          else {
            e.geometry.relative = true;
            e.geometry.x = -1;
            e.geometry.y = -24;
          }
        })
      }


    })

  }

}

export default DesignBasisScenarioLayout;
