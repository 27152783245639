import React,{useEffect, useState} from 'react';
import createStyles from "@material-ui/core/styles/createStyles";
import { withStyles, Theme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import TextField from '@material-ui/core/TextField/TextField';
import Button from '@material-ui/core/Button';
import PhoenixApiManager from '../../../../../../Api/PhoenixApiManager';
import ProbabilityInput
  from "hcl-web-editor/app/components/Model/SingleHCLModelView/SingleTreeView/Inputs/ProbabilityInput";
import InputRules from "hcl-web-editor/app/components/Model/SingleHCLModelView/SingleTreeView/Inputs/InputRules";
import PhoenixModelContext from "../../../../PhoenixModelContext";
import cfModelContext from "../../../../cfModelContext";
import {injectIntl} from "react-intl";
import {PhoenixEventTreeMxGraph} from "../../../PhoenixTreeMxGraphView";
import NotePadButton from "../../DP/NotePadButton";
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from "@material-ui/core/Typography";


const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    minWidth: '100%',
  },
  responseContainer: {
    flexGrow: '1',
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  actionButtonIcon: {
    width: '32px',
    height: '32px',
  },
  inputRoot: {
    transition: theme.transitions.create(['border']),
    padding: `${1.5 * theme.spacing(1)}px`,
    borderRadius: `${1.5 * theme.spacing(1)}px`,
    border: '1px solid rgba(0,0,0,0)',
    '&:hover': {
      border: theme.palette.type === 'light' ? '1px solid rgba(0,0,0,0.22)' : '1px solid rgba(255,255,255,0.70)',
    },
  },
  entryLabel: {
    margin: 'auto',
    padding: theme.spacing(1),
  },
  entryContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: 'unset',
    margin: 'unset',
  },
  entryRow: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
  entryList: {
    margin: 'unset',
    padding: 'unset',
    listItemStyle: 'none',
  },
  entryTextField: {
    padding: `${theme.spacing(1)}px 0px`,
  },
});

interface Props {
  classes: any,
  details: any,
  requestAnswerUpdate: (event: any, updated: any) => void,
  descriptions: any,
  bpFormData: any,
  onBPFormChange: any
}

interface State {
  details: {
    successResponse: string,
    failureResponse: string,
    constantValue: any,
    label: any,
    failure_probability?: any,
    name?:string,
  },
  saveClicked:boolean,
}

class BPHorizontalStepContent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      details: {
        successResponse: props.details.successResponse,
        failureResponse: props.details.failureResponse,
        constantValue: props.details.constantValue,
        label: {...props.details.label},
        name:null,
      },
      saveClicked:false,
    };
  }

    componentDidMount(){
    const {misc, details} = this.props
    
    this.setState({
      details: {
        ...details,
        label: {
          ...details.label,
          name:  misc && misc[this.props.details.name] || this.props.details.name
        },
      },
    });
  }

  componentWillReceiveProps(){ // This will essentially update the state when there are new props passed. 
    const {misc, details} = this.props
    this.setState({
      details: {
        ...details,
        label: {
          ...details.label,
          name:  misc && misc[this.props.details.name] || this.props.details.name
        },
      },
    }); 
  }


  updateDetail = () => {
    const {misc, details} = this.props
    this.setState({
      details: {
        ...details,
        label: {
          ...details.label,
          name:  misc && misc[this.props.details.name] || this.props.details.name
        },
      },
    });
    
  }

  onAnswerUpdate = (event: any, misc) => { // called on button click save&continue
    // debugger;
    const { requestAnswerUpdate } = this.props;
    const { details } = this.state;
    this.setState({saveClicked:true});
    let updated = {
      success: details.successResponse,
      failure: details.failureResponse,
      label: details.label,
    };
    if (this.props.details.constant) {
      updated = {
        ...updated,
        //@ts-ignore
        failure_probability: Number(details.constantValue)
      }
    }
    requestAnswerUpdate(event, updated);
    this.updateDetail();
  };

  handleChange = (event: any, key: any) => {
    const { details } = this.state;
    this.setState({
      details: {
        ...details,
        [key]: event.target.value,
      },
    });
  };

  handleLabelChange = (event: any, description: string) => {
    const { details } = this.state;
    this.setState({
      details: {
        ...details,
        label: {
          ...details.label,
          name: event.target.value,
          description: description
        },
      },
    });
  };



  createBPEntryTypeConstant = (entry: any) => {
    const { classes, details:{ constant }, intl, cfid, misc, onBPFormChange, idx} = this.props;
    const { details:{ label, failureResponse, successResponse, constantValue } } = this.state;

    return (
      <PhoenixModelContext.Consumer>
        {
          () => {
            return (
              <div className={classes.entryContainer}>
                <div className={classes.entryRow}>
                  <Tooltip
                    title={
                      entry.label.description && entry.label.description !== "" ?
                      entry.label.description :
                      `${intl.formatMessage({ id: 'noDescriptionFor' })} ${entry.name}`
                  }>
                    <TextField
                      className={classes.entryTextField}
                      // disabled={editingDisabled}
                      value={this.props.bpFormData.label.name}
                      type="text"
                      variant="outlined"
                      fullWidth
                      required
                      margin="dense"
                      label={intl.formatMessage({ id: 'analysis.bp.label' })}
                      rows={2}
                      multiline
                      autoFocus // line below important
                      defaultValue={misc && misc[this.state.details.name] && misc[this.state.details.name].toString() || entry.label.name }
                      onChange={e=> onBPFormChange('name', e.target.value, idx, true)}
                      InputProps={{
                        disableUnderline: true,
                      }}
                    />
                  </Tooltip>
                </div>
                <div className={classes.entryRow}>
                  <Tooltip title={entry.successQuestionPrompt}>
                    <TextField
                      className={classes.entryTextField}
                      type="text"
                      value={this.props.bpFormData.success}
                      variant="outlined"
                      fullWidth
                      required
                      label={intl.formatMessage({ id: 'analysis.bp.successPath' })}
                      defaultValue={(this.state.details.successResponse==='')?(entry.successResponse):(this.state.details.successResponse)}
                      rows={5}
                      multiline
                      onChange={e => onBPFormChange('success', e.target.value, idx, false)}
                      InputProps={{
                        disableUnderline: true,
                        classes: {
                          root: classes.inputRoot,
                          focused: classes.inputFocused,
                        },
                      }}
                    />
                  </Tooltip>
                </div>
                <div className={classes.entryRow}>
                  <Tooltip title={entry.failureQuestionPrompt}>
                    <TextField
                      className={classes.entryTextField}
                      type="text"
                      value={this.props.bpFormData.failure}
                      variant="outlined"
                      fullWidth
                      required
                      label={intl.formatMessage({ id: 'analysis.bp.failurePath' })}
                      defaultValue={(this.state.details.failureResponse==='')?(entry.failureResponse):(this.state.details.failureResponse)}
                      rows={5}
                      multiline
                      onChange={e => onBPFormChange('failure', e.target.value, idx, false)}
                      InputProps={{
                        disableUnderline: true,
                        classes: {
                          root: classes.inputRoot,
                          focused: classes.inputFocused,
                        },
                      }}
                    />
                  </Tooltip>
                </div>
                <div className={classes.entryRow}>
                  <ProbabilityInput
                    className={classes.entryTextField}
                    // disabled={editingDisabled}
                    variant="outlined"
                    margin="dense"
                    label={intl.formatMessage({ id: 'analysis.bp.failureProbability' })}
                    value={this.props.bpFormData.failure_probability}
                    onChange={e => onBPFormChange('failure_probability', e.target.value, idx, false)}
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                </div>
              </div>
            )
          }
        }
      </PhoenixModelContext.Consumer>
    );
  };

  createBPEntry = (entry: any) => {
    const { classes, details:{ constant }, intl, descriptions,cfid, misc, idx, onBPFormChange } = this.props;
    const { details:{ label, failureResponse, successResponse, constantValue } } = this.state;
    if (entry.constant) {
      return this.createBPEntryTypeConstant(entry);
    }
    return (
      <PhoenixModelContext.Consumer>
        {
          () => {
            const description = descriptions[entry.name] && descriptions[entry.name].label ? descriptions[entry.name].label.description : "Description Unavailable";
            return (
              <div className={classes.entryContainer}>
                <div className={classes.entryRow}>
                  <Tooltip title={description}>
                    <TextField
                      className={classes.entryTextField}
                      type="text"
                      value={this.props.bpFormData.label.name}
                      variant="outlined"
                      fullWidth
                      required
                      margin="dense"
                      label={intl.formatMessage({ id: 'analysis.bp.label' })}
                      rows={2}
                      multiline
                      autoFocus
                      defaultValue={misc && misc[this.state.details.name] && misc[this.state.details.name].toString() || entry.label.name }
                      onChange={e => { 
                        onBPFormChange('name', e.target.value, idx, true, {description})
                      }}
                      InputProps={{
                        disableUnderline: true,
                      }}
                    />
                  </Tooltip>
                </div>
                <div className={classes.entryRow}>
                  <Tooltip title={entry.successQuestionPrompt}>
                    <TextField
                      className={classes.entryTextField}
                      type="text"
                      value={this.props.bpFormData.success}
                      variant="outlined"
                      fullWidth
                      required
                      label={intl.formatMessage({ id: 'analysis.bp.successPath' })}
                      defaultValue={(this.state.details.successResponse==='')?(entry.successResponse):(this.state.details.successResponse)}
                      rows={5}
                      multiline
                      onChange={e => onBPFormChange('success', e.target.value, idx, false)}
                      InputProps={{
                        disableUnderline: true,
                        classes: {
                          root: classes.inputRoot,
                          focused: classes.inputFocused,
                        },
                      }}
                    />
                  </Tooltip>
                  <Tooltip title={entry.failureQuestionPrompt}>
                    <TextField
                      className={classes.entryTextField}
                      type="text"
                      value={this.props.bpFormData.failure}
                      variant="outlined"
                      fullWidth
                      required
                      label={intl.formatMessage({ id: 'analysis.bp.failurePath' })}
                      defaultValue={(this.state.details.failureResponse==='')?(entry.failureResponse):(this.state.details.failureResponse)}
                      onChange={e => onBPFormChange('failure', e.target.value, idx, false)}
                      rows={5}
                      multiline
                      InputProps={{
                        disableUnderline: true,
                        classes: {
                          root: classes.inputRoot,
                          focused: classes.inputFocused,
                        },
                      }}
                    />
                  </Tooltip>
                </div>
              </div>
            )
          }
        }
      </PhoenixModelContext.Consumer>
    );
  };

  render() {
    const { classes, details } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.responseContainer}>
          {this.createBPEntry(details)}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(injectIntl(BPHorizontalStepContent));
