import React from 'react';
import { injectIntl } from 'react-intl';
import { withStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { HashLink as Link } from "react-router-hash-link";

//@ts-ignore
import CircularIndeterminate from 'hcla-web-frontend-primitives/app/components/primitives/Progress/CircularIndeterminate';

import InputRules from "hcl-web-editor/app/components/Model/SingleHCLModelView/SingleTreeView/Inputs/InputRules";
import computePIFScoreWithBuiltins from './PIFEmpiricalScoring';
import PhoenixApiManager from '../../../../../../Api/PhoenixApiManager';
import PIFScoreChip from '../../../../../primitives/Chip/PIFScoreChip';
import CardHeader from "@material-ui/core/CardHeader";
import Divider from "@material-ui/core/Divider";
import {ButtonGroup} from "@material-ui/core";
import AnalysisReferencesHeader from "../../../../../primitives/AnalysisReferencesHeader";
import {HFEType} from "../../../../Types";
import {PhoenixFaultTreeMxGraph} from "../../../PhoenixTreeMxGraphView";

const SCORE_DIGITS = 3;

const styles = (theme: Theme) => ({
  entryContainer: {
    width: '100%',
    transition: theme.transitions.create(['background', 'border-color']),
    border: '2px solid',
    borderColor: 'rgba(0,0,0,0.0)',
    display: 'flex',
    flexDirection: 'column',
    margin: `${theme.spacing(1) * 2}px 0px`,
    padding: theme.spacing(1),
    borderRadius: '12px',
    '&:hover': {
      background: 'rgba(255,151,89,0.2)', // on hover every entry gets this color
      borderColor: 'rgba(61, 103, 153, 0.5)',
    },
    '&:nth-of-type(even)': {
      background: 'rgba(93, 146, 212, 0.1)', //
      '&:hover': {
        background: 'rgba(255,151,89,0.2)',
      },
    },
  },
  entryRow: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  entryTextFieldLarge: {
    margin: theme.spacing(1),
  },
  entryTextFieldSmall: {
    minWidth: 80,
    maxWidth: 200,
    margin: theme.spacing(1),
  },
  cardActions: {
    display: 'contents',
    flexGrow: '1',
    justifyContent: 'space-between',
  },
  entryList: {
    width: '100%',
    minWidth: 30,
    margin: 'unset',
    padding: 'unset',
    listItemStyle: 'none',
  },
  margin: {
    margin: theme.spacing(1),
  },
  cardHeaderRoot: {
    paddingBottom: 'unset'
  },
  cardQuestionRoot: {
    overflow: 'auto',
  }
});

const INIT_PIF_ANSWERS = () => ({
  score: 0,
  questions: {},
});

interface Props {
  classes: { [key: string]: any },
  intl: { [key: string]: any },
  loading: boolean,
  pifData: {
    score: any,
    questions: { [key: string]: any},
    override: boolean,
    expression?: { [key: string]: any }
  },
  pifTemplate: { [key: string]: any },
  match: { [key: string]: any }
  onResponseUpdated: () => void,
  hfe: HFEType,
  nextHFEName?: string
}

interface State {
  answers: {
    data: { [key: string]: any },
    unsavedChanges: boolean,
  },
  cfmTreeId: number
  cfTitle:any;
  clickedSave: boolean;
}

class PIFViewer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      answers: {
        data: {
          score: props.pifData.score,
          questions: props.pifData.questions,
        },
        unsavedChanges: false,
      },
      cfmTreeId: props.hfe.cfms[props.match.params.cfmName]?.fault_tree_id,
      cfTitle:null,
      clickedSave: false
    };
  }
  
componentDidMount(){
  const {
    match: {
      params: {
        cfid
      },
    }
  } = this.props;
  this.getCfName(cfid);
};


  static getDerivedStateFromProps(props: any, state: any) {
    if (props.pifData.score !== state.answers.score && !state.answers.unsavedChanges) {
      return {
        answers: {
          ...state.answers,
          data: {
            ...state.answers.data,
            score: props.pifData.score
          }
        },
      };
    }

    // Return null if the state hasn't changed
    return null;
  }

  // componentDidUpdate(prevProps, prevState, snapshot) {
  //   const { score } = this.props.pifData.score;
  //
  //   if (prevProps.pifData.score !== score) {
  //     this.setState({
  //       answers: {
  //         ...prevState.answers,
  //         data: {
  //           ...prevState.answers.data,
  //           score
  //         }
  //       }
  //     })
  //   }
  // }


  getCfName=(cfid)=>{
    PhoenixApiManager.getCfFunctionDetails(cfid)
    .then(response=>response.status===200 && response.json())
    .then((data)=>{
      this.setState({cfTitle:data.title});
    })
  };

  handleAnswersSave = () => { // saves info
    const {
      match: {
        params: {
          cfid, hfeName, cfmName, pifName,
        },
      },
      onResponseUpdated,
    } = this.props;
    const { answers: { data } } = this.state;
    const payload = JSON.stringify({
      score: data.score,
      questions: data.questions,
      name: pifName,
    });
    PhoenixApiManager.patchPIFForModelWithIdAndHFEAndCFM(cfid, hfeName, cfmName, pifName, payload)
      .then(response => response.status === 200 && response.json())
      .then((data2) => {
        this.setState({
          answers: {
            unsavedChanges: false,
            data: data2,
          },
          clickedSave: true,
        });
      }).then(() => onResponseUpdated());
  };

  setAnswer = (pifName: string, questionId: any, key: any, value: any) => {
    this.setState((state, props) => {
      const { pifTemplate } = props;
      const newState = state;
      newState.answers.unsavedChanges = true;
      newState.answers.data.questions = {
        ...newState.answers.data.questions,
        [questionId]: {
          ...newState.answers.data.questions[questionId],
          [key]: value,
        },
      };
      newState.answers.data.score = computePIFScoreWithBuiltins(
        pifName, pifTemplate[pifName].questions, newState.answers.data,
      );
      return newState;
    });
  };

  getScore = (pifName: string) => {
    const { answers } = this.state;
    return (answers || {}).data.score || 0;
  };

  getAnswer = (pifName: string, questionId: any, field: any) => {
    const { answers } = this.state;
    return ((answers || INIT_PIF_ANSWERS()).data.questions[questionId] || {})[field] || ' ';
  };

  renderQuestion = (pifName: string, questionId: any) => {
    const { classes, pifTemplate, intl } = this.props;
    const question = pifTemplate[pifName].questions[questionId];
    return (
      <li className={classes.entryContainer} key={questionId}>
        <div className={classes.entryRow}>
          <TextField
            className={classes.entryTextFieldLarge}
            type="text"
            variant="outlined"
            fullWidth
            label={questionId}
            defaultValue={question.question}
            rowsMax={5}
            multiline
            InputProps={{
              readOnly: true,
              classes: {
                focused: classes.inputFocused,
              },
            }}
          />
          <TextField
            className={classes.entryTextFieldSmall}
            type="text"
            variant="outlined"
            label={intl.formatMessage({ id: 'analysis.pifQuestionLowerLevelPIF' })}
            defaultValue={question.lower_level_pif}
            fullWidth
            multiline
            InputProps={{
              readOnly: true,
              classes: {
                focused: classes.inputFocused,
              },
            }}
          />
        </div>
        <div className={classes.entryRow}>
          <TextField
            className={classes.entryTextFieldSmall}
            select
            label={intl.formatMessage({ id: 'analysis.pifQuestionAnswer' })}
            value={this.getAnswer(pifName, questionId, 'answer')}
            onChange={event => this.setAnswer(pifName, questionId, 'answer', event.target.value)}
            SelectProps={{
              autoWidth: true,
              classes: {
                focused: classes.inputFocused,
              },
            }}
          >
            <MenuItem value="" />
            {Object.keys(question.response_options).map(option => (
              <MenuItem value={option}>{option}</MenuItem>
            ))}
          </TextField>
          <TextField
            className={classes.entryTextFieldLarge}
            type="text"
            label={intl.formatMessage({ id: 'analysis.pifQuestionJustification' })}
            value={this.getAnswer(pifName, questionId, 'justification')}
            // error={InputRules.isEmpty(this.getAnswer(pifName, questionId, 'justification'))}
            onChange={event => this.setAnswer(pifName, questionId, 'justification', event.target.value)}
            disabled={this.getAnswer(pifName, questionId, 'answer') === ''}
            multiline
            fullWidth
            InputProps={{
              classes: {
                focused: classes.inputFocused,
              },
            }}
          />
        </div>
      </li>
    );
  };

  saveButtonShouldBeDisabled = () => { // determining if save button should be diesabled
    const {
      match: { params: { pifName } }, pifTemplate,
    } = this.props;
    const { answers } = this.state;
    const { questions } = pifTemplate[pifName];

    return !answers.unsavedChanges ||
      Object.keys(answers.data.questions).length !== Object.keys(questions).length 
      ||
      Object.entries(answers.data.questions).some((q) =>
        !q[1].hasOwnProperty('justification') || InputRules.isEmpty(q[1].justification));
  };

  nextButtonShouldBeDisabled = () => { // determining if next button should be disabled (not used in original code)
    const {
      match: { params: { pifName, cfmName } }, pifTemplate, hfe
    } = this.props;
    const { answers } = this.state;
    const { questions } = pifTemplate[pifName];
    if (Object.keys(questions) === null || !('pifName' in hfe.cfms[cfmName].pifs)) { 
      return true;
    }
    const pifNotAnswered = hfe && Object.keys(hfe.cfms[cfmName].pifs[pifName].questions).length === 0;

    return pifNotAnswered || Object.keys(answers.data.questions).length !== Object.keys(questions).length 
    ||
      Object.entries(answers.data.questions).some((q) =>
        !q[1].hasOwnProperty('justification') || InputRules.isEmpty(q[1].justification));
  };

  render() {
    const {
      loading, intl, classes, match: { params: { hfeName, cfmName, pifName, id } }, cfid, pifTemplate, hfe, nextHFEName, history, pifData, onResponseUpdated
    } = this.props; // getting props from SinglePIFViewer

    console.log(this.props.hfe, 'this is keeeeeeeeeeeeeeeeeyyy PIF')
    console.log(this.props.match.params.cfmName, '**********************************')
    const { cfmTreeId } = this.state;
    const{ unsavedChanges } = this.state.answers;
    if (loading) {
      return <CircularIndeterminate size={80} color="secondary" />;
    }
    const score = this.getScore(pifName).toFixed(SCORE_DIGITS);
    const { questions } = pifTemplate[pifName];
    const isLastCFM = Object.keys(hfe.cfms).pop() === cfmName;
    const isLastPIF = Object.keys(hfe.cfms[Object.keys(hfe.cfms).pop()].pifs).pop() === pifName;
    const shouldLinkToNextHFE = nextHFEName && isLastCFM && isLastPIF;
    const nextLink = `/model/${id}/main-analysis/hfe/#${shouldLinkToNextHFE ? nextHFEName : hfeName}`;
    return (
      <React.Fragment>
        <AnalysisReferencesHeader
          crt={{ cfid }} // sending crt 
          bp={hfe.label.name}
          hfe={{ id: hfe.fault_tree_id }}
          cfm={{
            treeId: cfmTreeId,
            label: hfe.cfms[cfmName].label.name
          }}
          pif={pifName}
          inPaperContainer={true}
        />
        <h2>CF Name: {this.state.cfTitle}</h2>
        <Card className={classes.cardQuestionRoot}>
          <CardHeader
            className={classes.cardHeaderRoot}
            title={`${intl.formatMessage({ id: 'analysis.pif.singular' })} - ${pifName}`}
            subheader={<Divider />}
          />
          <CardContent>
            <ul className={classes.entryList}>
              {Object.keys(questions).map(
                (questionId, idx) => this.renderQuestion(pifName, questionId, idx),
              )}
            </ul>
          </CardContent>
          <CardActions className={classes.actions} disableActionSpacing>
            <div className={classes.cardActions}>
              <PIFScoreChip
                modelId={id}
                cfmKey={cfmName}
                cfmName={hfe.cfms[cfmName].label.name}
                hfeKey={hfeName}
                hfeName={hfe.label.name}
                pifName={pifName}
                score={score}
                faultTreeId={hfe.fault_tree_id}
                cfmTreeId={cfmTreeId}
                onResponseUpdated={onResponseUpdated}
                expression={pifData.expression}
                override={pifData.override}
                disabled={unsavedChanges}
              />
              <ButtonGroup>
                <Button
                  disabled={false}
                  color="primary"
                  variant="contained"
                  size="medium"
                  aria-label={intl.formatMessage({ id: 'analysis.pifSave' })}
                  onClick={this.handleAnswersSave} // use this to save
                >
                  {intl.formatMessage({ id: 'analysis.pifSave' })}
                </Button>
                <Link onClick={window.location.reload} to={nextLink} >

                <Button
                  size="medium"
                  disabled={!(this.state.clickedSave)} // {!this.nextButtonShouldBeDisabled()} = true when filled out everything, not false when hit save
                  color="primary" // for above, clickedSave = false when haven't clicked save yet, true when have clicked save
                  variant="contained" // whenever one of these is true, we want disabled to be false so that can click button (which is why we have !(a || b)
                  onClick={() => {this.handleAnswersSave(); }}
                >
                  {intl.formatMessage({ id: 'analysis.next' })}
                </Button>
                </Link>
              </ButtonGroup>
            </div>
          </CardActions>
        </Card>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(injectIntl(PIFViewer));
