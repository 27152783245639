import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import createStyles from "@material-ui/core/styles/createStyles";
import { withStyles } from '@material-ui/core/styles';

import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';

import ApiManager from 'hcla-web-frontend-primitives/app/Api/ApiManager';

import NavBar from "hcla-web-frontend-primitives/app/components/primitives/NavBar/NavBar";

//@ts-ignore
import PhoenixLogo from 'branding-asset-primitives/phoenix/notext-logo.png';
// @ts-ignore
import FilterKeyRoot, { FilterContext } from 'hcla-web-frontend-primitives/app/Utils/FilterKeyRoot';
// @ts-ignore
import ViewNoMatch from 'hcla-web-frontend-primitives/app/components/primitives/Error/ViewNoMatch';
// @ts-ignore
import ModelViewNoMatch from 'hcla-web-frontend-primitives/app/components/primitives/Error/ModelViewNoMatch';
//@ts-ignore
import MainContent from 'hcla-web-frontend-primitives/app/components/primitives/Views/MainContent';

import { injectIntl } from 'react-intl';

import Models from '../Model/Models';
import NewModel from '../Model/NewModel';
import NewModel_main from '../Model/NewModel_main';
import SinglePhoenixModelView from '../Model/SinglePhoenixModelView/SinglePhoenixModelView';
import SinglePhoenixMainModelView from '../Model/SinglePhoenixMainModelView/SinglePhoenixMainModelView';
import MasterESD_main from "../Model/MasterESD_main";
import MasterESD from "../Model/MasterESD";
import AnalysisHeader from "../Model/SinglePhoenixMainModelView/Analysis/AnalysisHeader";
import QuantificationView from "../Model/SinglePhoenixMainModelView/Quantification/QuantificationView";



import CreateFAB from "./CreateFAB";

const styles = () => createStyles({
  root: {
    zIndex: 1,
    overflow: 'auto',
    height: '100vh',
    position: 'relative',
  },
  logo: {
    height: '1rem',
    width: 'auto'
  }
});

export interface PhoenixRouteParams {
  id: string;
}

interface Props {
  classes: any;
  location: { [key: string]: any };
  history: { [key: string]: any };
  intl: any;
}

const { version } = require('../../../package');

function MainContainer(props: Props) {
  if (!ApiManager.isLoggedIn()) {
    const location = {
      pathname: '/auth',
      state: {
        from: {
          pathname: props.location.pathname,
        },
      },
    };
    return (<Redirect to={location} />);
  }

  const {
    classes, location, history, intl,
  } = props;

  const logo = <img alt="Phoenix" className={classes.logo} src={PhoenixLogo} />;
  return (
    <div className={classes.root}>
      <FilterKeyRoot
        location={location}
        history={history}
        render={
          (filterKey: any, setFilterKey: any) => {
            return (
              <React.Fragment>
                <BreadcrumbsItem to="/">{intl.formatMessage({ id: 'home', defaultMessage: 'Home' })}</BreadcrumbsItem>
                <FilterContext.Provider value={{ filterKey, setFilterKey: (key: any) => setFilterKey(key, '/') }}>
                  <NavBar
                    menuItems={[
                      {
                        path: "/",
                        title: logo,
                      },
                    ]}
                    version={version}
                    appbarProps={{
                      color: 'secondary'
                    }}
                  >
                    <CreateFAB />
                  </NavBar>
                  {/*<NavBarOld />*/}
                  <Switch>
                    <Route exact path="/" render={rProps => <Models depth={0} {...rProps} />} />
                    <Route exact path="/model/new" render={rProps => <MainContent><NewModel depth={0} {...rProps} /></MainContent>} />
                    <Route exact path="/model/main-new" render={rProps => <MainContent><NewModel_main depth={0} {...rProps} /></MainContent>} />
                    <Route exact path="/model/not-found" render={rProps => <ModelViewNoMatch depth={0} {...rProps} />} />
                    <Route path="/model/:id/main-overview" render={rProps => <SinglePhoenixMainModelView depth={1} {...rProps}/>} />
                    <Route path="/model/:id" render={rProps => rProps.match.params.id<1 ?(<SinglePhoenixMainModelView depth={1} {...rProps}/>):(<SinglePhoenixMainModelView depth={1} {...rProps}/>)}  />
                    <Route path="/model/:id/overview" render={rProps => <SinglePhoenixMainModelView depth={1} {...rProps} />} />
                    <Route path="/model/:id/main-master-esd" render={rProps => <MasterESD_main depth={2} {...rProps} />} />                  
                    <Route path="/model/:id/main-analysis/hfe/" render={rProps => <AnalysisHeader {...rProps} />} />
                    <Route exact path="/model/:id/main-quantification" render={prop => <QuantificationView {...prop} />} />
                    <Route
                      render={prop => (
                        <ViewNoMatch
                          {...prop}
                          message={intl.formatMessage({ id: '404.pageNotFound' })}
                        />
                      )}
                    />
                  </Switch>
                </FilterContext.Provider>
              </React.Fragment>
            );
          }
        }
      />
    </div>
  );
}

export default withStyles(styles)(injectIntl(MainContainer));
