class BuiltinFormulae {
  constructor(pifQuestions, pifAnswers) {
    this.pifAnswers = pifAnswers;
    this.answersCount = { Yes: 0, No: 0, 'N/A': 0 };
    Object.keys(pifAnswers.questions || {}).forEach((questionId) => {
      const { answer } = pifAnswers.questions[questionId];
      if (!answer) return;
      this.answersCount[answer] = (this.answersCount[answer] || 0) + 1;
    });
  }

  getAnswer = (questionId) => {
    return (this.pifAnswers.questions[questionId] || {}).answer;
  };

  clamp(value) {
    if (!Number.isFinite(value) || Number.isNaN(value)) return 0;
    return Math.min(Math.max(0, value), 1);
  }

  builtIns = {
    Procedures: () => {
      if (this.getAnswer('Q1') === 'Yes') return 1;
      if (this.getAnswer('Q2') === 'Yes') return this.clamp(0.7 + (this.answersCount.Yes - 1) / (this.answersCount.Yes + this.answersCount.No - 2));
      return this.default();
    },
    ['Team Effectiveness']: () => {
      if (this.getAnswer('Q1') === 'Yes') return this.clamp(0.3 + (this.answersCount.Yes - 1) / (this.answersCount.Yes + this.answersCount.No - 1));
      return this.default();
    },
  };

  default = () => {
    return this.clamp(this.answersCount.Yes / (this.answersCount.Yes + this.answersCount.No));
  };

  get = (pifName) => {
    // some PIF names have an invisible Unicode character at the beginning
    return (this.builtIns[pifName.trim()] || this.default)();
  }
}

export default function computePIFScoreWithBuiltins(pifName, pifQuestions, pifAnswers) {
  return (new BuiltinFormulae(pifQuestions, pifAnswers)).get(pifName);
}
