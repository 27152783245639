import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { injectIntl } from 'react-intl';

import ApiManager from 'hcla-web-frontend-primitives/app/Api/ApiManager';
import TransferButton from 'hcla-web-frontend-primitives/app/components/primitives/Buttons/TransferButton';
import TypedConfirmationDialog from 'hcla-web-frontend-primitives/app/components/primitives/Dialogs/TypedConfirmationDialog';
import RemoveButton from 'hcla-web-frontend-primitives/app/components/primitives/Buttons/RemoveButton';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  transferColor: {
    color: '#ee9838',
  },
  removeColor: {
    color: '#ca4830',
  },
  inCardContainer: {
    border: '1px solid #ccc',
    borderRadius: theme.spacing(1) / 2,
    padding: theme.spacing(1) * 2,
  },
});

class ModelSettingsAdvanced extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      details: props.details,
      openTypedConfirmationDialog: false,
      redirect: false,
    };
  }

  handleToggle = (e, id) => {
    this.setState({
      [id]: !this.state[id],
    });
  };

  handleOnConfirmed = () => {
    const { details: { id } } = this.props;
    ApiManager.deleteModel(id)
      .then((response) => {
        if (response.status === 204) {
          this.handleToggle(null, 'redirect');
        }
      });
  };

  render() {
    const { classes, intl, details: { title } } = this.props;
    const { openTypedConfirmationDialog, redirect } = this.state;
    if (redirect) {
      return <Redirect to="/" />;
    }

    return (
      <div className={classes.root}>
        <div className={classes.inCardContainer}>
          <Typography
            className={classes.transferColor}
            gutterBottom
            variant="h6"
          >
            {intl.formatMessage({ id: 'advancedSettings.transferModel' })}
          </Typography>
          <Divider />
          <Typography variant="body1">
            {intl.formatMessage({ id: 'advancedSettings.transferModelDesc' })}
          </Typography>
          <Typography variant="body1" paragraph>
            {intl.formatMessage({ id: 'advancedSettings.transferModelDesc2' })}
          </Typography>
          <TransferButton
            disabled
            variant="outlined"
            size="large"
          >
            {intl.formatMessage({ id: 'transfer' })}
          </TransferButton>
        </div>
        <br />
        <div className={classes.inCardContainer}>
          <Typography
            className={classes.removeColor}
            gutterBottom
            variant="h6"
          >
            {intl.formatMessage({ id: 'advancedSettings.removeModel' })}
          </Typography>
          <Divider />
          <Typography variant="body1">
            {intl.formatMessage({ id: 'advancedSettings.removeModelDesc' })}
          </Typography>
          <Typography variant="body1" paragraph>
            <b>{intl.formatMessage({ id: 'advancedSettings.removeModelDesc2' })}</b>
          </Typography>
          <RemoveButton
            onClick={e => this.handleToggle(e, 'openTypedConfirmationDialog')}
            variant="outlined"
            size="large"
          >
            {intl.formatMessage({ id: 'remove' })}
          </RemoveButton>
          <TypedConfirmationDialog
            title={intl.formatMessage({ id: 'advancedSettings.confirmationRequired' })}
            content={(
              <div>
                <Typography
                  className={classes.removeColor}
                  variant="h6"
                  gutterBottom
                >
                  {intl.formatMessage({ id: 'advancedSettings.confirmationRequiredDesc' })}
                </Typography>
                <Typography variant="body1">
                  {intl.formatMessage({ id: 'advancedSettings.confirmationRequiredDesc2' })}
                </Typography>
                <Typography variant="body1">
                  {intl.formatMessage({ id: 'advancedSettings.confirmationRequiredDesc3' })}
                </Typography>
                <Typography variant="body1">
                  {intl.formatMessage({ id: 'advancedSettings.confirmationRequiredDesc4' })}
                </Typography>
              </div>
            )}
            open={openTypedConfirmationDialog}
            onConfirmed={() => this.handleOnConfirmed()}
            onRequestClose={e => this.handleToggle(e, 'openTypedConfirmationDialog')}
            matchText={title}
          />
        </div>
      </div>
    );
  }
}

ModelSettingsAdvanced.propTypes = {
  details: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    date_created: PropTypes.string.isRequired,
    date_modified: PropTypes.string.isRequired,
    creator: PropTypes.number.isRequired,
    assigned_users: PropTypes.arrayOf(Number).isRequired,
    subsystems: PropTypes.arrayOf(Number).isRequired,
  }).isRequired,
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(injectIntl(ModelSettingsAdvanced));
