import { mxgraph as MxGraphType } from 'mxgraph';

import EventTreeEdge from 'hcl-web-editor/app/mxGraph/HCLTreeNode/EventTreeNode/EventTreeEdge';
// refer to the actual source code
const MxGraph: typeof MxGraphType = require('mxgraph-js');

class BranchPointEdge extends EventTreeEdge {
  public inactive: boolean = false;

  constructor(value: string) {
    super(BranchPointEdge.getMyStyle(value));
    this.value = value;
  }

  public static getMyStyle(value: string): { [index: string]: any } {
    const edgeStyle: { [index: string]: any } = {};

    edgeStyle[MxGraph.mxConstants.STYLE_PERIMETER_SPACING] = 0;
    edgeStyle[MxGraph.mxConstants.STYLE_STROKEWIDTH] = 2;
    edgeStyle[MxGraph.mxConstants.STYLE_ENDARROW] =
      MxGraph.mxConstants.ARROW_CLASSIC;
    edgeStyle[MxGraph.mxConstants.STYLE_ALIGN] =
      MxGraph.mxConstants.ALIGN_MIDDLE;
    edgeStyle[MxGraph.mxConstants.STYLE_LABEL_WIDTH] = '100%';
    edgeStyle[MxGraph.mxConstants.STYLE_LABEL_POSITION] =
      MxGraph.mxConstants.ALIGN_CENTER;
    edgeStyle[MxGraph.mxConstants.STYLE_VERTICAL_ALIGN] =
      MxGraph.mxConstants.ALIGN_CENTER;

    if (value === 'failure') {
      // edgeStyle[MxGraph.mxConstants.STYLE_SHAPE] =
      //   MxGraph.mxConstants.SHAPE_CONNECTOR;
      edgeStyle[MxGraph.mxConstants.STYLE_STROKECOLOR] = 'red';  

      edgeStyle[MxGraph.mxConstants.STYLE_EDGE] =
        MxGraph.mxConstants.EDGESTYLE_ELBOW;
      edgeStyle[MxGraph.mxConstants.STYLE_ELBOW] =
        MxGraph.mxConstants.ELBOW_VERTICAL;
    }  if (value === 'success') {
      edgeStyle[MxGraph.mxConstants.STYLE_STROKECOLOR] = 'green';
  }

    return edgeStyle;
  }
}

export default BranchPointEdge;
