import React from 'react';
import { withStyles } from '@material-ui/core/styles/index';

import Tooltip from '@material-ui/core/Tooltip/index';

import { injectIntl } from 'react-intl';
import CircularProgressbar from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import { getColorBasedOnPercentage } from 'hcla-web-frontend-primitives/app/Utils/Utils';
import WraparoundCircularProgressBar from './WraparoundCircularProgressBar';
import BinaryIncompleteIcon from '../Icons/BinaryIncompleteIcon';
import BinaryCompleteIcon from '../Icons/BinaryCompleteIcon';

const styles = () => ({
});

interface Props {
  classes: { [key: string]: any },
  intl: { [key: string]: any },
  theme: any,
  progress: {
    completed: number,
    total: number,
  },
  checkmark: boolean,
}

const defaultProps: Partial<Props> = {
  checkmark: false,
};

const CircularProgressIcon = (props: Props) => {
  const { progress, checkmark, theme, intl, classes } = props;

  const getProgressIcon = () => {
    const { completed, total } = progress;

    const getPercentageComplete = (complete: number, N: number) => {
      return (complete / N) * 100.0;
    };

    const percentComplete = getPercentageComplete(completed, total);

    const getStrokeColorFromPercentage = (percentage: any) => {
      return getColorBasedOnPercentage(percentage);
    };

    const pbStyles = {
      root: {
        width: 64,
        height: 64,
        minWidth: 64,
        minHeight: 64,
      },
      path: {
        stroke: getStrokeColorFromPercentage(percentComplete),
      },
      text: {
        fill: theme.palette.type === 'light' ? 'rgba(0,0,0,0.70)' : theme.palette.grey[100],
        fontSize: '18px',
        fontWeight: 'bold',
        fontFamily: theme.typography.fontFamily,
      },
    };

    if (!checkmark) {
      return (
        <CircularProgressbar
          initialAnimation
          percentage={percentComplete}
          strokeWidth={10}
          text={`${completed}/${total}`}
          styles={pbStyles}
        />
      );
    }

    const icon = (completed <= 0) ? <BinaryIncompleteIcon /> : <BinaryCompleteIcon />;
    return (
      <WraparoundCircularProgressBar
        initialAnimation
        percentage={percentComplete}
        strokeWidth={10}
        styles={pbStyles}
      >
        {icon}
      </WraparoundCircularProgressBar>
    );
  };

  return (
    <Tooltip title={intl.formatMessage({ id: 'progress' })}>
      {getProgressIcon()}
    </Tooltip>
  );
};

CircularProgressIcon.defaultProps = defaultProps;

export default withStyles(styles, { withTheme: true })(injectIntl(CircularProgressIcon));
