import React,{useMemo} from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { injectIntl } from 'react-intl';
import { Switch, Route } from 'react-router-dom';
import ApiManager from 'hcla-web-frontend-primitives/app/Api/ApiManager';


import Card from "@material-ui/core/Card";
import {CardActions} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Theme} from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import ResultsIcon from "../../../../primitives/Icons/ResultsIcon";
import { Link } from 'react-router-dom';

//@ts-ignore
import ViewNoMatch from 'hcla-web-frontend-primitives/app/components/primitives/Error/ViewNoMatch';

import PhoenixApiManager from '../../../../../Api/PhoenixApiManager';
import HumanFailureEventOverview from './HumanFailureEventOverview';

import HFERouter from './SingleHFEView/HFERouter';
import { HFEType } from "../../../Types";
import { mxTemporaryCellStates } from 'mxgraph-js';
import { TEMPLATE_NAME_MAP } from '../../../SinglePhoenixModelView/Analysis/DP/CrewResponseTreeView';
import TreeReferenceButton from "./TreeReferenceButton";
import HCLTreeMxGraphView from 'hcl-web-editor/app/components/Model/SingleHCLModelView/SingleTreeView/MxGraphViews/HCLTreeMxGraphView';
import HCLApiManager from 'hcl-web-editor/app/Api/HclApiManager';
import HumanFailureEventEditorView from "./SingleHFEView/HumanFailureEventEditorView";
import { constructLineAggregationData } from 'hcl-web-editor/app/components/Generic/RiskCurveHelper';
import { Switch as S, FormControlLabel } from '@material-ui/core';




const useStyles = makeStyles((theme: Theme) => ({
  cardActionsRoot: {
    flex: '0 0 auto'
  },
  quantifyIcon: {
    marginRight: theme.spacing(1)
  },
}));


const updatableDataInitStates: Partial<State> = {
  items: [],
  isLoading: true,
};

interface Props {
  depth: number,
  details: any,
  match: { [key: string]: any },
  location: { [key: string]: any },
  allCFs:any,
  intl: any,
  onResponseUpdated: () => void
}

interface State {
  items: any[],
  isLoading: boolean,
  criticalFunctions:any[],
  cf_id_to_names:{},
  cf_to_hfes:{},
  cf_to_progress:{},
  sort: (i: any) => any,
  data:any,
  classes:any,
  updated: number, 
  reloadFromHfe: boolean,
  expertmodeactive: boolean, 
  updatedProgressForQuantificationAccess: any
}


//current plan: get all the hfes in the items, create dictionary for mapping CFs and
//HFEs

class HFEs extends React.Component<Props, State> {
 
  constructor(props: Props) {
    super(props);
    this.state = {
      ...updatableDataInitStates,
      criticalFunctions:[],
      cf_id_to_names:{},
      cf_to_hfes:{},
      cf_to_progress:{},
      sort: (i: any) => i,
      data:null,
      classes:useStyles,
      updated: 0,
      reloadFromHfe: false, 
      expertmodeactive: false, 
      updatedProgressForQuantificationAccess: this.props.progress ? this.props.progress : null
    };
  }
  

  restartHFEreloadCounter = () => {
    this.setState({updated: 0})
  }

  getMasterESD = () => {
    const { match: { params: { id } } } = this.props;
    PhoenixApiManager.getMasterEsd(id)
			.then((response) => this.setState({data:response.data}))
  }

  componentDidMount() {
    this.loadData();
    this.getMasterESD();
  }

  componentWillReceiveProps(nextProps: Readonly<Props>, nextContext: any): void {
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
      if (this.props.history?.location?.state?.reload == true && this.state.updated == 0){
        this.setState({updated: this.state.updated + 1, reloadFromHfe: true}, ()=>this.getCFs(true))
      }
  }



  loadData = () => {
    const { isLoading } = this.state;
    if (!isLoading) {
      return this.setState({ isLoading: true }, () => {
        this.getCFs();
        
      })
    }
    return this.getCFs();
  };


  getHFEs = (cf_id) => {
    // console.log("what is this ID!!")
    PhoenixApiManager.getHFEsForModelWithId(cf_id)
      .then(response => response.status === 200 && response.json())
      .then(async data => {
        let temp=[...this.state.items]
        temp=temp.concat(data)
        await this.setState({ items: [...temp], isLoading: false,  cf_to_hfes: {...this.state.cf_to_hfes,[cf_id]: data}})
        // this.state.cf_to_hfes[cf_id]=data
        //sort the data within the cf_to_hfes
        // this.state.cf_to_hfes[cf_id].sort((a,b)=>(a.fault_tree_id>b.fault_tree_id)?1:-1); 
      });
    const newObj = {}
      
      Object.keys(this.state.cf_to_hfes).forEach(key =>{
        if(this.state.cf_to_hfes[key] !== undefined){
          newObj[key] = this.state.cf_to_hfes[key]
        }
      });
      this.setState({...this.state, cf_to_hfes: newObj })
  };


  getProgress=(cf_id)=>{
    PhoenixApiManager.getPhoenixModelWithId(cf_id)
    .then(response => response.status === 200 && response.json())
    .then(data => {
      // this.state.cf_to_progress[cf_id]=data.progress;
      // Object.keys(data.progress).forEach(key => key===undefined||data.progress[key] === undefined ? delete data.progress[key] : {})
      // {Object.keys(cf_to_hfes).forEach(key => key===undefined||cf_to_hfes[key] === undefined  ? delete cf_to_hfes[key] : {})}

      this.setState({cf_to_progress:{...this.state.cf_to_progress, [cf_id]: data.progress}})
    });

  }
 

  getCFs=(retry=false)=>{
    const { match: { params: { id } } } = this.props;
    PhoenixApiManager.getMainCriticalFunctions(id, true)
    .then(response=>{
      let hfeDetails = []
      let cf_to_hfes: any = {}
      const cfToName: any = {}
      const cfProgression: any = {}
      const crtIDs = []
      for(let cf of response.data){
        // iterates through all of the critical functions
        if(cf.crt_model!=null && Object.keys(cf.crt_model_data.hfe).length >0){
          //checks to see if hfe data was received
          cf_to_hfes[cf.crt_model] = [...cf.crt_model_data.hfe]
          cfToName[cf.crt_model] = cf.title
          hfeDetails.push(...cf.crt_model_data.hfe)
          cfProgression[cf.crt_model] = {...cf.progress, crt_model: cf.crt_model}
          crtIDs.push(cf.crt_model)
        }
      }
      const valid_cf_to_hfes: any = {}
      Object.keys(cf_to_hfes).forEach(key=>{
        if (cf_to_hfes[key] !== undefined){
          valid_cf_to_hfes[key] = cf_to_hfes[key]
        }
      })
    
      this.setState({
        cf_to_hfes:valid_cf_to_hfes,
        items: hfeDetails,
        cf_to_progress: {...cfProgression},
        cf_id_to_names: cfToName,
        criticalFunctions: crtIDs.reverse(),
        isLoading: false
      })
    })
    .catch(err=>{
      // 500 error from the server after calling this endpoint immediately after
      // selecting basic events. The call eventually works after 2 or 3 tries. TODO address this on the backend
      // detail: "duplicate key value violates unique constraint \"hcl_psqltree_tree_id_key\"\nDETAIL:  Key (tree_id)=(620d858e477da3173343ad7f_temp) already exists.\n"
      if (retry){
        this.getCFs(retry)
        this.setState({reloadFromHfe: false})
      }
    })
  //                 .then((data) => {
  //                     let temp=[];
  //                     let tempObj = {}
  //                     for (var i=0; i< data.data.length;i++){
                        
  //                       let key=data.data[i].crt_model
  //                       if (key!=null){
  //                       this.getHFEs(key)
  //                       this.getProgress(key)
  //                       temp.push(key)
  //                       tempObj[key]=data.data[i].title
  //                       }
                        
  //                     }
  //                     this.setState({...this.state, cf_id_to_names:tempObj})
  //                     console.log(this.state.cf_id_to_names)
                      
  //                     this.setState({...this.state, criticalFunctions: temp.reverse()})
  //                     // this.state.criticalFunctions.sort((a,b)=>a-b);
  //                 });
  //                 const newObj = {}
  //                 Object.keys(this.state.cf_to_progress).forEach(key =>{ 
  //                   if(this.state.cf_to_progress[key]!==undefined){
  //                     newObj[key] = this.state.cf_to_progress[key]
  //                   }
  //                 })
  //                 this.setState({...this.state, cf_to_progress:newObj})
  // Object.keys(this.state.cf_to_progress).forEach(key => this.state.cf_to_progress[key] === undefined ? delete this.state.cf_to_progress[key] : {});
  };



  handleSortChange = (sort: any) => {
    this.setState({ sort });
    // todo: fetch data if pagination is enabled
  };

  isHFEEmpty = (hfe: HFEType) => {
    try {
      return Object.keys(hfe.nodes.basic_events).length === 0
        && Object.keys(hfe.nodes.house_events).length === 0
        && hfe.nodes.excluded_gates.length === 0
        && hfe.nodes.cfms.length === 0;
    } catch (e) {
      return true;
    }
  };

  cfmsNotAnswered = (hfe: HFEType) => {
    try {
      return Object.keys(hfe.cfms).some((cfmKey) => {
        return Object.keys(hfe.cfms[cfmKey].pifs).length === 0;
      });
    } catch (e) {
      return true;
    }
  };

  pifsNotAnswered = (hfe: HFEType) => {
    try {
      return Object.keys(hfe.cfms).some((cfmKey) => {
        return Object.values(hfe.cfms[cfmKey].pifs).some((pif) => {
          return Object.keys(pif.questions).length === 0;
        })
      })
    } catch (e) {
      return true;
    }
  };

  updateScore = (score: string | number, cfid: number | string, cfmKey: string, pifName: string) => {
    this.setState({
      cf_to_hfes: {
        ...this.state.cf_to_hfes,
        [cfid]: this.state.cf_to_hfes[cfid].map(hfe=>{
          if (cfmKey in hfe.cfms){
            hfe.cfms[cfmKey].pifs[pifName].score = score
          }
          return hfe
        })
      }
    })
  }

  updateProgress = () => {
    const { match: { params: { id } } } = this.props;
    
    PhoenixApiManager.openMainModel(id).then(res => {
      this.setState({ updatedProgressForQuantificationAccess: res.data.progress }, () => {
        // State update has completed, now call getProgress
      this.getCFs(false)
      });
    });
  }
  

  disableEditing = (hfeName: string) => {
    const { items } = this.state;
    const hfeIndex = items.findIndex(item => item.name === hfeName);
    if (hfeIndex === 0 || hfeIndex === -1) return false;

    const prevHFE = items[hfeIndex - 1];
    if (this.isHFEEmpty(prevHFE)) return true;
    if (this.cfmsNotAnswered(prevHFE)) return true;
    if (this.pifsNotAnswered(prevHFE)) return true;
  };

  render() {
    const { items, isLoading,criticalFunctions,cf_id_to_names,cf_to_hfes,classes } = this.state;
    const {
      depth, match: { params: { id } }, intl, allCFs, onResponseUpdated
    } = this.props;

    return (
      <React.Fragment>
        <BreadcrumbsItem to={`/model/${id}/main-analysis/hfe`}>{intl.formatMessage({ id: 'analysis.hfe' })}</BreadcrumbsItem>
        <div style={{textAlign: "right"}}>
        <TreeReferenceButton
        tooltipTitle={intl.formatMessage({ id: 'checkMasterESD' })}
        title="Master ESD"
        graphView={<HCLTreeMxGraphView data={this.state.data} />}
      />
      </div>
        <Switch>
          <Route
            exact
            path="/model/:id/main-analysis/hfe/"
            render={prop => {
              const disabledItems = items.filter(item => this.disableEditing(item.name)).map(item => item.name);
              
              return (
                <div>
                  <FormControlLabel
                    control={
                      <S
                        checked={this.state.expertmodeactive}
                        onChange={()=> this.setState({expertmodeactive: !this.state.expertmodeactive})}
                        name="toggle"
                        color="primary"
                      />
                    }
                    label="Expert Mode?"
                  />
            
                  <HumanFailureEventOverview
                    cf_to_hfes={cf_to_hfes}
                    cf_id_to_names={cf_id_to_names}
                    criticalFunctions={criticalFunctions}
                    items={items}
                    isLoading={isLoading}
                    handleSortChange={this.handleSortChange}
                    cf_to_progress={this.state.cf_to_progress}
                    disabledItems={disabledItems}
                    onResponseUpdated={onResponseUpdated}
                    updateScore={this.updateScore}
                    expertmodeactive={this.state.expertmodeactive}
                    getProgress={this.updateProgress}
                    {...prop}
                  />
                </div>
              );
            }}
          />
          {/* /model/:id/main-analysis/:cfid/hfe/ */}
          <Route
            path="/model/:id/main-analysis/:cfid/hfe/"
            // model/1306/main-analysis/1307/hfe/BP_B/edit/
            render={prop => {
              const { match: { params: { hfeName,cfid }} } = prop;
              if(items.length > 0){
                return (
                  <HFERouter
                    {...prop}
                    hfeItems={items}
                    depth={depth + 1}
                    progress={this.state.cf_to_progress[cfid]}
                    cfid={cfid}
                    disableEditing={this.disableEditing(hfeName)}
                    onResponseUpdated={onResponseUpdated}
                    restartHFEreloadCounter={this.restartHFEreloadCounter}
                  />
                )
              }
              return null;
            }}
          />
          <Route
            render={prop => (
              <ViewNoMatch
                {...prop}
                message={intl.formatMessage({ id: '404.pageNotFound' })}
              />
            )}
          />
        </Switch>
       
{window.location.href.split('/').slice(-2)[0] === "hfe"?(
        <Link to={`${ApiManager.MODEL_PATH}/${id}/main-quantification`}>
              <Button
              variant={"contained"}
              color={"primary"}
              size={"large"}
              fullWidth={true}
              disabled={this.state.updatedProgressForQuantificationAccess.all.summary.completed !== this.state.updatedProgressForQuantificationAccess.all.summary.total}
            >
              <ResultsIcon className={this.state.classes.quantifyIcon} />
              {intl.formatMessage({ id: 'results.quantify' })}
            </Button>
      </Link>
):(null)}

      </React.Fragment>
    );
  }
}

export default injectIntl(HFEs);
