import HumanFailureEventTraveler from "../HumanFailureEventTraveler";
import {useIntl} from "react-intl";

import Button from "@material-ui/core/Button";
import React,{useEffect,useState} from "react";
import RestoreIcon from '@material-ui/icons/Restore';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from "@material-ui/core/Typography";
type HumanFailureEventControllerActionsViewProps = {
	id: number,
	hfeName: string,
	traveler: HumanFailureEventTraveler,
	currentSelection: any,
	setCurrentSelection: (currentSelection: any) => void,
	history: any,
	modelId:number,
	onResponseUpdated: any
}

const HumanFailureEventControllerActionsView = (props: HumanFailureEventControllerActionsViewProps) => {

	const { id, hfeName, history, traveler, currentSelection, setCurrentSelection, onResponseUpdated,modelId } = props;
	
	const intl = useIntl();
	
	const handleStep = (answer: boolean) => {
		traveler.next(answer);
		setCurrentSelection(traveler.getCurrentSelection());
	};

	const nextButton = () => {
		const [nextClicked,setNextClicked]=useState(false);
		return (
<div>
 <Button disabled={nextClicked} variant="contained" color="primary" fullWidth onClick={ () => {
				setNextClicked(true);
				traveler.save()
					
					.then(() => 
						onResponseUpdated())
					
					.then(() => history.push({
						pathname: `/model/${modelId}/main-analysis/hfe/`
					}, {reload: true}
					));
			}}>
				{intl.formatMessage({ id: "analysis.next" })}
			</Button>
			{nextClicked ?(<div>
      <CircularProgress />
      <Typography>Posting data...</Typography>
    </div>
):(null)}
			</div>
		
		);
		
	};

	if (currentSelection) {
		if (Array.isArray(currentSelection)) {
			return (
				<React.Fragment>
					<Button
						variant="contained"
						startIcon={<RestoreIcon />}
						onClick={() => {
							traveler.undo();
							setCurrentSelection(traveler.getCurrentSelection());
						}}
						disabled={traveler.isUndoHistoryEmpty()}
					>
						Back
					</Button>
					<Button
						variant="contained"
						color="primary"
						fullWidth
						onClick={() => handleStep(true)}
					>
						{intl.formatMessage({ id: "save&continue" })}
					</Button>
				</React.Fragment>
			);
		}

		return (
			<React.Fragment>
				<Button
					variant="contained"
					startIcon={<RestoreIcon />}
					onClick={() => {
						traveler.undo();
						setCurrentSelection(traveler.getCurrentSelection());
					}}
					disabled={traveler.isUndoHistoryEmpty()}
				>
					Back
				</Button>
				<Button
					variant="contained"
					color="secondary"
					fullWidth
					onClick={() => handleStep(false)}
				>
					{intl.formatMessage({ id: "no" })}
				</Button>
				<Button
					variant="contained"
					color="primary"
					fullWidth
					onClick={() => handleStep(true)}
				>
					{intl.formatMessage({ id: "yes" })}
				</Button>
			</React.Fragment>
		);
	} else if (traveler.savedNodesAreEmpty()) {
		return (
			<Button variant="contained" color="primary" fullWidth onClick={() => {
				traveler.init();
				setCurrentSelection(traveler.getCurrentSelection());
			}}>
				Restart
			</Button>
		)
	} else {
		return nextButton();
	}
};

export default HumanFailureEventControllerActionsView;
