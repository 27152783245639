import React, {FunctionComponent, useEffect, useRef, useState} from "react";
import EventTreeMxGraph from "hcl-web-editor/app/mxGraph/HCLTreeMxGraph/EventTreeMxGraph";
import FaultTreeMxGraph from "hcl-web-editor/app/mxGraph/HCLTreeMxGraph/FaultTreeMxGraph";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Switch, Theme, Tooltip} from "@material-ui/core";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import GpsFixedTwoToneIcon from '@material-ui/icons/GpsFixedTwoTone';
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import {mxCellHighlight, mxCell, mxGraphLayout, mxEvent} from "mxgraph-js";
import HCLTreeVertex from "hcl-web-editor/app/mxGraph/HCLTreeNode/HCLTreeVertex";
import useTheme from "@material-ui/core/styles/useTheme";
import DecisionPointVertex from "../../../MxGraph/HCLTreeNode/PhoenixFlowchartNode/DecisionPointVertex";
import HCLTreeEdge from "hcl-web-editor/app/mxGraph/HCLTreeNode/HCLTreeEdge";
import PhoenixFlowchartMxGraphDecoder from "../../../MxGraph/PhoenixFlowchart/PhoenixFlowchartMxGraphDecoder";
import DesignBasisScenarioLayout from "../../../MxGraph/PhoenixFlowchart/DesignBasisScenarioLayout";
import MaintenanceScenarioLayout from "../../../MxGraph/PhoenixFlowchart/MaintenanceScenarioLayout";
import BranchPointVertex from "../../../MxGraph/HCLTreeNode/PhoenixFlowchartNode/BranchPointVertex";
import InitVertex from "hcl-web-editor/app/mxGraph/HCLTreeNode/EventTreeNode/InitEvent/InitVertex";
import EndStateVertex from "hcl-web-editor/app/mxGraph/HCLTreeNode/EventTreeNode/EndStateNode/EndStateVertex";
import PhoenixEndStateVertex from "../../../MxGraph/HCLTreeNode/PhoenixFlowchartNode/PhoenixEndStateVertex";
import { useIntl } from "react-intl";
import { UserPreferencesContext } from "hcla-web-frontend-primitives/app/config/PreferencesProvider";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderWidth: 1,
    display: 'flex',
    borderStyle: "solid",
    borderColor: theme.palette.text.primary,
    overflow: "hidden",
    height: "100%",
    backgroundColor: theme.palette.background.paper,
    position: "relative"
  },

  content: { // for some reason, the trees are being rendered in this file (/SinglePhoenixModelView, rather than the identical file in /SinglePhoenixMainModelView)
    height: "100%",
    width: "100%"
  },

  controller: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    "& > *": {
      marginTop: theme.spacing(1)
    }
  },

  buttonGroup: {
    background: theme.palette.background.paper,
    borderRadius: "20%",
    boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2)",
  },

  zoomInButton: {
    borderRadius: "20% 20% 0 0"
  },

  zoomOutButton: {
    borderRadius: "0 0 20% 20%"
  },

  targetButton: {
    borderRadius: "20%"
  },

  center: {
    textAlign: "center",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  }
}));

type PhoenixTreeMxGraphViewProps = {
  graph: PhoenixFaultTreeMxGraph | PhoenixEventTreeMxGraph | PhoenixFlowChartMxGraph, // three different types of graphs, faulttreegraph is messed up
  target?: string | string[], // key of the cell to highlight
}

export const PhoenixTreeMxGraphView = (props: PhoenixTreeMxGraphViewProps) => {

  const { graph, target } = props;

  const classes = useStyles();

  if (!graph) {
    return (
      <div className={classes.root}>
        <PhoenixTreeMxGraphLoadingView />
      </div>
    );
  }

  return (
    <UserPreferencesContext.Consumer>
      {
        (value: any) => {
          graph.setPreferencesProvider(value.provider);
          return (
            <div className={classes.root}>
              <PhoenixTreeMxGraphContentView
                graph={graph}
                target={target}
              />
            </div>
          );
       }
      }
    </UserPreferencesContext.Consumer>
  );

};

const PhoenixTreeMxGraphLoadingView: FunctionComponent = () => {

  const classes = useStyles();
  const intl = useIntl();

  return (
    <div className={classes.center}>
      <CircularProgress />
      <Typography>{intl.formatMessage({ id: 'loadingTheGraph' })}</Typography>
    </div>
  );
};

const TEMPLATE = {
  DESIGN_BASIS_SCENARIO: 'Design Basis Scenarios',
  DESIGN_BASIS_SCENARIO_TEMPLATE: 'Design Basis Scenarios Template',
  MAINTENANCE_SCENARIO: 'Maintenance Scenarios',
};

export class PhoenixEventTreeMxGraph extends EventTreeMxGraph {

  private highlighters: mxCellHighlight[];
  private data: any;

  constructor(data: any) {
    super(() => this.onGraphMounted(data), () => {});
    // const theme = useTheme();
    this.highlighters = [];
    this.data = data;
  }

  /**
   * Highlight target cell
   * This will dehighlight the previous highlighted cell
   * @param target {mxCell} - target cell to highlight
   */
  highlight(target: mxCell[]) {

    this.highlighters.forEach(highlighter => highlighter.destroy());
    this.highlighters = [];

    target.forEach(el => {
      const targetHighlighter = new mxCellHighlight(this, "red", 2, true);
      this.highlighters.push(targetHighlighter);
      targetHighlighter.highlight(this.view.getState(el))
    })
    // this.highlighter.highlight(this.view.getState(target))
  }

  /**
   * Translate to target cell such that target cell is located
   * at the center of the graph container
   * @param target {mxCell} - target cell to translate to
   */
  translateTo(target: mxCell[]) {
    const scale = this.getView().scale;
    const middle = target[Math.floor((target.length) / 2)];
    const x = -middle.geometry.x-middle.geometry.width/2+this.container.clientWidth/2/scale;
    const y = -middle.geometry.y-middle.geometry.height/2+this.container.clientHeight/2/scale;
    this.getView().setTranslate(x, y);
  }

  onGraphMounted(data: any) {
    this.populateFromJSON(data);
    this.setPanning(true);
    this.applyDefaultLayout();
    this.setEnabled(false);
  }

}

export class PhoenixFaultTreeMxGraph extends FaultTreeMxGraph {

  private highlighters: mxCellHighlight[];
  private rootKey: string;

  constructor(data: any, onCellClick?: (cell: mxCell) => void, onCellsPopulated?: () => void) {
    super(() => {
      this.onGraphMounted(data);
      if (onCellsPopulated) onCellsPopulated();
    }, () => {});
    // const theme = useTheme();
    this.highlighters = [];
    this.rootKey = data.top_node.name;
    this.addClickListener(onCellClick);
  }

  private addClickListener(callback: (cell: mxCell) => void) {
    this.addListener(mxEvent.CLICK, (sender: any, evt: any) => {
      const cell = evt.getProperty("cell");
      callback(cell);
      evt.consume();
    });
  }

  getRootKey() {
    return this.rootKey;
  }

  rerender(cell: mxCell) {
    this.getView().clear(cell, false, false);
    this.getView().validate();
  }

  /**
   * Highlight target cell
   * This will dehighlight the previous highlighted cell
   * @param target {mxCell} - target cell to highlight
   */
  highlight(target: mxCell[]) {

    this.highlighters.forEach(highlighter => highlighter.destroy());
    this.highlighters = [];

    target.forEach(el => {
      const targetHighlighter = new mxCellHighlight(this, "red", 2, true);
      this.highlighters.push(targetHighlighter);
      targetHighlighter.highlight(this.view.getState(el))
    })
    // this.highlighter.highlight(this.view.getState(target))
  }
  /**
   * Translate to target cell such that target cell is located
   * at the center of the graph container
   * @param target {mxCell} - target cell to translate to
   */
  translateTo(target: mxCell[]) {
    const scale = this.getView().scale;
    const middle = target[Math.floor((target.length) / 2)];
    const x = -middle.geometry.x-middle.geometry.width/2+this.container.clientWidth/2/scale;
    const y = -middle.geometry.y-middle.geometry.height/2+this.container.clientHeight/2/scale;
    this.getView().setTranslate(x, y);
  }

  onGraphMounted(data: any) {
    this.populateFromJSON(data);
    this.forceSetIdsVisible(false);
    this.setPanning(true);
    this.applyDefaultLayout();
    this.setEnabled(false);
  }

}

export class PhoenixFlowChartMxGraph extends PhoenixEventTreeMxGraph {

  private templateName: string;

  constructor(data: any, templateName: string) {
    super(data);
    this.templateName = templateName;
  }

  populateFromJSON(inputJSON: any) {
    new PhoenixFlowchartMxGraphDecoder(this, inputJSON).decode();
  }

  getDefaultLayout(): mxGraphLayout {
    switch (this.templateName) {
      case TEMPLATE.DESIGN_BASIS_SCENARIO_TEMPLATE:
      case TEMPLATE.DESIGN_BASIS_SCENARIO:
        return new DesignBasisScenarioLayout(this);
      case TEMPLATE.MAINTENANCE_SCENARIO:
        return new MaintenanceScenarioLayout(this);
      default:
        break;
    }

    return super.getDefaultLayout();
  }

  setStyleForCells(cells: mxCell[], style: {[key: string]: string}) {
    const model = this.getModel();
    cells.forEach(v => {
      const prevStyles = v.getStyle();
      model.setStyle(v, prevStyles + ';' + HCLTreeVertex.mapStyles(style));
    });
  };

  setStyleForVerticesWithKeys(keys: string[], style: {[key: string]: string}) {
    keys.forEach(k => this.setStyleForCells(this.getVerticesWithKey(k), style));
  }

  applyDecisionPointStyles(answeredDecisionPointNames: { yes: string[], no: string[] }, activeDecisionPointName: string) {
    const decisionPoints = this.getVertices().filter(v => v instanceof DecisionPointVertex);
    const activeDecisionPointNames = [...answeredDecisionPointNames.yes, ...answeredDecisionPointNames.no, activeDecisionPointName];
    const inactiveDecisionPointNames = decisionPoints.filter(dp => !activeDecisionPointNames.includes(dp.getKey())).map(d => d.getKey());

    this.setStyleForVerticesWithKeys(answeredDecisionPointNames.yes, DecisionPointVertex.yesStyle);
    this.setStyleForVerticesWithKeys(answeredDecisionPointNames.no, DecisionPointVertex.noStyle);
    this.setStyleForVerticesWithKeys([activeDecisionPointName], DecisionPointVertex.activeStyle);
    this.setStyleForVerticesWithKeys(inactiveDecisionPointNames, DecisionPointVertex.inactiveStyle);

    // for each yes - type DP set all:
    // 1. outgoing success edges to active
    // 2. outgoing failure edges to inactive
    answeredDecisionPointNames.yes.forEach(y => {
      this.getVerticesWithKey(y).forEach(v => {
        if (!v.edges) return;
        v.edges.forEach(e => {
          if (e.source === v) {
            e.active = e.value === 'yes';
            this.setStyleForCells([e], e.active ? HCLTreeEdge.activeStyle : HCLTreeEdge.inactiveStyle);
          }
        });
      });
    });

    // for each no - type DP set all:
    // 1. outgoing success edges to inactive
    // 2. outgoing failure edges to active
    answeredDecisionPointNames.no.forEach((n) => {
      this.getVerticesWithKey(n).forEach((v) => {
        if (!v.edges) return;
        v.edges.forEach((e) => {
          if (e.source === v) {
            e.active = e.value === 'no';
            this.setStyleForCells([e], e.active ? HCLTreeEdge.activeStyle : HCLTreeEdge.inactiveStyle);
          }
        });
      });
    });

    // for each active-type DP set all:
    // 1. outgoing edges to inactive.
    this.getVerticesWithKey(activeDecisionPointName).forEach((v) => {
      if (!v.edges) return;
      v.edges.forEach((e) => {
        if (e.source === v) {
          e.active = false;
          this.setStyleForCells([e], HCLTreeEdge.inactiveStyle);
        }
      });
    });

    // for each inactive-type - DP set all:
    // 1. outgoing and incoming edges to inactive.
    inactiveDecisionPointNames.forEach((dp) => {
      this.getVerticesWithKey(dp).forEach((v) => {
        if (!v.edges) return;
        v.edges.forEach((e) => {
          e.active = false;
          this.setStyleForCells([e], HCLTreeEdge.inactiveStyle);
        });
      });
    });
  }

  applyBranchPointStyles() {
    const allBPs = this.getVertices().filter(v => v instanceof BranchPointVertex);
    // for every BP
    // 0. begin by setting the BP to inactive and setting all its outgoing edges to inactive
    // 1. if BP has an incoming active edge:
    //    1a. set BP to active
    //    1b. set that BPs outgoing edges to active
    allBPs.forEach((bp) => {
      if (!bp.edges) bp.edges = [];

      const hasIncomingActiveEdge = bp.edges.filter(e => e.target === bp && e.active).length > 0;
      const outgoingEdges = bp.edges.filter(e => e.source === bp) || [];
      if (hasIncomingActiveEdge) {
        this.setStyleForCells([bp], BranchPointVertex.activeStyle);
        outgoingEdges.forEach((e) => {
          e.active = true;
          this.setStyleForCells([e], HCLTreeEdge.activeStyle);
        });
      } else {
        this.setStyleForCells([bp], BranchPointVertex.inactiveStyle);
        outgoingEdges.forEach((e) => {
          e.active = false;
          this.setStyleForCells([e], HCLTreeEdge.inactiveStyle);
        });
      }

    });
  };

  applyInitEventStyles = () => {
    const allInits = this.getVertices().filter(v => v instanceof InitVertex);
    allInits.forEach(v => {
      this.setStyleForCells(v.edges, HCLTreeEdge.activeStyle);
    });
  };

  applyEndStateStyles = () => {
    const allEndStates = this.getVertices().filter(v => v instanceof EndStateVertex);
    allEndStates.forEach((es) => {
      const inEdge = es.edges && es.edges.filter(e => e.target === es && e.active).length > 0;
      this.setStyleForCells([es], inEdge ? PhoenixEndStateVertex.activeStyle : PhoenixEndStateVertex.inactiveStyle);
    });
  };
}

type PhoenixTreeMxGraphViewContentProps = {
  graph: PhoenixFaultTreeMxGraph | PhoenixFlowChartMxGraph | PhoenixEventTreeMxGraph,
  target?: string | string[]
}

const PhoenixTreeMxGraphContentView = (props: PhoenixTreeMxGraphViewContentProps) => {

  const { graph, target } = props;
  const classes = useStyles();
  const ref = useRef(null);

  useEffect(() => {
    graph.mount(ref.current);
    return () => graph.unmount();
  }, []);

  useEffect(() => {
    if (target) {

      let targetVertices: any[] = [];

      (Array.isArray(target) ? target : [target]).forEach(key => {
        if (!graph.getVerticesMap()[key]) return;
        targetVertices.push(graph.getVerticesMap()[key][0]);
      });
      if (targetVertices.length > 0) {
        graph.highlight(targetVertices);
        graph.translateTo(targetVertices);
      }
    }
  }, [target]);

  return (
    <React.Fragment>
      <div className={classes.content} ref={ref} />
      <PhoenixTreeMxGraphController
        graph={graph}
        target={target}
      />
    </React.Fragment>
  );
};

type PhoenixTreeMxGraphControllerProps = {
  graph: PhoenixEventTreeMxGraph | PhoenixFaultTreeMxGraph,
  target?: string | string[]
}

const PhoenixTreeMxGraphController: FunctionComponent<PhoenixTreeMxGraphControllerProps> = (props) => {

  const { graph, target } = props;
  const classes = useStyles();

  const [descriptions, setDescriptions] = useState<boolean>(graph.getDescriptionEnabled());

  return (
    <div className={classes.controller}>
      <PhoenixTreeMxGraphTargetController
        graph={graph}
        target={target}
      />
      <PhoenixTreeMxGraphZoomController graph={graph}  />
      <PhoenixTreeMxGraphDescriptionsController graph={graph} />
    </div>
  );
};

const PhoenixTreeMxGraphZoomController: FunctionComponent<PhoenixTreeMxGraphControllerProps> = (props) => {

  const { graph } = props;
  const classes = useStyles();

  return (
    <ButtonGroup
      className={classes.buttonGroup}
      orientation="vertical"
      size="small"
      variant="text"
    >
      <Button
        className={classes.zoomInButton}
        onClick={() => graph.zoomIn()}
      >
        <AddIcon />
      </Button>
      <Button
        className={classes.zoomOutButton}
        onClick={() => graph.zoomOut()}
      >
        <RemoveIcon />
      </Button>
    </ButtonGroup>
  );
};

const PhoenixTreeMxGraphTargetController: FunctionComponent<PhoenixTreeMxGraphControllerProps> = (props) => {

  const classes = useStyles();
  const { graph, target } = props;

  return (
    <ButtonGroup
      className={classes.buttonGroup}
      orientation="vertical"
      size="small"
      variant="text"
    >
      <Button
        className={classes.targetButton}
        onClick={() => {
          let targetVertices: any[] = [];

          (Array.isArray(target) ? target : [target]).forEach(key => {
            if (!graph.getVerticesMap()[key]) return;
            targetVertices.push(graph.getVerticesMap()[key][0]);
          });

          graph.translateTo(targetVertices)
        }}
      >
        <GpsFixedTwoToneIcon />
      </Button>
    </ButtonGroup>
  );
};

const PhoenixTreeMxGraphDescriptionsController: FunctionComponent<PhoenixTreeMxGraphControllerProps> = (props) => {
  const { graph } = props;
  const classes = useStyles();
  const intl = useIntl();

  const [isDescriptionsEnabled, setIsDescriptionsEnabled] = useState<boolean>(graph.getDescriptionEnabled());

  const handleChange = () => {
    setIsDescriptionsEnabled(!isDescriptionsEnabled);
  };

  useEffect(() => {
    graph.setDescriptionEnabled(isDescriptionsEnabled);
  },[isDescriptionsEnabled]);

  return (
    <div className={classes.buttonGroup}>
      <Tooltip title={intl.formatMessage({ id: 'nodeDescriptions' })}>
        <Switch checked={isDescriptionsEnabled} onChange={handleChange} color={"primary"} />
      </Tooltip>
    </div>
  );
};
