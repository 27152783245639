import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import TextField from '@material-ui/core/TextField/TextField';
import { withStyles } from '@material-ui/core';


const styles = theme => ({
  tooltipRoot: {
    maxWidth: 500,
    fontSize: 'large',
  },

  inputRoot: {
    padding: `${1.5 * theme.spacing(1)}px`,
    borderRadius: `${1.5 * theme.spacing(1)}px`,
    border: '1px solid rgba(0,0,0,0)',
  },

  inputFocused: {
    border: theme.palette.type === 'light' ? '1px solid rgba(0,0,0,0.22)' : '1px solid rgba(255,255,255,0.70)',
  },
});


const StepperDescriptionTextField = (props) => {
  const {
    classes, questionDescription, responseDescription, onChange, editingDisabled
  } = props;
  return (
    <Tooltip
      title={questionDescription}
      classes={{ tooltip: classes.tooltipRoot }}
    >
      <TextField
        type="text"
        disabled={editingDisabled}
        variant="filled"
        fullWidth
        defaultValue={responseDescription}
        rows={5}
        rowsMax={5}
        multiline
        autoFocus
        onChange={onChange}
        InputProps={{
          disableUnderline: true,
          classes: {
            root: classes.inputRoot,
            focused: classes.inputFocused,
          },
        }}
      />
    </Tooltip>
  );
};

StepperDescriptionTextField.defaultProps = {
  responseDescription: '',
};

StepperDescriptionTextField.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  questionDescription: PropTypes.string.isRequired,
  responseDescription: PropTypes.string,
  onChange: PropTypes.instanceOf(Function).isRequired,
};

export default withStyles(styles)(StepperDescriptionTextField);
