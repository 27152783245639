import React, {useEffect, useState} from 'react';
import { injectIntl } from 'react-intl';
import { withStyles, Theme } from '@material-ui/core/styles';

//@ts-ignore
import CircularIndeterminate from 'hcla-web-frontend-primitives/app/components/primitives/Progress/CircularIndeterminate';

import CFMViewerEntry from '../CFMEntry/CFMViewerEntry';
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
//@ts-ignore
import { HashLink as Link } from "react-router-hash-link";
import AnalysisReferencesHeader from "../../../../../primitives/AnalysisReferencesHeader";
import CardHeader from "@material-ui/core/CardHeader";
import Divider from "@material-ui/core/Divider";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import List from "@material-ui/core/List";
import { withRouter } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import AssessmentIcon from "@material-ui/icons/Assessment";
import Tooltip from "@material-ui/core/Tooltip";
import {HFEType} from "../../../../Types";
import PhoenixApiManager from "../../../../../../Api/PhoenixApiManager";
import {PhoenixFaultTreeMxGraph} from "../../../PhoenixTreeMxGraphView";
import ActionButtons from "../../../../../primitives/Button/ActionButtons";
import AvatarText from "../../../../../primitives/Icons/AvatarText";
import {Typography} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import NotePadButton from '../../DP/NotePadButton';


const styles = (theme: Theme) => ({
  cardRoot: {
    overflow: 'auto'
  },
  cardContent: {
    paddingTop: theme.spacing(0)
  },
  cardHeaderRoot: {
    paddingBottom: theme.spacing(0)
  },
  fullWidth: {
    width: '100%'
  },
  cardTitleContainer: {
    border: `1px solid ${theme.palette.grey.A100}`,
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  cardTitleLeftContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  cardTitleRightContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
});

const getLowerLevelPifs = (pifsTemplate: any, pif: any) => {
  const lowerLevelPifs: any[] = [];
  Object.keys(pifsTemplate[pif].questions).forEach(
    (question) => {
      const newElement = pifsTemplate[pif].questions[question].lower_level_pif;
      if (lowerLevelPifs.indexOf(newElement) === -1) lowerLevelPifs.push(newElement);
    },
  );
  return lowerLevelPifs;
};

interface Props {
  classes: { [key: string]: any },
  pifs: { [key: string]: any },
  pifsTemplate: { [key: string]: any },
  loading: boolean,
  title: any,
  filterInput: string,
  handleFilterChange: () => void,
  handleFilterSubmit: () => void,
  handleSortChange: () => void,
  onChange: () => void,
  match: { [key: string]: any },
  intl: { [key: string]: any },
  history: { [key: string]: any },
  disableEditing: boolean,
  faultTreeId: number,
  onResponseUpdated: () => void,
  hfe: HFEType
}

const CFMViewer = (props: Props) => {
  const {
    loading, classes, disableEditing, pifsTemplate, pifs, onChange, hfeItems, intl, history, match: { params, params: { cfmName, hfeName, id, cfid }}, onResponseUpdated, faultTreeId, ...others
  } = props;
  console.log("94", props)
  const [hfe, setHfe] = useState({})
  const [cfmTreeId, setCfmTreeId] = useState(null)
  const [cfName,setCfName]=useState('');
  useEffect(()=>{
    PhoenixApiManager.getHFEsForModelWithId(cfid)
      .then(response => response.status === 200 && response.json())
      .then(data =>{
        console.log(data, id, "~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~")
        data.forEach(currentHfe=> {
            if(currentHfe.cfms[cfmName]){
              setCfmTreeId(currentHfe.cfms[cfmName].fault_tree_id)
              setHfe(currentHfe)
            }
        })
      })
      getCfName(cfid);
  },[])

console.log(hfe, cfmTreeId, pifsTemplate, pifs, 626, pifsTemplate)


  const [graph, setGraph] = useState(null);

  const cfmBasePathName = `/model/${id}/analysis/hfe/${hfeName}/cfm/${cfmName}`;
  // const cfmTreeId = hfe.cfms[cfmName] && hfe.cfms[cfmName].fault_tree_id;

  // useEffect(() => {
  //   if(cfmTreeId){
  //     console.log(cfmTreeId, 'skill')
    
  //   PhoenixApiManager.getPhoenixTreeWithMetadata(cfmTreeId)
  //     .then((response) =>response.status===200 && response.json())
  //     .then((data: any) => {console.log(data, 'this is crt id'); setGraph(new PhoenixFaultTreeMxGraph(data.tree_data))})
  //     .catch(err=>console.log(err))
  //   }
  // },[cfmTreeId]);


const getCfName=(cfid)=>{
  PhoenixApiManager.getCfFunctionDetails(cfid)
  .then(response=>response.status===200 && response.json())
  .then((data)=>{
    setCfName(data.title)
  })
};


  const getScore = (pifKey: string) => {
    const pif = hfe.cfms[cfmName].pifs[pifKey];
    const questionsNotAnswered = pif && pif.questions && Object.keys(pif.questions).length === 0;
    if (pif && pifs.includes(pifKey) && !questionsNotAnswered) {
      return pif.score;
    }
    return null;
  };
  
  const getExpression = (pifKey: string) => {
    try {
      const pif = hfe.cfms[cfmName].pifs[pifKey];
      return pif.expression;
    } catch (e) {
      return null;
    }
  };

  const getOverride = (pifKey: string) => {
    try {
      const pif = hfe.cfms[cfmName].pifs[pifKey];
      return pif.override;
    } catch (e) {
      return false;
    }
  };

  if (disableEditing) {
    history.push(`/model/${id}/analysis/hfe/`);
  }
  if (loading || cfmTreeId===null || Object.keys(hfe).length===0 || !Object.keys(pifsTemplate).length) {
    return <CircularIndeterminate size={80} color="secondary" />;
  }
  const currentCFM = hfe && hfe.cfms[cfmName];
  return (
    <React.Fragment>
      <AnalysisReferencesHeader
        crt={{id:cfid}}
        bp={hfe && hfe.label.name}
        hfe={hfe && { id: hfe.fault_tree_id }}
        inPaperContainer={true}
      />

      
      <Card className={classes.cardRoot}>
      <h1>{cfName}</h1>
      <NotePadButton
            cfId={cfid}
            title={'Notepad'}
            tooltipTitle={'Open Notepad'}
            />
        <CardHeader
          className={classes.cardHeaderRoot}
          title={
            <Grid container className={classes.cardTitleContainer}>
              <Grid item xs={8} className={classes.cardTitleLeftContainer}>
                <AvatarText text={'CFM'}/>
                <Typography variant={"h5"}>{currentCFM && currentCFM.label.name}</Typography>
              </Grid>
              <Grid item xs={4} className={classes.cardTitleRightContainer}>
                {/* <ActionButtons
                  graphView={{
                    graph: graph,
                    toolTipTitle: 'View CFM'
                  }}
                  quantifyLink={{
                    url: `${cfmBasePathName}/quantify/`,
                  }}
                /> */}
              </Grid>
            </Grid>
          }
          subheader={
            <React.Fragment>
              <Typography variant={"h6"}>{intl.formatMessage({ id: 'analysis.pif' })}</Typography>
              <Divider />
            </React.Fragment>
            }
        />
        <CardContent className={classes.cardContent}>
          <List>
            {Object.keys(pifsTemplate).map(
              pif =>{ 
                console.log("123", pif)
                return (
                <CFMViewerEntry
                  checked={pifs.includes(pif)}
                  pif={{
                    name: pif,
                    lowerLevelPifs: getLowerLevelPifs(pifsTemplate, pif),
                    score: getScore(pif),
                    expression: getExpression(pif),
                    override: getOverride(pif),
                  }}
                  handleToggle={onChange}
                  faultTreeId={hfe.fault_tree_id}
                  cfmTreeId={cfmTreeId}
                  onResponseUpdated={onResponseUpdated}
                  hfe={hfe}
                  {...others}
                />
              )
                })}
          </List>
        </CardContent>
        <CardActions className={classes.actions} disableActionSpacing>
          <Link onClick={window.location.reload} to={`/model/${params.id}/main-analysis/hfe/`} className={classes.fullWidth}>
            <Button
              disabled={pifs.length < 1}
              color="primary"
              variant="contained"
              fullWidth
            >
              {intl.formatMessage({ id: 'analysis.next' })}
            </Button>
          </Link>
        </CardActions>
      </Card>
    </React.Fragment>
  );
};


CFMViewer.defaultProps = {
  title: null,
};

export default withStyles(styles)(injectIntl(withRouter(CFMViewer)));
