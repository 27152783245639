import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import ListView from 'hcla-web-frontend-primitives/app/components/primitives/List/ListView';
import { injectIntl } from 'react-intl';
import PIFEntry from './PIFEntry/PIFEntry';
import { PIFDetailsProgress } from '../../../Types';

const styles = () => ({
  listRoot: {
    width: '100%',
    maxWidth: 'unset',
  },
});

interface Props {
  classes: { [key: string]: any },
  items: {
    pif: string,
    pifScore: any,
    pifOverride: boolean,
    pifExpression: { [key: string]: any },
    cfm: string,
    cfmLabel: string,
    hfe: string,
    hfeLabel: string,
    key: string,
    faultTreeId: number,
    cfmTreeId: number
  }[],
  isLoading: boolean,
  title: any,
  defaultText?: string,
  filterInput: string,
  handleFilterChange: () => void,
  handleFilterSubmit: () => void,
  handleSortChange: () => void,
  depth: number,
  match: { [key: string]: any },
  intl: { [key: string]: any },
  location: { [key: string]: any },
  detailsProgress: PIFDetailsProgress[],
  onResponseUpdated: () => void,
  hfeLabel: string
}
const PIFListView = (props: Props) => {
  const {
    classes, items, match: { params: { id,cfid } }, defaultText, intl, detailsProgress, hfeLabel, onResponseUpdated, ...others
  } = props;
  const getProgress = (hfe: string, cfm: string, pif: string) => detailsProgress.find(item => item.hfe === hfe && item.cfm === cfm && item.name === pif);
  return (
    <ListView
      header={null}
      items={items.map(
        item => (
          <PIFEntry
            key={item.key}
            pifName={item.pif}
            pifScore={item.pifScore}
            pifExpression={item.pifExpression}
            pifOverride={item.pifOverride}
            cfmKey={item.cfm}
            cfmLabel={item.cfmLabel}
            hfeKey={item.hfe}
            hfeLabel={hfeLabel ? hfeLabel : item.hfeLabel}
            isFlatList
            modelId={id}
            cfid={cfid}
            progress={getProgress(item.hfe, item.cfm, item.pif)}
            faultTreeId={item.faultTreeId}
            cfmTreeId={item.cfmTreeId}
            onResponseUpdated={onResponseUpdated}
          />
        ),
      )}
      rootClassOverride={classes.listRoot}
      defaultText={defaultText || intl.formatMessage({ id: 'analysis.noPIFLabel' })}
      {...others}
    />
  );
};

PIFListView.defaultProps = {
  title: null,
};

export default withStyles(styles)(injectIntl(PIFListView));
