import React from 'react';

const PhoenixModelContext = React.createContext({
  details: null,
  refresh: () => {},
  isSavingInProgress: false,
  isLoading: true,
  loaded: false,
});
 
export default PhoenixModelContext;
