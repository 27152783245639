import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { injectIntl } from "react-intl";
import PhoenixModelContext from "../../PhoenixModelContext";
import ImageUploader from "./ImageUploader";
import ReportButton from "../../Report/ReportButton";
import ListView from "hcla-web-frontend-primitives/app/components/primitives/List/ListView";
import CriticalFunctionEntry from "../../../primitives/CriticalFunctionEntry";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import CreateButton from "hcla-web-frontend-primitives/app/components/primitives/Buttons/CreateButton";
import AddIcon from "@material-ui/icons/Add";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import PhoenixApiManager from "../../../../Api/PhoenixApiManager";
import Switch from "@material-ui/core/Switch";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const styles = (theme) => ({
  formControl: {
    "label + &": {
      marginTop: 20,
    },
  },
  descriptionText: {
    color: theme.palette.type === "light" ? "#3a3a3a" : theme.palette.grey[100],
  },
  descriptionPaper: {
    padding: "16px",
    marginTop: "16px",
    marginBottom: "16px",
    boxShadow: "unset",
    background: "rgba(0,0,0,0.1)",
    maxHeight: "20vh",
    overflow: "auto",
    border: "1px solid rgba(0,0,0,0.13)",
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
  },
  listViewRoot: {
    maxWidth: "none",
  },
  criticalFunctionsRoot: {
    marginBottom: `${theme.spacing(2)}px`,
  },
  criticalFunctionsHeaderRoot: {
    width: "100%",
    padding: `${theme.spacing(4)}px`,
  },
  criticalFunctionsFormRoot: {
    ["@media only screen and (min-width: 1441px)"]: {
      maxWidth: "50%",
      margin: `0 auto ${theme.spacing(2)}px`,
    },
  },
});

const HeaderView = (props) => {
  const {
    classes,
    description,
    intl,
    match: {
      params: { id },
    },
  } = props;
  console.log(id);
  return (
    <div>
      <div className={classes.row}>
        <Typography variant="h5">
          {intl.formatMessage({ id: "overview" })}
        </Typography>
        <ReportButton id={id} />
      </div>
      <Paper square className={classes.descriptionPaper}>
        <Typography className={classes.descriptionText} variant="body1">
          {description}
        </Typography>
      </Paper>
    </div>
  );
};

const emptyCriticalFunctionState = {
  title: "",
  description: "",
  probability: "",
};

const Overview = (props) => {
  const {
    classes,
    intl,
    details,
    history,
    match: {
      params: { id },
    },
  } = props;
  const [criticalFunctions, setCriticalFunctions] = useState([]);
  const [newCriticalFunction, setNewCriticalFunction] = useState(
    emptyCriticalFunctionState
  );
  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: false,
  });

  const filterCriticalFunctions = (results) => {
    return results.filter((result) =>
      result.title.includes(`${id.toString()} - `)
    );
  };
  useEffect(() => {
    PhoenixApiManager.getMainCriticalFunctions(id).then((data) => {
      console.log(data);
      setCriticalFunctions(data.data);
      if (data.data.next) getNextCriticalFunctions(data.data.next);
    });
  }, []);

  const getNextCriticalFunctions = (next) => {
    PhoenixApiManager.getMainNextCriticalFunctions(next)
      .then((res) => res.json())
      .then((data) => {
        const newCriticalFunctions = [...criticalFunctions];
        newCriticalFunctions.concat(filterCriticalFunctions(data.results));
        if (data.next) getNextCriticalFunctions(data.next);
      });
  };

  const handleNewCriticalFunctionChange = (e) => {
    const criticalFunction = { ...newCriticalFunction };
    criticalFunction[e.target.name] = e.target.value;
    setNewCriticalFunction(criticalFunction);
  };

  const addCriticalFunction = () => {
    const criticalFunction = {
      title: newCriticalFunction.title,
      description: newCriticalFunction.description,
    };
    const criticalFunctionWithID = {
      ...criticalFunction,
      id: criticalFunctions ? criticalFunctions.length + 1 : 1,
    };
    const updatedCriticalFunctions = criticalFunctions
      ? [...criticalFunctions, criticalFunctionWithID]
      : [criticalFunctionWithID];
    if (!state.checkedA) {
      criticalFunction["failure_probability"] = newCriticalFunction.probability;
    }
    PhoenixApiManager.createMainCriticalFunction(id, criticalFunction)
      .then((response) =>
        setCriticalFunctions([...criticalFunctions, response.data])
      )
      .then(() => setNewCriticalFunction(emptyCriticalFunctionState));
  };

  const removeCriticalFunction = (cfId) => {
    const indexToRemove = criticalFunctions.findIndex(
      (item) => item.id === cfId
    );
    console.log("cfId: " + cfId + " id: " + id);
    const updatedCriticalFunctions = [...criticalFunctions];
    PhoenixApiManager.deleteMainCriticalFunction(id, cfId)
      .then(() => updatedCriticalFunctions.splice(indexToRemove, 1))
      .then(() => setCriticalFunctions(updatedCriticalFunctions));
  };

  const handleNextButton = () => {
    history.push(`/model/${id}/main-master-esd`);
  };

  const handleChange = (event) => {
    setState({
      checkedA: !state.checkedA,
      checkedB: !state.checkedB,
    });

    // setState({ ...state, [event.target.name]: event.target.checked });
  };

  const disableAddHandler = () => {
    if (state.checkedA) {
      return newCriticalFunction.title.trim() === "";
    } else {
      return (
        newCriticalFunction.title.trim() === "" ||
        newCriticalFunction.probability.trim() === ""
      );
    }
  };

  const newCriticalFunctionForm = (
    <Card>
      <Grid container spacing={2}>
        <CardHeader
          classes={{ root: classes.criticalFunctionsHeaderRoot }}
          title={
            <Typography variant={"h5"}>
              Please Define the Critical Functions
            </Typography>
          }
          subheader={
            <React.Fragment>
              <Grid item xs={12}>
                <TextField
                  id={"newCriticalFunctionTitle"}
                  name={"title"}
                  autoFocus
                  value={newCriticalFunction.title}
                  onChange={handleNewCriticalFunctionChange}
                  placeholder={"Title"}
                  fullWidth
                  size={"small"}
                  variant={"standard"}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id={"newCriticalFunctionDescription"}
                  name={"description"}
                  value={newCriticalFunction.description}
                  onChange={handleNewCriticalFunctionChange}
                  placeholder={"Description"}
                  multiline
                  fullWidth
                  rows={2}
                  variant={"outlined"}
                />
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={state.checkedA}
                        onChange={handleChange}
                        name="checkedA"
                      />
                    }
                    label="Phoenix Model"
                    labelPlacement="start"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={state.checkedB}
                        onChange={handleChange}
                        name="checkedA"
                      />
                    }
                    label="Probability Based"
                    labelPlacement="start"
                  />
                  <TextField
                    id={"newCriticalFunctionProbability"}
                    name={"probability"}
                    autoFocus
                    value={newCriticalFunction.probability}
                    onChange={handleNewCriticalFunctionChange}
                    placeholder={"Enter Probability"}
                    fullWidth
                    size={"small"}
                    variant={"standard"}
                    disabled={state.checkedA}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                <Button
                  color={"secondary"}
                  variant={"contained"}
                  fullWidth
                  startIcon={<AddIcon />}
                  onClick={addCriticalFunction}
                  disabled={disableAddHandler()}
                >
                  Add
                </Button>
              </Grid>
            </React.Fragment>
          }
          disableTypography
        />
      </Grid>
    </Card>
  );

  return (
    <PhoenixModelContext.Consumer>
      {(value) => {
        const {
          details: { description, pra_tree },
          refresh,
        } = value;

        return (
          <div className={classes.root}>
            <HeaderView {...props} description={description} />
            <Grid container spacing={4}>
              <Grid item xs={8}>
                <ImageUploader
                  id={Number(id)}
                  imageData={pra_tree}
                  withContainer
                  onImageSet={refresh}
                />
              </Grid>
              <Grid item xs={4}>
                {newCriticalFunctionForm}
                <Paper>
                  {criticalFunctions.length > 0 && (
                    <ListView
                      defaultText={"Add Critical Functions to Continue"}
                      items={
                        criticalFunctions &&
                        criticalFunctions.map((item) => (
                          <CriticalFunctionEntry
                            key={item.id}
                            item={item}
                            remove={removeCriticalFunction}
                          />
                        ))
                      }
                      isLoading={false}
                      paginationEnabled={false}
                      rootClassOverride={classes.listViewRoot}
                    />
                  )}
                  {criticalFunctions.length >= 1 && (
                    <Button
                      size={"large"}
                      color={"primary"}
                      variant={"contained"}
                      onClick={handleNextButton}
                      fullWidth
                    >
                      {intl.formatMessage({ id: "analysis.next" })}
                    </Button>
                  )}
                </Paper>
              </Grid>
            </Grid>
          </div>
        );
      }}
    </PhoenixModelContext.Consumer>
  );
};

Overview.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
    path: PropTypes.string,
  }).isRequired,
  classes: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
  depth: PropTypes.number.isRequired,
};

export default withStyles(styles)(injectIntl(Overview));
