import React, {useState} from 'react';
import { withStyles } from '@material-ui/core/styles';
import AuthCard from 'hcla-web-frontend-primitives/app/Auth/AuthCard';
//@ts-ignore
import PhoenixLogo from 'branding-asset-primitives/phoenix/PhoenixHRALogo.png';
import ScreeningPassword from './ScreeningPassword'

const styles = () => ({
  root: {
    display: 'grid',
    overflow: 'auto',
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logoImage: {
    width: '18rem',
    height: 'auto',
  },
});

const { version } = require('../../../package');
const versionText = (`build v${version}`);

interface Props {
  classes: { [key: string]: any }
}

const PageAuth = (props: Props) => {

  const [intialScreeningPassword, setInitialScreeningPassword] = useState('')
  const tempScreen = '21efdde290043a0efab79ced2dee63cd2d34e8efdcc124572a3d26b8835ad469'
  const { classes } = props;

  if (intialScreeningPassword != tempScreen){
    return <ScreeningPassword 
      intialScreeningPassword={intialScreeningPassword} 
      setInitialScreeningPassword={setInitialScreeningPassword}
    />
  }

  return (
    <div className={classes.root}>
      <AuthCard
        headerProps={{
          avatar: (<img className={classes.logoImage} aria-label="Phoenix" src={PhoenixLogo} alt="Phoenix Logo" />),
        }}
      />
    </div>
  );
};

export default withStyles(styles)(PageAuth);
