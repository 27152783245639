import React from 'react';
import { withStyles, Theme } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';

const styles = (theme: Theme) => ({
  chip: {
    height: '21px',
    overflow: 'hidden',
    fontSize: '0.7rem',
  },
  chipAvatar: {
    fontSize: '0.8rem',
    overflow: 'hidden',
    borderRadius: '0px',
    padding: '0px 24px',
  },
});

interface Props {
  classes: { [key: string]: any },
  name: string,
  label: string,
  link: any,
}

const HFEChip = (props: Props) => {
  const {
    name, label, link, classes, ...others
  } = props;

  return (
    <Chip
      avatar={name ? <Avatar>{name}</Avatar> : null}
      classes={{
        root: classes.chip,
        avatar: classes.chipAvatar,
      }}
      label={label}
      component={link}
      clickable
      variant="outlined"
      color="primary"
      {...others}
    />
  );
};

HFEChip.defaultProps = {
  name: null,
};

export default withStyles(styles)(HFEChip);
