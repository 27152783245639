import React,{FunctionComponent,useEffect, useState}from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { injectIntl } from "react-intl";
import ListItem from "@material-ui/core/ListItem/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import PhoenixApiManager from '../../../../Api/PhoenixApiManager';
import {RouteComponentProps} from "react-router";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";

import ListView from "hcla-web-frontend-primitives/app/components/primitives/List/ListView";
import ApiManager from "hcla-web-frontend-primitives/app/Api/ApiManager";

import CircularProgressIcon from "../../../primitives/Progress/CircularProgressIcon";
import PhoenixModelContext from "../../PhoenixModelContext";
import ResultsIcon from "../../../primitives/Icons/ResultsIcon";

const styles = theme => ({
  progressView: {
    marginTop: `${theme.spacing(1) * 3}px`,
    display: "flex",
    flexDirection: "column"
  },

  listCard: {
    margin: "auto",
    width: "100%",
    maxWidth: "600px"
  },

  extendedIcon: {
    marginRight: theme.spacing(1)
  },

  resultsButton: {
    marginBottom: theme.spacing(1),
    padding: `${theme.spacing(1)}px ${theme.spacing(1) * 4}px`
  },

  listItem: {
    padding: `${theme.spacing(1) * 2}px`,
    display: "flex",
    alignItems: "center",
    "& > *": {
      marginRight: `${theme.spacing(1)}px`
    },
    "& :last-child": {
      marginRight: "unset"
    },
    "& :first-child": {
      marginRight: theme.spacing(1),
    }
  },

  actionsContainer: {
    right: `${theme.spacing(1) * 2}px`,
    "& > *": {
      marginRight: `${theme.spacing(1)}px`
    },
    "& :last-child": {
      marginRight: "unset"
    }
  },

  textBox: {
    paddingRight: `${theme.spacing(1) / 4}px`
  },

  listRoot: {
    width: "100%",
    maxWidth: "unset"
  },

  analysisTitle: {
    justifyContent: 'space-between !important'
  },

  quantifyBtn: {
    boxShadow: 'none'
  }
});


const progressCardEntry = (
  name,
  description,
  disabled,
  classes,
  progress,
  detail,
  path
) => {
  const renderLink = itemProps => (
    <Link
      to={{
        pathname: `${ApiManager.MODEL_PATH}/${path}`,
        state: {
          details: detail
        }
      }}
      {...itemProps}
    />
  );
  return (
    <ListItem
      key={name}
      disabled={disabled}
      alignItems="center"
      className={classes.listItem}
      button
      component={renderLink}
      disableGutters
      divider
    >
      <ListItemAvatar>
        <CircularProgressIcon noCheckmark progress={progress} />
      </ListItemAvatar>

      <ListItemText
        className={classes.textBox}
        primary={name}
        primaryTypographyProps={{ variant: "h6" }}
        secondary={description}
      />
    </ListItem>
  );
};


const progressView = (classes, details, intl, history, cfid, id) => {
  const crtItems = [];
  const hfeItems = [];
  const pifItems = [];
  crtItems.push(
    progressCardEntry(
      intl.formatMessage({ id: "analysis.crt" }),
      intl.formatMessage({ id: "analysis.crtDesc" }),
      details.progress.dp.summary.total === 0,
      classes,
      details.progress.dp.summary,
      details,
      `${id}/main-analysis/${cfid}/crt/`
    )
  );
  crtItems.push(
    progressCardEntry(
      intl.formatMessage({ id: "analysis.bpd" }),
      intl.formatMessage({ id: "analysis.bpdDesc" }),
      details.progress.bp.summary.total === 0 || (details.progress.dp.summary.completed !== details.progress.dp.summary.total),
      classes,
      details.progress.bp.summary,
      details,
      `${id}/main-analysis/${cfid}/bp/`
    )
  );
  /*
  hfeItems.push(
    progressCardEntry(
      intl.formatMessage({ id: "analysis.hfe" }),
      intl.formatMessage({ id: "analysis.hfeDesc" }),
      details.progress.hfe.summary.total === 0,
      classes,
      details.progress.hfe.summary,
      details,
      `${id}/main-analysis/hfe/`
    )
  );
  hfeItems.push(
    progressCardEntry(
      intl.formatMessage({ id: "analysis.cfm" }),
      intl.formatMessage({ id: "analysis.cfmDesc" }),
      details.progress.cfm.summary.total === 0,
      classes,
      details.progress.cfm.summary,
      details,
      `${id}/main-analysis/${cfid}/hfe/cfm`
    )
  );
  pifItems.push(
    progressCardEntry(
      intl.formatMessage({ id: "analysis.pif" }),
      intl.formatMessage({ id: "analysis.pifDesc" }),
      details.progress.pif.summary.total === 0,
      classes,
      details.progress.pif.summary,
      details,
      `${id}/main-analysis/${cfid}/hfe/cfm/pif`
    )
  );
  */
  return (
    <div className={classes.progressView}>
      <div className={classes.listCard}>
        <ListView
          title={(
            <PhoenixModelContext.Consumer>
              {value => {
                const { details: { id,cfid }, details: { progress: { all:{ summary: { completed, total }}}} } = value;
                return (
                  <Grid container className={classes.analysisTitle}>
                    <Typography variant="h5">
                      {intl.formatMessage({ id: "analysisProgress" })}
                    </Typography>
                    <Button
                      variant="contained"
                      aria-label="Quantify"
                      color="primary"
                      size="small"
                      className={classes.quantifyBtn}
                      disabled={completed !== total}
                      onClick={() => history.push(`/model/${id}/${cfid}/detailed-quantification`)}
                    >
                      <ResultsIcon disabled={completed !== total} className={classes.extendedIcon} />
                      {intl.formatMessage({ id: 'results.quantify' })}
                    </Button>
                  </Grid>
                );
              }}
            </PhoenixModelContext.Consumer>
          )}
          items={crtItems}
          rootClassOverride={classes.listRoot}
          isLoading={false}
        />
        {/*
        <ListView
          items={hfeItems}
          rootClassOverride={classes.listRoot}
          isLoading={false}
        />
        <ListView
          items={pifItems}
          rootClassOverride={classes.listRoot}
          isLoading={false}
        />
        */}
      </div>
    </div>
  );
};




const AnalysisView =  (props) => {
  const { classes, intl, history,match} = props;
  const { params: { id, cfid } } = match;
  const cfId = parseInt(cfid)
  const Id = parseInt(id)
  const [cfdetail, setCfdetail] = useState(null);
  const [cfname, setCfname] = useState('');

  useEffect(() => {
    PhoenixApiManager.getCfFunctionDetails(cfId)
      .then(response => response.json())
      .then(data => {
        setCfdetail(data)
        setCfname(data.title)
      })
      .catch(err => console.error(err));
  },[]);

  return (
    <PhoenixModelContext.Consumer>
      {value => {
        const { details } = value;
        return (
          <div className={classes.root}>
            <BreadcrumbsItem to={`/model/${id}/main-analysis/${cfId}`}>
              {intl.formatMessage({ id: `${cfname} ` })}
            </BreadcrumbsItem>
            {cfdetail!=null?(progressView(classes, cfdetail, intl, history,cfId,Id)):(null)}
          </div>
        );
      }}
    </PhoenixModelContext.Consumer>
  );
};

AnalysisView.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
    path: PropTypes.string
  }).isRequired,
  classes: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
  depth: PropTypes.number.isRequired,
};

export default withStyles(styles)(injectIntl(AnalysisView));
