import React,{ useState,useEffect }from "react";
import { Switch, Route} from "react-router-dom";
import { withStyles } from "@material-ui/core";
import { injectIntl } from "react-intl";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { useParams} from "react-router";

// @ts-ignore
import ViewNoMatch from "hcla-web-frontend-primitives/app/components/primitives/Error/ViewNoMatch";

import CrewResponseTreeBuilderView from "./DP/CrewResponseTreeBuilderView";
import BranchPointDescriptionBuilder from "./BP/BranchPointDescriptionBuilder";
import HFEs from "./HFE/HFEs";
import AnalysisView from "./AnalysisView";
import PhoenixApiManager from '../../../../Api/PhoenixApiManager';
import cfModelContext from "../../cfModelContext";
import PhoenixModelContext from "../../PhoenixModelContext";
import CFCrewResponseTreeBuilderView from "./DP/CFCrewResponseTreeBuilderView";
import HumanFailureEventEditorView from "./HFE/SingleHFEView/HumanFailureEventEditorView";
import QuantifyCF from "../Quantification/QuantifyCF"


const styles = () => ({
  fullHeight: {
    height: "100%"
  }
});

interface Props {
  match: {
    url: string,
    path: string,
    params: any,
  },
  location: any,
  classes: any,
  depth: number,
  intl: any
}


export const AnalysisContext = React.createContext(null)

const AnalysisHeader = (props: Props) => {
  const { depth, match: { params: { id} }, intl } = props;
  const [cfdetail, setCfdetail] = useState({});
  const [crtModelId, setCrtModelId] = useState(null);
  const [cfname, setCfname] = useState('');
  const [copiedCFID,setCopiedCFID]=useState(null); // using this in context
  const [copiedCF,setCopiedCF]=useState([]);

  const cfid=window.location.href.split('/').slice(-1)[0];
  useEffect(() => {
    setCrtModelId(cfid)
  },[]);

  const handleCopyInCFM= (copiedCFID)=>{
    if (copiedCFID != null) {
      localStorage.setItem("copiedCFID", copiedCFID) // storing copy id in local storage because page refreshes and loses state
      localStorage.setItem("clickedCopyCF", "true")
    }
    setCopiedCFID(copiedCFID)
    PhoenixApiManager.getCfFunctionDetails(copiedCFID)
    .then(response => response.json())
    .then( (data) => {
      setCopiedCF([data])
    })
    
  }


  // const getCopiedCF=(copiedCFID)=>{
  //   PhoenixApiManager.getCfFunctionDetails(copiedCFID)
  //   .then(response => response.json())
  //   .then( (data) => {
  //     console.log("DATA IN SET COPY",data)
  //     setCopiedCF([data])
  //     console.log("COPIED CF",copiedCF)
  //   })
  // }

  useEffect(()=>{
    
    handleCopyInCFM(copiedCFID)
  },[copiedCFID])



  useEffect(() => {
   PhoenixApiManager.getCfFunctionDetails(cfid)
    .then(data => {
      setCfdetail(data);
      setCfname(data.title)
    })

    .catch(err => console.error(err));
  }, [crtModelId])

  
  return (
    <PhoenixModelContext.Consumer>
      {value => {
        const { details, refresh } = value;
        return (
          <React.Fragment>
            
            {/*<BreadcrumbsItem to={`/model/${id}/main-analysis/${crtModelId}`} depth={depth}>
            
        </BreadcrumbsItem>*/}
           
            <Switch>
              <Route
                path="/model/:id/main-analysis/:cfid/crt/"
                render={ prop =>(
                  <CFCrewResponseTreeBuilderView
                    {...prop}
                    handleCopyInCFM={handleCopyInCFM}
                    onResponseUpdated={refresh}
                  />
                )}

              />      
              <Route
              exact
              path="/model/:id/main-analysis/:cfid/quantify/"
              render={prop => (
                <QuantifyCF
                  {...prop}
                  depth={depth + 1}
                />
              )}
            />
              {/* <Route
                path="/model/:id/main-analysis/crt/"
                render={prop => (
                  <CrewResponseTreeBuilderView
                    {...prop}
                    crtID={details.model_data.event_trees[0]}
                    templateID={details.model_data.template}
                    onResponseUpdated={refresh}
                  />
                )}
              /> */}
              <Route
                path="/model/:id/main-analysis/:cfid/bp/"
                render=
                {prop => (
                  <BranchPointDescriptionBuilder
                    {...prop}
                    templateID={details.template}
                    onResponseUpdated={refresh}
                    depth={depth}
                    
                  />
                )}
              />
              <Route
                exact
                path="/model/:id/main-analysis/hfe/"
                render={prop => (
                  <HFEs
                    {...prop}
                    progress={details.progress}
                    depth={depth}
                    onResponseUpdated={refresh}
                  />
                )}
              />
                            <Route
                path="/model/:id/main-analysis/:cfid/"
                render={prop => (
                  <HFEs
                    {...prop}
                    progress={details.progress}
                    depth={depth}
                    onResponseUpdated={refresh}
                  />
                )}
              />
              
              <Route
                exact
                path="/model/:id/main-analysis/:cfid/"
                render={prop => <AnalysisView {...prop} depth={depth+1}/>}
              />
                      {/* <Route
          exact
          path="/model/:id/main-analysis/:cfid/hfe/:hfeName/edit/"
          render={prop => (
            <HumanFailureEventEditorView
              {...prop}
              // hfe={hfeItems.find(hfe => hfeName === hfe.name)}
              // disableEditing={disableEditing}
              // onResponseUpdated={onResponseUpdated}
            />
          )}
        /> */}
              {/* <Route
                render={prop => (
                  <ViewNoMatch
                    {...prop}
                    message={intl.formatMessage({ id: "404.pageNotFound" })}
                  />
                )}
              /> */}
            </Switch>
          </React.Fragment>
        );
      }}
    </PhoenixModelContext.Consumer>


  );
};

export default withStyles(styles)(injectIntl( AnalysisHeader));


