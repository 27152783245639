import BasicEventVertex from "hcl-web-editor/app/mxGraph/HCLTreeNode/FaultTreeNode/BasicEventNode/BasicEventVertex";
import FaultTreeTransferGateVertex
	from "hcl-web-editor/app/mxGraph/HCLTreeNode/FaultTreeNode/FaultTreeTransferGateNode/FaultTreeTransferGateVertex";
import HumanFailureEventTraveler from "../HumanFailureEventTraveler";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Typography from "@material-ui/core/Typography";
import Switch from "@material-ui/core/Switch";
import BasicEventVertexValue
	from "hcl-web-editor/app/mxGraph/HCLTreeNode/FaultTreeNode/BasicEventNode/BasicEventVertexValue";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Theme} from "@material-ui/core";
import BasicEventEditingDialog from "../Dialogs/BasicEventEditingDialog";
import CFMEditingDialog from "../Dialogs/CFMEditingDialog";
import CFMQuestionDialog from "../Dialogs/CFMQuestionDialog";
import InsertCommentIcon from '@material-ui/icons/InsertComment';
import HelpIcon from '@material-ui/icons/Help';
import { Tooltip } from "@material-ui/core";
const useStyles = makeStyles((theme: Theme) => ({
	tableRoot: {
		tableLayout: 'fixed'
	},
}));

type HumanFailureEventNodeTableProps = {
	title: string,
	nodes: BasicEventVertex[] | FaultTreeTransferGateVertex[],
	traveler: HumanFailureEventTraveler
}

const HumanFailureEventNodeTable = (props: HumanFailureEventNodeTableProps) => {

	const { title, nodes, traveler } = props;
	const classes = useStyles();
	const intl = useIntl();

	const isBasicEvent = nodes[0] instanceof BasicEventVertex;

	const [isCFM, setIsCFM] = useState(null)
	const [checked, setChecked] = useState(new Array(nodes.length).fill(false));
	const [targetBasicEvent, setTargetBasicEvent] = useState(null);
	const [targetCFM, setTargetCFM] = useState(null);
	const [targetCFMQuestion, setTargetCFMQuestion] = useState(null);
	const [preCheckIsCFM, setPreCheckIsCFM] = useState(null)
	useEffect(() => {

		const checks = nodes.map(node => {
			if (node instanceof BasicEventVertex && !node.getKey().includes("CFM")) {
				setIsCFM(false)
				const lol = traveler.isBasicEventSet(node.getKey());
				console.log(lol, 'fease event')
				return traveler.isBasicEventSet(node.getKey());
			}
			else {
				setIsCFM(true)
				const sheesh = traveler.isCFMSet(node.getKey());
				console.log(sheesh, 'CFM for fease')
				return true
				// return traveler.isCFMSet(node.getKey());

			}
		});

		// setChecked(checks);
		setPreCheckIsCFM(checks)
	}, [nodes]);


	if (isCFM === null) {
		return null
	}

	return (
		<React.Fragment>
			<TableContainer component={Paper}>
				<Table className={classes.tableRoot}>
					<TableHead>
						<TableRow>
							<TableCell>{isBasicEvent && isCFM === false ? title : 'CFM'}</TableCell>
							<TableCell align="center">{`${intl.formatMessage({ id: 'no' })}/${intl.formatMessage({ id: 'yes' })}`}</TableCell>
							{
								 isBasicEvent && isCFM === false ? <TableCell align="left">{intl.formatMessage({ id: 'Failure Probability' })}</TableCell> : <TableCell />
							}
							<TableCell />
						</TableRow>
					</TableHead>
					<TableBody>
						{
							nodes.map((node: BasicEventVertex | FaultTreeTransferGateVertex, index: number) => {
								return (
									<TableRow key={node.getKey()}>
										<TableCell component="th" scope="row">
											<Typography variant="body2">{node.getValue().getLabel().getName()}</Typography>
										</TableCell>
										<TableCell align="center">
											<Switch
												checked={checked[index]}
												onChange={(e) => {
													let checks = checked.slice();
													checks[index] = e.target.checked;
													setChecked(checks);
													console.log(checks, 'this is checked')
													if (preCheckIsCFM[index] === false) {
														console.log('isBasicEvent && isCFM === false')
														if (e.target.checked) {
															console.log('deeper', node)
															setTargetBasicEvent(node);
															const prob = (node.getValue() as BasicEventVertexValue).getExpression().getValue();
															console.log(prob, 'probability of basic event')
															traveler.setBasicEvent(node.getKey(), { failure_probability: prob });
														}
														else {
															traveler.unsetBasicEvent(node.getKey())
														}
													} else {
														if (e.target.checked) {
															console.log('THIS SHOULD NOT HAPPEN!!!')
															traveler.setCFM(node.getKey())
														} else {
															traveler.unsetCFM(node.getKey())
														}
													}
												}}
											/>
										</TableCell>
										{
											preCheckIsCFM[index] === false ?
												(
													<React.Fragment>
														<TableCell align="center">
															<Typography variant="body2">
																{(node.getValue() as BasicEventVertexValue).getExpression().getValue()}
															</Typography>
														</TableCell>
														<TableCell align="center">
															<Button
																variant="contained"
																disabled={!checked[index]}
																onClick={() => {
																	setTargetBasicEvent(node);
																}}
															>
																{intl.formatMessage({ id: 'edit' })}
															</Button>
														</TableCell>
													</React.Fragment>
												) :
												(
													<TableCell align="center" colSpan={2}>
														<Button  color="primary" onClick={() => setTargetCFMQuestion(node)}>
															<Tooltip title={"Guiding Question"}>
																<HelpIcon />
															</Tooltip>
														</Button>
														<Button  color="primary" onClick={() => setTargetCFM(node)}>
															<Tooltip title={"Description"}>
																<InsertCommentIcon />
															</Tooltip>
														</Button>
													</TableCell>
												)
										}
									</TableRow>
								);
							})
						}
					</TableBody>
				</Table>
			</TableContainer>
			<BasicEventEditingDialog
				targetBasicEvent={targetBasicEvent}
				setTargetBasicEvent={setTargetBasicEvent}
				traveler={traveler}
			/>
			<CFMEditingDialog
				targetCFM={targetCFM}
				setTargetCFM={setTargetCFM}
			/>			
			<CFMQuestionDialog
				targetCFMQuestion={targetCFMQuestion}
				setTargetCFMQuestion={setTargetCFMQuestion}
			/>
		</React.Fragment>
	);

};

export default HumanFailureEventNodeTable;
