import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { injectIntl } from 'react-intl';
import { withStyles, Theme } from '@material-ui/core/styles/index';
import PhoenixApiManager from '../../../../../../Api/PhoenixApiManager';
import QuantificationCard from '../../../Quantification/QuantificationCard';
// import QuantificationCard from './QuantificationResult/QuantificationCard'
import { computeHEPFromData } from '../../../Quantification/QuantificationView';
import {HFEType} from "../../../../Types";
import List from '@material-ui/core/List';
import { makeStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import CopyToClipboardChip from "hcla-web-frontend-primitives/app/components/primitives/Chips/CopyToClipboardChip";
import Card from "@material-ui/core/Card";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";




const styles = (theme: Theme) => ({
});
 

interface Props {
  classes: { [key: string]: any },
  match: { [key: string]: any },
  intl: { [key: string]: any },
  depth: number,
  cfm: {
    cfms: { [key: string]: any },
    fault_tree_id: number,
    hfe: Partial<HFEType>,
    label: {
      description: string,
      name: string
    },
    nodes: { [key: string]: any },
    pifs: { [key: string]: any },
    role: string,
    source_type: string
  },
}

interface State {
  quantificationResult: {
    data: { [key: string]: any },
    loaded: boolean,
    loading: boolean
  },
  tree: {
    id: number,
    loaded: boolean,
    loading: boolean
  }
}

class QuantifyCFM extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      tree: {
        id: null,
        loading: false,
        loaded: false,
      },
      quantificationResult: {
        data: {},
        loaded: false,
        loading: false,
      },
    };
  }

  componentDidMount() {
    const { match: { params: { cfmName, hfeName, id,cfid } } } = this.props;
    PhoenixApiManager.getCFMForModelWithIdAndHFE(cfid, hfeName, cfmName)
      .then(response => response.status === 200 && response.json())
      .then((cfm) => {
        if ('fault_tree_id' in cfm) {
          this.setState({
            tree: {
              id: cfm.fault_tree_id,
              loading: false,
              loaded: true,
            },
          }, () => console.log('cfm', cfm));
        } else {
          this.setState({
            tree: {
              id: null,
              loading: false,
              loaded: false,
            },
            quantificationResult: {
              data: {},
              loading: false,
              loaded: false,
            },
          });
        }
      });
  }

  fetchQuantificationResults = (treeId: number) => {
    const { match: { params: { id,cfid } } } = this.props;
    PhoenixApiManager.getQuantificationResultsForPhoenixTreeAndModelWithId(
      treeId,
      cfid,
      // limit,
      // offset,
      // nextOrPrev
    )
      .then(response => response.status === 200 && response.json())
      .then((list) => {
        if (list.results ? list.results.length === 0 : list.length === 0) { // if empty, then just set to 0
          this.setState({
            quantificationResult: {
              data: {},
              loading: false,
              loaded: true,
            },
          });
        } else {
          this.setState({
            quantificationResult: {
              data: list.results ? list.results[list.results.length - 1] : list[list.length - 1], // this is where the data is being set, and is set to the very last one
              loading: false,
              loaded: true,
            },
          });
        }
      });
  };

  isModelQuantifiable = () => {
    const { tree } = this.state;
    return tree.id !== null && tree.id !== undefined;
  };

  quantify = (configuration: any) => {
    const { match: { params: { id,cfid } } } = this.props;
    this.setState({
      quantificationResult: {
        data: {},
        loading: true,
        loaded: false,
      },
    }, () => {
      const { tree } = this.state;
      PhoenixApiManager.quantifyPhoenixTreeForModelWithId(
        tree.id,
        cfid,
        configuration
      )
        .then(response => response.status === 201 && response.json())
        .then((data) => {
          this.setState({
            quantificationResult: {
              data, // setting the state here
              loading: false,
              loaded: true,
            },
          });
        });
    });
  };

  computeHEPFromData = (data: any) => {
    try {
      const { result } = data.result_data.sequences['SQ_F.0'];
      return Number(result);
    } catch (e) {
      return Number.NaN;
    }
  };



    
  
    
 

  render() {
    const {
      depth, match: { params: { cfmName, hfeName, id,cfid } }, intl, cfm
    } = this.props;
    const { quantificationResult: { loaded, loading, data } } = this.state;
    
    

    if (Object.keys(data).length == 0 ) {
      return (
        <>
        <BreadcrumbsItem
          to={`/model/${id}/main-analysis/${cfid}/hfe/${hfeName}/cfm`}
          depth={depth - 2}
        >
          {intl.formatMessage({ id: 'analysis.cfm' })}
        </BreadcrumbsItem>
        <BreadcrumbsItem to={`/model/${id}/main-analysis/${cfid}/hfe/${hfeName}/cfm/${cfmName}`} depth={depth - 1}>
        <Typography variant="h6" color="primary" style={{ fontWeight: 600 }}>
        {cfmName}
    </Typography>
        </BreadcrumbsItem>
        <BreadcrumbsItem to={`/model/${id}/${cfid}/hfe/${hfeName}/cfm/${cfmName}/quantify`} depth={depth}>
          {intl.formatMessage({ id: 'quantify' })}
        </BreadcrumbsItem>
        <QuantificationCard
          loaded={loaded}
          loading={loading}
          data={data}
          onRequestQuantify={(configurations: any) => this.quantify(configurations)}
          title={intl.formatMessage({ id: 'results.quantificationCFM' })}
          computeErrorFunc={computeHEPFromData}
          quantifyDisabled={!this.isModelQuantifiable()}
          cfm={cfm}
        />
        </>
      )
    }
    return (
      <React.Fragment>
        <BreadcrumbsItem
          to={`/model/${id}/main-analysis/${cfid}/hfe/${hfeName}/cfm`}
          depth={depth - 2}
        >
          {intl.formatMessage({ id: 'analysis.cfm' })}
        </BreadcrumbsItem>
        <BreadcrumbsItem to={`/model/${id}/main-analysis/${cfid}/hfe/${hfeName}/cfm/${cfmName}`} depth={depth - 1}>
            <Typography variant="h6" color="primary" style={{ fontWeight: 600 }}>
            {cfmName}
        </Typography>
        </BreadcrumbsItem>
        <BreadcrumbsItem to={`/model/${id}/${cfid}/hfe/${hfeName}/cfm/${cfmName}/quantify`} depth={depth}>
          {intl.formatMessage({ id: 'quantify' })}
        </BreadcrumbsItem>
        {/* <QuantificationCard
          loaded={loaded}
          loading={loading}
          data={data}
          onRequestQuantify={(configurations: any) => this.quantify(configurations)}
          title={intl.formatMessage({ id: 'results.quantificationCFM' })}
          computeErrorFunc={computeHEPFromData}
          quantifyDisabled={!this.isModelQuantifiable()}
          cfm={cfm}
        /> */}
        <div className="App">
          <h1> <Typography variant="h4" color="primary" style={{ fontWeight: 600 }}>
        {cfmName}
    </Typography> Quantification Results</h1>
          {Object.keys(data.result_data).forEach(key => key===undefined||data.result_data[key] === undefined ? delete data.result_data[key] : {})}
          {Object.keys(data.result_data).map(key => {
            if (key !== "label_map" ) {
              if (data.result_data[key].Failure) {
                return (
                  <Card style={{ margin: "10px" }}>
                    <ListItem>
                      <ListItemText primary={`Name: ${key}`} />
                      <span>
                        <CopyToClipboardChip color="primary" variant="outlined" label={ `Failure = ${data.result_data[key].Failure.toExponential(3)}`} />
                        <CopyToClipboardChip color="primary" variant="outlined" label={ `Success = ${data.result_data[key].Success.toExponential(3)}`} />
                      </span>
                    </ListItem>
                  </Card>
                )
              } else if(data.result_data[key].Nominal) {    
                return (
                  <Card style={{ margin: "10px" }}>
                    <ListItem>
                      <ListItemText primary={`Name: ${key}`} />
                      <span>
                        <CopyToClipboardChip color="primary" variant="outlined" label={ `Nominal = ${data.result_data[key].Nominal.toExponential(3)}`} />
                        <CopyToClipboardChip color="primary" variant="outlined" label={ `Degraded = ${data.result_data[key].Degraded.toExponential(3)}`} />
                      </span>
                    </ListItem>
                  </Card>
                );
              }
            }
          })}
        </div>
      </React.Fragment>
    );
  }
}

export default injectIntl(withStyles(styles)(QuantifyCFM));
