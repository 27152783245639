import React from 'react';
import 'react-circular-progressbar/dist/styles.css';
import { withStyles } from '@material-ui/core/styles';
import CircularProgressbar from 'react-circular-progressbar';

const styles = () => ({
  root: {
    width: '64px',
    height: '64px',
    minWidth: '64px',
    minHeight: '64px',
  },
  progressBarContainer: {
    position: 'absolute',
  },
  wrapper: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

interface Props {
  children: any,
  classes: { [key: string]: any },
  progressbarProps: any,
}

const WraparoundCircularProgressBar = (props: Props) => {
  const { children, classes, ...others } = props;

  return (
    <div className={classes.root}>
      <div className={classes.progressBarContainer}>
        <CircularProgressbar {...others} />
      </div>
      <div className={classes.wrapper}>
        {children}
      </div>
    </div>
  );
};

WraparoundCircularProgressBar.defaultProps = {
  progressbarProps: null,
};

export default withStyles(styles)(WraparoundCircularProgressBar);
