import React from 'react';
import PropTypes from 'prop-types';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { injectIntl } from 'react-intl';
import ModelSettings from './ModelSettings';
import PhoenixModelContext from '../../PhoenixModelContext';

const SettingsView = (props) => {
  console.log("this is props",props)
  const {
    onRequestSave, depth, intl, match: { params: { id } },
  } = props;
  return (
    <PhoenixModelContext.Consumer>
      {
        (value) => {
          const { details, isSavingInProgress } = value;
          return (
            <React.Fragment>
              <BreadcrumbsItem to={`/model/${id}/main-settings`} depth={depth}>{intl.formatMessage({ id: 'settings' })}</BreadcrumbsItem>
              <ModelSettings
                details={details}
                onRequestSave={onRequestSave}
                isSaving={isSavingInProgress}
              />
            </React.Fragment>
          );
        }
      }
    </PhoenixModelContext.Consumer>
  );
};

SettingsView.propTypes = {
  onRequestSave: PropTypes.instanceOf(Function).isRequired,
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
    path: PropTypes.string,
  }).isRequired,
  depth: PropTypes.number.isRequired,

};

export default injectIntl(SettingsView);
