import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { withStyles, Theme } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Typography from '@material-ui/core/Typography';

import ApiManager from 'hcla-web-frontend-primitives/app/Api/ApiManager';
//@ts-ignore
import ModifiedTimeChip from "hcla-web-frontend-primitives/app/components/primitives/Chips/ModifiedTimeChip";
import ModelEntrySettingsIcon from './Buttons/ModelEntrySettingsIcon';
import CircularProgressIcon from '../../primitives/Progress/CircularProgressIcon';
import ModelEntryResultsIcon from './Buttons/ModelEntryResultsIcon';

const styles = (theme: Theme) => ({
  root: {
    padding: `${theme.spacing(1) * 2}px`,
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginRight: `${theme.spacing(1)}px`,
    },
    '& :last-child': {
      marginRight: 'unset',
    },
    '& :first-child': {
      // margin: 'unset',
      marginRight: theme.spacing(1),
    },
  },

  actionsContainer: {
    right: `${theme.spacing(1) * 2}px`,
  },

  textBox: {
    paddingRight: '260px',
  },

});

interface Props {
  detail: {
    id: number,
    title: string,
    date_created: string,
    date_modified: string,
    creator: number,
    assigned_users: any[],
    description: string,
    path: string,
    type: { [key: string]: any },
    subsystems: any[],
    progress: { [key: string]: any }
  },
  classes: any,
  key: any
}

const ModelEntry = (props: Props) => {
  const { classes, detail, key } = props;
  const progress = detail.progress.all.summary;
  const primaryText = (
    <Typography noWrap variant="subtitle1" color="textPrimary">{detail.title}</Typography>
  );

  const secondaryText = (
    <Typography noWrap variant="body2" color="textSecondary">{detail.description}</Typography>
  );

  const isModelComplete = progress.completed === progress.total;

  const renderLink = (itemProps: any) => (
    <Link
      to={{
        pathname: `${ApiManager.MODEL_PATH}/${detail.id}/overview`,
        state: {
          path: `/hcla/api/phoenix/model/${detail.id}/`,
          details: null,
        },
      }}
      {...itemProps}
    />
  );
  return (
    <ListItem
      key={key}
      alignItems="center"
      className={classes.root}
      button
      component={renderLink}
      divider
    >
      <ListItemAvatar>
        <CircularProgressIcon progress={progress} />
      </ListItemAvatar>
      <ListItemText
        disableTypography
        className={classes.textBox}
        primary={primaryText}
        secondary={secondaryText}
      />
      <ListItemSecondaryAction className={classes.actionsContainer}>
        <ModifiedTimeChip timestamp={detail.date_modified} />
        <ModelEntrySettingsIcon details={detail} />
        <ModelEntryResultsIcon complete={isModelComplete} details={detail} />
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default withStyles(styles)(withRouter(ModelEntry));
