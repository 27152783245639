import React from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Typography } from '@material-ui/core';
import PhoenixApiManager from '../../../../../../Api/PhoenixApiManager';


interface Props {
    openDialog: boolean,
    handleClose: () => void,
    bpFormData: any,
    cfid: any
}


export default function BPEditConfirmDialog(props: Props) {
    const { openDialog, handleClose, bpFormData, cfid } = props
    const [fullWidth] = React.useState(true);
    const [maxWidth] = React.useState<DialogProps['maxWidth']>('md');
    const status =  (bpFormData && bpFormData.completed) ? 'Edit': 'Create'
    const [loading, setLoading] = React.useState<boolean>(false);
    const [errors, setErrors] = React.useState<string>('')

    const onSubmit = () => {
      setLoading(true)
      setErrors('')
      const finalBPData: any = {...bpFormData}
      if (finalBPData.completed){
        if('failure_probability' in finalBPData){
          bpFormData['failure_probability'] = Number(bpFormData['failure_probability'])
        }
        PhoenixApiManager.patchBranchPointResponseForModelWithId(cfid, finalBPData.name, JSON.stringify(finalBPData))
        .then(response => response.status === 200 && response.json())
        .then(()=> {
          setLoading(false)
          handleClose()
        })
        .catch(()=>setErrors('There was an error fulfilling this request'))
      } else {
        PhoenixApiManager.postBranchPointResponseForModelWithId(cfid, JSON.stringify(finalBPData))
        .then(response => response.status === 201 && response.json())
        .then(() => {
          setLoading(false)
          handleClose()
        })
        .catch(()=>setErrors('There was an error fulfilling this request'))
      }
  }
     

    if (bpFormData == undefined) return <></>

    const styles = {style: {fontSize: '22px', margin: '10px 0'}, variant: 'h2', }

    const showBpFormData = () =>{
      return (
        <div style={{}}>
          <Typography {...styles}>Label: {bpFormData.label.name}</Typography>
          <Typography {...styles}>Success Path: {bpFormData.success}</Typography>
          <Typography {...styles}>Failure Path: {bpFormData.failure}</Typography>
          {bpFormData.failure_probability != null && <Typography {...styles}>Failure Probability: {bpFormData.failure_probability}</Typography>}
        </div>
      )
    }

    return (
    <React.Fragment>
        <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
        >
        <DialogTitle id="max-width-dialog-title">{`${status} ${bpFormData.name}`}</DialogTitle>
        {errors.length > 0 && <DialogTitle style={{color: 'red'}} id="max-width-dialog-title">{errors}</DialogTitle>}
        <DialogContent>
            <DialogContentText>
              <Typography variant='subtitle1'>
                  The information below will be saved to the database when you click on save, please edit the fields on form and open this dialog again to change the values
              </Typography>
              <br/>
              {showBpFormData()}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose} color="primary">
            Close
            </Button>
            {loading ? <CircularProgress/> :
              <Button onClick={onSubmit} color="primary">
              Save
            </Button>}
        </DialogActions>
        </Dialog>
    </React.Fragment>
    );
}
