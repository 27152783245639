import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {RouteComponentProps} from "react-router";
import { Redirect, Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import {CardActions} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Theme} from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";

//@ts-ignore
import CircularIndeterminate from "hcla-web-frontend-primitives/app/components/primitives/Progress/CircularIndeterminate";

import HumanFailureEventListItem from './HumanFailureEventListItem';
import {PhoenixRouteParams} from "../../../../NavBar/MainContainer";
import PhoenixApiManager from "../../../../../Api/PhoenixApiManager";
import {
  PhoenixEventTreeMxGraph,
  PhoenixTreeMxGraphView
} from "../../PhoenixTreeMxGraphView";
import ResultsIcon from "../../../../primitives/Icons/ResultsIcon";
import TreeReferenceButton from "./TreeReferenceButton";
import EventTreeMxGraphEditorView from 'hcl-web-editor/app/components/Model/SingleHCLModelView/SingleTreeView/MxGraphViews/EventTreeMxGraphEditorView';
import HCLApiManager from 'hcl-web-editor/app/Api/HclApiManager';

//expansion panel based on CFs
import { Accordion } from '@material-ui/core';
import { AccordionSummary } from '@material-ui/core';
import { AccordionDetails } from '@material-ui/core'
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { ViewArraySharp } from '@material-ui/icons';
import Grid from "@material-ui/core/Grid";
import ActionButtons from "../../../../primitives/Button/ActionButtons";
import NotePadButton from '../DP/NotePadButton';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import CircularProgressIcon from '../../../../primitives/Progress/CircularProgressIcon';


const useStyles = makeStyles((theme: Theme) => ({
  cardFrame: {
    maxHeight: 'calc(100vh - 114px)',
    minHeight: 'calc(100vh - 114px)',
  },

  header: {
    display: "flex",
    flex: '0 0 auto',
    padding: theme.spacing(2),
    alignItems: 'center',
    justifyContent: "space-between"
  },

  content: {
    flexGrow: 1,
    overflowY: "auto",
    padding: theme.spacing(2)
  },

  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    width:"100%"
  },


  quantifyIcon: {
    marginRight: theme.spacing(1)
  },

  cardActionsRoot: {
    flex: '0 0 auto'
  }

}));

interface HumanFailureEventOverviewProps extends RouteComponentProps<PhoenixRouteParams> {
  disabledItems: any;
  items: any;
  criticalFunctions:any;
  cf_id_to_names:any;
  cf_to_hfes:any;
  cf_to_progress:any;
  isLoading: boolean;
  handleSortChange: (sort: any) => void;
  onResponseUpdated: () => void;
  allCFs:any;
  updateScore: () => void;
  expertmodeactive: boolean;
}

const HumanFailureEventOverview = (props: HumanFailureEventOverviewProps) => {

  const {getProgress, expertmodeactive, cf_to_hfes,cf_id_to_names,criticalFunctions,items, disabledItems, cf_to_progress, match, history, isLoading, onResponseUpdated } = props;
  // const { all, bp, cfm, dp, hfe, pif } = progress;
  const { params: { id } } = match;
  const classes = useStyles();
  const intl = useIntl();


  const [completionByCFId, setCompletionByCFId] = useState<{[key: string]: number}>({})

  useEffect(()=>{
    const completionByCFIdTemp: {[key: string]: any} = {}
    for(let key in cf_to_progress){
      const progression: any = cf_to_progress[key]
      if (progression){
        const total = progression.all.summary.total
        const completed = progression.all.summary.completed
        completionByCFIdTemp[progression.crt_model] = {total, completed}
      }
    }
    setCompletionByCFId(completionByCFIdTemp)
  },[cf_to_progress])

  const getHFEProgress =  (name: string, cfid: number): Array<any> => {
      const cfToProgressCopy: any = {...cf_to_progress}
      for(let key in cfToProgressCopy){
        if (cfToProgressCopy[key] == undefined) {
          delete cfToProgressCopy[key]
        }
        return cfToProgressCopy[cfid].hfe.details.filter((item: any)=>!item ? false : item.hfe===name)};
      }
      
  const getCFMProgress =  (name: string, cfid: number): Array<any> => cf_to_progress[cfid].cfm.details.filter((item: any)=>item.hfe===name);
  const getPIFProgress =   (name: string, cfid: number): Array<any> => cf_to_progress[cfid].pif.details.filter((item: any) => item.hfe === name)


  if (isLoading || cf_to_progress=== undefined ||cf_to_progress==={} || cf_to_progress===null) return <CircularIndeterminate />;

  const getActionsForCf = (cf: Number) => (
    <Grid item xs={4} alignItems={"left"}>
      <div style={{
          justifyContent: 'center',
          display: 'flex',
          alignItems: 'center',
          height: '100%'
      }}>
        <ActionButtons
          style={{width: '1000px'}}
          others={
              <NotePadButton
              tooltipTitle={'Open Notepad'}
              cfId={cf}
              title={'Notepad'}
              /> 
  
          }
        />
      </div>
    </Grid>
  );

  return (
  
    criticalFunctions.map((cf:any)=>(
<Accordion>
    <AccordionSummary expandIcon={<ExpandMoreIcon />} >
    <Grid container alignItems={'center'}>
    <Typography style={{display: 'flex', justifyContent: 'center'}} >{cf_id_to_names[cf]}</Typography>
    </Grid>
    <Grid>
      {Object.keys(completionByCFId).length > 0 && <CircularProgressIcon size={1} progress={completionByCFId[cf]}/>}
    </Grid>
    {getActionsForCf(cf)}
    </AccordionSummary>
    <AccordionDetails>
      <Card className={classes.card}>
      <HumanFailureEventOverviewHeader modelId={parseInt(id)}  cfId={cf} />
      <Divider />
      {/* {Object.keys(cf_to_progress).forEach(key => key===undefined||cf_to_progress[key] === undefined ? delete cf_to_progress[key] : {})} */}
      {/* {Object.keys(cf_to_hfes).forEach(key => key===undefined||cf_to_hfes[key] === undefined  ? delete cf_to_hfes[key] : {})} */}
      
      {(cf!= undefined && cf!=null && cf_to_hfes[cf]!= undefined &&cf_to_progress[cf]!=undefined && cf_to_hfes[cf]!=[])?(<List className={classes.content}>
        {
          
          cf_to_hfes[cf].map((item: any) => (
            <HumanFailureEventListItem
              key={item.name}
              detail={item}
              modelId={id}
              cfId={cf}
              hfeProgress={getHFEProgress(item.name,cf)}
              cfmProgress={getCFMProgress(item.name,cf)}
              pifProgress={getPIFProgress(item.name,cf)}
              disableEditing={disabledItems.includes(item.name)}
              onResponseUpdated={onResponseUpdated}
              updateScore={props.updateScore}
              expertmodeactive={expertmodeactive}
              getProgress={getProgress}
            />
          ))
        }
      </List>):(null)}
      
        {/* // !analysisIncomplete &&
        // ( */}
          {/* <CardActions classes={{ root: classes.cardActionsRoot }}>
            <Button
              variant={"contained"}
              color={"primary"}
              fullWidth
              onClick={() => history.push(`/model/${id}/main-quantification`)}
            >
              <ResultsIcon className={classes.quantifyIcon} />
              {intl.formatMessage({ id: 'results.quantify' })}
            </Button>
          </CardActions> */}
        {/* // ) */}

      </Card>
    </AccordionDetails>
      </Accordion>
 
    ))


  );
};

export default HumanFailureEventOverview;

type HumanFailureEventOverviewHeaderProps = {
  modelId: number,
  cfId:number,
}

const HumanFailureEventOverviewHeader = (props: HumanFailureEventOverviewHeaderProps) => {

  const { modelId,cfId } = props;
  const classes = useStyles();

  return (
    <div className={classes.header}>
      <HumanFailureEventOverviewHeaderTitle />
      <HumanFailureEventOverviewHeaderActions modelId={modelId}  cfId={cfId}/>
    </div>
  );
};

const HumanFailureEventOverviewHeaderTitle = () => {

  const intl = useIntl();

  return (
    <div>
      <Typography variant="h5">
        {intl.formatMessage({ id: 'analysisView.hfe' })}
      </Typography>
      <Typography variant="caption">
        {intl.formatMessage({ id: 'analysis.hfeDesc' })}
      </Typography>
    </div>
  );
};
//
//Note: in here, I created a new route for detailed overview of a certain model's certain CF
//The URL is model/modelId/cfIddetailed-quantification
const HumanFailureEventOverviewHeaderActions = (props: HumanFailureEventOverviewHeaderProps) => {

  const { modelId,cfId } = props;
  const classes = useStyles();
  const intl = useIntl();
  const [graph, setGraph] = useState(null);


  // const getMasterESD = () => {
	// 	PhoenixApiManager.getMasterEsd(modelId)
	// 		.then((response) => setData(response.data))
  // }

  useEffect(() => {
    PhoenixApiManager.getCrewResponseTreeAnalysisEventTree(cfId)
      .then(response => {
        setGraph(new PhoenixEventTreeMxGraph(response.data));
        })
      .catch(err => console.error(err));
  }, []);

  return (
    <div>
      <TreeReferenceButton
        tooltipTitle={intl.formatMessage({ id: 'analysis.checkCRT' })}
        title="CRT"
        graphView={<PhoenixTreeMxGraphView graph={graph} />}
      />  
            <Button
            component={Link}
            to={`/model/${modelId}/${cfId}/detailed-quantification`}
              variant={"contained"}
              color={"default"}
            >
              <ResultsIcon className={classes.quantifyIcon} />
            </Button>
    </div>
  );
};