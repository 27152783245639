import React, { useState, useEffect } from "react";
import { injectIntl } from "react-intl";
import { Link, withRouter } from "react-router-dom";
import { withStyles, Theme } from "@material-ui/core/styles";

import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";

import ApiManager from "hcla-web-frontend-primitives/app/Api/ApiManager";
import PhoenixApiManager from "../../../../../../Api/PhoenixApiManager";
import { IconButton } from "@material-ui/core";
import CircularProgressIcon from "../../../../../primitives/Progress/CircularProgressIcon";
import { EntryDefaultProgress, EntryProgress } from "../../../../Types";
import PIFScoreChip from "../../../../../primitives/Chip/PIFScoreChip";
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from "@material-ui/icons/Edit";
import HFEChip from "../../../../../primitives/Chip/HFEChip";
import CFMChip from "../../../../../primitives/Chip/CFMChip";
import { OpenInNew, Refresh } from "@material-ui/icons";
import PhoenixModelContext from "../../../../PhoenixModelContext";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import PIFScoreDialog from "./PIFScoreDialog";
import AnalysisReferencesHeader from "../../../../../primitives/AnalysisReferencesHeader";

const styles = (theme: Theme) => ({
  root: {
    padding: `${theme.spacing(1) * 2}px`,
    display: "flex",
    alignItems: "center",
    "& > *": {
      marginRight: `${theme.spacing(1)}px`,
    },
    "& :first-child": {
      marginLeft: "unset",
    },
  },
  container: {
    width: "100%",
  },
  textBox: {
    paddingRight: `${theme.spacing(2) * 4}px`,
  },
  margin: {
    margin: theme.spacing(1),
  },
  secondaryTypographyProps: {
    marginRight: "90px",
    overflow: "hidden",
    whiteSpace: "normal",
    textOverflow: "ellipsis",
    "& > *": {
      margin: `${theme.spacing(1) / 4}px`,
    },
  },
  secondaryActionRoot: {
    flexDirection: "column",
    right: 0,
  },
  addButton: {
    flexDirection: "column",
  },
});

interface Props {
  classes: { [key: string]: any };
  intl: { [key: string]: any };
  history: { [key: string]: any };
  modelId: number;
  cfid: number;
  pifName: string;
  pifScore: number;
  pifOverride: boolean;
  pifExpression: { [key: string]: any };
  cfmKey: string;
  cfmLabel?: string;
  hfeKey: string;
  hfeLabel?: string;
  progress: EntryProgress;
  onSelect: (data: any) => void;
  faultTreeId?: number;
  cfmTreeId?: number;
  onResponseUpdated?: () => void;
  isFlatList?: boolean;
  hfeName: any;
  updateScore: ()=>void;
}

const PIFEntry = (props: Props) => {
  const [dialog, setDialog] = useState(null);
  const {
    updateProgress,
    classes,
    intl,
    history,
    pifName,
    pifScore,
    pifOverride,
    pifExpression,
    cfmKey,
    cfmLabel,
    hfeKey,
    hfeLabel,
    modelId,
    progress,
    faultTreeId,
    cfmTreeId,
    isFlatList,
    cfid,
    hfeName,
    onSelect,
    onResponseUpdated,
    ...others
  } = props;
  const [cfData, setCfData] = useState(pifScore);

  const primaryText = () => {
    if (onSelect instanceof Function) {
      return null;
    }
    return (
      <Typography
        noWrap
        variant={isFlatList ? "h6" : "body2"}
        color="textPrimary"
      >
        {pifName}
      </Typography>
    );
  };

  const hfePathBasename = `${ApiManager.MODEL_PATH}/${modelId}/main-analysis/${cfid}/hfe/${hfeKey}/`;
  const cfmPathBasename = `${hfePathBasename}cfm/${cfmKey}/`;
  const pifPathBasename = `${cfmPathBasename}pif/${pifName}/`;

  useEffect(() => {

  }, [pifScore]);


  const value = !pifOverride
    ? Number(pifScore).toExponential(3)
    : pifExpression
    ? new Expression(pifExpression).toString()
    : Number(pifScore).toExponential(3);
    
  const save = (payload: any) => {
    PhoenixApiManager.patchPIFForModelWithIdAndHFEAndCFM(
      cfid,
      hfeKey,
      cfmKey,
      pifName,
      payload
    )
    .then(res => res.json())
    .then(response=> {
      props.updateScore(response.score, cfid, cfmKey, pifName)
      setCfData(response.score)
      setDialog(null)
    }).then(() => {updateProgress(cfid);})

}

  const getProgress = () => {
    if (onSelect instanceof Function) {
      return null;
    } else {
      return (
        <ListItemAvatar>
          <CircularProgressIcon progress={progress} />
        </ListItemAvatar>
      );
    }
  };

  const hfeLink = (itemProps) => (
    <Link to={{ pathname: hfePathBasename }} {...itemProps} />
  );
  const cfmLink = (itemProps) => (
    <Link to={{ pathname: cfmPathBasename }} {...itemProps} />
  );
  const pifLink = (itemProps) => (
    <Link to={{ pathname: pifPathBasename }} {...itemProps} />
  );

  const secondaryText = (
    <div className={classes.secondaryTypographyProps}>
      <HFEChip name="HFE" label={hfeLabel} link={hfeLink} />
      <CFMChip name="CFM" label={cfmLabel} link={cfmLink} />
    </div>
  );

  const extraProps = () => {
    if (onSelect instanceof Function) {
      return {
        component: null,
      };
    } else {
      return null;
    }
  };

  const handleOnClick = (e: any) => {
    if (onSelect instanceof Function) {
      onSelect({
        pifName,
        hfeKey,
        cfmKey,
      });
    }
  };

  const getCfDetails = (cf_id: any) => {
    PhoenixApiManager.getCfFunctionDetails(cf_id)
      .then((response) => response.status === 200 && response.json())
      .then((data) => {
        setCfData(data);
      });
  };

  const externalLink = () => {
    if (onSelect instanceof Function) {
      return (
        <Link target="_blank" to={{ pathname: pifPathBasename }}>
          <IconButton>
            <OpenInNew />
          </IconButton>
        </Link>
      );
    } else {
      return null;
    }
  };
  if (!hfeName) {
    return null;
  }
  return (
    <PhoenixModelContext.Consumer>
      {(value) => {
        const { refresh } = value;
        // const to_be_read=getCfDetails(cfid);
        // console.log(to_be_read);

        return (
          <ListItem
            button={isFlatList}
            component={isFlatList ? pifLink : undefined}
            key={`${pifName}.${cfmKey}.${hfeKey}`}
            alignItems="center"
            classes={{
              root: classes.root,
              container: classes.container,
            }}
            divider
            {...others}
            {...extraProps()}
            onClick={(e) => handleOnClick(e)}
          >
            {getProgress()}
            <ListItemText
              disableTypography
              classes={{
                root: classes.textBox,
              }}
              primary={primaryText()}
              secondary={isFlatList ? secondaryText : null}
            />
            <ListItemSecondaryAction
              classes={{ root: classes.secondaryActionRoot }}
            >
              <PIFScoreChip
                cfid={cfid}
                modelId={modelId}
                cfmKey={cfmKey}
                cfmName={cfmLabel}
                hfeKey={hfeKey}
                hfeName={hfeName}
                pifName={pifName}
                score={pifScore ? pifScore: cfData}
                override={pifOverride}
                expression={pifExpression}
                faultTreeId={faultTreeId}
                cfmTreeId={cfmTreeId}
                onResponseUpdated={onResponseUpdated}
                updateScore={props.updateScore}
                updateEntryScore={setCfData}
              />
              {externalLink()}
              {!isFlatList && (
                <>
                  {(!cfData) && (
                    <Tooltip title={intl.formatMessage({ id: "Input Score" })}>
                      <IconButton>
                        <AddCircleIcon
                          onClick={() =>
                            setDialog(
                              <PIFScoreDialog
                                header={
                                  <AnalysisReferencesHeader
                                    crt={{ id: modelId }}
                                    bp={hfeName}
                                    hfe={{ id: faultTreeId }}
                                    cfm={{
                                      treeId: cfmTreeId,
                                      label: cfmLabel,
                                    }}
                                    pif={pifName}
                                    inPaperContainer={false}
                                  />
                                }
                                handleClose={() => {onResponseUpdated(); setDialog(null);} }
                                save={save}
                                score={!pifScore ? cfData: pifScore}
                                isOverride={true}
                                expression={pifExpression}
                                onResponseUpdated={onResponseUpdated}
                              />
                            )
                          }
                        />
                      </IconButton>
                    </Tooltip>
                  )}
                  {dialog}

                  <Tooltip title={intl.formatMessage({ id: "edit" })}>
                    <IconButton
                      onClick={() => history.push(`${pifPathBasename}`)}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                </>
              )}
            </ListItemSecondaryAction>
          </ListItem>
        );
      }}
    </PhoenixModelContext.Consumer>
  );
};

PIFEntry.defaultProps = {
  pifScore: null,
  progress: EntryDefaultProgress,
};

export default withStyles(styles)(withRouter(injectIntl(PIFEntry)));
