import React from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { RouteComponentProps } from "react-router";
import { Link, useHistory } from "react-router-dom";

import { QuantificationResultJSON } from "hcl-web-editor/app/components/Model/Quantification/QuantificationResultJSON";
import StatsTable, {
  StatsData,
} from "hcl-web-editor/app/components/Model/QuantificationResult/Chips/StatsTable";

import PhoenixApiManager from "../../../../Api/PhoenixApiManager";
import QuantificationCard from "./QuantificationCard";
import { PhoenixRouteParams } from "../../../NavBar/MainContainer";
import { Grid, MenuItem, MenuList } from "@material-ui/core";
import { withRouter } from "react-router";
import TreeReferenceButton from "./TreeReferenceButton";
import HCLTreeMxGraphView from 'hcl-web-editor/app/components/Model/SingleHCLModelView/SingleTreeView/MxGraphViews/HCLTreeMxGraphView';
const percentile = require("percentile");

//expansion panel based on CFs
import { Accordion } from "@material-ui/core";
import { AccordionSummary } from "@material-ui/core";
import { AccordionDetails } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import CopyToClipboardChip from "hcla-web-frontend-primitives/app/components/primitives/Chips/CopyToClipboardChip";

import CircularProgress from '@material-ui/core/CircularProgress';



import ActionBarButton from "hcl-web-editor/app/components/Model/SingleHCLModelView/SingleTreeView/Tools/ActionBar/ActionBarButton";
import {
  getConfigurations,
  deleteConfiguration,
  updateCurrentlySelected,
} from "hcl-web-editor/app/components/Model/SingleHCLModelView/SingleTreeView/Tools/Utils";
import ArrowDown from "@material-ui/icons/ArrowDropDownTwoTone";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme, WithStyles, withStyles } from "@material-ui/core/styles";
import { Configurations } from "hcl-web-editor/app/components/Model/SingleHCLModelView/SingleTreeView/Tools/Quantification";
import ActionBarMenu from "hcl-web-editor/app/components/Model/SingleHCLModelView/SingleTreeView/Tools/ActionBar/ActionBarMenu";
import ListItemText from "@material-ui/core/ListItemText";
import UserQuantificationSettings from "hcl-web-editor/app/components/Model/SingleHCLModelView/SingleTreeView/Tools/UserQuantificationSettings";
import { TreeNames } from "hcl-web-editor/app/components/Model/SingleHCLModelView/SingleTreeView/Tools/TreeDictionaries";
import Edit from "@material-ui/icons/EditTwoTone";
import Close from "@material-ui/icons/CloseTwoTone";
import ResultsIcon from "../../../primitives/Icons/ResultsIcon";
import QuantificationResultsView from "./QuantificationResultsView";
import "./QuantificationStyles.css";
import PopUpForQuantification from "./PopUpForQuantification";


const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: "calc(100vh - 120px)",
      width: "100%",
      overflowY: "auto",
      display: "flex",
      flexDirection: "column",
    },
    tableContainer: {
      display: "flex",
      flexGrow: 1,
      height: "100%",
      width: "100%",
    },
    cardHeaderTitleView: {
      margin: theme.spacing(1),
    },
    cardHeaderRoot: {
      flex: "0 0 auto",
    },
    message: {
      paddingBottom: theme.spacing(1) * 4,
      textAlign: "center",
      color: "#888",
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
    rootHEP: {
      padding: "24px 0px",
      borderRadius: "24px",
    },
    cardHeaderAction: {
      marginTop: theme.spacing(1),
    },

    errorView: {
      position: "relative",
      height: "100%",
    },

    errorViewContent: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      textAlign: "center",
    },

    menu: {
      maxHeight: "30vh",
      overflowY: "auto",
    },

    allConfigs: {
      fontSize: "15px",
      fontWeight: 500,
    },

    newConfiguration: {
      textAlign: "center",
      fontSize: "15px",
      fontWeight: "bold",
    },

    editIcon: {
      padding: "0 8px 0 8px",
      marginRight: "1rem",
      width: "2em",
      minWidth: "unset",
    },

    deleteIcon: {
      padding: "0 8px 0 8px",
      width: "2em",
      minWidth: "unset",
    },

    downIcon: {
      width: "2em",
      minWidth: "unset",
    },
  });

interface QuantificationView
  extends WithStyles<typeof styles>,
  ReactIntl.InjectedIntlProps {
  loading: boolean;
  data: QuantificationResultJSON;
  onRequestQuantify: (configuration?: any) => void;
  computeErrorFunc: (data: QuantificationResultJSON) => {};
  title?: string;
  quantifyDisabled?: boolean;
  hfe?: any;
  cfm?: any;
  treeId: number;
}

const mean = (nums: number[]): number => {
  return nums.reduce((a, b) => a + b) / nums.length;
};

export const computeHEPFromData = (
  data: QuantificationResultJSON,
  failureKey = "SQ_F.0"
) => {
  let accumErrorP5 = 0.0;
  let accumErrorP50 = 0.0;
  let accumErrorP95 = 0.0;
  let accumMean = 0.0;
  let uncertaintyDetected = false;
  try {
    const {
      result_data: { sequences },
    } = data;
    Object.entries(sequences).forEach(([endstateKey, endstateObj]) => {
      if (endstateKey.includes(failureKey)) {
        if (Array.isArray(endstateObj.result)) {
          uncertaintyDetected = true;
          const p5 = percentile(5, endstateObj.result);
          const p50 = percentile(50, endstateObj.result);
          const avg = mean(endstateObj.result);
          accumMean += avg;
          const p95 = percentile(95, endstateObj.result);
          accumErrorP5 += p5;
          accumErrorP50 += p50;
          accumErrorP95 += p95;
        } else {
          accumErrorP50 += endstateObj.result;
        }
      }
    });
  } catch (e) {
    return null;
  }
  let dataArr: StatsData[];
  if (uncertaintyDetected) {
    dataArr = [
      {
        title: "Mean",
        text: accumMean,
        label: accumMean.toExponential(3),
      },
      {
        title: "Median",
        text: accumErrorP50,
        label: accumErrorP50.toExponential(3),
      },
      {
        title: "5th",
        text: accumErrorP5,
        label: accumErrorP5.toExponential(3),
      },
      {
        title: "95th",
        text: accumErrorP95,
        label: accumErrorP95.toExponential(3),
      },
    ];
  } else {
    dataArr = [
      {
        title: "Best Estimate",
        text: accumErrorP50,
        label: accumErrorP50.toExponential(3),
      },
    ];
  }
  return <StatsTable data={dataArr} />;
};

function QuantDialog(props) {
  const { onClose, open, text } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">Important Quantification Information</DialogTitle>
      {text}
    </Dialog>
  );
}

interface QuantificationViewProps
  extends RouteComponentProps<PhoenixRouteParams>,
    InjectedIntlProps {}

interface QuantificationViewState {
  trimmedCRT: {
    id: string;
    loading: boolean;
  };
  quantificationResult: {
    data: any;
    loading: boolean;
  };
  cf_id_to_names: any;
  cf_id_to_CRTids: any;
  criticalFunctions: any;

  trimmedCRT_set: { [index: number]: any };
  quantificationResult_set: { [index: number]: any };
  masterESD:any;
  quantificationTitle:string;
  dependency:boolean;
  titleConfirmed:boolean;
  open:boolean;
  errorMessage: string;
}

class QuantificationView extends React.Component<
  QuantificationViewProps,
  QuantificationViewState
> {
  localPagination = JSON.parse(localStorage.getItem("pagination"));
  constructor(props: QuantificationViewProps) {
    super(props);
    this.state = {
      trimmedCRT: {
        id: null,
        loading: true,
      },
      quantificationResults: {
        items: [],
        itemTotal: null,
        paginationEnabled: false,
        limit: this.localPagination ? this.localPagination.rowsPerPage : null,
        offset: null,
        loading: true,
      },
      treeMetadata: {
        map: {},
        loading: true,
      },
      quantificationResult: {
        data: {},
        loading: false,
        allData: [],
      },
      cf_id_to_names: {},
      cf_id_to_CRTids: {},
      trimmedCRT_set: {},
      quantificationResult_set: {},
      criticalFunctions: [],
      masterESD:null,
      quantificationTitle:'',
      dependency:false,
      titleConfirmed:true,
      open:true,
      errorMessage: 'There was a bad request'
    };
  }

  handleModalClose = () => {
    this.quantifyCRT(this.getMyConfigs())
    this.setTitleConfirmed();
    // this.setState(false);
  }; 

  setDependency=()=>{
    this.setState({dependency:!this.state.dependency})
  }

  setTitle=(val:string)=>{
    this.setState({quantificationTitle:val})
  }

  setTitleConfirmed=()=>{
    // JSON.parse(localStorage.getItem("configurations")) == null ? alert('Please set up configurations') :
    this.setState({titleConfirmed:!this.state.titleConfirmed})
  }

  componentDidMount() {
    this.getCFs();
    this.getQuantificationResults();
    this.getMasterESD();
  }

  //work flow: get all the CFs--> get the trimmedCRTid for each CF--> add those id and do the map
  getCFs = () => {
    const {
      classes,
      match: {
        params: { id },
      },
    } = this.props;
    PhoenixApiManager.getMainCriticalFunctions(id).then((data) => {
      let temp = [];
      for (var i = 0; i < data.data.length; i++) {
        let key = data.data[i].crt_model;
        if (key != null) {
          temp.push(key);
          this.state.cf_id_to_names[key] = data.data[i].title;
          this.state.cf_id_to_CRTids[key] = data.data[i].id;
          // this.fetchTrimmedCRTId(key);
        }
      }
      this.setState({ criticalFunctions: temp });
    });
  };

  getQuantificationResults = (nextOrPrev) => {
    const {
      classes,
      match: {
        params: { id },
      },
    } = this.props;
    const {
      quantificationResults: { limit, offset },
    } = this.state;
    PhoenixApiManager.getMainQuantificationResultsForPhoenixModelWithId(
      id,
      limit,
      offset,
      nextOrPrev
    )
      .then((response) => response.status === 200 && response.json())
      .then((list) => {
        if (list.results ? list.results.length === 0 : list.length === 0) {
          this.setState({
            quantificationResult: {
              data: {},
              loading: false,
              loaded: true,
            },
            quantificationResults: {
              items: list.results ? list.results : list,
              itemTotal: list.count ? list.count : null,
              limit,
              offset,
              paginationEnabled: !!(list && list.results),
              loading: false,
            },
          });
        } else {
          this.setState({
            quantificationResult: {
              data: nextOrPrev
                ? this.state.quantificationResult.data
                : list.results
                ? list.results[list.results.length - 1]
                : list[list.length - 1],
              allData: list.results,
              next: list.next,
              prev: list.previous,
              loading: false,
              loaded: true,
            },
          });
        }
      });
  };
  

  //this function should be done  on the CF data(CF data has the trimmed id)
  // fetchTrimmedCRTId = () => {
  //   const {
  //     match: {
  //       params: { cf_id },
  //     },
  //   } = this.props;
  //   PhoenixApiManager.getPhoenixModelWithId(cf_id)
  //     .then((response) => response.status === 200 && response.json())
  //     .then((data) => {
  //       const treeId = data.model_data.crt.trimmed_crt_id;
  //       // this.setState({
  //       //   trimmedCRT: {
  //       //     id: treeId,
  //       //     loading: false
  //       //   },
  //       // });
  //       this.state.trimmedCRT_set[treeId] = false;
  //       this.state.cf_id_to_CRTids[cf_id] = treeId;
  //     });
  // };

  //quantifyCRT will take the id for the cf
  quantifyCRT = (configuration: any) => {
    const {
      classes,
      match: {
        params: { id },
      },
    } = this.props;
    this.setState(
      {
        quantificationResult: {
          data: {},
          loading: true,
          allData: this.state.quantificationResult.allData
        },
        
      },
      () => {
        console.log("these are my con", configuration);
        PhoenixApiManager.quantifyMainPhoenixTreeForModelWithId(
          null,
          id,
          this.state.dependency,
          this.state.quantificationTitle,
          configuration
        )
          .then((response) => {
            return response.status === 201 ? response.json() : response.json().then((res: any) =>{
              alert(`Quantification Error: ${res.detail}`)
              this.setState({errorMessage: `Quantification Error: ${res.detail}`})
            } )

          })
          .then((data) => {
            this.setState({
              quantificationResult: {
                data,
                loading: false,
                //@ts-ignore
                loaded: true,
                allData: this.state.quantificationResult.allData
              },
              // treeMetadata: {
              //   map: this.mapTreeMetadataList(data.results ? data.results : data),
              //   loading: false,
              // },
              // );
            });
          });
      }
    );
  };

  private handleClick = (e: React.MouseEvent<HTMLElement>) => {
    const configs = getConfigurations();
    this.setState({
      configurations: configs.configurations,
      currentConfig: configs.currentlySelected,
      anchorEl: e.currentTarget,
    });
  };

  private handleClose = () => {
    this.setState({ anchorEl: null });
  };

  private openSettings = (value: boolean) => {
    this.setState({
      addNewConfiguration: true,
      anchorEl: null,
    });
    this.checkForNewConfigClick(value);
  };

  private checkForNewConfigClick = (value: boolean) => {
    this.setState({
      newConfig: value,
    });
  };
  private onConfigurationAdded = () => {
    const configs = getConfigurations();
    this.setState({
      configurations: configs.configurations,
      currentConfig: configs.currentlySelected,
      addNewConfiguration: false,
    });
  };

  private resetConfigs = () => {
    const configs = getConfigurations();
    this.setState({
      configurations: configs.configurations,
      currentConfig: null,
    });
  };

  private handleSettingsClose = () => {
    const configs = getConfigurations();
    this.setState({
      addNewConfiguration: false,
      currentConfig: configs.currentlySelected,
      configurations: configs.configurations,
    });
  };

  updateQuantificationResultSet = (limit, offset) => {
    this.setState(
      (prevState) => ({
        quantificationResults: {
          ...prevState.quantificationResults,
          limit,
          offset,
        },
      }),
      this.fetchQuantificationResults
    );
  };

  mapTreeMetadataList = (list) => {
    const map = {};
    list.forEach((item) => {
      map[item.id] = {
        ...item,
        quantificationResults: [],
      };
    });
    return map;
  };
 
  getTreeName = () => {
    const {
      match: {
        params: { treeId },
      },
    } = this.props;
    try {
      const {
        treeMetadata: { map },
      } = this.state;
      return map[`${treeId}`].title;
    } catch (e) {
      return null;
    }
  };

  mapQRswithMetadata = () => {
    const {
      treeMetadata: { map, loading },
      quantificationResults: { items },
    } = this.state;
    const { filteredTreeIds } = this.props;
    if (items.length === 0 || loading) {
      return {
        results: [],
        traces: [],
      };
    }
    const myMap = {};

    const traces = {};
    const itemsSorted = items.sort(
      (a, b) => Date.parse(b.date_created) - Date.parse(a.date_created)
    );
    itemsSorted.forEach((item) => {
      const {
        configuration: {
          constructor: { tree_id },
        },
      } = item;
      if (!Object.keys(item.result_data).length) {
        return;
      }
      if (!filteredTreeIds || filteredTreeIds.includes(tree_id)) {
        if (map[tree_id]) {
          if (!myMap[tree_id]) {
            myMap[tree_id] = JSON.parse(JSON.stringify(map[tree_id]));
          }
          // iterate over all the sequences
          const cutsets = [];
          if ("sequences" in item.result_data) {
            cutsets.push(...Object.entries(item.result_data.sequences));
          }
          if ("targets" in item.result_data) {
            cutsets.push(...Object.entries(item.result_data.targets));
          }
          cutsets.forEach((e) => {
            const traceId = `${myMap[tree_id].title}.${e[0]}`;
            let value;
            try {
              value = e[1].result;
            } catch (e) {
              try {
                value = e[1].probability;
              } catch (e) {
                value = Number.NaN;
              }
            }
            if (!traces[traceId]) {
              traces[traceId] = {
                x: [],
                y: [],
                name: traceId,
                type: "scatter",
                mode: "lines+markers",
                title: traceId,
              };
            }
            traces[traceId].x.push(new Date(Date.parse(item.date_created)));
            traces[traceId].y.push(value);
          });

          myMap[tree_id].quantificationResults.push(item);
        }
      }
    });
    return {
      results: Object.values(myMap),
      traces: Object.values(traces),
    };
  };

  //During render: the critical functions to the expansion panel, and map all the related CRT's id

  getMyConfigs = () => {
    
    const configs = JSON.parse(localStorage.getItem("configurations"));
    // const currentlySelectedKey = configs.currentlySelected;
    // this is in place until we need hcl configurations
    const currentlySelectConfig = {

              "configuration": {
                  "constructor": {
                      "replace_transfer_gates_with_basic_events": false
                  },
                  "engine": {
                      "BBNSolver": 1000,
                      "BDDConstructor": 2001,
                      "OrderingRule": 3000
                  },
                  "quantify": {
                      "type": "point_estimate",
                      "mission_test_interval": null,
                      "user_defined_max_cutset": -1,
                      "targets": "__all__",
                      "sampling": {},
                      "importance": {}
                  }
              },
      "currentlySelected": "default"
  }
    // const currentlySelectConfig = configs.configurations[currentlySelectedKey];
    return currentlySelectConfig;
  };

  getMasterESD = () => {
    const { match: { params: { id } } } = this.props;
    PhoenixApiManager.getMasterEsd(parseInt(id))
			.then((response) => this.setState({masterESD:response.data}))
  }

  dateFormat = (date_created) => {
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

    const currentDate = new Date(date_created)

    return currentDate.toLocaleDateString(undefined, options)

    
  }

  getFormattedSequence = (obj, cfId, _case, sequences=null, pathHEP=null) =>{
    // masterESD, critical_function (CF1), paths
    if(_case==="masterESD"){
      console.log(_case)
      return "masterESD"
    } else if (_case==="critical_function"){
      localStorage.removeItem("isMasteresd");
      console.log(_case)
      const critSequence = obj.result_data.critical_functions[cfId].sequences
      Object.keys(critSequence).forEach(key=>{
        critSequence[key] = {...critSequence[key],  cutsets: critSequence[key].cutsets? critSequence[key].cutsets: []}
      })
      return {...obj, result_data: {...obj.result_data, sequences: {...obj.result_data.critical_functions[cfId].sequences, } }, _totalHep: obj.result_data.critical_functions[cfId].total_HEP}
    } else if (_case==="path"){
      localStorage.removeItem("isMasteresd");
      console.log(_case)
      return {...obj, result_data:{...obj.result_data, sequences: sequences, }, _totalHep: pathHEP || obj.result_data.critical_functions[cfId].total_HEP}
    }
    // const requiredSequences = {...obj.result_data.sequences, ...obj.result_data.critical_functions[cfId].sequences }
    // Object.keys(requiredSequences).forEach(key=>requiredSequences[key] = {...requiredSequences[key], cutsets: []})
    // console.log("required sequences",requiredSequences)
    // const modifiedObj = {...obj, result_data:{ label_map: obj.result_data.label_map, sequences: requiredSequences  }}
    
    // // console.log("modified obj", modifiedObj)
    // return modifiedObj
  }

  closeDialog = () => {
    this.setState({ open: false })
  }

  render() {
    const {
      match: {
        params: { cfId, id },
      },
      intl,
      classes,
    } = this.props;
    const {
      quantificationResult,
      trimmedCRT,
      cf_id_to_names,
      cf_id_to_CRTids,
      criticalFunctions,
      addNewConfiguration,
      configurations,
      anchorEl,
      currentConfig,
      editableConfig,
      currentKey,
      newConfig,
      
    } = this.state;
    if (quantificationResult.loading) <p>loading</p>
    if(this.state.quantificationResult.data == undefined) return this.state.errorMessage
    if(this.state.quantificationResult.data != undefined) {
    
    if (Object.keys(this.state.quantificationResult.data).length === 0) {
      return (
        
        <>
        {/* <h1>
          Please complete full model then come back to this page and quantify
        </h1> */}
        <div style={{textAlign: "right"}}>
        <TreeReferenceButton
        tooltipTitle={intl.formatMessage({ id: 'checkMasterESD' })}
        title="Master ESD"
        graphView={<HCLTreeMxGraphView data={this.state.masterESD} />}
      />
      </div>
        <span>
        {this.state.titleConfirmed ===false ?(<>
        <PopUpForQuantification
          setDependency={this.setDependency}
          setTitle={this.setTitle}
          setTitleConfirmed={this.setTitleConfirmed}
          cur_dependency={this.state.dependency}
          open={!this.state.titleConfirmed}
          handleClose={this.handleModalClose}
          currentTitle={this.state.quantificationTitle}

          />
          <Button // commenting out variant and color does nothing
            variant="contained"
            color="primary"
            // disabled={quantifyDisabled || loading}
            onClick={() => {
              this.setTitleConfirmed(); 
            }}
          >
            <ResultsIcon
              //@ts-ignore
              className={classes.extendedIcon}
              // disabled={quantifyDisabled || loading}
            />
            {intl.formatMessage({ id: "results.quantify" })}
          </Button>
          </>):( <Button
            variant="contained"
            color="primary"
            // disabled={quantifyDisabled || loading}
            onClick={() => {
              this.setTitleConfirmed();}}
          >
            <ResultsIcon
              //@ts-ignore
              className={classes.extendedIcon}
              // disabled={quantifyDisabled || loading}
            />
            {intl.formatMessage({ id: "results.quantify" })}
          </Button>)}
        <ActionBarButton
          onClick={this.handleClick}
          color="primary"
          variant="contained"
          classOverride={classes.downIcon}
        >
          <ArrowDown />
        </ActionBarButton>
        <ActionBarMenu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          placementOverride="bottom-end"
        >
          <MenuList dense disablePadding className={classes.menu}>
            <MenuItem
              onClick={() => {
                this.setState({ currentKey: null }, () =>
                  this.openSettings(true)
                );
              }}
            >
              <ListItemText
                primary="+ New Configuration"
                className={classes.newConfiguration}
                disableTypography
              />
            </MenuItem>
            {Object.keys(configurations || {}).map((key: any) => (
              <MenuItem
                key={key}
                selected={currentConfig === key}
                onClick={() => {
                  updateCurrentlySelected(key);
                  this.setState({ currentConfig: key }, () => {
                    this.handleClose();
                  });
                }}
              >
                <ListItemText
                  primary={key}
                  className={classes.allConfigs}
                  disableTypography
                />
                <ActionBarButton
                  onClick={(e) => {
                    e.stopPropagation();
                    const config = getConfigurations();
                    this.setState(
                      {
                        currentKey: key,
                        editableConfig: { [key]: config.configurations[key] },
                      },
                      () => this.openSettings(false)
                    );
                  }}
                  classOverride={classes.editIcon}
                >
                  <Edit />
                </ActionBarButton>
                <ActionBarButton
                  onClick={(e) => {
                    deleteConfiguration(e, key);
                    this.resetConfigs();
                  }}
                  classOverride={classes.deleteIcon}
                >
                  <Close />
                </ActionBarButton>
              </MenuItem>
            ))}
          </MenuList>
        </ActionBarMenu>
        <UserQuantificationSettings
          modelId={id}
          // treeId={treeId}
          treeType={TreeNames.FAULT_TREE}
          open={addNewConfiguration}
          onClose={this.handleSettingsClose}
          editableConfig={editableConfig}
          currentKey={currentKey}
          newConfig={newConfig}
          quantifyOverride={() => {
            this.quantifyCRT(this.getMyConfigs());
            this.handleSettingsClose();
          }}
        />
        {quantificationResult.loading?(<div>
      <CircularProgress />
      <Typography>Quantifiying...</Typography>
    </div>):(null)}
      </span>
      </>
      );
    }
  }
    const quantText = "Before you can Globally quantify or quantify an individual Critical Function, you must create a Quantification Configurationby pressing the drop-down arrow to the right of the Quantify button. If no configuration is set, then after clicking quantify and confirming selection, nothing will occur. After setting up this configuration, you may click the quantification button on this page to globally quantify. Or, return to the Analysis page in the side bar, click one of the completed critical functions, then click the Quantify Icon next to the CRT button to quantify the individual critical function."
    return (
      <React.Fragment>
        <QuantDialog open={this.state.open} onClose={this.closeDialog} text={quantText}></QuantDialog>
        <BreadcrumbsItem to={`/model/${id}/main-quantification`}>
          {intl.formatMessage({ id: "quantification" })}
        </BreadcrumbsItem>
        <div style={{textAlign: "right"}}>
        <TreeReferenceButton
        tooltipTitle={intl.formatMessage({ id: 'checkMasterESD' })}
        title="Master ESD"
        graphView={<HCLTreeMxGraphView data={this.state.masterESD} />}
      />
      </div>
        <span>
          {this.state.titleConfirmed ===false?(<>
          <PopUpForQuantification
          setDependency={this.setDependency}
          setTitle={this.setTitle}
          setTitleConfirmed={this.setTitleConfirmed}
          cur_dependency={this.state.dependency}
          quantify={()=> {this.quantifyCRT(this.getMyConfigs())}}
          open={!this.state.titleConfirmed}
          handleClose={this.handleModalClose}
          currentTitle={this.state.quantificationTitle}

          /> <Button
          variant="contained"
          color="primary"
          // disabled={quantifyDisabled || loading}
          onClick={() => {
            this.setTitleConfirmed(); 
          }}
        >
          <ResultsIcon
            //@ts-ignore
            className={classes.extendedIcon}
            // disabled={quantifyDisabled || loading}
          />
          {intl.formatMessage({ id: "results.quantify" })}
        </Button>
        </>
        ):( <Button
            variant="contained"
            color="primary"
            // disabled={quantifyDisabled || loading}
            onClick={() => {
              this.setTitleConfirmed(); 
            }}
          >
            <ResultsIcon
              //@ts-ignore
              className={classes.extendedIcon}
              // disabled={quantifyDisabled || loading}
            />
            {intl.formatMessage({ id: "results.quantify" })}
          </Button>)}
            
          {/* <ActionBarButton
            onClick={this.handleClick}
            color="primary"
            variant="contained"
            classOverride={classes.downIcon}
          >
            <ArrowDown />
          </ActionBarButton> */}
          <ActionBarMenu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={this.handleClose}
            placementOverride="bottom-end"
          >
            <MenuList dense disablePadding className={classes.menu}>
              <MenuItem
                onClick={() => {
                  this.setState({ currentKey: null }, () =>
                    this.openSettings(true)
                  );
                }}
              >
                <ListItemText
                  primary="+ New Configuration"
                  className={classes.newConfiguration}
                  disableTypography
                />
              </MenuItem>
              {Object.keys(configurations || {}).map((key: any) => (
                <MenuItem
                  key={key}
                  selected={currentConfig === key}
                  onClick={() => {
                    updateCurrentlySelected(key);
                    this.setState({ currentConfig: key }, () => {
                      this.handleClose();
                    });
                  }}
                >
                  <ListItemText
                    primary={key}
                    className={classes.allConfigs}
                    disableTypography
                  />
                  <ActionBarButton
                    onClick={(e) => {
                      e.stopPropagation();
                      const config = getConfigurations();
                      this.setState(
                        {
                          currentKey: key,
                          editableConfig: { [key]: config.configurations[key] },
                        },
                        () => this.openSettings(false)
                      );
                    }}
                    classOverride={classes.editIcon}
                  >
                    <Edit />
                  </ActionBarButton>
                  <ActionBarButton
                    onClick={(e) => {
                      deleteConfiguration(e, key);
                      this.resetConfigs();
                    }}
                    classOverride={classes.deleteIcon}
                  >
                    <Close />
                  </ActionBarButton>
                </MenuItem>
              ))}
            </MenuList>
          </ActionBarMenu>
          <UserQuantificationSettings
            modelId={id}
            // treeId={treeId}
            treeType={TreeNames.FAULT_TREE}
            open={addNewConfiguration}
            onClose={this.handleSettingsClose}
            editableConfig={editableConfig}
            currentKey={currentKey}
            newConfig={newConfig}
            quantifyOverride={() => {
              this.quantifyCRT(this.getMyConfigs());
              this.handleSettingsClose();
            }}
          />
        {quantificationResult.loading?(<div>
      <CircularProgress />
      <Typography>Quantifiying...</Typography>
    </div>):(null)}

        </span>

        <h3>Current Quantification</h3>
        <Accordion>
          <AccordionSummary >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
                <Typography>
                  { this.state.quantificationResult.data?.title == null ? this.state.quantificationResult.data.model_title :  this.state.quantificationResult.data.title}
                </Typography>
              <Typography>
                Master ESD
              </Typography>
              <Button // top banner button with quantification title and Master ESD has nothing in button
                  component={Link}
                  to={
                    "/model/" +
                    id +
                    "/main-analysis/" +
                    cfId +
                    "/quantify/"
                  }
                  style={{
                    position: 'absolute',
                    right: '23vw'
                }} 
                  target="_blank"
                  //@ts-ignore
                  onClick={async () =>{
                  // for all of the critical functions
                  await localStorage.setItem('obj_data',  "masterESD")
                  await localStorage.setItem('result_key', this.state.quantificationResult.data.id)
                  await localStorage.setItem('main_model_key', this.state.quantificationResult.data.model);     
                  }}>
                    <ResultsIcon                      
            className={classes.extendedIcon}
            // disabled={quantifyDisabled || loading}
          />
</Button>
              <Typography>
                {/* <CopyToClipboardChip color="secondary" variant="outlined" label={ `Total HEP = ${this.state.quantificationResult.data.result_data.sequences.EndState.result.toExponential(3)}`} /> */}
             {/* { `Total HEP = ${this.state.quantificationResult.data.result_data.critical_functions[cfId].total_HEP.toExponential(3)}`} */}
              </Typography>
              
            </div>
            {/* <Typography>{' '}</Typography>
  <Typography>{}</Typography> */}
          </AccordionSummary>
          </Accordion>

        {Object.keys(
          this.state.quantificationResult.data.critical_function_relations // mapping all
        ).map((cfId, index) => {

            const cfKey = Object.keys(this.state.cf_id_to_CRTids).find(key => this.state.cf_id_to_CRTids[key] == cfId);

        return  <>
        
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Typography>
                  {
                    this.state.quantificationResult.data.result_data.label_map[
                      cfId
                    ].label.name
                  }
                </Typography>
                <Button
                // variant="contained"
                // color="primary"
                component={Link}
                to={
                  "/model/" +
                  id +
                  "/main-analysis/" +
                  cfKey +
                  "/quantify/"
                } 
              style={{
                position: 'absolute',
                right: '23vw'
            }}
              
                target="_blank"    
                onClick={async () => {
                  await localStorage.setItem('obj_data', "critical_function")
                  await localStorage.setItem('main_model_key', this.state.quantificationResult.data.model);    
                  localStorage.setItem('detailed_id', cfId) // setting up this to get proper name later on
                  localStorage.setItem('clickedPath', "Overall")
                  localStorage.setItem("result_key", this.state.quantificationResult.data.id )
                }
              }>
                 <ResultsIcon // here, need to change button above and get rid of intl.formatmessage below
                  //@ts-ignore
                  className={classes.extendedIcon}
                  // disabled={quantifyDisabled || loading}
                />
            {/* {intl.formatMessage({ id: "results.quantify" })} */}
                  {/* {
                    this.state.quantificationResult.data.result_data.label_map[
                      cfId
                    ].label.name
                  } */}
                </Button>
                {/* <Button
                    component={Link}
                    to={
                      "/model/" +
                      id +
                      "/main-analysis/" +
                      cfId +
                      "/quantify/"
                    } 
                    target="_blank"
                    //@ts-ignore
                    onClick={async () =>{
                    // for all of the critical functions
                    await localStorage.setItem('obj_data', JSON.stringify(this.getFormattedSequence(this.state.quantificationResult.data, cfId, "critical_function")));
                    }}>
                      <ResultsIcon                      
              className={classes.extendedIcon}
              // disabled={quantifyDisabled || loading}
            />
</Button> */}
                <Typography>
                  <CopyToClipboardChip color="secondary" variant="outlined" label={ `Total HEP = ${this.state.quantificationResult.data.result_data.critical_functions[cfId].total_HEP.toExponential(3)}`} />
               {/* { `Total HEP = ${this.state.quantificationResult.data.result_data.critical_functions[cfId].total_HEP.toExponential(3)}`} */}
                </Typography>
                
              </div>
              {/* <Typography>{' '}</Typography>
    <Typography>{}</Typography> */}
            </AccordionSummary>
            <AccordionDetails> 
            <Grid container spacing={2} wrap="wrap-reverse" justify="flex-start">
            <Grid item xs={9}>
            {/* has the total hep stuff in AccordionDetails */}
                </Grid>
                {/* <Typography> */}
                <>
                  {this.state.quantificationResult.data.result_data.critical_functions[
                    cfId
                  ].paths.map((element) => {
                    const formattedPath = element.path
                      .reverse()
                      .reduce((prev, curr) => {
                        const key = Object.keys(curr)[0];
                        return prev + `${curr[key]} from ${this.state.quantificationResult.data.result_data.label_map[key].label.name} - `;
                      }, "");
                    const totalHEP = element.total_HEP; // the total hep on the right hand side

                    if (formattedPath.length) {
                      const link = formattedPath.slice(
                        0,
                        formattedPath.length - 3
                      );
                      return (
                        <Grid item xs={9}>
                        
                          <Button className="cfName"
                                            component={Link}
                                            to={
                                              "/model/" +
                                              id +
                                              "/main-analysis/" +
                                              cfKey +
                                              "/quantify/"
                                            }
                                            target='_blank' 
                            onClick={async () => {
                              localStorage.setItem('cf_id', cfId)
                              await localStorage.setItem('obj_data', "path")
                              await localStorage.setItem("result_key", this.state.quantificationResult.data.id)
                              await localStorage.setItem('main_model_key', this.state.quantificationResult.data.model);    
                              await localStorage.setItem('detailed_id', cfId) // setting up this to get proper name later on
                              await localStorage.removeItem('path_sequence')
                              await localStorage.setItem("path_sequence", JSON.stringify(element.sequences))
                              await localStorage.setItem("real_path_hep", totalHEP)
                              await localStorage.setItem("link_path", link)

                            }
                          }
                          
                          >
                            {link}
                          </Button>
                          
                          <span style={{position: 'absolute', right: '4.05rem'}} ><CopyToClipboardChip color="secondary" variant="outlined" label={ `Total HEP = ${totalHEP.toExponential(3)}`} /></span>
                          
                          </Grid>
                      );
                    } else {
                      return (
                        <Grid>
                          <span style={{position: 'absolute', right: '4.05rem', bottom: '0.3rem'}}> <CopyToClipboardChip color="secondary" variant="outlined" label={ `Total HEP = ${totalHEP.toExponential(3)}`} /></span>
                          </Grid>
                      );
                    }
                  })}
                </>
                {/* </Typography> */}
                </Grid>
            </AccordionDetails>
          </Accordion>
          </>
  })}

        <h3>Quantification History</h3>
        {this.state.quantificationResult.allData && this.state.quantificationResult.allData
          .filter(
            (current) => current.id !== this.state.quantificationResult.data.id
          )
          .map((obj, index) => { 
// obj is important here...
            return Object.keys(obj.result_data).length > 0 ?      
            <>     
            <Accordion>
            <AccordionSummary >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Typography>
                  { obj.title == null ? obj.model_title :  obj.title}
                </Typography>
                <Typography>
                  Master ESD
                </Typography>
                <Button 
                    component={Link}
                    to={
                      "/model/" +
                      id +
                      "/main-analysis/" +
                      this.state.criticalFunctions[index] +
                      "/quantify/"
                    } 
                    target="_blank"
                    //@ts-ignore
                    onClick={async () =>{
                    // for all of the critical functions
                    await localStorage.setItem('obj_data',  "masterESD")
                    await localStorage.setItem('result_key', obj.id)
                    await localStorage.setItem('main_model_key', obj.model);
                    }}>
                      <ResultsIcon                      
              className={classes.extendedIcon}
              // disabled={quantifyDisabled || loading}
            />
  </Button>
                {/* <Typography>
                  <CopyToClipboardChip color="secondary" variant="outlined" label={ `Total HEP = ${this.state.quantificationResult.data.result_data.sequences.EndState.result.toExponential(3)}`} /> 
                </Typography> */}
                
              </div>
              {/* <Typography>{' '}</Typography>
    <Typography>{}</Typography> */}
            </AccordionSummary>
            <AccordionDetails style={{display: "flow-root"}}>
         {Object.keys(obj.critical_function_relations).map((cfId) => {

          const cfKey = Object.keys(this.state.cf_id_to_CRTids).find(key => this.state.cf_id_to_CRTids[key] == cfId);

             return <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Typography>
                      {obj.result_data.label_map[cfId].label.name}
                    </Typography>
                    {/* <Button
                    component={Link}
                    to={
                      "/model/" +
                      id +
                      "/main-analysis/" +
                      cfId +
                      "/quantify/"
                    } 
                    target="_blank"
                    //@ts-ignore
                    onClick={async () =>{
                    await localStorage.setItem('obj_data', JSON.stringify(this.getFormattedSequence(obj, cfId)));
                    }}>
                      <ResultsIcon                      
              className={classes.extendedIcon}
              // disabled={quantifyDisabled || loading}
            />
</Button> */}
                    <Typography>
                      {this.dateFormat(obj.date_created)}
                    </Typography>
                    <Typography>
                     {/* {`Total HEP = ${obj.result_data.critical_functions[cfId].total_HEP}`}  */}
                     <CopyToClipboardChip color="secondary" variant="outlined" label={ `Total HEP = ${obj.result_data.critical_functions[cfId].total_HEP.toExponential(3)}`} />
                    </Typography>
                  </div>
                  {/* <Typography>{' '}</Typography>
    <Typography>{}</Typography> */}
                </AccordionSummary>
                <AccordionDetails>
                <Grid container spacing={2} wrap="wrap-reverse" justify="flex-start">
                <Grid item xs={9}>
                    <Button 
                  component={Link}
                  to={
                    "/model/" +
                    id +
                    "/main-analysis/" +
                    cfKey +
                    "/quantify/"
                  } 
                variant="contained" color="primary" 
                target="_blank"
                onClick={async () =>{
                  localStorage.setItem('cf_id', cfId) // setting up this to get proper name later on
                  await localStorage.setItem('obj_data',  "critical_function")
                  await localStorage.setItem('result_key', obj.id)
                  await localStorage.setItem('main_model_key', obj.model);
                  await localStorage.setItem("detailed_id", cfId)
                    }}>
                      {/* note this button below's label... */}
                      {obj.result_data.label_map[cfId].label.name}
                      </Button>
                      </Grid>
                    {/* <Typography> */}
                    <>
                      {obj.result_data.critical_functions[cfId].paths.map(
                        (element) => {
                          const formattedPath = element.path
                            .reverse()
                            .reduce((prev, curr) => {
                              const key = Object.keys(curr)[0];
                              return prev + `${curr[key]} from ${obj.result_data.label_map[key].label.name} - `;
                            }, "");
                          const totalHEP = element.total_HEP;

                          if (formattedPath.length) {
                            const link = formattedPath.slice(
                              0,
                              formattedPath.length - 3
                            );
                            return (
                              <Grid item xs={9}>
                                <Link style={{paddingLeft:'1rem'}}
                                  to={
                                    "/model/" +
                                    id +
                                    "/main-analysis/" +
                                    cfKey +
                                    "/quantify/"
                                  }
                                  target="_blank"
                                  onClick={async() => {
                                    await localStorage.setItem('obj_data',  "path")
                                    await localStorage.setItem('result_key', obj.id)
                                    await localStorage.setItem('main_model_key', obj.model);
                                    await localStorage.setItem('detailed_id', cfId);
                                    await localStorage.removeItem('path_sequence')
                                    await localStorage.setItem("path_sequence", JSON.stringify(element.sequences))
                                    await localStorage.setItem("real_path_hep", totalHEP)
                                    await localStorage.setItem("link_path", link)

                                  }
                                  }
                                >
                                  {link}
                                </Link>
                                <span style={{paddingLeft:'1.5rem'}}><CopyToClipboardChip color="secondary" variant="outlined" label={(totalHEP).toExponential(3)} /></span>
                                </Grid>
                            );
                          } else {
                            return (
                              <Grid>
                                <span style={{paddingLeft:'1.5rem'}}><CopyToClipboardChip color="secondary" variant="outlined" label={(totalHEP).toExponential(3)} /></span>
                                </Grid>
                            );
                          }
                        }
                      )}
                    </>
                    {/* </Typography> */}
                    </Grid>
                </AccordionDetails>
              </Accordion>
          })}
            </AccordionDetails>
            </Accordion>

            

            </>
            : 
            Object.keys(obj.critical_function_relations).map((cfId) => {
            const cfKey = Object.keys(this.state.cf_id_to_CRTids).find(key => this.state.cf_id_to_CRTids[key] == cfId);

             return <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Typography>
                      {obj.result_data.label_map[cfId].label.name}
                    </Typography>
                    {/* <Button
                    component={Link}
                    to={
                      "/model/" +
                      id +
                      "/main-analysis/" +
                      cfId +
                      "/quantify/"
                    } 
                    target="_blank"
                    //@ts-ignore
                    onClick={async () =>{
                    await localStorage.setItem('obj_data', JSON.stringify(this.getFormattedSequence(obj, cfId)));
                    }}>
                      <ResultsIcon                      
              className={classes.extendedIcon}
              // disabled={quantifyDisabled || loading}
            />
</Button> */}
                    <Typography>
                      {this.dateFormat(obj.date_created)}
                    </Typography>
                    <Typography>
                     {/* {`Total HEP = ${obj.result_data.critical_functions[cfId].total_HEP}`}  */}
                     <CopyToClipboardChip color="secondary" variant="outlined" label={ `Total HEP = ${obj.result_data.critical_functions[cfId].total_HEP.toExponential(3)}`} />
                    </Typography>
                  </div>
                  {/* <Typography>{' '}</Typography>
    <Typography>{}</Typography> */}
                </AccordionSummary>
                <AccordionDetails>
                <Grid container spacing={2} wrap="wrap-reverse" justify="flex-start">
                <Grid item xs={9}>
                    <Button 
                  component={Link}
                  to={
                    "/model/" +
                    id +
                    "/main-analysis/" +
                    cfKey +
                    "/quantify/"
                  } 
                variant="contained" color="primary" 
                target="_blank"
                onClick={async () =>{
                  localStorage.setItem('cf_id', cfId) // setting up this to get proper name later on
                  await localStorage.setItem('obj_data',  "critical_function")
                  await localStorage.setItem('result_key', obj.id)
                  await localStorage.setItem('main_model_key', obj.model);
                  await localStorage.setItem("detailed_id", cfId)
                                        }}>
                      {obj.result_data.label_map[cfId].label.name}
                      </Button>
                      </Grid>
                    {/* <Typography> */}
                    <>
                      {obj.result_data.critical_functions[cfId].paths.map(
                        (element) => {
                          const formattedPath = element.path // formattedPath is what we want to pass to the next thing...
                            .reverse()
                            .reduce((prev, curr) => {
                              const key = Object.keys(curr)[0];
                              return prev + `${curr[key]} from CF${key} - `;
                            }, "");
                          const totalHEP = element.total_HEP;

                          if (formattedPath.length) {
                            const link = formattedPath.slice(
                              0,
                              formattedPath.length - 3
                            );
                            localStorage.setItem('cfidPathObj', JSON.stringify(cfidPathObj))
                            return (
                              <Grid item xs={9}>
                                <Link style={{paddingLeft:'1rem'}}
                                  to={
                                    "/model/" +
                                    id +
                                    "/main-analysis/" +
                                    cfKey +
                                    "/quantify/"
                                  }
                                  target="_blank"
                                  onClick={async() => {
                                    await localStorage.setItem('obj_data',  "path")
                                    await localStorage.setItem('result_key', obj.id)
                                    await localStorage.setItem('main_model_key', obj.model);
                                    await localStorage.setItem('detailed_id', cfId);
                                    await localStorage.removeItem('path_sequence')
                                    await localStorage.setItem("path_sequence", JSON.stringify(element.sequences))
                                    await localStorage.setItem("real_path_hep", totalHEP)
                                    await localStorage.setItem("link_path", link)
                                    // localStorage.setItem('obj_data', JSON.stringify(this.getFormattedSequence(obj, null, "path", element.sequences, element.total_HEP )))
                                  } }// obj_data disappears after opening thing in new tab...
                                >
                                  {link}
                                </Link>
                                <span style={{paddingLeft:'1.5rem'}}><CopyToClipboardChip color="secondary" variant="outlined" label={(totalHEP).toExponential(3)} /></span>
                                </Grid>
                            );
                          } else {
                            return (
                              <Grid>
                                <span style={{paddingLeft:'1.5rem'}}><CopyToClipboardChip color="secondary" variant="outlined" label={(totalHEP).toExponential(3)} /></span>
                                </Grid>
                            );
                          }
                        }
                      )}
                    </>
                    {/* </Typography> */}
                    </Grid>
                </AccordionDetails>
              </Accordion>
          });
          })}

        <div className="btn">
          <button disabled={!this.state.quantificationResult.prev} onClick={()=>this.getQuantificationResults(this.state.quantificationResult.prev)}>Prev</button>
          <button disabled={!this.state.quantificationResult.next} onClick={()=>this.getQuantificationResults(this.state.quantificationResult.next)}>Next</button>
        </div>
 
        {/* <QuantificationResultsView
                    // loading={quantificationResults.loading || treeMetadata.loading}
                    detail={treeDetails}
                    results={mappedResults.results}
                    resultTotal={quantificationResults.itemTotal ? quantificationResults.itemTotal : null}
                    paginationEnabled={quantificationResults.paginationEnabled}
                    updateList={this.updateQuantificationResultSet}
                    traces={mappedResults.traces}
                  /> */}

        {/* 
        <QuantificationCard
          loading={quantificationResult.loading}
          data={quantificationResult.data}
          onRequestQuantify={(configurations: any) => this.quantifyCRT(configurations)}
          treeId={trimmedCRT.id && trimmedCRT.id}
          quantifyDisabled={!trimmedCRT.id}
          title={intl.formatMessage({ id: 'results.quantificationTotalHEP'})}
          computeErrorFunc={(data, failureKey) => computeHEPFromData(data, 'EF_')}
        /> */}
      </React.Fragment>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withRouter(injectIntl(QuantificationView))
);
