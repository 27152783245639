import React, { useState } from 'react';
import { TextField, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Checkbox, Button } from '@material-ui/core';

const ScreeningPassword = (props: any) => {
    const { initialScreeningPassword, setInitialScreeningPassword } = props;
    const [open, setOpen] = useState(true);
    const [checked, setChecked] = useState(false);

    const handleClose = () => {
        if (checked) {
            setOpen(false);
        } else {
            alert("Please acknowledge the terms of use to proceed.");
        }
    };

    const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
    };

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
            }}
        >
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Terms of Use</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        "Phoenix is developed by the B. John Garrick Institute for the Risk Sciences at UCLA (GIRS-UCLA). It is designed to develop models for testing and gaining familiarity with the Phoenix HRA methodology. This version of Phoenix is intended for educational and exploratory purposes only. It should not be utilized for decision-making processes. GIRS-UCLA assumes no responsibility for any uses of the results derived from this software."
                    </DialogContentText>
                    <div>
                        <Checkbox
                            checked={checked}
                            onChange={handleCheck}
                        />
                        I acknowledge the terms of use.
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Acknowledge and Proceed
                    </Button>
                </DialogActions>
            </Dialog>

            <div
                style={{
                    display: "flex",
                    flexDirection: 'column',
                }}
            >
                <Typography>Please Enter Password to Proceed with Phoenix</Typography>
                <Typography>For Details Email: mariliar@ucla.edu</Typography>
                <TextField
                    type='password'
                    variant='filled'
                    value={initialScreeningPassword}
                    onChange={(e) => setInitialScreeningPassword(e.target.value)}
                />
            </div>
        </div>
    );
}

export default ScreeningPassword;
