import Tooltip from "@material-ui/core/Tooltip";
import Button, { ButtonTypeMap } from "@material-ui/core/Button";
import {Dialog, Theme} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import React, {useState} from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import VisibilityIcon from '@material-ui/icons/Visibility';

const useStyles = makeStyles((theme: Theme) => ({

  graphViewContainer: {
    width: "72vw",
    height: '72vh'
  },
  btnRoot: {
    textTransform: 'none'
  }

}));


type TreeReferenceButtonProps = {
  title: string,
  tooltipTitle: string,
  disabled?: boolean,
  graphView: any,
  buttonVariant?: ButtonTypeMap['props']['variant'],
  icon?: any
}

const TreeReferenceButton = (props: TreeReferenceButtonProps) => {

  const { tooltipTitle, title, graphView, disabled, buttonVariant, icon } = props;
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  return (
    <React.Fragment>
      <Tooltip title={tooltipTitle}>
        <Button
          className={classes.btnRoot}
          startIcon={icon ? icon : <VisibilityIcon />}
          variant={buttonVariant ? buttonVariant : "outlined"}
          disabled={disabled}
          onClick={(e) => {
              e.stopPropagation();
              setOpen(true);
          }}
        >
          {title}
        </Button>
      </Tooltip>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth={false}
        fullWidth
        onClick={(e) => {
            e.stopPropagation();
        }}
      >
        <DialogContent className={classes.graphViewContainer}>
          {graphView}
        </DialogContent>
      </Dialog>
    </React.Fragment>
  )
};

export default TreeReferenceButton;
