import React from "react";
import Grid from "@material-ui/core/Grid";
import { Card, Typography } from "@material-ui/core";
import PIFEntry from "../PIF/PIFEntry/PIFEntry";
import { useIntl } from "react-intl";

interface Props {
  detail: any;
  pifs: any;
  cfmKey: string;
  pifProgress: any;
  match: any;
  onResponseUpdated: () => void;
  updateScore: ()=>void
}
const SelectedPIFs = (props: Props) => {
  const {
    getProgress,
    detail,
    pifs,
    cfmKey,
    pifProgress,
    cfId,
    match: {
      params: { id },
    },
    onResponseUpdated,
  } = props;
  const intl = useIntl();
  const cfmLabel = detail.cfms[cfmKey].label
    ? detail.cfms[cfmKey].label.name
    : detail.cfms[cfmKey];
  if (Object.keys(pifs).length > 0) {
    return (
      <React.Fragment>
        <Grid xs={12}>
          <Typography>{intl.formatMessage({ id: "analysis.pif" })}</Typography>
        </Grid>
        {Object.keys(pifs).map((pifKey: any) => {
          const pif = pifs[pifKey];
          const progress = pifProgress.find((item: any) => {
            // console.log("these are the items!!12345", item);
            return item.name === pifKey && item.cfm.includes(cfmLabel);
          });
        //   console.log("this is the progress", progress);

          const getScore = () => {
            // const questionsNotAnswered =
            //   progress.total == progress.completed;
            if (pif && progress.total == progress.completed) {
              return pif.score;
            }
            return null;
          };
          return (
            <Grid item xs={12} lg={6} component={Card}>
              <PIFEntry
                key={pifKey}
                pifName={pifKey}
                pifScore={getScore()}
                pifOverride={pif.override}
                pifExpression={pif.expression}
                cfmKey={cfmKey}
                cfmLabel={cfmLabel}
                hfeKey={detail.name}
                modelId={id}
                cfid={cfId}
                progress={{
                  completed: progress.completed,
                  total: progress.total,
                }}
                faultTreeId={detail.fault_tree_id}
                cfmTreeId={detail.cfms[cfmKey].fault_tree_id}
                hfeName={detail.label.name}
                onResponseUpdated={onResponseUpdated}
                updateScore={props.updateScore}
                updateProgress={getProgress}
              />
            </Grid>
          );
        })}
      </React.Fragment>
    );
  }

  return (
    <Typography variant="caption">
      {intl.formatMessage({ id: "noSelection.pifs" })}
    </Typography>
  );
};

export default SelectedPIFs;
