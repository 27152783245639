import EventTreeMxGraphDecoder from "hcl-web-editor/app/mxGraph/HCLTreeMxGraphDecoder/EventTreeMxGraphDecoder";
import FunctionalEventVertex from "hcl-web-editor/app/mxGraph/HCLTreeNode/EventTreeNode/FunctionalEventNode/FunctionalEventVertex";
import FunctionalEventVertexValue from "hcl-web-editor/app/mxGraph/HCLTreeNode/EventTreeNode/FunctionalEventNode/FunctionalEventVertexValue";
import EventTreeMxGraph, {
  BranchJSON,
  EventTreeMxGraphJSON,
} from "hcl-web-editor/app/mxGraph/HCLTreeMxGraph/EventTreeMxGraph";
import ReferenceTypes from "hcl-web-editor/app/mxGraph/HCLTreeNode/HCLTreeVertexValue/ReferenceTypes";
import Outcome, {
  OutcomeJSON,
} from "hcl-web-editor/app/mxGraph/HCLTreeNode/HCLTreeVertexValue/Outcome";
import Label from "hcl-web-editor/app/mxGraph/HCLTreeNode/HCLTreeVertexValue/Label";
import EventTreeVertex from "hcl-web-editor/app/mxGraph/HCLTreeNode/EventTreeNode/EventTreeVertex";
import ProxyTypes from "hcl-web-editor/app/mxGraph/HCLTreeNode/HCLTreeVertexValue/ProxyTypes";
import Instruction from "hcl-web-editor/app/mxGraph/HCLTreeNode/HCLTreeVertexValue/Instruction";
import FunctionalEventPortValue from "hcl-web-editor/app/mxGraph/HCLTreeNode/EventTreeNode/FunctionalEventNode/Port/FunctionalEventPortValue";
import PhoenixFlowchartMxGraph from "./PhoenixFlowchartMxGraph";
import BranchPointVertex from "../HCLTreeNode/PhoenixFlowchartNode/BranchPointVertex";
import DecisionPointVertex from "../HCLTreeNode/PhoenixFlowchartNode/DecisionPointVertex";
import BranchPointEdge from "../HCLTreeNode/PhoenixFlowchartNode/BranchPointEdge";
import DecisionPointEdge from "../HCLTreeNode/PhoenixFlowchartNode/DecisionPointEdge";
import InitVertex from "hcl-web-editor/app/mxGraph/HCLTreeNode/EventTreeNode/InitEvent/InitVertex";
import EndStateVertex from "hcl-web-editor/app/mxGraph/HCLTreeNode/EventTreeNode/EndStateNode/EndStateVertex";
import PhoenixEndStateVertex from "../HCLTreeNode/PhoenixFlowchartNode/PhoenixEndStateVertex";

type Node = {
  name: string;
  success?: Node | string;
  failure?: Node | string;
};

class NewPhoenixFlowchartMxGraphDecoder extends EventTreeMxGraphDecoder {
  private hasInitVertex = false;

  constructor(graph: PhoenixFlowchartMxGraph, json: Node) {
    super(graph, json);
    this.visitedNodes = {};
  }

  public decode(): void {
    if (this.json) {
      const initVertex = this.createVertex(this.json);
      this.createNodesAndConnections(this.json, initVertex);
    }
  }

  public updatedAnswer(updatedAnswers: any): void {
    const initVertex = this.hasInitVertex
      ? this.graph.getVerticesWithKey("Initiating Event")
      : this.createVertex({ name: "Initiating Event" });
    if (updatedAnswers.length > 1) {
      this.createNodesAndConnections(updatedAnswers, initVertex);
    }
  }

  private edgeExists(
    source: EventTreeVertex,
    target: EventTreeVertex,
    edgeType: "success" | "failure"
  ): boolean {
    const edges = this.graph.getEdgesBetween(source, target);
    return edges.some(
      (edge) => edge instanceof BranchPointEdge && edge.value === edgeType
    );
  }

  public createNodesAndConnections(
    node: Node,
    parentVertex?: EventTreeVertex,
    edgeType?: "success" | "failure"
  ): void {
    if (!this.visitedNodes[node.name]) {
      this.visitedNodes[node.name] = this.createVertex(node);
    }
  
    const vertex = this.visitedNodes[node.name];
  
    // Set the edge label based on the parent node
    let edgeLabel = edgeType;
    if (parentVertex && parentVertex.key === "Initiating Event") {
      edgeLabel = '';
    }
  
    // Logic to add edge
    if (parentVertex && edgeType !== undefined) {
      if (!this.edgeExists(parentVertex, vertex, edgeType)) {
        this.addEdge(new BranchPointEdge(edgeLabel), parentVertex, vertex);
      }
    } else if (parentVertex && edgeType === undefined) {
      this.addEdge(new BranchPointEdge(edgeLabel), parentVertex, vertex);
    }
  
    if (node.success) {
      if (typeof node.success === "object") {
        this.createNodesAndConnections(node.success, vertex, "success");
      } else if (typeof node.success === "string") {
        if (!this.visitedNodes[node.success]) {
          this.visitedNodes[node.success] = this.createVertex({
            name: node.success,
          });
        }
  
        const successVertex = this.visitedNodes[node.success];
  
        if (!this.edgeExists(vertex, successVertex, "success")) {
          this.addEdge(new BranchPointEdge("success"), vertex, successVertex);
        }
      }
    }
  
    if (node.failure) {
      if (typeof node.failure === "object") {
        this.createNodesAndConnections(node.failure, vertex, "failure");
      } else if (typeof node.failure === "string") {
        if (!this.visitedNodes[node.failure]) {
          this.visitedNodes[node.failure] = this.createVertex({
            name: node.failure,
          });
        }
        const failureVertex = this.visitedNodes[node.failure];
  
        if (!this.edgeExists(vertex, failureVertex, "failure")) {
          this.addEdge(new BranchPointEdge("failure"), vertex, failureVertex);
        }
      }
    }
  }
  
  

  private createVertex(node: Node): EventTreeVertex {
    let value = new FunctionalEventVertexValue(new Label(node.name));
    if (node.name.includes("DP_")) {
      return this.addVertex(
        new DecisionPointVertex(node.name, value)
      ) as DecisionPointVertex;
    } else if (node.name.includes("BP_")) {
      return this.addVertex(
        new BranchPointVertex(node.name, value)
      ) as BranchPointVertex;
    } else if (node.name.includes("ES_") || node.name.includes("EF_")) {
      return this.addVertex(
        new EndStateVertex(node.name, value)
      ) as EndStateVertex;
    } else if (node.name === "Initiating Event") {
      this.hasInitVertex = true;
      return this.addVertex(
        new InitVertex("Initiating Event", value)
      ) as InitVertex;
    }
  }

  public continueFromLastBP(newNodeData: Node): void {
    const lastBPKey = newNodeData.name;
    const lastBP = this.graph.getVerticesWithKey(lastBPKey);

    if (lastBP) {
      this.createNodesAndConnections(newNodeData, lastBP);
    } else {
      console.error("No existing BP to connect to!");
    }
  }
}

export default NewPhoenixFlowchartMxGraphDecoder;
