import React from 'react';
import {
  PhoenixEventTreeMxGraph,
  PhoenixFaultTreeMxGraph, PhoenixFlowChartMxGraph, PhoenixTreeMxGraphView
} from "../../Model/SinglePhoenixModelView/PhoenixTreeMxGraphView";
import TreeReferenceButton from "../../Model/SinglePhoenixModelView/Analysis/HFE/TreeReferenceButton";
import {useIntl} from "react-intl";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "@material-ui/core/Tooltip";
import {withRouter} from "react-router";
import AssessmentIcon from "@material-ui/icons/Assessment";
import {createStyles, Theme} from "@material-ui/core/styles";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    whiteSpace: 'nowrap'
  },
}));

interface Props {
  graphView?: {
    graph: PhoenixFaultTreeMxGraph | PhoenixEventTreeMxGraph | PhoenixFlowChartMxGraph,
    toolTipTitle: string,
    disabled?: boolean,
  },
  editLink?: {
    url: string,
    disabled?: boolean
  },
  quantifyLink?: {
    url: string,
    disabled?: boolean
  },
  others?: any,
  history: {[key: string] : any }
}

const ActionButtons = (props: Props) => {
  const { graphView, editLink, quantifyLink, others, history } = props;

  const classes = useStyles();
  const intl = useIntl();

  return (
    <div className={classes.root}>
      {
        graphView && (
          <TreeReferenceButton
            tooltipTitle={graphView.toolTipTitle}
            title={intl.formatMessage({id: 'view'})}
            disabled={graphView.disabled}
            graphView={<PhoenixTreeMxGraphView graph={graphView.graph} />}
          />
        )
      }
      {
        editLink && (
          <Tooltip title={intl.formatMessage({ id: 'edit' })}>
            <IconButton size={"small"} disabled={editLink.disabled} onClick={() => history.push(`${editLink.url}`)}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        )
      }
      {
        quantifyLink && (
          <Tooltip title={intl.formatMessage({ id: 'quantify' })}>
            <IconButton size={"small"} onClick={() => history.push(`${quantifyLink.url}`)} disabled={quantifyLink.disabled}>
              <AssessmentIcon />
            </IconButton>
          </Tooltip>
        )
      }
      {
        others && others
      }
    </div>
  );
};

export default withRouter(ActionButtons);
