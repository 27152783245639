import React from "react";
import "./styles.css";
import PhoenixApiManager from "../../../../../../Api/PhoenixApiManager";


class NotePad extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      noteText: "",
      branchPoints: ""
    };
  }

// Success(id: endstate), Failure(id: endstate) = array





getNotes = () => {
  const { cfId } = this.props;
// const crtPlaceholder = `#Please re-label the relevant BPs, and delete the non-relevant ones, as instructed by the pop-ups that appear when you answer the questions. Note that if you are asked to label the same BP, it is because it appears more than once in the CRT. Label accordingly (e.g. H1, H2, H3...). \nPlease click "save" before going to the next page #\n\nBP A: Manual initiation of the CF before it is automatically initiated\n\nBP B: Automatic initiation of the CF\n\nBP C: The crew does not manually turn off the automatically initiated CF\n\nBP D: Availability of relevant instruments for the Crew awareness on the CF\n\nBP E: Crew awareness of the CF\n\nBD F: The crew is on the correct procedure\n\nBP G: Equipment availability (specify for each new equipment to be included: G1, H2...)\n\nBP H: The crew correctly performs the procedural step (specify for each new step to be included: H1, H2...)\n\nBP I: Continuation of procedural steps after failure in non-critical step (Add probability = 0)\n\nBP J: The crew does not exit procedure before the critical function is achieved\n\nBP K: Continuation of procedural steps after failure of non-critical equipment (Add probability = 0)\n\nBP HRec: The crew recovers from failure in critical procedural step (specify for each new HRec: HRec1, HRec2...)\n\nBP GRec: Recovery from critical equipment unavailability (specify for each new GRec: GRec1, GRec2...)`
const crtPlaceholder = ''
      PhoenixApiManager.openMainModelNote(cfId)
        .then(res => {
          this.setState({ noteText: res.data.misc.notes ? res.data.misc.notes : crtPlaceholder});
          this.setState({branchPoints: res.data.misc})
        });

};


onSaveNotes = () => {
  const { cfId, setOpen } = this.props;
    // const notes = document.getElementById("notes-value").value;
    const obj = { misc: { ...this.state.branchPoints, notes: this.state.noteText }};
      PhoenixApiManager.partialUpdateMainModel(cfId, obj)
        .then(res => {
          this.setState({ noteText: res.data.misc.noteText });
        });
        setOpen(false)


};


componentDidMount() {
  const { cfId } = this.props;

  this.getNotes(cfId);
}


  // onSaveNotes = () => {
  //   const notes = document.getElementById("notes-value").value;
  //   const obj = { notes };
  //   this.setState({
  //     notesText: "",
  //     noteList: [...this.state.noteList, obj],
  //   });
  //   console.log("noteList", this.state.noteList);
  // };

  onChangeValue = (e) => {
    const notes = document.getElementById("notes-value").value;
    this.setState({
      noteText: e.target.value
    });
  };

  onDeleteNote = index => {
    const deleteNotes = this.state.noteList.filter((el, i) =>  i !== index);
    this.setState({
      noteList: deleteNotes
    });
  };

  render() {
    return (
      <div className="Note-pad">
        <div className="some-test">
          <div>
            <textarea
              rows="5"
              cols="35"
              placeholder="Enter Notes here"
              id="notes-value"
              value={this.state.noteText}
              onChange={(e)=> this.onChangeValue(e)}
            />
            <button className="save-button" onClick={this.onSaveNotes}>
              Save and close
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default NotePad;
