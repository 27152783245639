import React from 'react';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import IconButton from '@material-ui/core/IconButton/IconButton';
import SettingsIcon from '@material-ui/icons/SettingsTwoTone';
import { withStyles, Theme } from '@material-ui/core';
import { Link } from 'react-router-dom';
import ApiManager from 'hcla-web-frontend-primitives/app/Api/ApiManager';
import { injectIntl } from 'react-intl';
import { DetailsSettingsPropType } from '../../Types';

const styles = (theme: Theme) => ({
  badge: {
    top: 1,
    right: -16,
    border: `2px solid ${
      theme.palette.type === 'light' ? '#fff' : theme.palette.grey[900]
    }`,
  },
});

interface Props {
  classes: any,
  intl: any,
  details: DetailsSettingsPropType,
}

const ModelEntrySettingsIcon = (props: Props) => {
  const { details, intl } = props;
  return (
    <Tooltip title={intl.formatMessage({ id: 'settings' })}>
      <Link
        to={{
          pathname: `${ApiManager.MODEL_PATH}/${details.id}/main-settings/`,
          state: { details },
        }}
      >
        <IconButton color="primary" aria-label="Settings">
          <SettingsIcon />
        </IconButton>
      </Link>
    </Tooltip>
  );
};

export default withStyles(styles)(injectIntl(ModelEntrySettingsIcon));
