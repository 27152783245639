import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { Breadcrumbs } from 'react-breadcrumbs-dynamic';
import CrumbIconItem from 'hcla-web-frontend-primitives/app/components/primitives/Breadcrumbs/CrumbIconItem';
import { Breadcrumb as BootstrapBreadcrumb } from 'react-bootstrap';
import LinearProgress from '@material-ui/core/LinearProgress';

import PhoenixModelContext from '../PhoenixModelContext';
import ReportButton from '../Report/ReportButton';

const styles = theme => ({
  root: {
    color: theme.palette.text.primary,
  },
  breadcrumbs: {
    padding: '0px',
  },
  crumbsReportBar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

const newBreadcrumb = ({ classes, children, ...props }) => (
  <BootstrapBreadcrumb className={classes.breadcrumbs} {...props}>{children}</BootstrapBreadcrumb>
);

newBreadcrumb.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  children: PropTypes.instanceOf(Object).isRequired,
};

const BreadcrumbWrapper = withStyles(styles)(newBreadcrumb);

const getTotalProgress = (value) => {
  const { details: { progress: { all: { summary: { completed, total } } } }, isLoading, loaded } = value;
  if (isLoading || !loaded) {
    return 0;
  }
  localStorage.setItem('progress', completed / total * 100.0);
  return completed / total * 100.0;
};

const ModelHeader = (props) => {
  const { classes, id } = props;
  return (
    <PhoenixModelContext.Consumer>
      {
        value => (
          <div className={classes.root}>
            <LinearProgress
              variant={value.isLoading ? 'indeterminate' : 'determinate'}
              value={getTotalProgress(value)}
            />
            <div className={classes.crumbsReportBar}>
              <Breadcrumbs
                className={classes.breadcrumbs}
                separator={<b> / </b>}
                item={CrumbIconItem}
                container={BreadcrumbWrapper}
                finalProps={{ active: true }}
                duplicateProps={{ to: 'href' }}
                compare={(a, b) => a.depth - b.depth}
              />
              {/*<ReportButton id={id} />*/}
            </div>
          </div>
        )
      }
    </PhoenixModelContext.Consumer>
  );
};

ModelHeader.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  id: PropTypes.number.isRequired,
};

export default withStyles(styles)(ModelHeader);
