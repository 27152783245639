import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';

interface Props {
  match: { [key: string]: any },
  location: { [key: string]: any },
  depth: number,
}

const EditCFM = (props: Props) => {
  const { depth, match: { params: { hfeName, cfmName, id,cfid } } } = props;
  return (
    <React.Fragment>
      <BreadcrumbsItem to={`/model/${id}/main-analysis/${cfid}/hfe/${hfeName}/cfm`} depth={depth - 2}>CFMs</BreadcrumbsItem>
      <BreadcrumbsItem to={`/model/${id}/main-analysis/${cfid}/hfe/${hfeName}/cfm/${cfmName}`} depth={depth - 1}>
        {cfmName}
      </BreadcrumbsItem>
      <BreadcrumbsItem to={`/model/${id}/${cfid}/hfe/${hfeName}/cfm/${cfmName}/edit`} depth={depth}>
        Edit
      </BreadcrumbsItem>
      Edit CFM
    </React.Fragment>
  );
};

export default EditCFM;
