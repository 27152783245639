import React, {useState} from 'react';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { Title } from '@material-ui/icons';


const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function CustomizedDialogs(props) {
    // const {open, handleClose} = props;
  const {currentTitle, setDependency,setTitle,setTitleConfirmed,cur_dependency, quantify, handleClose, open}=props
  console.log(props, 'pop up props')


  return (
    <div>
      <Dialog disableBackdropClick={true} onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Phoenix Quantification Configuration
        </DialogTitle>
        If the "Confirm selections" button is disabled, please make sure to enter a title. If it's still disabled, refresh the page and make a configuration with the arrow button on the right of Quantify
        <DialogContent dividers>
        <TextField
        required
        variant="outlined"
        fullWidth
        label="Quantification Title"
        onChange={(event)=>{setTitle(event.target.value)}}
        >
        </TextField>
        <br/>
        <FormControlLabel
    control={<Switch checked={cur_dependency} onChange={setDependency} />}
    label="Enable PIF dependency"
    labelPlacement="start"
  />

        </DialogContent>
        <DialogActions>
        <Button disabled={currentTitle.length == 0} autoFocus onClick={handleClose} color="primary">
            Comfirm selections
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}