import React, {useEffect, useState} from 'react';
import { Typography, withStyles, Theme } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';

import { useIntl } from 'react-intl';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import InputRules from "hcl-web-editor/app/components/Model/SingleHCLModelView/SingleTreeView/Inputs/InputRules";
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import { Expression, ExpressionJSON } from 'hcl-web-editor/app/mxGraph/HCLTreeNode/HCLTreeVertexValue/Expression';
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Divider from '@material-ui/core/Divider';
import ProbabilityInput from "hcl-web-editor/app/components/Model/SingleHCLModelView/SingleTreeView/Inputs/ProbabilityInput";
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme: Theme) => ({
    tableRoot: {
		tableLayout: 'fixed'
	},
    cellStyle: {
        borderBottom: 'none'
    },
    headerText: {
        marginBottom: '20px'
    },
    secondHeaderText: {
        marginBottom: '20px',
        marginTop: '20px'
    }
}));

const questions1 = [
    "Does the success of this critical task require reaching an information source and/or a tool or equipment that is not accesssible to the crew?",
    "Does the success of this critical task require more manpower than the available crew?",
    "Is the time required for finalizing this critical task higher than the available time for the crew to perform it?"
];
const questions2 = [
    "Is this a time-sensitive task?",
    "Probability that the time required for completing the ask is higher than the time available for the crew:"
];

const HFEFeasibilityDialog = (props: HFEFeasibilityDialogProps) => {
    const { header, handleClose, score, save, expression, isOverride, onSubmitClose } = props;
  
    const intl = useIntl();
	const classes = useStyles();
    
    const [value, setValue] = useState(0);
    const [expressionError, setExpressionError] = useState(true); // used for if justification is filled out or not
    const [expr, setExpr] = useState(new Expression(expression).toExtendedJSON());
    const [override, setOverride] = useState(isOverride ? isOverride : false);
    const [hasError, setHasError] = useState(!InputRules.isProbabilityValid(value));
    const [title, setTitle] = useState("");
	  const [checked, setChecked] = useState(new Array(4).fill(false));
    const [returnValue, setReturnValue] = useState(false);
    const [loading, setLoading] = useState(false);



    const timeBasicEvent = {
      "wPBAi37o3": {
          "failure_probability": 0.5
      }}
  




    useEffect(() => {
      setHasError(!InputRules.isProbabilityValid(value));
    },[value]);

    useEffect(() => {
      setReturnValue(checked.includes(true));
      props.setDialogReturn(checked);
    },[checked]);
  
    const payload = JSON.stringify({
      score: value,
      override,
      score_justification: title,
      expression: new Expression(expression).toJSON(),
    });
  
    const handleChange = (e: any, name: string, multiple: boolean) => {
      e.persist();
      if (multiple) {
        setExpr({ ...expr, ...e.target.value });
      } else {
        setExpr({ ...expr, [e.target.name]: e.target.value });
      }
    };

    if(loading){
      <Typography>
      <CircularProgress/>
      Loading...
      </Typography>
    }

    let btnLabel = `${intl.formatMessage({ id: 'submit' })}`

    if (loading) {
      btnLabel = intl.formatMessage({ id: 'fease' });
    }

    const addValueToArray = (value: Number) => {
      checked.push(value)
      return checked
    }
  
    return (
      
      <Dialog open={true} maxWidth="lg" fullWidth onClose={handleClose} aria-labelledby="form-dialog-title">
        {header}
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <DialogTitle id="form-dialog-title">
              {`${intl.formatMessage({ id: 'Feasibility Assessment' })}`}
            </DialogTitle>
          </Grid>
          <Grid item>
            {/* <Switch checked={override} onChange={() => setOverride(!override)} /> */}
          </Grid>
        </Grid>
        <DialogContent>
          <Typography gutterBottom={true} className={classes.headerText}>
            If the answer to any of these questions is "Yes", this task will be assigned a probability of failure of 1.
          </Typography>
            <TableContainer component={Paper}>
                <Table className={classes.tableRoot}>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.cellStyle}></TableCell>
                            <TableCell className={classes.cellStyle} align="center">{`${intl.formatMessage({ id: 'no' })}/${intl.formatMessage({ id: 'yes' })}`}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        questions1.map((question, index) => {
                            return (
                              <TableRow key={index}>
                                <TableCell className={classes.cellStyle} component="th" scope="row">
                                  <Typography>{question}</Typography>
                                </TableCell>
                                <TableCell className={classes.cellStyle} align="center">
                                  <Switch
                                    checked={checked[index]}
                                    onChange={(e) => {
                                        let checks = checked.slice();
                                        checks[index] = e.target.checked;
                                        setChecked(checks);
                                        }}
                                  />
                                </TableCell>
                              </TableRow>
                            );
                        })
                      }
                      <Divider />
                    <Typography gutterBottom={true} className={classes.secondHeaderText}>Probability of failure due to a lack of time to accomplish the task:</Typography>
                    <TableRow>
                        <TableCell className={classes.cellStyle} component="th" scope="row">
                            <Typography>{questions2[0]}</Typography>
                        </TableCell>
                        <TableCell className={classes.cellStyle} align="center">
                            <Switch
                            disabled={(checked[0] || checked[1] || checked[2])}
                            checked={checked[3]}
                            onChange={(e) => {
                                let checks = checked.slice();
                                checks[3] = e.target.checked;
                                setChecked(checks);
                                }}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className={classes.cellStyle} component="th" scope="row">
                            <Typography>{questions2[1]}</Typography>
                        </TableCell>
                        <TableCell className={classes.cellStyle} align="center">
                            <ProbabilityInput
                                autoFocus={true}
                                disabled={!checked[3]}
                                margin="dense"
                                label={intl.formatMessage({ id: 'analysis.pifScore' })}
                                value={value}
                                onChange={e => setValue(e.target.value)}
                                InputProps={{ inputProps: { min: 0, max: 1 } }}
                            />
                        </TableCell>
                    </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose} color="primary">
                {`${intl.formatMessage({ id: 'cancel' })}`}
            </Button>
            <Button
                variant="contained"
                onClick={()=>{
                  setLoading(true)
                  onSubmitClose(checked[3] && value > 0 ? addValueToArray(value) : checked.includes(true) && checked[3] == false ? checked : null)}}
                color="primary"
            >
                {btnLabel}
            </Button>
        </DialogActions>
      </Dialog>
    );
  };

export default HFEFeasibilityDialog;