import React from 'react';
import Typography from "@material-ui/core/Typography";
import {Avatar} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Theme} from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		marginRight: theme.spacing(1),
		color: 'white'
	},
}));

interface Props {
	text: string,
	rootClassOverride?: any,
}
const AvatarText = (props: Props) => {
	const { text, rootClassOverride } = props;
	const classes = useStyles();

	return (
		<Avatar className={`${classes.root} ${rootClassOverride ? rootClassOverride : null}`}>
			<Typography variant={"caption"}>{text}</Typography>
		</Avatar>
	);
};

export default AvatarText;