export const outgoingEdges = {
  DP_1: {
    Yes: 'DP_2',
    No: 'DP_5',
  },
  DP_2: {
    Yes: 'BP_B',
    No: 'DP_3A',
  },
  DP_3A: {
    Yes: 'BP_D1',
    No: 'BP_B',
  },
  DP_3B: {
    Yes: 'BP_D2',
    No: 'EF_3',
  },
  DP_4: {
    Yes: 'DP_5',
    No: 'EF_8',
  },
  DP_5: {
    Yes: 'DP_9',
    No: 'DP_3B',
  },
  DP_6: {
    Yes: 'ES_5',
    No: 'DP_7',
  },
  DP_7: {
    Yes: 'BP_F',
    No: 'DP_12',
  },
  DP_8: {
    Yes: 'ES_4',
    No: 'DP_9',
  },
  DP_9: {
    Yes: 'BP_G',
    No: 'BP_H',
  },
  DP_10: {
    Yes: 'DP_11',
    No: 'ES_3',
  },
  DP_11: {
    Yes: 'BP_J',
    No: 'DP_9',
  },
  DP_12: {
    Yes: 'DP_13',
    No: 'EF_6',
  },
  DP_13: {
    Yes: 'BP_GRec',
    No: 'BP_HRec',
  },
  DP_14: {
    Yes: 'DP_15',
    No: 'BP_K',
  },
  DP_15: {
    Yes: 'DP_13',
    No: 'EF_7',
  },
  DP_16: {
    Yes: 'DP_12',
    No: 'BP_I',
  },
  DP_17: {
    Yes: 'BP_C',
    No: 'ES_6',
  },
  BP_A: {
    Yes: 'ES_1',
    No: 'BP_B',
  },
  BP_B: {
    Yes: 'DP_17',
    No: 'DP_4',
  },
  BP_C: {
    Yes: 'ES_2',
    No: 'EF_2',
  },
  BP_D1: {
    Yes: 'BP_E1',
    No: 'BP_B',
  },
  BP_D2: {
    Yes: 'BP_E2',
    No: 'EF_4',
  },
  BP_E1: {
    Yes: 'BP_A',
    No: 'BP_B',
  },
  BP_E2: {
    Yes: 'DP_6',
    No: 'EF_5',
  },
  BP_F: {
    Yes: 'DP_8',
    No: 'DP_12',
  },
  BP_G: {
    Yes: 'BP_H',
    No: 'DP_14',
  },
  BP_GRec: {
    Yes: 'BP_HRec',
    No: 'EF_7',
  },
  BP_H: {
    Yes: 'DP_10',
    No: 'DP_16',
  },
  BP_HRec: {
    Yes: 'DP_10',
    No: 'EF_6',
  },
  BP_I: {
    Yes: 'DP_10',
    No: 'EF_10_0',
  },
  BP_J: {
    Yes: 'DP_9',
    No: 'DP_16',
  },
  BP_K: {
    Yes: 'BP_H',
    No: 'EF_8_0',
  },
  ES_1: null,
  ES_2: null,
  ES_3: null,
  ES_4: null,
  ES_5: null,
  ES_6: null,
  EF_2: null,
  EF_3: null,
  EF_4: null,
  EF_5: null,
  EF_6: null,
  EF_7: null,
  EF_8: null,
  EF_8_0: null,
  EF_10_0: null,
};

// export const prefixes = [
//   DP_1
//   DP_2
//   DP_3A
//   DP_3B
//   DP_4
//   DP_5
//   DP_6
//   DP_7
//   DP_8
//   DP_9
//   DP_10
//   DP_11
//   DP_12
//   DP_13
//   DP_14
//   DP_15
//   DP_16
//   DP_17
//   BP_A
//   BP_B
//   BP_C
//   BP_D1
//   BP_D2
//   BP_E1
//   BP_E2
//   BP_F
//   BP_G
//   BP_GRec
//   BP_H
//   BP_HRec
//   BP_I
//   BP_J
//   BP_K
//   ES_1
//   ES_2
//   ES_3
//   ES_4
//   ES_5
//   ES_6
//   EF_2
//   EF_3
//   EF_4
//   EF_5
//   EF_6
//   EF_7
//   EF_8
//   EF_8_0
//   EF_10_0
// ]

export const findConnections = (answeredDPs) => {
  const res = {
    name: 'Initiating Event',
    success: null,
    failure: null,
  };

  if (!answeredDPs.length) return res;

  const start = answeredDPs[0].name;

  //Constant time lookup for each DP in answeredDPs
  const dpRef = answeredDPs.reduce((ref, dp) => {
    ref[dp.name] = dp;
    return ref;
  }, {});

  const determineFullName = (name, parentName) => {
    if (name === 'DP_1') return 'DP_1';

    const parent = extractPrefixAndSuffix(parentName, null);
    const child = extractPrefixAndSuffix(name, parentName);

    function extractPrefixAndSuffix(child, parent) {
      const parts = child.split('_');

      let prefix = `${parts[0]}_${parts[1]}`;
      let suffix;

      if (prefix === 'EF_8' && (!parent || parent.includes('DP_4'))) {
        prefix = 'EF_8';
        suffix = parts.length === 2 ? '' : parts[parts.length - 1];
      } else if (
        prefix === 'EF_8' &&
        parent &&
        parent.includes('BP_K')
      ) {
        prefix = 'EF_8_0';
        suffix = parts.length > 3 ? parts[parts.length - 1] : '';
      } else if (prefix === 'EF_10') {
        prefix = 'EF_10_0';
        suffix = parts.length > 3 ? parts[parts.length - 1] : '';
      } else {
        suffix = parts.length === 2 ? '' : parts[parts.length - 1];
      }

      const result = {
        prefix,
        suffix,
      };

      return result;
    }

    //Begin loop and increment counter
    if (parent.prefix === 'DP_11' && child.prefix === 'BP_J') {
      if (!parent.suffix) {
        const fullName = `${child.prefix}`;
        return fullName;
      } else {
        const fullName = `${child.prefix}_${Number(parent.suffix)}`;
        return fullName;
      }
    } else if (
      parent.prefix === 'DP_11' ||
      parent.prefix === 'BP_J'
    ) {
      if (!parent.suffix) {
        const fullName = `${child.prefix}_1`;
        return fullName;
      } else {
        const fullName = `${child.prefix}_${
          Number(parent.suffix) + 1
        }`;
        return fullName;
      }
    } else {
      if (!parent.suffix) {
        const fullName = `${child.prefix}`;
        return fullName;
      } else {
        const fullName = `${child.prefix}_${Number(parent.suffix)}`;
        return fullName;
      }
    }
  };

  const traverse = (name, parentName) => {
    if (name.includes('ES') || name.includes('EF')) {
      return determineFullName(name, parentName);
    }
    if (name.includes('DP')) {
      const fullName = determineFullName(name, parentName);
      const dp = dpRef[fullName];

      if (!dp) return null;
      const split = name.split('_'); // ['DP', '1']

      const basicName = `${split[0]}_${split[1]}`;

      const nextBasicName = outgoingEdges[basicName][dp.response];

      const childName = determineFullName(nextBasicName, fullName);

      return traverse(childName, name);
    }
    if (name.includes('BP')) {
      const fullName = determineFullName(name, parentName);
      const split = name.split('_'); // ['DP', '1']
      const basicName = `${split[0]}_${split[1]}`;

      const next = outgoingEdges[basicName];

      const curr = {
        name: fullName,
        success: null,
        failure: null,
        parent: parentName,
      };

      const successPath = determineFullName(next.Yes, fullName);
      const failurePath = determineFullName(next.No, fullName);

      //Priority:
      //DP_10 -> Success Path DP -> Failure Path DP -> Success Path BP/ES/EF -> Failure Path BP/ES/EF

      if (failurePath.includes('DP')) {
        curr.failure = traverse(failurePath, fullName);
      }

      if (successPath.includes('DP')) {
        curr.success = traverse(successPath, fullName);
      }

      if (!successPath.includes('DP')) {
        curr.success = traverse(successPath, fullName) || null;
      }

      if (!failurePath.includes('DP')) {
        curr.failure = traverse(failurePath, fullName) || null;
      }

      return curr;
    }
  };

  res.failure = traverse(start, 'Initiating Event');

  return res;
};
