import React from 'react';
import Typography from '@material-ui/core/Typography';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles/index';

import ListView from 'hcla-web-frontend-primitives/app/components/primitives/List/ListView';
//@ts-ignore
import TypedConfirmationDialog from 'hcla-web-frontend-primitives/app/components/primitives/Dialogs/TypedConfirmationDialog';

import PhoenixApiManager from '../../../../../../Api/PhoenixApiManager';
import CFMViewer from './CFMViewer';
import DependantPIFSelectionDialog from './DependantPIFSelectionDialog';
import PIFEntry from '../../PIF/PIFEntry/PIFEntry';
import {HFEType} from "../../../../Types";
import CircularIndeterminate from 'hcla-web-frontend-primitives/app/components/primitives/Progress/CircularIndeterminate';

const styles = () => ({
  removeColor: {
    color: '#ca4830',
  },
});

const objectToArr = (obj: { [key: string]: any}) => {
  return Object.keys(obj).map(key => (key));
};

interface Props {
  match: { [key: string]: any},
  intl: { [key: string]: any},
  classes: { [key: string]: any},
  onResponseUpdated: () => void,
  hfe: HFEType,
  disableEditing: boolean
}

interface State {
  template: {
    pif: { [key: string]: any },
    loading: boolean,
    loaded: boolean,
  },
  selectedPifs: {
    data: any[],
    loading: boolean,
    loaded: boolean,
  },
  deepCopySelectionDialogOpen: boolean,
  openTypedConfirmationDialog: boolean,
  pifName: string,

  pifMap: {
    data: { [key: string]: any},
    loading: boolean,
    loaded: boolean,
  },

  faultTreeId: number
}

class SingleCFMView extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      template: {
        pif: {},
        loading: true,
        loaded: false,
      },
      selectedPifs: {
        data: [],
        loading: true,
        loaded: false,
      },
      deepCopySelectionDialogOpen: false,
      openTypedConfirmationDialog: false,
      pifName: '',

      pifMap: {
        data: {},
        loading: true,
        loaded: false,
      },

      faultTreeId: null
    };
  }

  componentDidMount() {
    const { match: { params: { id, hfeName, cfmName, cfid } } } = this.props;

    PhoenixApiManager.getAnsweredHumanFailureEvent(cfid, hfeName)
      .then(response => {

        this.setState({
          faultTreeId: response.data.fault_tree_id
        });
      })
      .then(() => this.loadPIFData());
  }

  loadPIFData = () => {
    const { match: { params: { id, hfeName, cfmName,cfid } } } = this.props;
    PhoenixApiManager.getHFEsForModelWithId(cfid)
      .then(response => response.status === 200 && response.json())
      .then(data => this.setState({
        pifMap: {
          data: this.adaptItemsToPIFs(data),
          loading: false,
          loaded: true,
        },
      }));

    PhoenixApiManager.getTemplateForModelWithId(cfid)
      .then(response => response.status === 200 && response.json())
      .then(data => this.setState({
        template: {
          pif: data.questionnaire.pif,
          loading: false,
          loaded: true,
        },
      }));

    PhoenixApiManager.getPIFsForModelWithIdAndHFEAndCFM(cfid, hfeName, cfmName)
      .then(response => response.status === 200 && response.json())
      .then(data => this.setState({
        selectedPifs: {
          data: objectToArr(data.pifs),
          loading: false,
          loaded: true,
        },
      }));
  };

  adaptItemsToPIFs = (hfeItems: HFEType[]) => {
    const pifMap: any = {};

    hfeItems.forEach((hfe) => {
      const cfmKeys = Object.keys(hfe.cfms);
      cfmKeys.forEach((cfmKey) => {
        const pifForCfm = hfe.cfms[cfmKey].pifs;
        const pifKeysForCfm = Object.keys(pifForCfm);
        pifKeysForCfm.forEach((pifKeyForCfm) => {
          if (Object.keys(pifForCfm[pifKeyForCfm].questions).length <= 0) {
            return;
          }
          if (!pifMap[pifKeyForCfm]) {
            pifMap[pifKeyForCfm] = [];
          }
          pifMap[pifKeyForCfm] = [...pifMap[pifKeyForCfm], {
            cfm: cfmKey,
            data: pifForCfm[pifKeyForCfm],
            pifScore: 'score' in pifForCfm[pifKeyForCfm] ? pifForCfm[pifKeyForCfm].score : null,
            pifOverride: 'override' in pifForCfm[pifKeyForCfm] ? pifForCfm[pifKeyForCfm].override : false,
            pifExpression: 'expression' in pifForCfm[pifKeyForCfm] ? pifForCfm[pifKeyForCfm].expression : null,
            hfe: hfe.name,
            hfeLabel: hfe.label.name,
            key: `${hfe.name}.${cfmKey}.${pifKeyForCfm}`,
          }];
        });
      });
    });
    return pifMap;
  };

  handleToggle = (e: any, id: any, value?: any) => {
    this.setState({
      [id]: !this.state[id],
      pifName: value,
    });
  };

  handleOnConfirmed = () => {
    const { pifName, selectedPifs } = this.state;
    const { data } = selectedPifs;
    const currentIndex = data.indexOf(pifName);
    const { match: { params: { id, hfeName, cfmName, cfid } }, onResponseUpdated } = this.props;
    PhoenixApiManager.deletePIFForModelWithIdAndHFEAndCFM(cfid, hfeName, cfmName, pifName)
      .then(response => response.status === 204)
      .then((deleted) => {
        if (!deleted) {
          return;
        }
        const newSelectedPifs = data;
        newSelectedPifs.splice(currentIndex, 1);
        this.setState({
          openTypedConfirmationDialog: false,
          selectedPifs: {
            ...selectedPifs,
            data: newSelectedPifs,
          },
        }, () => onResponseUpdated());
      });
  };
 
  handleChange = (event: any, value: any) => {
    const { selectedPifs, pifMap } = this.state;
    const { data } = selectedPifs;
    const currentIndex = data.indexOf(value);
    const remove = currentIndex > -1;
    const { match: { params: { id, hfeName, cfmName } }, onResponseUpdated } = this.props;

    if (remove) {
      this.handleToggle(event, 'openTypedConfirmationDialog', value);
    } else {
      // if PIF map already contains the PIF that we are attempting to add, show the dialog,
      // otherwise, post a new one with a generic empty payload.
      this.postPIFWithPayload({
        name: value,
        score: 0.0,
        questions: {},
      });
      // if (value in pifMap.data) {
      //   this.handleToggle(event, 'deepCopySelectionDialogOpen', value);
      // } else {
      //   this.postPIFWithPayload({
      //     name: value,
      //     score: 0.0,
      //     questions: {},
      //   });
      // }
    }
  };
 
  postPIFWithPayload = (payload: any) => {
    const { selectedPifs } = this.state;
    const { data } = selectedPifs;
    const { match: { params: { id, hfeName, cfmName, cfid } }, onResponseUpdated } = this.props;
    return PhoenixApiManager.postPIFForModelWithIdAndHFEAndCFM(cfid, hfeName, cfmName, JSON.stringify(payload))
      .then(response => response.status === 201 && response.json())
      .then((newPif) => {
        this.setState({
          selectedPifs: {
            ...selectedPifs,
            data: [...data, newPif.name],
          },
        });
      })
      .then(() => onResponseUpdated());
  };

  removePIFConfimationDialog = () => {
    const { classes, intl } = this.props;
    const {
      openTypedConfirmationDialog, pifName,
    } = this.state;
    return (
      <TypedConfirmationDialog
        title={intl.formatMessage({ id: 'analysis.pifRemoveConfirmationHeader' })}
        content={(
          <Typography
            className={classes.removeColor}
            variant="subtitle2"
          >
            {intl.formatMessage({ id: 'analysis.pifRemoveConfirmationDescription' })}
          </Typography>
        )}
        open={openTypedConfirmationDialog}
        onConfirmed={() => this.handleOnConfirmed()}
        onRequestClose={(e: any) => this.handleToggle(e, 'openTypedConfirmationDialog')}
        matchText={pifName}
      />
    );
  };

  deepCopyPIFSelectionDialog = () => {
    const { classes, intl, hfe } = this.props;
    const {
      deepCopySelectionDialogOpen, pifName, pifMap, faultTreeId
    } = this.state;
    console.log("THIS SHOULD BE DEEPCOPYOFPIF", pifMap)
    console.log("HFEEEEEE",hfe);
    const { match: { params: { id, cfmName, cfid,hfeName } } } = this.props;
    console.log("hfeName NAME",hfeName)
    const real_hfe = hfe.find(to_be_found => to_be_found.name == hfeName)
    console.log("HFEEEEEE[cfmName]",real_hfe);
    
    const items = (pifMap.data[pifName]) ? pifMap.data[pifName] : [];

    const onSelected = (d: any) => {
      const data = items.filter((x: any) => x.hfe === d.hfeKey && x.cfm === d.cfmKey)[0];
      const payload = {
        ...data.data,
        name: pifName,
      };
      this.postPIFWithPayload(payload);
      this.handleToggle(null, 'deepCopySelectionDialogOpen');
    };

    return (
      <DependantPIFSelectionDialog
        title={`${pifName} ${intl.formatMessage({ id: 'analysis.pifDependencySelectionHeader' })}`}
        content={(
          <Typography
            className={classes.removeColor}
            variant="subtitle2"
          >
            {intl.formatMessage({ id: 'analysis.pifDependencySelectionDescription' })}
          </Typography>
        )}
        open={deepCopySelectionDialogOpen}
        onRequestClose={(e: any) => this.handleToggle(e, 'deepCopySelectionDialogOpen')}
      >
        <ListView
          header={null}
          items={items.map(
            (item: any) => {
              return <PIFEntry
                key={item.key}
                pifName={pifName}
                pifScore={item.pifScore}
                pifExpression={item.pifExpression}
                pifOverride={item.pifOverride}
                cfmKey={item.cfm}
                cfmLabel={real_hfe.cfms[item.cfm] ? real_hfe.cfms[item.cfm].label.name : item.cfm}
                hfeKey={item.hfe}
                hfeLabel={item.hfeLabel}
                modelId={id}
                cfid={cfid}
                onSelect={onSelected}
                faultTreeId={faultTreeId}
                onResponseUpdated={this.loadPIFData}
              />
            },
          )}
          rootClassOverride={classes.listRoot}
          defaultText={intl.formatMessage({ id: 'analysis.noPIFLabel' })}
        />
      </DependantPIFSelectionDialog>
    );
  };

  render() {
    const { hfeItems, hfe, disableEditing, ...others } = this.props;
    const { template, selectedPifs, faultTreeId } = this.state;
    console.log(this.state.selectedPifs, 909, this.state)
    if (!this.state.selectedPifs.loaded) {
      return <CircularIndeterminate size={80} color="secondary" />;
    }
    return (
      <React.Fragment>
        <CFMViewer
          hfeItems={hfeItems}
          pifs={selectedPifs.data}
          pifsTemplate={template.pif}
          loading={template.loading || selectedPifs.loading}
          onChange={this.handleChange}
          hfe={hfe}
          disableEditing={disableEditing}
          onResponseUpdated={this.loadPIFData}
          faultTreeId={faultTreeId}
          {...others}
        />
        {this.removePIFConfimationDialog()}
        {this.deepCopyPIFSelectionDialog()}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(injectIntl(SingleCFMView));
