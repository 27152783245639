import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';

const styles = theme => ({
  chip: {
    height: '20px',
    overflow: 'hidden',
    margin: `0px ${theme.spacing(1) / 2}px`,
  },
  chipAvatar: {
    overflow: 'hidden',
    borderRadius: '0px',
  },
});

interface Props {
  classes: { [key: string]: any },
  name: string,
  label: string,
}

const SecondaryPIFChip = (props: Props) => {
  const {
    name, label, classes, ...others
  } = props;

  return (
    <Chip
      avatar={name ? <Avatar>{name}</Avatar> : null}
      classes={{
        root: classes.chip,
        avatar: classes.chipAvatar,
      }}
      label={label}
      color="default"
      {...others}
    />
  );
};

SecondaryPIFChip.defaultProps = {
  name: null,
};

export default withStyles(styles)(SecondaryPIFChip);
