import React from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Button from "@material-ui/core/Button";
import Divider from '@material-ui/core/Divider';


const BasicEventAccordion = ({
  basicEvent,
  title,
  focusedGroup,
  addGroup,
  groups,
  labelMap,
}) => {
  console.log(basicEvent);
  return (
    <>
      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>{labelMap[title]}</Typography>
          <Divider />
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ paddingLeft: "2%" }}>
            {Object.keys(basicEvent).map((basic) => (
              <div>
                <Typography>{labelMap[`${title}_${basic}`]}</Typography>
                <Divider />
                <div style={{ paddingLeft: "10%" }}>
                  {Object.keys(basicEvent[basic]).map((cfm) =>
                    basicEvent[basic][cfm] ? (
                      <Button
                        color="primary"
                        disabled={
                          focusedGroup === null || !basicEvent[basic][cfm]
                        }
                        variant="contained"
                        onClick={() => {
                          const newGroups = [...groups];
                          if (newGroups[focusedGroup][title]) {
                            if (newGroups[focusedGroup][title][basic]) {
                              newGroups[focusedGroup][title][basic] = {
                                ...newGroups[focusedGroup][title][basic],
                                [cfm]: basicEvent[basic][cfm],
                              };
                            } else {
                              newGroups[focusedGroup][title] = {
                                ...newGroups[focusedGroup][title],
                                [basic]: basicEvent[basic],
                              };
                            }
                          } else {
                            newGroups[focusedGroup] = {
                              ...newGroups[focusedGroup],
                              [title]: {
                                [basic]: {
                                  [cfm]: basicEvent[basic][cfm],
                                },
                              },
                            };
                          }

                          const payload = {
                            dependency_groups: [
                              {
                                [title]: {
                                  [basic]: {
                                    [cfm]: basicEvent[basic][cfm],
                                  },
                                },
                              },
                            ],
                          };
                          console.log(newGroups, 'I am new groups')
                          addGroup({dependency_groups:newGroups});
                        }}
                      >
                        {labelMap[`${title}_${basic}_${cfm}`]}
                      </Button>
                    ) : (
                      <Button disabled={true}>{labelMap[`${title}_${basic}_${cfm}`]}</Button>
                    )
                  )}
                </div>
              </div>
            ))}
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default BasicEventAccordion;
