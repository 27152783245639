import React from 'react';

import EventTreeInitiatingEventIcon from 'hcla-web-frontend-primitives/app/components/primitives/Icons/EventTreeInitiatingEventIcon';
import EventTreeFunctionalEventIcon from 'hcla-web-frontend-primitives/app/components/primitives/Icons/EventTreeFunctionalEventIcon';
import EventTreeEndStateIcon from 'hcla-web-frontend-primitives/app/components/primitives/Icons/EventTreeEndStateIcon';
import EventTreeTransferEndStateIcon from 'hcla-web-frontend-primitives/app/components/primitives/Icons/EventTreeTransferEndStateIcon';
import ToolboxButton from 'hcl-web-editor/app/components/Model/SingleHCLModelView/SingleTreeView/Tools/ToolboxButton';
import Toolbox from 'hcl-web-editor/app/components/Model/SingleHCLModelView/SingleTreeView/Tools/Toolbox';
import ANDGateVertex from 'hcl-web-editor/app/mxGraph/HCLTreeNode/FaultTreeNode/GateNode/ANDGateNode/ANDGateVertex';
import ORGateVertex from 'hcl-web-editor/app/mxGraph/HCLTreeNode/FaultTreeNode/GateNode/ORGateNode/ORGateVertex';
import BasicEventVertex from 'hcl-web-editor/app/mxGraph/HCLTreeNode/FaultTreeNode/BasicEventNode/BasicEventVertex';
import BasicEventVertexValue
  from 'hcl-web-editor/app/mxGraph/HCLTreeNode/FaultTreeNode/BasicEventNode/BasicEventVertexValue';
import HouseEventVertexValue
  from 'hcl-web-editor/app/mxGraph/HCLTreeNode/FaultTreeNode/HouseEventNode/HouseEventVertexValue';
import HouseEventVertex from 'hcl-web-editor/app/mxGraph/HCLTreeNode/FaultTreeNode/HouseEventNode/HouseEventVertex';
import FaultTreeTransferGateVertex
  from 'hcl-web-editor/app/mxGraph/HCLTreeNode/FaultTreeNode/FaultTreeTransferGateNode/FaultTreeTransferGateVertex';
import NOTGateVertex from 'hcl-web-editor/app/mxGraph/HCLTreeNode/FaultTreeNode/GateNode/NOTGateNode/NOTGateVertex';
import ANDGateVertexValue
  from 'hcl-web-editor/app/mxGraph/HCLTreeNode/FaultTreeNode/GateNode/ANDGateNode/ANDGateVertexValue';
import ORGateVertexValue from 'hcl-web-editor/app/mxGraph/HCLTreeNode/FaultTreeNode/GateNode/ORGateNode/ORGateVertexValue';
import NOTGateVertexValue
  from 'hcl-web-editor/app/mxGraph/HCLTreeNode/FaultTreeNode/GateNode/NOTGateNode/NOTGateVertexValue';
import FaultTreeTransferGateVertexValue
  from 'hcl-web-editor/app/mxGraph/HCLTreeNode/FaultTreeNode/FaultTreeTransferGateNode/FaultTreeTransferGateVertexValue';
import { TreeNames } from 'hcl-web-editor/app/components/Model/SingleHCLModelView/SingleTreeView/Tools/TreeDictionaries';
import HCLTreeMxGraph from 'hcl-web-editor/app/mxGraph/HCLTreeMxGraph/HCLTreeMxGraph';
// @ts-ignore
import InitVertex from 'hcl-web-editor/app/mxGraph/HCLTreeNode/EventTreeNode/InitEvent/InitVertex';
import InitVertexValue from 'hcl-web-editor/app/mxGraph/HCLTreeNode/EventTreeNode/InitEvent/InitVertexValue';
// @ts-ignore
import FunctionalEventVertex from 'hcl-web-editor/app/mxGraph/HCLTreeNode/EventTreeNode/FunctionalEventNode/FunctionalEventVertex';
import FunctionalEventVertexValue from 'hcl-web-editor/app/mxGraph/HCLTreeNode/EventTreeNode/FunctionalEventNode/FunctionalEventVertexValue';
// @ts-ignore
import EndStateVertex from 'hcl-web-editor/app/mxGraph/HCLTreeNode/EventTreeNode/EndStateNode/EndStateVertex';
import EndStateVertexValue from 'hcl-web-editor/app/mxGraph/HCLTreeNode/EventTreeNode/EndStateNode/EndStateVertexValue';
// @ts-ignore
import EventTreeTransferGateVertex from 'hcl-web-editor/app/mxGraph/HCLTreeNode/EventTreeNode/EventTreeTransferGateNode/EventTreeTransferGateVertex';
import EventTreeTransferGateVertexValue from 'hcl-web-editor/app/mxGraph/HCLTreeNode/EventTreeNode/EventTreeTransferGateNode/EventTreeTransferGateVertexValue';

import BayesianNetworkVertex from 'hcl-web-editor/app/mxGraph/HCLTreeNode/BayesianNetworkNode/BayesianNetworkVertex';
import BayesianNetworkVertexValue from 'hcl-web-editor/app/mxGraph/HCLTreeNode/BayesianNetworkNode/BayesianNetworkVertexValue';

import HouseEventIcon from 'hcl-web-editor/app/assets/icons/HouseEventIcon';
import BasicEventIcon from 'hcl-web-editor/app/assets/icons/BasicEventIcon';
import AndGateIcon from 'hcl-web-editor/app/assets/icons/AndGateIcon';
import OrGateIcon from 'hcl-web-editor/app/assets/icons/OrGateIcon';
import NotGateIcon from 'hcl-web-editor/app/assets/icons/NotGateIcon';
import TransferGateIcon from 'hcl-web-editor/app/assets/icons/TransferGateIcon';
import ATLEASTGateVertex
  from 'hcl-web-editor/app/mxGraph/HCLTreeNode/FaultTreeNode/GateNode/ATLEASTGateNode/ATLEASTGateVertex';
import ATLEASTGateVertexValue
  from 'hcl-web-editor/app/mxGraph/HCLTreeNode/FaultTreeNode/GateNode/ATLEASTGateNode/ATLEASTGateVertexValue';
import AtLeastGateIcon from 'hcl-web-editor/app/assets/icons/AtLeastGateIcon';
import EventTreeSuccessEdge from 'hcl-web-editor/app/mxGraph/HCLTreeNode/EventTreeNode/EventTreeSuccessEdge';
import EventTreeFailureEdge from 'hcl-web-editor/app/mxGraph/HCLTreeNode/EventTreeNode/EventTreeFailureEdge';
import HCLTreeVertex from 'hcl-web-editor/app/mxGraph/HCLTreeNode/HCLTreeVertex';
import FaultTreeInstanceTransferGateVertex
  from "hcl-web-editor/app/mxGraph/HCLTreeNode/FaultTreeNode/FaultTreeTransferGateNode/FaultTreeInstanceTransferGateNode/FaultTreeInstanceTransferGateVertex";
import FaultTreeInstanceTransferGateVertexValue
  from "hcl-web-editor/app/mxGraph/HCLTreeNode/FaultTreeNode/FaultTreeTransferGateNode/FaultTreeInstanceTransferGateNode/FaultTreeInstanceTransferGateVertexValue";
import HCLTreeVertexValue from "hcl-web-editor/app/mxGraph/HCLTreeNode/HCLTreeVertexValue/HCLTreeVertexValue";

interface Props {
  graph: HCLTreeMxGraph;
  treeName: TreeNames;
  saving: boolean;
}

class EditorLeftToolbox extends React.Component<Props> {
  private addVertexWithUniqueKey = (Vertex: HCLTreeVertex, VertexValue: HCLTreeVertexValue) => {
    const { graph } = this.props;
    const model = graph.getModel();
    const selectedCells = graph.getSelectionCells() as HCLTreeVertex[];
    try {
      model.beginUpdate();
      const newCell = graph.addVertexWithUniqueKey(new Vertex(null, new VertexValue()));
      selectedCells.forEach((selectedCell) => {
        if (selectedCell instanceof HCLTreeVertex) {
          selectedCell.onRequestConnectChildVertex(newCell);
        }
      });
    } catch (e) {
      console.error(e);
    } finally {
      graph.applyDefaultLayout();
      model.endUpdate();
    }
  };

  private handleANDGateClick = (e: React.MouseEvent<HTMLElement>) => {
    this.addVertexWithUniqueKey(ANDGateVertex, ANDGateVertexValue);
  };

  private handleORGateClick = (e: React.MouseEvent<HTMLElement>) => {
    this.addVertexWithUniqueKey(ORGateVertex, ORGateVertexValue);
  };

  private handleNOTGateClick = (e: React.MouseEvent<HTMLElement>) => {
    this.addVertexWithUniqueKey(NOTGateVertex, NOTGateVertexValue);
  };

  private handleATLEASTGateClick = (e: React.MouseEvent<HTMLElement>) => {
    this.addVertexWithUniqueKey(ATLEASTGateVertex, ATLEASTGateVertexValue);
  };

  private handleBasicEventClick = (e: React.MouseEvent<HTMLElement>) => {
    this.addVertexWithUniqueKey(BasicEventVertex, BasicEventVertexValue);
  };

  private handleHouseEventClick = (e: React.MouseEvent<HTMLElement>) => {
    this.addVertexWithUniqueKey(HouseEventVertex, HouseEventVertexValue);
  };

  private handleFaultTreeTransferGateClick = (e: React.MouseEvent<HTMLElement>) => {
    this.addVertexWithUniqueKey(FaultTreeTransferGateVertex, FaultTreeTransferGateVertexValue);
  };

  private handleFaultTreeInstanceTransferGateClick = (e: React.MouseEvent<HTMLElement>) => {
    this.addVertexWithUniqueKey(FaultTreeInstanceTransferGateVertex, FaultTreeInstanceTransferGateVertexValue);
  };

  private handleInitEventClick = (e: React.MouseEvent<HTMLElement>) => {
    this.addVertexWithUniqueKey(InitVertex, InitVertexValue);
  };

  private handleFunctionalEventClick = (e: React.MouseEvent<HTMLElement>) => {
    this.addVertexWithUniqueKey(FunctionalEventVertex, FunctionalEventVertexValue);
  };

  private handleEndStateClick = (e: React.MouseEvent<HTMLElement>) => {
    this.addVertexWithUniqueKey(EndStateVertex, EndStateVertexValue);
  };

  private handleEventTreeTransferGateClick = (e: React.MouseEvent<HTMLElement>) => {
    this.addVertexWithUniqueKey(EventTreeTransferGateVertex, EventTreeTransferGateVertexValue);
  };

  private handleBayesianNetworkVertexClick = (e: React.MouseEvent<HTMLElement>) => {
    this.addVertexWithUniqueKey(BayesianNetworkVertex, BayesianNetworkVertexValue);
  };

  /**
   * @throws An error if treeName is not in {@link TreeNames}
   */
  render() {
    const { saving, treeName, add, hide} = this.props;
    console.log(this.props);

    const items = [];

    switch (treeName) {
      case TreeNames.FAULT_TREE:
        items.push(
          <ToolboxButton
            key="AND"
            label="AND"
            icon={<AndGateIcon />}
            onClick={this.handleANDGateClick}
            disabled={saving}
          />,
        );
        items.push(
          <ToolboxButton
            key="OR"
            label="OR"
            icon={<OrGateIcon />}
            onClick={this.handleORGateClick}
            disabled={saving}
          />,
        );
        items.push(
          <ToolboxButton
            key="NOT"
            label="NOT"
            icon={<NotGateIcon />}
            onClick={this.handleNOTGateClick}
            disabled={saving}
          />,
        );
        items.push(
          <ToolboxButton
            key="AT LEAST"
            label="AT LEAST"
            icon={<AtLeastGateIcon />}
            onClick={this.handleATLEASTGateClick}
            disabled={saving}
          />,
        );
        items.push(
          <ToolboxButton
            key="BASIC EVENT"
            label="BASIC EVENT"
            icon={<BasicEventIcon />}
            onClick={this.handleBasicEventClick}
            disabled={saving}
          />,
        );
        items.push(
          <ToolboxButton
            key="FLAG/HOUSE EVENT"
            label="FLAG/HOUSE EVENT"
            icon={<HouseEventIcon />}
            onClick={this.handleHouseEventClick}
            disabled={saving}
          />,
        );
        items.push(
          <ToolboxButton
            key="TRANSFER GATE"
            label="TRANSFER GATE"
            icon={<TransferGateIcon />}
            onClick={this.handleFaultTreeTransferGateClick}
            disabled={saving}
          />,
        );
        items.push(
          <ToolboxButton
            key="INSTANCE TRANSFER GATE"
            label="INSTANCE TRANSFER GATE"
            icon={<TransferGateIcon />}
            onClick={this.handleFaultTreeInstanceTransferGateClick}
            disabled={saving}
          />,
        );
        break;
      case TreeNames.EVENT_TREE:
        /*
        items.push(
          <ToolboxButton
            key="IE"
            label="INITIATING EVENT"
            icon={<EventTreeInitiatingEventIcon />}
            onClick={this.handleInitEventClick}
            disabled={saving}
          />,
        );*/
        /*
        add && add.criticalFunction ? null : items.push(
          <ToolboxButton
            key="PE"
            label="PIVOTAL EVENT"
            icon={<EventTreeFunctionalEventIcon />}
            onClick={this.handleFunctionalEventClick}
            disabled={saving}
          />,
        );*/
        /*
        add && add.criticalFunction && items.push(
          <ToolboxButton
            key="CFPP"
            label="Critical Function Point Probability"
            icon={<EventTreeFunctionalEventIcon />}
            onClick={this.handleFunctionalEventClick}
            disabled={saving}
          />,
        );*/
        /*
        add && add.criticalFunction && items.push(
          <ToolboxButton
            key="CFTA"
            label="Critical Function To Analyze"
            icon={<EventTreeFunctionalEventIcon />}
            onClick={this.handleFunctionalEventClick}
            disabled={saving}
          />,
        );*/
        items.push(
          <ToolboxButton
            key="ES"
            label="END STATE"
            icon={<EventTreeEndStateIcon />}
            onClick={this.handleEndStateClick}
            disabled={saving}
          />,
        );
        /*
        hide && !hide.transferGate || !hide && items.push(
          <ToolboxButton
            key="TES"
            label="TRANSFER END STATE"
            icon={<EventTreeTransferEndStateIcon />}
            onClick={this.handleEventTreeTransferGateClick}
            disabled={saving}
          />,
        );*/
        break;
      case TreeNames.BAYESIAN_NETWORK:
        items.push(
          <ToolboxButton
            key="V"
            label="VERTEX"
            icon={<EventTreeInitiatingEventIcon />}
            onClick={this.handleBayesianNetworkVertexClick}
            disabled={saving}
          />,
        );
        break;
      default:
        throw new Error(`Fatal Error: Tree Name ${treeName} is not supported`);
    }

    return <Toolbox items={items} />;
  }
}

export default EditorLeftToolbox;
