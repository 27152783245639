// TODO: Clicking on a previous node will traverse back to that node
import React, {useEffect, useState} from "react";
import {BreadcrumbsItem} from "react-breadcrumbs-dynamic";
import {RouteComponentProps} from "react-router";
import {useIntl} from "react-intl";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Theme} from "@material-ui/core";
import HumanFailureEventTraveler from "./HumanFailureEventTraveler";
import HumanFailureEventControllerView from "./Components/HumanFailureEventControllerView";
import FaultTreeVertex from "hcl-web-editor/app/mxGraph/HCLTreeNode/FaultTreeNode/FaultTreeVertex";
import {mxCell} from "mxgraph-js";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import PhoenixApiManager from "../../../../../../Api/PhoenixApiManager";
import { PhoenixFaultTreeMxGraph, PhoenixTreeMxGraphView } from "../../../PhoenixTreeMxGraphView";
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from "@material-ui/core/Typography";
import HFEFeasibilityDialog from "./Dialogs/HFEFeasibilityDialog";
import AnalysisReferencesHeader from "../../../../../primitives/AnalysisReferencesHeader";
import NotePadButton from "../../DP/NotePadButton";


const useStyles = makeStyles((theme: Theme) => ({
  cardFrame: {
    maxHeight: 'calc(100vh - 114px)',
    minHeight: 'calc(100vh - 114px)',
  },

  cardFrameContainer: {
    maxHeight: 'calc(100vh - 114px)',
    minHeight: 'calc(100vh - 114px)',
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      flexGrow: 1
    }
  },

  subheader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },

  controllerViewRoot: {
    height: "100%",
    position: "relative",
    display: 'flex',
    flexDirection: 'column'
  },

  cardContent: {
    overflowY: "auto",
    flexGrow: 1
  },

  tableRoot: {
    tableLayout: 'fixed'
  },

  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  formControl: {
    marginTop: theme.spacing(1) * 2,
    minWidth: 120,
  },
  closeIcon: {
    position: 'absolute',
    top: 0,
    right: 0
  },
  errorMsg: {
    display: 'flex',
    backgroundColor: `${theme.palette.error.main}`,
    color: `${theme.palette.common.white}`,
    padding: '20px'
  },
  errorIcon: {
    verticalAlign: 'bottom',
    marginRight: '5px'
  }

}));

interface HumanFailureEventEditorViewProps extends RouteComponentProps<{ id: string, hfeName: string }> {
  hfe: any,
  disableEditing: boolean,
  onResponseUpdated: () => void
}

const HumanFailureEventEditorView = (props: HumanFailureEventEditorViewProps) => {

  const { disableEditing, history, match: { params }, onResponseUpdated } = props;
  const { cfid, id, hfeName } = params;


  const intl = useIntl();
  const classes = useStyles();
  const [basicEventsFeasibility, setBasicEventOfFeasibility] = useState(null)
  const [loading, setLoading] = useState(false);

  let timeNode;


  const save = (updatedNodesFeasability) => {
    setLoading(true);
    const payload = {
      name: hfeName,
      nodes: updatedNodesFeasability,
    };

    if (updatedNodesFeasability) {
      // console.log("PAYLOAD HERE",payload)
       PhoenixApiManager.postHFEForModelWithId(cfid, JSON.stringify(payload))
        .then(response => response.status === 201 && response.json())
        .then(() => {   
          setLoading(false)
          setDialog(null)
          history.push({
          pathname: `/model/${id}/main-analysis/hfe/`
          // hash: hfeName
        })});
    }
  };

  const onSubmitClose = (arrayOfbasicEvents: any) => {
    // console.log(arrayOfbasicEvents, 'arrayOfbasicEvents')

    if(arrayOfbasicEvents == null ){
      console.log('2 hour')
      setDialog(null)
    }

    let feaseabilityNodes = {
      basic_events: {
        
    },
      house_events: {iGzsjK5OG: {constant: true}},
      excluded_gates: [],
      cfms: [],
    }

    let feaseabilityNodesNoFlagEvent = {
      basic_events: {
        
    },
      house_events: {},
      excluded_gates: [],
      cfms: [],
    }



    const newNodes = [
      {
     "s8z4GypYy": {
         "failure_probability": 1
     }},

      {
       "0jT7t4ABy": {
         "failure_probability": 1
     }},

     {
       "jmTCSfb1_": {
         "failure_probability": 1
     }},
     {
      "wPBAi37o3": {
        "failure_probability": 0.5
    }
  }  
   ]

   const updateLastNode = (value) => (
    {
      "wPBAi37o3": {
   "failure_probability": value}
      }
   )


   if (arrayOfbasicEvents[3]){
    arrayOfbasicEvents.forEach((element, index) => {
      if (element && index == 3 ) {
        // console.log(element, '888', arrayOfbasicEvents)
        // console.log('should be true', arrayOfbasicEvents[4])
        feaseabilityNodesNoFlagEvent.basic_events = {...feaseabilityNodesNoFlagEvent.basic_events, ...updateLastNode(arrayOfbasicEvents[4])} 
        console.log(feaseabilityNodesNoFlagEvent, 'onSubmitClose no flag')
        setBasicEventOfFeasibility(arrayOfbasicEvents)
        timeNode = feaseabilityNodesNoFlagEvent
        setLoading(false)
        setDialog(null)
        // save(feaseabilityNodesNoFlagEvent) 
      } 
    })
   } else {
    arrayOfbasicEvents.forEach((element, index) => {
      if (element && index !=3 ) {
        // console.log(element, '777', arrayOfbasicEvents)
        feaseabilityNodes.basic_events = {...feaseabilityNodes.basic_events, ...newNodes[index]} 
        // console.log(feaseabilityNodes, 'onSubmitClose with flag')
        // setBasicEventOfFeasibility(arrayOfbasicEvents)
        save(feaseabilityNodes) 
      } 
    })
  }



    // console.log(feaseabilityNodes, 'onSubmitClose')
    // setBasicEventOfFeasibility(arrayOfbasicEvents)
    // save(feaseabilityNodes) 

  }
 
  const [graph, setGraph] = useState(null);
  const [traveler, setTraveler] = useState(null);
  const [currentSelection, setCurrentSelection] = useState(null);
  const [undoCell, setUndoCell] = useState(null);
  const [hfe, setHfe] = useState(null);
  const [dialogReturn, setDialogReturn] = useState(null);
  const [dialog, setDialog] = useState(

null
  );
  const [template, setTemplate] = useState(null)

  
  useEffect(() => {

    PhoenixApiManager.openMainModel(id).then(res=>{
      setTemplate(res.data.template)
      res.data.template == 1 ? setDialog(<HFEFeasibilityDialog
        handleClose={() => setDialog(null)}
        onSubmitClose={onSubmitClose}
        isOverride={true}
        expression={undefined}
        setDialogReturn={setDialogReturn}
        loading={loading}
      />) : null
    })
  }, [])
  // console.log("WHAT'S DIALOG RETURN", dialogReturn)

  useEffect(() => {
    console.log(template, '000')
    if (disableEditing) history.push(`/model/${id}/main-analysis/hfe/`);
    if( false){
      console.log(basicEventsFeasibility, 'newarray')

// traveler.setBasicEvent(feaseabilityNodes.getKey(), { failure_probability: prob })

PhoenixApiManager.getHumanFailureEvent(parseInt(cfid), hfeName)
.then(response => {
  PhoenixApiManager.getAnsweredHumanFailureEvent(parseInt(cfid), hfeName)
    .then(response => {
      PhoenixApiManager.getPhoenixTemplateFaultTree(response.data.template.template_fault_tree_id)
        .then(response => {
          const graph = new PhoenixFaultTreeMxGraph(response.data, (cell: mxCell) => {
            if (cell instanceof FaultTreeVertex && traveler.isVisited(cell.getKey())) {
              setUndoCell(cell);
            }
          }, () => {
            // instantiate traveler after graph cells are populated
            const traveler = new HumanFailureEventTraveler(feaseabilityNodes, graph, hfeName, cfid);
            setTraveler(traveler);
            setCurrentSelection(traveler.getCurrentSelection());
          });
          setGraph(graph);
        })
        .catch(err => console.error(err));
    })
    .catch(err => console.error(err));
})
.catch(err => console.error(err));



    }
if(dialog == null) {
    
    PhoenixApiManager.getHumanFailureEvent(parseInt(cfid), hfeName)
      .then(response => {
        console.log(response, 'hfe', template)
        const savedNodes = response.data.nodes;
        PhoenixApiManager.getAnsweredHumanFailureEvent(parseInt(cfid), hfeName)
        .then(response => {
          console.log('answeredhfe', response.data.template.template_fault_tree_id, response)
          // here we will use fault tree Model 2764 so user can see this model when traversing through HFE (response.data.template.template_fault_tree_id) is not used anymore
          PhoenixApiManager.getPhoenixTemplateFaultTree(template == 1 ? 2764 : response.data.template.template_fault_tree_id)
            .then(response => {
              console.log(response, "donut")
                const graph = new PhoenixFaultTreeMxGraph(response.data, (cell: mxCell) => {
                  if (cell instanceof FaultTreeVertex && traveler.isVisited(cell.getKey())) {
                    setUndoCell(cell);
                  }
                }, () => {
                  // instantiate traveler after graph cells are populated
                  const freshNodes = {
                    basic_events: {},
                    house_events: {},
                    excluded_gates: [],
                    cfms: [],
                  }
                  let traveler;
                  if(basicEventsFeasibility){
                    let timeValue; 
                    basicEventsFeasibility.length == 5 ? timeValue = basicEventsFeasibility[4] : null
                    traveler = new HumanFailureEventTraveler(freshNodes, graph, hfeName, cfid, timeValue);
                  } else {
                    traveler = new HumanFailureEventTraveler(freshNodes, graph, hfeName, cfid, null);

                  }
                  setTraveler(traveler);
                  setCurrentSelection(traveler.getCurrentSelection());
                });
                setGraph(graph);
              })
              .catch(err => console.error(err));
          })
          .catch(err => console.error(err));
      })
      .catch(err => console.error(err));
    }

  }, [timeNode, dialog]);

  useEffect(() => {
    PhoenixApiManager.getHFEsForModelWithId(cfid)
    .then(response => response.status === 200 && response.json())
    .then(data =>{
      data.forEach(currentHfe=> {
          if(currentHfe.name === hfeName){
            setHfe(currentHfe)
          }
      })
    })

  }, []);


  const target = (() => {
    if (Array.isArray(currentSelection)) return currentSelection.map(selection => selection.getKey());
    else if (currentSelection) return currentSelection.getKey();
    else return null;
  })();

  return (
    <React.Fragment>
      <BreadcrumbsItem to={`/model/${id}/main-analysis/${cfid}/hfe/${hfeName}/edit/`}>
        {intl.formatMessage({ id: 'edit' })}
      </BreadcrumbsItem>
      {dialog}
      <Grid container spacing={1}>
        <Grid item sm={7} className={classes.cardFrame}>
          <PhoenixTreeMxGraphView
            graph={graph}
            target={target}
          />
        </Grid>

        {/* 
            Here we will pass in the fease bool to determine what nodes we will send. 
          */}
        <Grid item sm={5} className={classes.cardFrameContainer}>
        <NotePadButton
            cfId={cfid}
            title={'Notepad'}
            tooltipTitle={'Open Notepad'}
            />
          {(hfe&&traveler) ? (<HumanFailureEventControllerView
              label={hfe.label.name}
              id={parseInt(cfid)}
              modelId={parseInt(id)}
              hfe={hfe}
              history={history}
              traveler={traveler}
              currentSelection={currentSelection}
              setCurrentSelection={setCurrentSelection}
              onResponseUpdated={onResponseUpdated}
            />):(
            <>
            <Typography>
              <CircularProgress/>
              Loading...
              </Typography>
              </>)
            
          
          }
        </Grid>
      </Grid>
      {
        undoCell &&
        <HumanFailureEventEditorViewDialog
          title={undoCell.getValue().getLabel().getName()}
          open={Boolean(undoCell)}
          onClose={() => setUndoCell(null)}
          undoAction={
            () => {
              traveler.undoUntilKey(undoCell.getKey());
              // currentSelection is ANDGate or ORGate
              const currentSelection = traveler.getCurrentSelection();
              setCurrentSelection(currentSelection);
            }
          }
        />
      }
    </React.Fragment>
  );

};

export default HumanFailureEventEditorView;

type HumanFailureEventEditorViewDialogProps = {
  title: string,
  open: boolean,
  onClose: () => void;
  undoAction: () => void;
}

const HumanFailureEventEditorViewDialog = (props: HumanFailureEventEditorViewDialogProps) => {

  const { open, onClose, undoAction, title } = props;
  const intl = useIntl();

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle>
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {intl.formatMessage({ id: 'hfe.backDescription' })}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {intl.formatMessage({ id: 'no' })}
        </Button>
        <Button
          onClick={() => {
            undoAction();
            onClose();
          }}
          color="primary"
          autoFocus
        >
          {intl.formatMessage({ id: 'yes' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
