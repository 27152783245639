import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  root2: theme.buttonTypes != null ? {
    color: theme.buttonTypes.transfer.backgroundColor,
    '&:hover': {
      color: theme.buttonTypes.transfer['&:hover'].backgroundColor,
    },
  } : {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.getContrastText(theme.palette.secondary.main),
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
  },

  disabled: {
    background: theme.palette.grey[200],
    borderColor: theme.palette.grey[400],
  },

  green: {
    background: 'rgba(90, 193, 164, 0.5)',
    borderColor: theme.buttonTypes.create.backgroundColor,
  },

  red: {
    background: 'rgba(202, 72, 48, 0.5)',
    borderColor: theme.buttonTypes.remove.backgroundColor,
  },

  base: {
    background: 'rgba(193, 124, 21, 0.5)',
    borderColor: theme.palette.secondary.main,
  },

  root: {
    transition: theme.transitions.create(['background', 'border-color']),
    transform: 'rotate(45deg)',
    borderStyle: 'solid',
    borderRadius: '4px',
    borderWidth: '2px',
    height: '37px',
    width: '37px',
  },

  content: {
    transform: 'rotate(-45deg)',
    verticalAlign: 'middle',
    display: 'table-cell',
    color: theme.palette.grey[theme.palette.type === 'light' ? 900 : 0],
    width: '36px',
    height: '36px',
    fontWeight: 'bold',
  },
});


const DPIcon = (props) => {
  const {
    disabled, classes, label, completed, green, ...others
  } = props;

  const classToApply = classNames(classes.root, {
    [classes.base]: !completed && !disabled,
    [classes.red]: completed && !green,
    [classes.green]: completed,
    [classes.disabled]: disabled,
  });

  return (
    <div className={classToApply}>
      <Typography variant="caption" align="center" classes={{ root: classes.content }} {...others}>
        {label}
      </Typography>
    </div>
  );
};

DPIcon.defaultProps = {
  completed: false,
  disabled: false,
  green: true,
  label: '',
};

DPIcon.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  completed: PropTypes.bool,
  green: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
};


export default withStyles(styles)(DPIcon);
