import React, {useEffect, useState} from 'react';
import { injectIntl } from 'react-intl';
import createStyles from "@material-ui/core/styles/createStyles";
import { withStyles, Theme } from '@material-ui/core/styles';
import PhoenixApiManager from '../../../../../Api/PhoenixApiManager';
import {PhoenixEventTreeMxGraph, PhoenixTreeMxGraphView} from "../../PhoenixTreeMxGraphView";

type BranchPointDescriptionViewProps = {
  graph: PhoenixEventTreeMxGraph
  classes: any,
  intl: any,
  branchPointMappings: any,
  focusedBranchPoint: any,
  cfid: Number
}

const BranchPointDescriptionView = (props: BranchPointDescriptionViewProps) => {

  const { branchPointMappings, focusedBranchPoint, graph, cfid } = props;
  const lastBP = branchPointMappings[branchPointMappings.length - 1];

  return (
<>
      <PhoenixTreeMxGraphView
        graph={graph}
        target={focusedBranchPoint ? focusedBranchPoint.name : lastBP.name}
      />

</>
  );

};

export default BranchPointDescriptionView;
