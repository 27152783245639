import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { injectIntl } from 'react-intl';
import { Switch, Route } from 'react-router-dom';

//@ts-ignore
import ViewNoMatch from 'hcla-web-frontend-primitives/app/components/primitives/Error/ViewNoMatch';

import PhoenixApiManager from '../../../../../Api/PhoenixApiManager';

import PIFListView from './PIFListView';
import {HFEType, PIFDetailsProgress} from '../../../Types';

const updatableDataInitStates: Partial<State> = {
  items: [],
  isLoading: true,
};

interface Props {
  depth: number,
  match: { [key: string]: any },
  location: { [key: string]: any },
  intl: { [key: string]: any }
  detailsProgress: PIFDetailsProgress,
}

interface State {
  items: any[],
  isLoading: boolean,
  sort: (i: any) => any,
  hfeFTIds: { [key: string]: any },
  cfmFTIds: { [key: string]: any }
}

class PIFs extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      ...updatableDataInitStates,
      sort: i => i,
      hfeFTIds: {},
      cfmFTIds: {}
    };
  }

  componentDidMount() {
    this.loadData();
  }

  adaptItemsToPIFs = (hfeItems: HFEType[], hfeFTIds: { [key: string]: any }) => {
    const { cfmFTIds } = this.state;
    const pifEntries: any[] = [];

    hfeItems.forEach((hfe) => {
      const cfmKeys = Object.keys(hfe.cfms);
      cfmKeys.forEach((cfmKey) => {
        const pifForCfm = hfe.cfms[cfmKey].pifs;
        const pifKeysForCfm = Object.keys(pifForCfm);
        const output = pifKeysForCfm.map((pifKeyForCfm) => {
          return {
            pif: pifKeyForCfm,
            pifScore: ('score' in pifForCfm[pifKeyForCfm]) ? pifForCfm[pifKeyForCfm].score : null,
            pifOverride: ('override' in pifForCfm[pifKeyForCfm] ? pifForCfm[pifKeyForCfm].override : false),
            pifExpression: pifForCfm[pifKeyForCfm].expression,
            cfm: cfmKey,
            cfmLabel: hfe.cfms[cfmKey].label.name,
            hfe: hfe.name,
            hfeLabel: hfe.label.name,
            key: `${hfe.name}.${cfmKey}.${pifKeyForCfm}`,
            faultTreeId: hfeFTIds[hfe.name],
            cfmTreeId: cfmFTIds[cfmKey]
          };
        });
        pifEntries.push(...output);
      });
    });
    return pifEntries;
  };

  loadData = () => {
    const { match: { params: { id,cfid } } } = this.props;
    PhoenixApiManager.getHFEsForModelWithId(cfid)
      .then(response => response.json())
      .then(data => this.setState({ items: data, isLoading: false }))
      .then(() => this.setHFEFTIds(this.state.items))
      .then(() => this.setCFMFTIds(this.state.items));
  };

  setHFEFTIds = (items: HFEType[]) => {
    const { match: { params: { id,cfid } } } = this.props;
    const { hfeFTIds } = this.state;
    items.map(item => {
      if (Object.keys(hfeFTIds).length === 0 || hfeFTIds[item.name] === undefined) {
        PhoenixApiManager.getAnsweredHumanFailureEvent(cfid, item.name)
          .then(response => {
            let hfe: { [key: string]: any } = {};
            hfe[item.name] = response.data.fault_tree_id;
            this.setState(prevState => ({
              hfeFTIds: {
                ...prevState.hfeFTIds,
                ...hfe
              }
            }));
          });
      }
    })
  };

  setCFMFTIds = (items: HFEType[]) => {
    let treeIds = {};
    items.map(item => {
      Object.keys(item.cfms).map((cfmKey: string) => {
        treeIds[cfmKey] = item.cfms[cfmKey].fault_tree_id
      })
    });
    this.setState({
      cfmFTIds: treeIds
    })
  };

  handleSortChange = (sort) => {
    this.setState({ sort });
    // todo: fetch data if pagination is enabled
  };

  render() {
    const { items, isLoading, hfeFTIds } = this.state;
    const {
      depth, match: { params: { id,cfid } }, intl, detailsProgress,
    } = this.props;
    return (
      <React.Fragment>
        <BreadcrumbsItem to={`/model/${id}/main-analysis/${cfid}/hfe/cfm`} depth={depth - 1}>{intl.formatMessage({ id: 'analysis.cfm' })}</BreadcrumbsItem>
        <BreadcrumbsItem to={`/model/${id}/main-analysis/${cfid}/hfe/cfm/pif`} depth={depth}>{intl.formatMessage({ id: 'analysis.pif' })}</BreadcrumbsItem>
        <Switch>
          <Route
            exact
            path="/model/:id/main-analysis/:cfid/hfe/cfm/pif"
            render={prop => (
              <PIFListView
                items={this.adaptItemsToPIFs(items, hfeFTIds)}
                isLoading={isLoading}
                title={null}
                filterInput={null}
                handleFilterChange={null}
                handleFilterSubmit={null}
                handleSortChange={this.handleSortChange}
                detailsProgress={detailsProgress}
                onResponseUpdated={this.loadData}
                {...prop}
              />
            )}
          />
          <Route
            render={prop => (
              <ViewNoMatch
                {...prop}
                message={intl.formatMessage({ id: '404.pageNotFound' })}
              />
            )}
          />
        </Switch>
      </React.Fragment>
    );
  }
}

export default injectIntl(PIFs);
