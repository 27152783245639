import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ClearRounded from '@material-ui/icons/ClearRounded';

const styles = () => ({
  icon: {
    fontSize: '36px',
    color: 'rgb(214, 214, 214)',
  },
});

const BinaryCompleteIcon = (props) => {
  const { classes, ...others } = props;
  return (
    <ClearRounded
      classes={{
        root: classes.icon,
      }}
      {...others}
    />
  );
};

BinaryCompleteIcon.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(BinaryCompleteIcon);
