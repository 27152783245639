import React, {useEffect, useState} from 'react';
import { Typography, withStyles, Theme } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';

import { useIntl } from 'react-intl';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import InputRules from "hcl-web-editor/app/components/Model/SingleHCLModelView/SingleTreeView/Inputs/InputRules";
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import ExpressionInput
  from 'hcl-web-editor/app/components/Model/SingleHCLModelView/SingleTreeView/Inputs/ExpressionInput';
import { Expression, ExpressionJSON } from 'hcl-web-editor/app/mxGraph/HCLTreeNode/HCLTreeVertexValue/Expression';
import AnalysisReferencesHeader from "../AnalysisReferencesHeader";
import PhoenixApiManager from "../../../Api/PhoenixApiManager";
import ProbabilityInput
  from "hcl-web-editor/app/components/Model/SingleHCLModelView/SingleTreeView/Inputs/ProbabilityInput";

const styles = (theme: Theme) => ({
  root: {
    color: theme.buttonTypes.create.backgroundColor,
    border: `1px solid ${theme.buttonTypes.create.backgroundColor}`,
    maxWidth: '100%',
    marginRight: theme.spacing(2)
  },
  rootScoreOverride: {
    color: theme.palette.secondary,
    border: `1px solid ${theme.palette.secondary}`,
    maxWidth: '100%',
    marginRight: theme.spacing(2)
  },
  chipAvatar: {
    overflow: 'hidden',
    borderRadius: '24px 0px 0px 24px',
    width: '2.5rem !important',
    color: 'white !important',
    background: theme.buttonTypes.create.backgroundColor,
    border: `1px solid ${theme.buttonTypes.create.backgroundColor}`,
  },
  chipAvatarScoreOverride: {
    overflow: 'hidden',
    borderRadius: '24px 0px 0px 24px',
    width: '2.5rem !important',
    color: 'white !important',
    background: theme.palette.secondary,
    border: `1px solid ${theme.palette.secondary}`,
  },
  score: {
    textAlign: 'right'
  }
});

interface PIFScoreDialogProps {
  header: any,
  handleClose: () => void,
  score: any,
  save: (payload: string) => void,
  expression: ExpressionJSON,
  intl: { [key: string]: any },
  isOverride: boolean
}

const PIFScoreDialog = (props: PIFScoreDialogProps) => {
  const { header, handleClose, score, save, expression, isOverride, updateEntryScore } = props;

  const intl = useIntl();

  const [value, setValue] = useState(score);
  const [expressionError, setExpressionError] = useState(false);
  const [expr, setExpr] = useState(new Expression(expression).toExtendedJSON());
  const [override, setOverride] = useState(isOverride ? isOverride : false);
  const [hasError, setHasError] = useState(!InputRules.isProbabilityValid(value));
  const [justificationText, setJustificationText] = useState("override")

  let score_justification;

  useEffect(() => {
    setHasError(!InputRules.isProbabilityValid(value));
  },[value]);

  const payload = JSON.stringify({
    score: value,
    override,
    score_justification: justificationText,
    
    expression: new Expression(expression).toJSON(),
  });

  const handleChange = (e: any, name: string, multiple: boolean) => {
    e.persist();
    if (multiple) {
      setExpr({ ...expr, ...e.target.value });
    } else {
      setExpr({ ...expr, [e.target.name]: e.target.value });
    }
  };

  return (
    <Dialog open={true} maxWidth="lg" fullWidth onClose={handleClose} aria-labelledby="form-dialog-title">
      {header}
      <Grid container justify="space-between" alignItems="center">
        <Grid item>
          <DialogTitle id="form-dialog-title">
            {`${intl.formatMessage({ id: 'override' })}`}
          </DialogTitle>
        </Grid>
        <Grid item>
          <Switch checked={override} onChange={() => setOverride(!override)} />
        </Grid>
      </Grid>
      <DialogContent>
        <ProbabilityInput
          autoFocus={true}
          disabled={override}
          fullWidth
          margin="dense"
          label={intl.formatMessage({ id: 'analysis.pifScore' })}
          value={value}
          onChange={e => setValue(e.target.value)}
          InputProps={{ inputProps: { min: 0, max: 1 } }}
        />
        {/* {expr &&
          <ExpressionInput
            expression={new Expression(expr)}
            onChange={(e, name, multiple) => handleChange(e, name, multiple)}
            onError={(e) => setExpressionError(e)}
            name="expression"
            disabled={!override}
          />
        } */}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {`${intl.formatMessage({ id: 'cancel' })}`}
        </Button>
        <Button
          variant="contained"
          onClick={() => save(payload)}
          color="primary"
          disabled={hasError || expressionError || !override}
        >
          {`${intl.formatMessage({ id: 'save' })}`}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

interface PIFScoreChipProps {
  classes: { [key: string]: any },
  score: any,
  modelId: number,
  cfmKey: string,
  cfmName: string,
  hfeKey: string,
  hfeName: string,
  pifName: string,
  override: boolean,
  expression: ExpressionJSON,
  faultTreeId: number,
  cfmTreeId: number,
  onResponseUpdated: () => void,
  disabled?: boolean,
  cfid: number,
  updateScore: () => void, 
  updateEntryScore: () => void
}

const PIFScoreChip = (props: PIFScoreChipProps) => {
  const {
    updateEntryScore, modelId, match, cfmKey, params, cfid, cfmName, hfeKey, hfeName, pifName, score, override, expression, faultTreeId, cfmTreeId, classes, onResponseUpdated, disabled, ...others
  } = props;

  console.log(match, cfid, params, props, '000')

  const intl = useIntl();

  if (score === null) {
    return null;
  }

  const [dialog, setDialog] = useState(null);

  const value = !override ? Number(score).toExponential(3) : expression ? new Expression (expression).toString() : Number(score).toExponential(3);
  const save = (payload: any) => {
    PhoenixApiManager.patchPIFForModelWithIdAndHFEAndCFM(cfid, hfeKey, cfmKey, pifName, payload)
      .then(res => res.json())
      .then(response=> {
        props.updateScore(response.score, cfid, cfmKey, pifName)
        updateEntryScore(response.score)
        setDialog(null)
      })
  };

  return (
    <React.Fragment>
      <Chip
        avatar={<Avatar>{`${intl.formatMessage({ id: 'analysis.pifScore' })}`}</Avatar>}
        classes={{
          root: override ? classes.rootScoreOverride : classes.root,
          avatar: override ? classes.chipAvatarScoreOverride : classes.chipAvatar,
        }}
        label={<Typography color="inherit" noWrap variant="body1" className={classes.score}>{value}</Typography>}
        color={`${override ? "secondary" : "default"}`}
        variant="outlined"
        disabled={disabled}
        onClick={() => setDialog(
          <PIFScoreDialog
            header={
              <AnalysisReferencesHeader
                crt={{ id: modelId }}
                bp={hfeName}
                hfe={{ id: faultTreeId }}
                cfm={{
                  treeId: cfmTreeId,
                  label: cfmName
                }}
                pif={pifName}
                inPaperContainer={false}
              />
            }
            handleClose={() => setDialog(null)}
            save={save}
            score={score}
            isOverride={override}
            expression={expression}
          />
        )}
        {...others}
      />
      {dialog}
    </React.Fragment>
  );
};

PIFScoreChip.defaultProps = {
  score: null,
};
export default withStyles(styles)(PIFScoreChip);
