import React from 'react';
import Typography from '@material-ui/core/Typography';
import createStyles from "@material-ui/core/styles/createStyles";
import { withStyles, Theme } from '@material-ui/core/styles';
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import CircularProgress from '@material-ui/core/CircularProgress';

import BPHorizontalStepContent from './BPHorizontalStepContent';
import Card from "@material-ui/core/Card";
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import Button from '@material-ui/core/Button';
import {injectIntl} from "react-intl";
import InputRules from "hcl-web-editor/app/components/Model/SingleHCLModelView/SingleTreeView/Inputs/InputRules";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import EditIcon from '@material-ui/icons/Edit';
import BPEditConfirmDialog from './BPEditConfirmDialog'

const styles = (theme: Theme) => createStyles({
  root: {
    height: '100%',
    overflowY: 'auto',
    padding: theme.spacing(2)
  },
  responseSummary: {
    paddingLeft: '36px',
    marginLeft: '12px',
    color: theme.palette.grey[theme.palette.type === 'light' ? 700 : 500],
  },
  entryTextField: {
    padding: `${theme.spacing(1)}px 0px`,
  }
});

interface AllBPs {
  name: string,
  constant: boolean,
  label: {
    name: string,
    description: string,
  },
  successQuestionPrompt: string,
  failureQuestionPrompt: string,
  successResponse: string,
  failureResponse: string,
  constantValue: string,
}

interface Props {
  classes: any,
  allBPs: AllBPs[],
  onAnswerUpdate: (event: any, data: any) => void,
  setFocusedBranchPoint: (idx: number) => void,
  descriptions: any,
  cfid:any,
  misc: any,
  isBuildingBP: Boolean, 
  setToBuildingMode: any,
  postRequestData: any,
  postRequestDataMap: any,
  postBPLoading: boolean
}

interface State {
  activeStep: number
  saveClicked: Boolean
  openDialog: boolean
  dialogBP: any,
}

class BPHorizontalStepper extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      activeStep: null,
      saveClicked:false,
      openDialog: false,
      dialogBP: null,
    };
  }

  componentWillReceiveProps(nextProps: Readonly<Props>, nextContext: any): void {
    const { answered } = nextProps;
      if (answered.length === this.props.answered.length) {
        return;
      }
      this.setState({
        activeStep: answered.length - 1,
      });
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    const { setFocusedBranchPoint } = this.props;
    const { activeStep } = this.state;
    if (prevState.activeStep !== activeStep) {
      setFocusedBranchPoint(activeStep);
    }
  }

  incrementActiveStep = () => {
    this.setState(state => ({
      activeStep: state.activeStep + 1,
    }));
  };

  toggleActiveStep = (event: any, index: number) => {
    event.stopPropagation();
    this.setState((prevState) => ({
      activeStep: index,
      saveClicked:false,
    }));
  };

  handleClickOpen = () => {
    this.setState({openDialog: true})
  };

  handleClose = () => {
    this.setState({openDialog: false})
  };

  setDialogBP = (bp) =>{
    this.setState({dialogBP: bp})
  }

  getStep = (bp: any, idx: any, bpFormData: any) => {
    const { classes, descriptions,cfid, misc, setToBuildingMode, onBPFormChange } = this.props;
    setToBuildingMode(true);
    const handleRequestAnswerUpdate = (event: any, updated: any) => {
      const { onAnswerUpdate, setToBuildingMode } = this.props;
    
      this.setState({saveClicked:true});
      this.incrementActiveStep();
      onAnswerUpdate(event, {
        name: bp.name,
        ...updated,
      }); 
     setToBuildingMode(false);
    };

    const validBP = this.isBPValid(bpFormData)
    return (
      <Card onClick={(e) => this.toggleActiveStep(e, idx)}>
        <ExpansionPanel expanded={true}>
          <ExpansionPanelSummary
            aria-controls={`${name}${idx}-content`}
            id={`${name}${idx}-header`}
            style={{display: 'flex',  justifyContent: 'space-between', width: "100%"}}
          >
              <div style={{display: 'flex',  justifyContent: 'space-between', width: "100%"}}>
                <Typography
                  className={classes.heading}
                  variant='h5'
                >{bp.name}</Typography>
                <div style={{display: 'flex',  justifyContent: 'space-between', width: "15%"}}>
                  <EditIcon onClick={()=>{
                    if (validBP){
                    this.handleClickOpen()
                    this.setDialogBP(bpFormData)
                  }
                }}
                  style={{color: validBP ? 'white': 'grey'}}
                    />
                  {bpFormData.completed && <CheckCircleIcon style={{ color: '#3CB371' }} />}
                </div>
              </div>
            
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <BPHorizontalStepContent
              descriptions={descriptions}
              misc={misc}
              details={bp}
              cfid={cfid}
              requestAnswerUpdate={handleRequestAnswerUpdate}
              bpFormData={bpFormData}
              onBPFormChange={onBPFormChange}
              idx={idx}
            />
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Card>

    );
  };

  /**
   * Will return true if all form data is valid
   * Will return false if all BPs are complete
   */
  areBPsValid = () => {
    const { postRequestData } = this.props;
    let validBps = true
    let completedBps = true
    for(let bp of postRequestData){
        if (!this.isBPValid(bp)){
          validBps = false
        }
        if (!bp.completed){
          completedBps = false
        }
    }
    return validBps && !completedBps
  }

  isBPValid = (bp: any) =>{
    let validProbability = true; // if BP has a probability, will check the validity
                                 // otherwise by default it is true
    if ('failure_probability' in bp){
      validProbability = InputRules.isProbabilityValid(bp.failure_probability)
    }
    return (
        bp.label.name != '' && bp.failure != '' && bp.success != '' && validProbability
    )
  }

  render() {
    const { classes, postBPLoading, answered, intl, postRequestData, postRequestDataMap, cfid, bpFormOnsubmit, convertTime } = this.props;
    const disabled = !this.areBPsValid()
    return (
      <div className={classes.root}>
        <BPEditConfirmDialog
         openDialog={this.state.openDialog}
         handleClose={this.handleClose}
         bpFormData={this.state.dialogBP}
         cfid={cfid}
        />
        {answered.map((bp, idx) => ( this.getStep(bp, idx, postRequestData[postRequestDataMap[bp.name]]) ))}
          {
            postBPLoading ?  
            <div style={{display: 'flex', width: '100%', justifyContent: 'space-between'}}>
              <Typography variant='h5'>{convertTime()}</Typography>
              <CircularProgress/>
            </div> 
            :
            <Tooltip title={intl.formatMessage({ id: 'save&continue' })} placement="top"> 
              <div className={classes.entryTextField}>
                  <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  onClick={bpFormOnsubmit}
                  // if any of the BPs are invalid, the button will be disabled
                  disabled={disabled}
                  >
                  {intl.formatMessage({ id: 'save&continue' })}
                  </Button>
              </div>
          </Tooltip>
        }
      </div>
    );
  }
}

export default withStyles(styles)(injectIntl(BPHorizontalStepper));
