import React, { Component } from 'react';
import ReportMenuService from '../../../Report/ReportService';

const ReportSelectionContext = React.createContext();

export class ReportSelectionProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menu: {
        crt: ReportMenuService.getReportSetting('crt'),
        bp: ReportMenuService.getReportSetting('bp'),
        hfe: ReportMenuService.getReportSetting('hfe'),
        pif: ReportMenuService.getReportSetting('pif'),
        quantify: ReportMenuService.getReportSetting('quantify'),
        hfeQuantify: ReportMenuService.getReportSetting('hfeQuantify'),
      }
    };
  }

  handleSelectionChange = name => event => {
    const { menu } = this.state;
    ReportMenuService.setReportSetting(name, !menu[name]);
    this.setState(prevState => ({
      menu: {
        ...prevState.menu,
        [name]: !prevState.menu[name],
      }
    }));
  }

  render() {
    const { children } = this.props;
    const { menu: { crt, bp, hfe, pif, quantify, hfeQuantify } } = this.state;
    return (
      <ReportSelectionContext.Provider
        value={{
          handleChange: this.handleSelectionChange,
          crt, bp, hfe, pif, quantify, hfeQuantify,
        }}
      >
        {children}
      </ReportSelectionContext.Provider>
    );
  }
}

export const ReportSelectionConsumer = ReportSelectionContext.Consumer;