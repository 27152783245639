import {CreateDarkTheme, CreateLightTheme, DualVariantTheme} from "hcla-web-frontend-primitives/app/Utils/CreateTheme";

const secondary = {
  main: '#c9682c',
  dark: '#933b00',
  light: '#ff9759',
};
const primary = {
  main: '#3d6799',
  dark: '#003d6b',
  light: '#6e95ca',
};

const AmberTheme: DualVariantTheme = {
  name: 'Amber',
  darkTheme: CreateDarkTheme({
    overrides: {
      MuiPaper: {
        root: {
          backgroundColor: 'rgb(44,47,52)'
        },
      },
    },
    palette: {
      type: 'dark',
      text: {
        primary: 'rgb(194,188,184)',
        secondary: 'rgb(155,149,147)',
      },
      background: {
        default: 'rgb(29,30,34)',
        paper: 'rgb(44,47,52)'
      },
      secondary: secondary,
      primary: primary,
    },
  }),
  lightTheme: CreateLightTheme({
    palette: {
      type: 'light',
      background: {
        default: 'rgba(113, 51, 2, 0.14)',
      },
      secondary: secondary,
      primary: primary,
    },
  }),
};

export default AmberTheme;
