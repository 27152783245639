import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { withStyles, Theme } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';

import ApiManager from 'hcla-web-frontend-primitives/app/Api/ApiManager';
import SecondaryPIFChip from '../../../../../primitives/Chip/SecondaryPIFChip';
import PIFScoreChip from "../../../../../primitives/Chip/PIFScoreChip";
import {HFEType} from "../../../../Types";

const styles = (theme: Theme) => ({
  root: {
    padding: `${theme.spacing(1) * 2}px`,
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginRight: `${theme.spacing(1)}px`,
    },
    '& :last-child': {
      marginRight: 'unset',
    },
    '& :first-child': {
      margin: 'unset',
    },
  },
  actionsContainer: {
    '& > *': {
      marginRight: `${theme.spacing(1) * 2}px`,
    },
  },
  textBox: {
    paddingRight: `${theme.spacing(2) * 8}px`,
  },
});

interface Props {
  classes: { [key: string]: any },
  checked: boolean,
  pif: {
    name: string,
    lowerLevelPifs: string[],
    score: any,
    override: boolean,
    expression: { [key: string]: any },
  },
  handleToggle: (e: any, name: string) => void,
  match: { [key: string]: any },
  faultTreeId: number,
  cfmTreeId: number,
  onResponseUpdated: () => void,
  hfe: HFEType
}

const CFMViewerEntry = (props: Props) => {
  const {
    classes,
    match: { params: { id, hfeName, cfmName,cfid } },
    pif, pif: { name, lowerLevelPifs, score, expression, override },
    match,
    checked,
    handleToggle,
    faultTreeId,
    cfmTreeId,
    onResponseUpdated,
    hfe
  } = props;

  console.log(match)

  const primaryText = (
    <Typography noWrap variant="h6" color="textPrimary">{name}</Typography>
  );
  const secondaryText = (
    lowerLevelPifs.map(item => (<SecondaryPIFChip label={item} color={checked ? 'primary' : 'default'} />))
  );

  const findHfeName = (hfe) => {
    if(!hfe){
      return  ""
    } else if(hfe.length === undefined) 
       {
       return hfe.cfms[cfmName].label.name
      }  else {
        try {
       return hfe.find(currentHfe=>currentHfe.cfms[cfmName])?.cfms[cfmName].label.name
        } catch (error) {
          console.error(error);
          return ""
          // Note - error messages will vary depending on browser
        }
        
      
      }
      
  }

  const findCFMName = (hfe) => {
    if(!hfe){
      return  ""
    } else if(hfe.length === undefined) 
       {
       return hfe.label.name
      }  else {
        try {
       return hfe.find(currentHfe=>currentHfe.cfms[cfmName])?.label.name
        } catch (error) {
          console.error(error);
          return ""
          // Note - error messages will vary depending on browser
        }
        
      
      }
  }

  const pifPathBasename = `${ApiManager.MODEL_PATH}/${id}/main-analysis/${cfid}/hfe/${hfeName}/cfm/${cfmName}/pif/${name}/`;
  const viewPif = itemProps => (<Link to={{ pathname: pifPathBasename }} {...itemProps} />);
  
  console.log(hfe, pif, 323)
  return (hfe.length===undefined || pif) && (
    <ListItem
      button={checked}
      component={checked ? viewPif : null}
      key={`${hfeName}.${cfmName}.${name}`}
      alignItems="center"
      className={classes.root}
      divider
    >
      <ListItemText
        disableTypography
        className={classes.textBox}
        primary={primaryText}
        secondary={secondaryText}
      />
      <ListItemSecondaryAction className={classes.actionsContainer}>
        <PIFScoreChip
          cfid={cfid}
          modelId={id}
          cfmKey={cfmName}
          cfmName={findHfeName(hfe)}
          hfeKey={hfeName}
          hfeName={findCFMName(hfe)}
          pifName={pif.name}
          score={score}
          expression={expression}
          override={override}
          faultTreeId={faultTreeId}
          cfmTreeId={cfmTreeId}
          onResponseUpdated={onResponseUpdated}
        />
        <Switch
          onChange={async e =>  handleToggle(e, name)}
          checked={checked}
          color="primary"
        />
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default withStyles(styles)(withRouter(CFMViewerEntry));
