import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CheckRounded from '@material-ui/icons/CheckRounded';

const styles = () => ({
  icon: {
    fontSize: '36px',
    color: 'rgb(70, 189, 85)',
  },
});

const BinaryCompleteIcon = (props) => {
  const { classes, ...others } = props;
  return (
    <CheckRounded
      classes={{
        root: classes.icon,
      }}
      {...others}
    />
  );
};

BinaryCompleteIcon.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(BinaryCompleteIcon);
