import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Fab from '@material-ui/core/Fab';
import { withStyles } from '@material-ui/core/styles';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ErrorIcon from '@material-ui/icons/Error';
import PhoenixApiManager from '../../../Api/PhoenixApiManager';

import { ReportSelectionConsumer } from './ReportSelectionContext';

const styles = theme => ({
  reportButton: {
    margin: theme.spacing(1),
  },
  errorButton: {
    backgroundColor: 'red'
  },
  icon: {
    marginRight: theme.spacing(1),
  },
});

const ReportButton = ({ classes, intl, id }) => {

  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const handleOnClick = () => {
    setLoading(true);
    PhoenixApiManager.getReport(id)
      .then(res => res.blob())
      .then(resBlob => {
        const file = new Blob([resBlob], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL, "_blank");
      })
      .then(() => setLoading(false))
      .catch(() => {
        setHasError(true);
        setLoading(false);
      });
  };

  let className = classes.reportButton;
  let btnLabel = intl.formatMessage({ id: 'reportButton.modelReport' });
  let Icon = AssignmentIcon;

  if (loading) {
    btnLabel = intl.formatMessage({ id: 'report.loading' });
  } else if (hasError) {
    className = classes.errorButton;
    btnLabel = intl.formatMessage({ id: 'report.error' });
    Icon = ErrorIcon;
  }

  return (
    <Fab
      size="medium"
      color="secondary"
      variant="extended"
      className={className}
      onClick={handleOnClick}
      disabled={loading}
    >
      <Icon className={classes.icon} />
      {btnLabel}
    </Fab>
  );
};

ReportButton.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(injectIntl(ReportButton));
