import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
//@ts-ignore
import SingleGenericModelSettingsPermissions from 'hcla-web-frontend-primitives/app/components/primitives/Model/SingleGenericModelSettings/SingleGenericModelSettingsPermissions';
//@ts-ignore
import ExpansionPanelView from 'hcla-web-frontend-primitives/app/components/primitives/ExpansionPanel/ExpansionPanelView';

import ModelSettingsAdvanced from './ModelSettingsAdvanced';
import ModelSettingsGeneral from './ModelSettingsGeneral';
import { DetailsSettingsPropType } from '../../Types';

const styles = () => ({
  root: {
    flexGrow: 1,
  }
});

interface Props {
  details: DetailsSettingsPropType,
  onRequestSave: () => any,
  isSaving: boolean,
  classes: any,
  intl: any
}

const ModelSettings = (props: Props) => {
  const {
    details, onRequestSave, classes, isSaving, intl,
  } = props;
  return (
    <div className={classes.root}>
      <ExpansionPanelView
        title={intl.formatMessage({ id: 'general' })}
        description={intl.formatMessage({ id: 'settings.generalDesc' })}
      >
        <ModelSettingsGeneral
          details={details}
          onRequestSave={onRequestSave}
          isSaving={isSaving}
        />
      </ExpansionPanelView>
      <ExpansionPanelView
        disabled
        title={intl.formatMessage({ id: 'permissions' })}
        description={intl.formatMessage({ id: 'settings.permissionsDesc' })}
      >
        <SingleGenericModelSettingsPermissions />
      </ExpansionPanelView>
      <ExpansionPanelView
        title={intl.formatMessage({ id: 'advanced' })}
        description={intl.formatMessage({ id: 'settings.advancedDesc' })}
      >
        <ModelSettingsAdvanced
          details={details}
        />
      </ExpansionPanelView>
    </div>
  );
};

export default withStyles(styles)(injectIntl(ModelSettings));
