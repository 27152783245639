import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { injectIntl } from 'react-intl';
import { Switch, Route } from 'react-router-dom';

//@ts-ignore
import ViewNoMatch from 'hcla-web-frontend-primitives/app/components/primitives/Error/ViewNoMatch';

import EditCFM from './EditCFM';
import { HFEType } from "../../../../Types";
import PIFsForCFM from '../../PIF/PIFsforCFM';
import PhoenixModelContext from '../../../../PhoenixModelContext';
import QuantifyCFM from './QuantifyCFM';
import SingleCFMView from './SingleCFMView';
import SinglePIFView from '../../PIF/SinglePIFView/SinglePIFView';
import QuantifyCF from '../../../Quantification/QuantifyCF'

interface Props {
  match: { [key: string]: any },
  location: { [key: string]: any },
  depth: number,
  intl: any,
  hfe: HFEType
}

const CFMForHFERouter = (props: Props) => {
  const { depth, match: { params: { hfeName, cfmName, id,cfid } }, intl, hfe, hfeItems } = props;
  console.log(cfmName, hfe, 'he satfjkehgew66666666666666666666666')
  console.log(props, 'this is props!@$%^#$&%$^&%#&&$%&$))))))))))))))))))))))))))')
  

  const disableEditing = (cfmName: string) => {
    const cfmKeys = Object.keys(hfe.cfms);
    const cfmIndex = cfmKeys.findIndex((cfmKey) => cfmName === cfmKey);

    if (cfmIndex === 0){
      return false; // First CFM should never be disabled
    } else { // Check if prevCFM's PIFs are not answered
      const pifsForPrevCFM = hfe && Object.values(hfe.cfms[cfmKeys[cfmIndex - 1]].pifs);
      return pifsForPrevCFM.length === 0 ||
        pifsForPrevCFM.some((pif) => Object.values(pif.questions).length === 0);
    }
  };

  return (
    <PhoenixModelContext.Consumer>
      {
        (value) => {
          const { refresh } = value;
          return (
            <React.Fragment>
              <BreadcrumbsItem
                to={`/model/${id}/main-analysis/${cfid}/hfe/${hfeName}/cfm`}
                depth={depth - 1}
              >
                CFMs
              </BreadcrumbsItem>
              <BreadcrumbsItem
                to={`/model/${id}/main-analysis/${cfid}/hfe/${hfeName}/cfm/${cfmName}`}
                depth={depth}
              >
                {cfmName}
              </BreadcrumbsItem>
              <Switch>
                <Route
                  exact
                  key={cfmName}
                  path="/model/:id/main-analysis/:cfid/hfe/:hfeName/cfm/:cfmName/"
                  render={prop => {
                    return (
                      <SingleCFMView
                        {...prop}
                        onResponseUpdated={refresh}
                        hfe={hfe}
                        disableEditing={false}
                        hfeItems={hfeItems}
                      />
                    )
                  }}
                />
                <Route
                  exact
                  path="/model/:id/main-analysis/:cfid/hfe/:hfeName/cfm/:cfmName/edit/"
                  render={prop => (
                    <EditCFM
                      {...prop}
                      depth={depth + 1}
                    />
                  )}
                />
                <Route
                  exact
                  path="/model/:id/main-analysis/:cfid/hfe/:hfeName/cfm/:cfmName/quantify/"
                  render={prop => (
                    <QuantifyCFM
                      {...prop}
                      depth={depth + 1}
                      cfm={hfe.cfms[cfmName]}
                    />
                  )}
                />
                <Route
                  exact
                  path="/model/:id/main-analysis/:cfid/hfe/quantify/"
                  render={prop => (
                    <QuantifyCF
                      {...prop}
                      depth={depth + 1}
                    />
                  )}
                />
                <Route
                  exact
                  path="/model/:id/main-analysis/:cfid/hfe/:hfeName/cfm/:cfmName/pif/"
                  render={prop => (
                    <PIFsForCFM
                      {...prop}
                      depth={depth + 1}
                    />
                  )}
                />
                <Route
                  exact
                  path="/model/:id/main-analysis/:cfid/hfe/:hfeName/cfm/:cfmName/pif/:pifName/"
                  render={prop => (
                    <SinglePIFView
                      {...prop}
                      onResponseUpdated={refresh}
                      depth={depth + 2}
                    />
                  )}
                />
                <Route
                  render={prop => (
                    <ViewNoMatch
                      {...prop}
                      message={intl.formatMessage({ id: '404.pageNotFound' })}
                    />
                  )}
                />
              </Switch>
            </React.Fragment>
          );
        }
      }
    </PhoenixModelContext.Consumer>
  );
};

export default injectIntl(CFMForHFERouter);
