//NOTIFY THE CHANGE:
//The progress button is deleted


import React, {FunctionComponent, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import {Theme} from '@material-ui/core/styles';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import OverviewIcon from '@material-ui/icons/DashboardTwoTone';
import SettingsIcon from '@material-ui/icons/SettingsTwoTone';
import AnalysisIcon from '@material-ui/icons/TimelineTwoTone';
import DescriptionIcon from '@material-ui/icons/Description';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import PhoenixApiManager from '../../../Api/PhoenixApiManager';
import QuantificationIcon from '@material-ui/icons/AssessmentTwoTone';
import makeStyles from "@material-ui/core/styles/makeStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import PhoenixModelContext from "../PhoenixModelContext";
import Divider from "@material-ui/core/Divider";
import ChromeReaderModeIcon from '@material-ui/icons/ChromeReaderMode';

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    color: theme.palette.primary.main
  }
}));

const options = [
  { icon: <OverviewIcon />, name: "overview" },
  { icon: <DescriptionIcon />, name: "master-esd" },
  { icon: <AnalysisIcon />, name: "analysis" },
  { icon: <ChromeReaderModeIcon />, name: "dependency" },
  { icon: <QuantificationIcon />, name: "quantification" },
  // { icon: <TrendingUpIcon />, name: "progress"},
  { icon: <SettingsIcon />, name: "settings" }
];

type DrawerListProps = {
  details: any;
  location: any;
}

const DrawerList: FunctionComponent<DrawerListProps> = (props) => {

  const { details, location } = props;
  // Check if url contains a substring included in Options[].name, return index if found, return -1 if no match
  const urlOptionIndex = options.findIndex((option: any) => location.pathname.indexOf(option.name) !== -1);
  const [selectedIndex, setSelectedIndex] = React.useState(urlOptionIndex !== -1 ? urlOptionIndex : 0);

  useEffect(() => {
    setSelectedIndex(urlOptionIndex);
  },[urlOptionIndex]);

  return (
    <List>
      {options.map((option, index) => {
        return (
          <PhoenixModelContext.Consumer>
            {
              value => {
                // const { details: { progress: { bp, dp }}} = value;
                // const analysisTabDisabled = option.name === "analysis" &&
                //   (bp.summary.completed !== bp.summary.total ||
                //     dp.summary.completed !== dp.summary.total);
                return (
                  <React.Fragment>
                    {/* {option.name === "progress" && (
                      <Divider />
                    )} */}
                    <DrawerListItem
                      key={index}
                      modelId={details.id}
                      icon={option.icon}
                      name={option.name}
                      selected={index === selectedIndex}
                      onClick={() => setSelectedIndex(index)}
                      disabled={option.name === "quantification" && parseInt(localStorage.getItem('progress')) !== 100.0}
                    />
                  </React.Fragment>
                )
              }
            }
          </PhoenixModelContext.Consumer>
        )}
      )}
    </List>
  );
};

type DrawerListItemProps = {
  modelId: number;
  name: string; // menu item label and intl key
  icon: JSX.Element;
  selected: boolean;
  onClick: () => void;
  disabled: boolean;
}

const DrawerListItem: FunctionComponent<DrawerListItemProps> = (props) => {

  const { modelId, name, icon, selected, onClick, disabled } = props;
  const { formatMessage } = useIntl();
  const classes = useStyles();

  return (
    <ListItemLink to={`${PhoenixApiManager.MODEL_PATH}/${modelId}/main-${name === "analysis" ? name + '/hfe/' : name}`} selected={selected} onClick={onClick} disabled={disabled}>
      <ListItemIcon className={selected ? classes.icon : null}>{icon}</ListItemIcon>
      <ListItemText primary={formatMessage({ id: name })} />
    </ListItemLink>
  );
};

const ListItemLink = (props: any) => {
  return <ListItem button component={Link} {...props} />
};

export default DrawerList;
