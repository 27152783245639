export type ProgressPropType = { [key: string]: any };

export type DetailsPropType = {
  id: number,
  title: string,
  creator: number,
  description: string,
  date_created: string,
  date_modified: string,
  assigned_users: any[],
  results: string[],
  model_data: {
    event_trees: any[],
    fault_trees: any[],
    init_events: any[],
    bayesian_networks: any[],
    template: number,
  },
  progress: ProgressPropType,
};

export type DetailsSettingsPropType = {
  id: number,
  title: string,
  description: string,
  date_created: string,
  date_modified: string,
  creator: number,
  assigned_users: any[],
  subsystems: number[],
};

export type DetailsModelDataPropType = {
  id: number,
  model_data: {
    template: number,
    event_trees: number,
  },
};

export type EntryProgress = {
  completed: number,
  total: number,
};

export const EntryDefaultProgress = {
  completed: 0,
  total: 0,
};

export type HFEDetailsProgress = EntryProgress & {
  name: string,
};

export type CFMDetailsProgress = EntryProgress & {
  hfe: string,
  name: string,
};

export type PIFDetailsProgress = EntryProgress & {
  hfe: string,
  cfm: string,
  name: string,
};

export type HFEType = {
  cfms: { [key: string]: any },
  success: string
  failure: string,
  fault_tree_id: number,
  label: {
    description: string,
    name: string
  }
  name: string,
  nodes: { [key: string]: any }
}
