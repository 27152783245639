import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid/Grid';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import IconButton from '@material-ui/core/IconButton/IconButton';
import StepperDescriptionTextField from './StepperDescriptionTextField';
import YesCircleIcon from '../../../../../primitives/Icons/YesCircleIcon';
import NoCircleIcon from '../../../../../primitives/Icons/NoCircleIcon';
import PhoenixModelContext from "../../../../PhoenixModelContext";

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  responseContainer: {
    flexGrow: '1',
  },

  button: {
    marginLeft: theme.spacing(1),
    width: '56px',
  },

  actionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    width: '72px',
  },

  actionButtonIcon: {
    width: '32px',
    height: '32px',
  },
});

class DPVerticalStepContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      response: props.response,
    };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const { response } = this.state;
    if (nextProps.response !== response) {
      this.setState({
        response: nextProps.response,
      });
    }
  }

  onAnswerUpdate = (event, answer) => {
    const { requestAnswerUpdate } = this.props;
    const { response  } = this.state;
    requestAnswerUpdate(event, {
      response: answer,
      description: response,
    });
  };

  hasResponseDescriptionChanged = () => {
    const { response } = this.props;
    return response !== this.state.response;
  };


  handleChange = (event) => {
    this.setState({
      response: event.target.value,
    });
  };

  render() {
    const { classes, response, explanation,cfid } = this.props;
    return (
      <PhoenixModelContext.Consumer>
        {
          (value) => {
            const to_be_read=value.allCFs.filter((item)=>item.crt_model==cfid)[0];

            const  dp  =to_be_read===undefined?undefined: to_be_read.progress.dp;
            {console.log("DP IN STEP ",dp)}
            const editingDisabled = dp===undefined? (false): (dp.summary.total === dp.summary.completed);
            return (
              <div className={classes.root}>
                <div className={classes.responseContainer}>
                  <StepperDescriptionTextField
                    responseDescription={response}
                    questionDescription={explanation}
                    onChange={this.handleChange}
                    editingDisabled={editingDisabled}
                  />
                </div>
                <div className={classes.actionsContainer}>
                  <Tooltip
                    title="Yes"
                    placement="right"
                    disabled={editingDisabled}
                  >
                    <IconButton
                      className={classes.button}
                      onClick={event => this.onAnswerUpdate(event, 'Yes')}
                      disabled={editingDisabled}
                    >
                      <YesCircleIcon className={classes.actionButtonIcon} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title="No"
                    placement="right"
                    disabled={editingDisabled}
                  >
                    <IconButton
                      className={classes.button}
                      onClick={event => this.onAnswerUpdate(event, 'No')}
                      disabled={editingDisabled}
                    >
                      <NoCircleIcon className={classes.actionButtonIcon} />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            )
          }
        }
      </PhoenixModelContext.Consumer>
    );
  }
}

DPVerticalStepContent.defaultProps = {
  response: '',
};

DPVerticalStepContent.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  response: PropTypes.string,
  explanation: PropTypes.string.isRequired,
  requestAnswerUpdate: PropTypes.instanceOf(Function).isRequired,
};

export default withStyles(styles)(DPVerticalStepContent);
