import FaultTreeTransferGateVertex
	from "hcl-web-editor/app/mxGraph/HCLTreeNode/FaultTreeNode/FaultTreeTransferGateNode/FaultTreeTransferGateVertex";
import {useIntl} from "react-intl";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Theme} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
	closeIcon: {
		position: 'absolute',
		top: 0,
		right: 0
	},
}));

type CFMEditingDialogProps = {
	targetCFMQuestion: FaultTreeTransferGateVertex,
	setTargetCFMQuestion: (cfm: FaultTreeTransferGateVertex) => void,
}

const CFMEditingDialog = (props: CFMEditingDialogProps) => {

	const { targetCFMQuestion, setTargetCFMQuestion } = props;

	const intl = useIntl();
	const classes = useStyles();

    var questionName = ""
    if (targetCFMQuestion) {
        switch (targetCFMQuestion.value.label.name.toLowerCase()) {
            case "key alarm not responded to intentionally":
                questionName = "Does success on this critical task depend on the crew reponding to a key alarm?";
                break;
            case "key alarm not responded to unintentionally":
                questionName = "Does success on this critical task depend on the crew reponding to a key alarm?";
                break;
            case "data discounted":
                questionName = "Does success on this critical task depend on the crew gathering and keeping data for decision-making?";
                break;
            case "decision to stop gathering data":
                questionName = "Does success on this critical task depend on the crew gathering data for decision-making?";
                break;
            case "data incorrectly processed":
                questionName = "Does success on this critical task depend on the crew correctly processing data gathered on the system state?";
                break;
            case "reading error":
                questionName = "Does success on this critical task depend on the crew reading data or parameters from the HSI, control panel, procedures, or other written guidances?";
                break;
            case "information miscommunicated":
                questionName = "Does success on this critical task depend on the crew receiving written or spoken information from another crewmember, supervisor, or others?";
                break;
            case "wrong data attended to":
                questionName = "Does success on this critical task depend on the crew monitoring a parameter with a certain frequency?";
                break;
            case "plant/system state misdiagnosed by following procedure":
                questionName = "Does success on this critical task depend on the crew diagnosing the state of the system following procedures?";
                break;
            case "plant/system state misdiagnosed by following own knowledge":
                questionName = "Does success on this critical task depend on the crew diagnosing the state of the system following their own knowledge?";
                break;
            case "procedure misinterpreted":
                questionName = "Should the crew follow a procedure for performing this critical task?";
                break;
            case "failure to adapt procedures to the situation":
                questionName = "Should the crew follow a procedure for performing this critical task?";
                break;
            case "procedure step omitted intentionally":
                questionName = "Does success on this critical task depend on the crew following all the steps of a multi-steps procedure?";
                break;
            case "decision to delay action":
                questionName = "Does success on this critical task depend on the crew deciding to perform an action on a component / equipment at a certain time?";
                break;
            case "inappropriate strategy chosen by following procedure":
                questionName = "Does success on this critical taks depend on the crew to choose a strategy when following a procedure?";
                break;
            case "failure to adapt own knowledge to the situation":
                questionName = "Does success on this critical task depend on the crew using their own knowledge for assessing the situation and making a decision?";
                break;
            case "inappropriate strategy chosen by following own knowledge":
                questionName = "Does success on this critical taks involve the crew to choose a strategy when following their own knowledge?";
                break;
            case "inappropriate transfer to a different procedure":
                questionName = "Should the crew follow a procedure for performing this critical task?";
                break;
            case "incorrect timing":
                questionName = "Does succes on this critical task require performing an action on a component / equipment that the crew could perfom too early or too late such that it compromises the task/ safety function?";
                break;
            case "incorrect operation of component":
                questionName = "Does succes on this critical task require performing an action on a component / equipment?";
                break;
            case "action on wrong component":
                questionName = "Does succes on this critical task require performing an action on a component / equipment?";
                break;
        }
    }

	return targetCFMQuestion && (
		<Dialog
			open={Boolean(targetCFMQuestion)}
			onClose={() => setTargetCFMQuestion(null)}
			fullWidth={false}
			maxWidth="md"
		>
			<DialogTitle id="cfm-dialog-title" disableTypography>
				<Typography variant={"h6"}>{targetCFMQuestion.value.label.name}</Typography>
				<IconButton onClick={() => setTargetCFMQuestion(null)} className={classes.closeIcon}>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent dividers>
				<Typography variant={"body1"}>{questionName}</Typography>
			</DialogContent>
		</Dialog>
	);
};

export default CFMEditingDialog;