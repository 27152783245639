class ReportMenuService {

  static hasSettingNotSet(token) {
    return token === null || token === 'undefined';
  }

  static setReportSetting(reportSection, setting) {
    localStorage.setItem(`report_${reportSection}`, setting);
  }

  static getReportSetting(reportSection) {
    const setting = localStorage.getItem(`report_${reportSection}`);
    return ReportMenuService.hasSettingNotSet(setting) ? true : JSON.parse(setting); // JSON.parse: convert "true" => true for example
  }
}

export default ReportMenuService;
  