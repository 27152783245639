import React, {useState} from 'react';
import HumanFailureEventTraveler from "../HumanFailureEventTraveler";
import {useIntl} from "react-intl";
import AnalysisReferencesHeader from "../../../../../../primitives/AnalysisReferencesHeader";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import { makeStyles, Theme } from "@material-ui/core/styles";
import HumanFailureEventControllerContentView from "./HumanFailureEventControllerContentView";
import HumanFailureEventControllerActionsView from "./HumanFailureEventControllerActionsView";
import {Button} from "@material-ui/core";
import RestoreIcon from '@material-ui/icons/Restore';

const useStyles = makeStyles((theme: Theme) => ({
	controllerViewRoot: {
		height: "100%",
		position: "relative",
		display: 'flex',
		flexDirection: 'column'
	},

	cardContent: {
		overflowY: "auto",
		flexGrow: 1
	},
}));

type HumanFailureEventControllerViewProps = {
	label: string,
	id: number,
	modelId: number,
	hfe: any,
	history: any,
	traveler: HumanFailureEventTraveler,
	onResponseUpdated: () => void,
	currentSelection: any,
	setCurrentSelection: (currentSelection: any) => void
}

const HumanFailureEventControllerView = (props: HumanFailureEventControllerViewProps) => {
	const { label, id, traveler, hfe, history, onResponseUpdated, currentSelection, setCurrentSelection,modelId } = props;

	const classes = useStyles();
	const intl = useIntl();

	return (
		<React.Fragment>
			<AnalysisReferencesHeader
				crt={id && { id }}
				bp={hfe && hfe.label.name}
				inPaperContainer={true}
			/>
			<Card className={classes.controllerViewRoot}>
				<CardHeader
					title={intl.formatMessage({ id: 'analysis.hfe'})}
					subheader={<Typography variant="caption">{intl.formatMessage({ id: 'analysis.hfeDesc' })}</Typography>}
				/>
				<Divider />
				<CardContent className={classes.cardContent}>
					<HumanFailureEventControllerContentView
						currentSelection={currentSelection}
						setCurrentSelection={setCurrentSelection}
						traveler={traveler}
						onResponseUpdated={onResponseUpdated}
					/>
				</CardContent>
				<CardActions>
					
					<HumanFailureEventControllerActionsView
						id={id}
						modelId={modelId}
						hfeName={hfe && hfe.name}
						history={history}
						traveler={traveler}
						currentSelection={currentSelection}
						setCurrentSelection={setCurrentSelection}
						onResponseUpdated={onResponseUpdated}
					/>
				</CardActions>
			</Card>
		</React.Fragment>
	);
};

export default HumanFailureEventControllerView;
