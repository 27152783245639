import React from 'react';
import { Theme, WithStyles } from '@material-ui/core';
import createStyles from '@material-ui/core/styles/createStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import { withPreferences } from 'hcla-web-frontend-primitives/app/config/PreferencesProvider';
import { mxEvent, mxEventObject, mxKeyHandler, mxCell } from 'mxgraph-js';
import HCLTreeMxGraph from 'hcl-web-editor/app/mxGraph/HCLTreeMxGraph/HCLTreeMxGraph';
import FaultTreeMxGraph, { FaultTreeMxGraphJSON } from 'hcl-web-editor/app/mxGraph/HCLTreeMxGraph/FaultTreeMxGraph';
import EventTreeMxGraph, { EventTreeMxGraphJSON } from 'hcl-web-editor/app/mxGraph/HCLTreeMxGraph/EventTreeMxGraph';
import BayesianNetworkMxGraph, { BayesianNetworkMxGraphJSON } from 'hcl-web-editor/app/mxGraph/HCLTreeMxGraph/BayesianNetworkMxGraph';

import { TreeNames, TreeTypes } from 'hcl-web-editor/app/components/Model/SingleHCLModelView/SingleTreeView/Tools/TreeDictionaries';
import { FaultTreeMxGraphViewJSON } from 'hcl-web-editor/app/components/Model/SingleHCLModelView/SingleTreeView/MxGraphViews/FaultTreeMxGraphEditorView';
import { EventTreeMxGraphViewJSON } from 'hcl-web-editor/app/components/Model/SingleHCLModelView/SingleTreeView/MxGraphViews/EventTreeMxGraphEditorView';
import { BayesianNetworkMxGraphViewJSON } from 'hcl-web-editor/app/components/Model/SingleHCLModelView/SingleTreeView/MxGraphViews/BayesianNetworkMxGraphEditorView';
import Editor from './Editor';
import HCLTreeMxGraphKeyHandler from 'hcl-web-editor/app/mxGraph/HCLTreeMxGraphKeyHandler/HCLTreeMxGraphKeyHandler';
import GateVertex from 'hcl-web-editor/app/mxGraph/HCLTreeNode/FaultTreeNode/GateNode/GateVertex';
import LogicGateSelectionDialog from 'hcl-web-editor/app/components/Model/SingleHCLModelView/SingleTreeView/Dialogs/LogicGateSelectionDialog';
import HouseEventSelectionDialog from 'hcl-web-editor/app/components/Model/SingleHCLModelView/SingleTreeView/Dialogs/HouseEventSelectionDialog';
import BasicEventVertex from 'hcl-web-editor/app/mxGraph/HCLTreeNode/FaultTreeNode/BasicEventNode/BasicEventVertex';
import HouseEventVertex from 'hcl-web-editor/app/mxGraph/HCLTreeNode/FaultTreeNode/HouseEventNode/HouseEventVertex';
import FaultTreeTransferGateSelectionDialog from 'hcl-web-editor/app/components/Model/SingleHCLModelView/SingleTreeView/Dialogs/FaultTreeTransferGateSelectionDialog';
import FaultTreeTransferGateVertex
  from 'hcl-web-editor/app/mxGraph/HCLTreeNode/FaultTreeNode/FaultTreeTransferGateNode/FaultTreeTransferGateVertex';
import PhoenixApiManager from '../../../../Api/PhoenixApiManager';
import BasicEventSelectionDialog from 'hcl-web-editor/app/components/Model/SingleHCLModelView/SingleTreeView/Dialogs/BasicEventSelectionDialog';
import EndStateVertex from 'hcl-web-editor/app/mxGraph/HCLTreeNode/EventTreeNode/EndStateNode/EndStateVertex';
import SimpleVertexSelectionDialog from 'hcl-web-editor/app/components/Model/SingleHCLModelView/SingleTreeView/Dialogs/SimpleVertexSelectionDialog';
import EventTreeTransferGateSelectionDialog from 'hcl-web-editor/app/components/Model/SingleHCLModelView/SingleTreeView/Dialogs/EventTreeTransferGateSelectionDialog';
import EventTreeTransferGateVertex
  from 'hcl-web-editor/app/mxGraph/HCLTreeNode/EventTreeNode/EventTreeTransferGateNode/EventTreeTransferGateVertex';
import FunctionalEventSelectionDialog from 'hcl-web-editor/app/components/Model/SingleHCLModelView/SingleTreeView/Dialogs/FunctionalEventSelectionDialog';
import FunctionalEventVertex
  from 'hcl-web-editor/app/mxGraph/HCLTreeNode/EventTreeNode/FunctionalEventNode/FunctionalEventVertex';
import InitVertex from 'hcl-web-editor/app/mxGraph/HCLTreeNode/EventTreeNode/InitEvent/InitVertex';
import BayesianNetworkMxGraphDialog from 'hcl-web-editor/app/components/Model/SingleHCLModelView/SingleTreeView/Dialogs/BayesianNetworkMxGraphDialog';
import BayesianNetworkVertex from 'hcl-web-editor/app/mxGraph/HCLTreeNode/BayesianNetworkNode/BayesianNetworkVertex';

import { HCLTreeMxGraphViewJSON } from 'hcl-web-editor/app/components/Model/SingleHCLModelView/SingleTreeView/MxGraphViews/HCLTreeMxGraphView';

import ATLEASTGateVertex
  from 'hcl-web-editor/app/mxGraph/HCLTreeNode/FaultTreeNode/GateNode/ATLEASTGateNode/ATLEASTGateVertex';
import AtLeastGateSelectionDialog from 'hcl-web-editor/app/components/Model/SingleHCLModelView/SingleTreeView/Dialogs/AtLeastGateSelectionDialog';
import { HCLTreeMxGraphViewProps } from 'hcl-web-editor/app/components/Model/SingleHCLModelView/SingleTreeView/MxGraphViews/HCLTreeMxGraphViewProps';
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import CommonCauseDialog from "hcl-web-editor/app/components/Generic/CommonCauseDialog";
import ReferenceTypes from "hcl-web-editor/app/mxGraph/HCLTreeNode/HCLTreeVertexValue/ReferenceTypes";
import GenericSnackbar, {SEVERITY} from "hcla-web-frontend-primitives/app/components/primitives/Snackbar/GenericSnackbar";
import BayesianEstimationDialog from "hcl-web-editor/app/components/Generic/BayesianEstimationDialog";
import ProxyTypes from "hcl-web-editor/app/mxGraph/HCLTreeNode/HCLTreeVertexValue/ProxyTypes";
import BasicEventVertexValue
  from "hcl-web-editor/app/mxGraph/HCLTreeNode/FaultTreeNode/BasicEventNode/BasicEventVertexValue";
const _ = require('lodash');

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexGrow: 1,
    color: theme.palette.text.primary,
    fontFamily: 'Roboto Condensed',
  },

  graphRoot: {
    width: '100%',
    height: '100%',
    color: theme.palette.text.primary,
    fontFamily: 'Roboto Condensed',
    outline: 'none',
    '&:active': {
      cursor: 'grabbing',
    },
  },
});

interface Props extends WithStyles<typeof styles>, HCLTreeMxGraphViewProps {
  data: HCLTreeMxGraphViewJSON | FaultTreeMxGraphViewJSON | EventTreeMxGraphViewJSON | BayesianNetworkMxGraphViewJSON;
  modelId: number;
  updateTreeData: () => void;
}

interface State {
  dialog: JSX.Element;
  saving: boolean;
  trashDisabled: boolean;
  dateModified: string;
  cloneDisabled: boolean;
  contextMenu: {
    x: number | null,
    y: number | null
  };
  selectedCells: any[],
  rightClickBasicEvent: boolean,
  snackBar: {
    show: boolean;
    message: string
  }
}

/**
 * You can edit the graph
 */
class HCLTreeMxGraphEditorView extends React.Component<Props, State> {
  private static GRAPH_OFFSET_X = 100000;

  private static GRAPH_OFFSET_Y = 100000;

  private graph: HCLTreeMxGraph;

  private graphContainer: React.RefObject<HTMLScriptElement | HTMLDivElement>; // div ref to mount graph onto

  private keyHandler: mxKeyHandler;

  private rootRef: React.RefObject<HTMLScriptElement | HTMLDivElement>;

  /**
   * @param {Props} props
   * @throws Error if tree name is not one of {@link TreeNames}
   */
  constructor(props: Props) {
    super(props);

    const { data, provider, modelId } = this.props;

    this.graphContainer = React.createRef();
    this.rootRef = React.createRef();
    this.keyHandler = null;
    // @ts-ignore
    console.log('****data****', data)
    // data.tree_data.initial_state.outcome = undefined
    this.graph = this.instantiateGraph(data.tree_type as TreeTypes);
    console.log('****graph****', this.graph)
    this.graph.setPreferencesProvider(provider);
    console.log('****graph****', this.graph)

    this.state = {
      dialog: null,
      saving: false,
      trashDisabled: true,
      dateModified: data.date_modified,
      cloneDisabled: true,
      contextMenu: {
        x: null,
        y: null
      },
      selectedCells: [],
      rightClickBasicEvent: false,
      snackBar: {
        show: false,
        message: "N/A"
      }
    };
  }

  private instantiateGraph(treeType: TreeTypes): FaultTreeMxGraph | EventTreeMxGraph | BayesianNetworkMxGraph {
    switch (treeType) {
      case TreeTypes.FAULT_TREE:
        return new FaultTreeMxGraph(this.onGraphMounted, this.onGraphUnmounted);
      case TreeTypes.EVENT_TREE:
        return new EventTreeMxGraph(this.onGraphMounted, this.onGraphUnmounted);
      case TreeTypes.BAYESIAN_NETWORK:
        return new BayesianNetworkMxGraph(this.onGraphMounted, this.onGraphUnmounted);
      default:
        throw new Error(`Fatal Error: Tree Name ${treeType} is not supported`);
    }
  }

  private addEventListeners(treeType: TreeTypes) {
    const graphFireMouseEvent = this.graph.fireMouseEvent;
    this.graph.fireMouseEvent = function(evtName, me, sender) {
      if (evtName == mxEvent.MOUSE_DOWN) this.container.focus();
      graphFireMouseEvent.apply(this, arguments);
    };

    this.graph.getSelectionModel().addListener(mxEvent.CHANGE, (sender: any, event: mxEventObject) => {
      const changeTargetAdded = event.getProperty('added') || [];
      const changeTargetRemoved = event.getProperty('removed') || [];
      this.updateTrashIconState();
      this.updateCopyIconState();
    });

    this.graph.getModel().addListener(mxEvent.NOTIFY, (sender: any, event: mxEventObject) => {
      this.saveTree();
    });

    switch (treeType) {
      case TreeTypes.FAULT_TREE:
        this.addEventListenersForFaultTree();
        break;
      case TreeTypes.EVENT_TREE:
        this.addEventListenersForEventTree();
        break;
      case TreeTypes.BAYESIAN_NETWORK:
        this.addEventListenersForBayesianNetwork();
        break;
      default:
        throw new Error(`Fatal Error: Tree Name ${treeType} is not supported`);
    }
  }

  private addEventListenersForFaultTree() {
    this.graph.addListener(mxEvent.CLICK, (sender: any, event: mxEventObject) => {
      const targetCell = event.getProperty('cell');
      const isRightClick = mxEvent.isRightMouseButton(event.properties.event);
      if (this.state.selectedCells.length !== 0 && targetCell instanceof BasicEventVertex && isRightClick) {
        this.setState({ rightClickBasicEvent: true })
      } else {
        this.setState({ rightClickBasicEvent: false })
      }
    });
    this.graph.addListener(mxEvent.DOUBLE_CLICK, (sender: any, event: mxEventObject) => {
      const targetCell = event.getProperty('cell');
      if (targetCell instanceof ATLEASTGateVertex) {
        this.setState({
          dialog:
            <AtLeastGateSelectionDialog
              vertex={targetCell}
              onClose={this.handleDialogClose}
              onSave={this.saveTree}
            />,
        });
      } else if (targetCell instanceof GateVertex) {
        this.setState({
          dialog:
            // <LogicGateSelectionDialog
            //   vertex={targetCell}
            //   onClose={this.handleDialogClose}
            //   onSave={this.saveTree}
            // />
            null,
        });
      } else if (targetCell instanceof BasicEventVertex) {
        this.setState({
          dialog:
            <BasicEventSelectionDialog
              vertex={targetCell}
              onClose={this.handleDialogClose}
              onSave={this.saveTree}
            />,
        });
      } else if (targetCell instanceof HouseEventVertex) {
        this.setState({
          dialog:
            <HouseEventSelectionDialog
              vertex={targetCell}
              onClose={this.handleDialogClose}
              onSave={this.saveTree}
            />,
        });
      } else if (targetCell instanceof FaultTreeTransferGateVertex) {
        this.setState({
          dialog:
            <FaultTreeTransferGateSelectionDialog
              vertex={targetCell}
              onClose={this.handleDialogClose}
              onSave={this.saveTree}
            />,
        });
      }

      event.consume();
    });
  }

  private addEventListenersForEventTree() {
    this.graph.addListener(mxEvent.DOUBLE_CLICK, (sender: any, event: mxEventObject) => {
      const targetCell = event.getProperty('cell');
      if (targetCell instanceof EndStateVertex) {
        this.setState({
          dialog:
            <SimpleVertexSelectionDialog
              vertex={targetCell}
              onClose={this.handleDialogClose}
              onSave={this.saveTree}
            />,
        });
      } else if (targetCell instanceof EventTreeTransferGateVertex) {
        this.setState({
          dialog:
            <EventTreeTransferGateSelectionDialog
              vertex={targetCell}
              onClose={this.handleDialogClose}
              onSave={this.saveTree}
            />,
        });
      } 
      /*else if (targetCell instanceof FunctionalEventVertex) {
        this.setState({
          dialog:
            <FunctionalEventSelectionDialog
              vertex={targetCell}
              onClose={this.handleDialogClose}
              onSave={this.saveTree}
            />,
        });
      } */
      else if (targetCell instanceof InitVertex) {
        this.setState({
          dialog:
            <SimpleVertexSelectionDialog
              vertex={targetCell}
              onClose={this.handleDialogClose}
              onSave={this.saveTree}
            />,
        });
      }
    });
  }

  private addEventListenersForBayesianNetwork() {
    this.graph.addListener(mxEvent.DOUBLE_CLICK, (sender: any, event: mxEventObject) => {
      const targetCell = event.getProperty('cell');
      if (targetCell instanceof BayesianNetworkVertex) {
        this.setState({
          dialog:
            <BayesianNetworkMxGraphDialog
              targetCell={targetCell}
              // @ts-ignore
              graph={this.graph as BayesianNetworkMxGraph}
              onClose={this.handleDialogClose}
              onSave={this.saveTree}
            />,
        });
      }
    });
  }

  private updateTrashIconState() {
    const cells = this.graph.getSelectionCells();
    this.setState({ trashDisabled: cells.length === 0 });
  }

  private saveTree = (e?: any, json?: FaultTreeMxGraphJSON | EventTreeMxGraphJSON | BayesianNetworkMxGraphJSON) => {
    const { modelId, data, updateTreeData } = this.props;
    if (this.graph.isLockEnabled()) {
      return;
    }

    this.graph.setLockEnabled(true);
    this.setState({ saving: true }, () => {
      try {
        console.log('****json****', json)
        console.log('****graph****', this.graph)
        console.log('****graph****', this.graph.toJSON())
        PhoenixApiManager.patchHclTreeDataOnlyJson(modelId, json ? json : this.graph.toJSON() as FaultTreeMxGraphJSON | EventTreeMxGraphJSON | BayesianNetworkMxGraphJSON)
          /*.then((res: any) => {
            return res.json();
          })*/
          .then((data: FaultTreeMxGraphViewJSON | EventTreeMxGraphViewJSON | BayesianNetworkMxGraphViewJSON) => {
            this.setState({ saving: false, dateModified: data.date_modified }, () => {
              this.graph.setLockEnabled(false);
              if(json) {
                this.handleDialogClose();
                updateTreeData();
              }
            });
          });
      } catch (e) {
        this.graph.setLockEnabled(false);
        this.setState({ saving: false });
        console.error(e);
      }
    });
  };

  private handleDialogClose = () => {
    this.setState({ dialog: null });
  };

  private bindKeyHandler = () => {
    this.keyHandler = new mxKeyHandler(this.graph);
    HCLTreeMxGraphKeyHandler.bindDeleteKey(this.keyHandler);
    HCLTreeMxGraphKeyHandler.bindEscKey(this.keyHandler);
    HCLTreeMxGraphKeyHandler.bindCKey(this.keyHandler);
    HCLTreeMxGraphKeyHandler.bindVKey(this.keyHandler);
    HCLTreeMxGraphKeyHandler.bindXKey(this.keyHandler);
    HCLTreeMxGraphKeyHandler.bindAKey(this.keyHandler);
    HCLTreeMxGraphKeyHandler.allowCTRLAndCMD(this.keyHandler);
  };

  private onGraphMounted = () => {
    const { data } = this.props;
    this.graph.populateFromJSON(data.tree_data);
    this.graph.setCellsEditable(false);
    this.graph.getRubberband().setEnabled(true);
    this.graph.applyDefaultLayout();
    this.graph.setPanning(true);
  };

  private onGraphUnmounted = () => { };

  private setGraphOffsetWithinView = (dx: number, dy: number) => {
    this.graph.getVertices().forEach((vertex) => {
      const geometry = vertex.getGeometry();
      if (geometry.relative) return;
      geometry.x += dx;
      geometry.y += dy;
    });

    this.graph.view.setTranslate(-dx + this.rootRef.current.offsetWidth / 2 - this.graph.getGraphBounds().width / 2, -dy + 50); // center x
    this.graph.view.refresh();
  };

  private updateCopyIconState = () => {
    const cell = this.graph.getSelectionCell();
    this.setState({ cloneDisabled: cell === undefined || cell.edge });
  };

  componentDidMount(): void {
    const { data } = this.props;
    this.graph.mount(this.graphContainer.current as HTMLScriptElement);
    this.bindKeyHandler();
    this.addEventListeners(data.tree_type as TreeTypes);
    if (data.tree_type !== TreeTypes.EVENT_TREE) {
      this.setGraphOffsetWithinView(HCLTreeMxGraphEditorView.GRAPH_OFFSET_X, HCLTreeMxGraphEditorView.GRAPH_OFFSET_Y);
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    const { data } = this.props;
    const { selectedCells, contextMenu } = this.state;
    const { selectionModel: { cells } } = this.graph;

    // From Graph
    const graphSelectedCells = [...cells].filter((cell: any) => cell.refType && cell.refType === ReferenceTypes.BASIC_EVENTS);
    const graphSelectedCellKeys = graphSelectedCells.map((cell: any) => cell.key);

    // From State
    const stateSelectedCellKeys = [...selectedCells].map((cell: any) => cell.key);

    const selectionIsSame = (graphSelectedCellKeys.length === stateSelectedCellKeys.length) && !graphSelectedCellKeys.some((element, i) => element !== stateSelectedCellKeys[i]);

    if (!selectionIsSame) {
      this.setState({ selectedCells: [...graphSelectedCells] });
    }

    if (data !== prevProps.data) {
      this.graph.destroy();
      this.graph = this.instantiateGraph(data.tree_type as TreeTypes);
      this.graph.mount(this.graphContainer.current as HTMLScriptElement);
      this.bindKeyHandler();
      this.addEventListeners(data.tree_type as TreeTypes);
      if (data.tree_type !== TreeTypes.EVENT_TREE) {
        this.setGraphOffsetWithinView(HCLTreeMxGraphEditorView.GRAPH_OFFSET_X, HCLTreeMxGraphEditorView.GRAPH_OFFSET_Y);
      }
    }
  }

  componentWillUnmount(): void {
    this.graph.unmount();
  }

  handleOpenContext = (event) => {
    const { selectedCells } = this.state;
    event.stopPropagation();
    event.preventDefault();
    const areSameExpression = !selectedCells.some((cell) => !_.isEqualWith(cell.value.getExpression().toExtendedJSON(), selectedCells[0].value.getExpression().toExtendedJSON()));
    if (selectedCells.length === 1 && !this.isBayesianEstimationCompatible()) {
      return this.setState({
        snackBar: {
          show: true,
          message: "Distribution parameters not supported for Bayesian Updating"
        }
      })
    } else if (selectedCells.length > 10) {
      return this.setState({
        snackBar: {
          show: true,
          message: "Common Cause selection must be between 2 and 10"
        }
      })
    } else if (!areSameExpression) {
      return this.setState({
        snackBar: {
          show: true,
          message: "Common Cause events are of incompatible type."
        }
      })
    } else {
      this.setState({
        contextMenu: {
          x: event.clientX - 2,
          y: event.clientY -4
        }
      })
    }
  }

  handleCloseContext = () => {
    this.setState({
      contextMenu: {
        x: null,
        y: null
      }
    })
  }

  isBayesianEstimationCompatible = () => {
    const { selectedCells } = this.state;
    if (selectedCells.length !== 1) return false;

    const cell = selectedCells[0];

    const value = cell.value as BasicEventVertexValue;
    if (value.getExpression().getProxy() === ProxyTypes.LOG_NORMAL_DISTRIBUTION || value.getExpression().getProxy() === ProxyTypes.NORMAL_DISTRIBUTION){
      return !(value.getExpression().getProxy() === ProxyTypes.NORMAL_DISTRIBUTION && value.getExpression().toExtendedJSON().normal_params === "median & error factor") && !(value.getExpression().getProxy() === ProxyTypes.LOG_NORMAL_DISTRIBUTION && value.getExpression().toExtendedJSON().log_normal_params === "mean & std");
    }
  }

  isCommonCauseCompatible = () => {
    const { selectedCells } = this.state;
    return !(selectedCells.length < 2 || selectedCells.length > 10);
  }

  shouldOpenContextMenu = () => {
    const { contextMenu, rightClickBasicEvent } = this.state;
    return rightClickBasicEvent && contextMenu.x && contextMenu.y && (this.isBayesianEstimationCompatible() || this.isCommonCauseCompatible())
  }

  render() {
    const { classes, data, add, hide, setMaxHeight } = this.props;
    const {
      dialog, trashDisabled, saving, cloneDisabled, contextMenu, selectedCells, snackBar, rightClickBasicEvent
    } = this.state;
    return (
      <div className={classes.root} ref={this.rootRef as React.RefObject<HTMLDivElement>} style={setMaxHeight ? { height: `calc(100vh - 150px)`} : {}}>
        <Menu
          keepMounted
          open={this.shouldOpenContextMenu()}
          onClose={this.handleCloseContext}
          anchorReference="anchorPosition"
          anchorPosition={this.shouldOpenContextMenu() ? { top: contextMenu.y, left: contextMenu.x } : undefined}
        >
          { this.isBayesianEstimationCompatible() ? (
            <MenuItem
              onClick={() => this.setState({
                dialog: (
                  <BayesianEstimationDialog
                    data={selectedCells[0]}
                    treeData={data.tree_data}
                    save={this.saveTree}
                    close={() => this.setState({ dialog: null })}
                  />
                )
              }, () => this.handleCloseContext())}>
              Perform Bayesian Updating
            </MenuItem>
          ) : this.isCommonCauseCompatible() ? (
            <MenuItem
              onClick={() => this.setState({
                dialog: (
                  <CommonCauseDialog
                    selectedCells={selectedCells}
                    save={this.saveTree}
                    close={() => this.setState({ dialog: null })}
                  />
                )
              }, () => this.handleCloseContext())}>
              Set as Common Cause
            </MenuItem>
          ) : null
          }
        </Menu>
        <Editor
          graph={this.graph}
          data={data}
          saving={saving}
          trashDisabled={trashDisabled}
          handleSave={this.saveTree}
          cloneDisabled={cloneDisabled}
          add={add}
          hide={hide}
        >
          <div onContextMenu={this.handleOpenContext} className={classes.graphRoot} ref={this.graphContainer as React.RefObject<HTMLDivElement>} tabIndex={0} />
        </Editor>
        <GenericSnackbar
          showSnackBar={snackBar.show}
          onClose={() => this.setState({ snackBar: { show: false, message: "N/A" } })}
          message={snackBar.message}
          severity={SEVERITY.ERROR}
        />
        {dialog}
      </div>
    );
  }
}

export default withStyles(styles)(withPreferences(HCLTreeMxGraphEditorView));
