import React from 'react';
import {
  Theme, WithStyles, withStyles, createStyles,
} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { fade } from '@material-ui/core/styles/colorManipulator';

import { withPreferences, UserPreferencesContextProps } from 'hcla-web-frontend-primitives/app/config/PreferencesProvider';
import WaxPaper from 'hcla-web-frontend-primitives/app/components/primitives/Paper/WaxPaper/WaxPaper';
import ActionBar from './ActionBar';
import { TreeTypeDictionary, TreeTypes } from 'hcl-web-editor/app/components/Model/SingleHCLModelView/SingleTreeView/Tools/TreeDictionaries';
import EditorLeftToolbox from './EditorLeftToolbox';
import StatusBar from 'hcl-web-editor/app/components/Model/SingleHCLModelView/SingleTreeView/Tools/StatusBar';
import { HCLTreeMxGraphViewJSON } from 'hcl-web-editor/app/components/Model/SingleHCLModelView/SingleTreeView/MxGraphViews/HCLTreeMxGraphView';
import HCLTreeMxGraph from 'hcl-web-editor/app/mxGraph/HCLTreeMxGraph/HCLTreeMxGraph';
import { FullScreenConsumer, FullScreenContext } from 'hcl-web-editor/app/components/Primitives/FullScreenProvider';
import TransferGatesToolbox from 'hcl-web-editor/app/components/Model/SingleHCLModelView/SingleTreeView/Tools/TransferGatesToolbox';

const styles = (theme: Theme) => createStyles({
  root: {
    flexGrow: 1,
    border: '1px solid #838796',
    position: 'relative',
    borderRadius: 'unset',
  },
  treeContainer: {
    overflow: 'auto',
    zIndex: 10,
    height: '100%',
    width: '100%',
    position: 'absolute',
  },
  leftContainer: {
    borderRight: '1px solid #838796',
    padding: theme.spacing(1.5),
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'column',
    zIndex: 11,
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  actionBarContainer: {
    zIndex: 12,
    width: '100%',
    overflow: 'unset',
  },
  statusContainer: {
    zIndex: 11,
    width: '100%',
  },
  overlayContainer: {
    zIndex: 11,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  outlineContainer: {
    position: 'fixed',
    bottom: '60px',
    right: '0px',
    width: '21em',
    height: '13em',
    border: `2px solid ${fade(theme.palette.secondary.dark, 0.5)}`,
    borderRight: 'unset',
    padding: theme.spacing(0.5),
    zIndex: 1200,
  },
  toolContainer: {
    borderRadius: '64px',
    background: theme.palette.type === 'light' ? 'rgba(0,0,0,0.10)' : 'rgba(255,255,255,0.10)',
    border: '1px solid rgba(20,20,20,0.10)',
    marginBlockStart: `${theme.spacing(2)}px`,
  },
});

interface Props extends WithStyles<typeof styles>, UserPreferencesContextProps {
  graph: HCLTreeMxGraph;
  data: HCLTreeMxGraphViewJSON;
  saving: boolean;
  trashDisabled: boolean;
  handleSave: () => void;
  cloneDisabled: boolean;
  children: any;
}

class Editor extends React.Component<Props> {
  private readonly graphOutlineContainer: React.RefObject<HTMLScriptElement | HTMLDivElement>; // div ref to mount graph outline onto

  constructor(props: Props) {
    super(props);
    this.graphOutlineContainer = React.createRef();
    props.graph.setOutlineContainer(this.graphOutlineContainer);
  }

  render() {
    const {
      children,
      saving,
      handleSave,
      trashDisabled,
      graph,
      data,
      classes,
      cloneDisabled,
      add,
      hide
    } = this.props;

    return (
      <div style={{ display: 'flex', flexGrow: 1 }}>
        {/* <WaxPaper elevation={4}>
          <div className={classes.outlineContainer} ref={this.graphOutlineContainer as React.RefObject<HTMLDivElement>} hidden />
        </WaxPaper> */}
        <Paper className={classes.root}>
          <div className={classes.treeContainer}>
            {children}
          </div>
          <div className={classes.overlayContainer}>
            <WaxPaper elevation={0}>
              <div className={classes.actionBarContainer}>
                <ActionBar
                  graph={graph}
                  quantifyDisabled={saving}
                  trashDisabled={trashDisabled}
                  modelId={data.model.id}
                  treeId={data.id}
                  treeType={TreeTypeDictionary[data.tree_type as TreeTypes]}
                  handleSave={handleSave}
                  cloneDisabled={cloneDisabled}
                  hide={hide}
                />
              </div>
            </WaxPaper>
            <WaxPaper elevation={0} style={{ flexGrow: 1 }}>
              <FullScreenConsumer>
                {({ isFull }) => (isFull
                  ? (
                    <div className={classes.leftContainer}>
                      <div className={classes.toolContainer}>
                        <EditorLeftToolbox
                          graph={graph}
                          treeName={TreeTypeDictionary[data.tree_type as TreeTypes]}
                          saving={saving}
                          add={add}
                          hide={hide}
                        />
                      </div>
                      <div className={classes.toolContainer}>
                        <TransferGatesToolbox
                          graph={graph}
                          treeName={TreeTypeDictionary[data.tree_type as TreeTypes]}
                          saving={saving}
                        />
                      </div>
                    </div>
                  )
                  : (
                    <div className={classes.leftContainer}>
                      <div className={classes.toolContainer}>
                        <EditorLeftToolbox
                          graph={graph}
                          treeName={TreeTypeDictionary[data.tree_type as TreeTypes]}
                          saving={saving}
                          add={add}
                          hide={hide}
                        />
                      </div>
                    </div>
                  ))
                }
              </FullScreenConsumer>
            </WaxPaper>
            <WaxPaper elevation={0}>
              <div className={classes.statusContainer}>
                <StatusBar
                  graph={graph}
                  dateModified={data.date_modified}
                  saving={saving}
                />
              </div>
            </WaxPaper>
          </div>
        </Paper>
      </div>
    );
  }
}

Editor.contextType = FullScreenContext;

export default withStyles(styles)(withPreferences(Editor));
