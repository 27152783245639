import React from 'react';
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from "@material-ui/core/IconButton";
import ListItemIcon from '@material-ui/core/ListItemIcon';



interface Props {
	item: {
		id: number,
		title: string,
		description: string,
	},
	remove: (id: number) => void,
}
const CriticalFunctionEntry = (props: Props) => {
const { item, remove } = props;

	return (
		<ListItem divider>
			<ListItemText
				primary={<Typography variant={"body1"}>{item.title}</Typography>}
				secondary={<Typography variant={"body2"}>{item.description}</Typography>}
			/>
			<ListItemIcon>
				<IconButton onClick={() => remove(item.id)}><ClearIcon /></IconButton>
			</ListItemIcon>
		</ListItem>
	);
};

export default CriticalFunctionEntry;