import React, { Fragment, useMemo, useCallback } from "react";
import ReactCrop, { makeAspectCrop } from "react-image-crop";
import { withStyles, withTheme } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import "react-image-crop/dist/ReactCrop.css";
import Dropzone from "react-dropzone";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import PhoenixApiManager from "../../../../Api/PhoenixApiManager";
import EditIcon from "@material-ui/icons/Edit";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import { injectIntl } from "react-intl";

var classNames = require("classnames");

const styles = theme => ({
  root: {
    marginBottom: theme.spacing(2)
  },
  container: {
    position: "relative"
  },
  cursorPointer: {
    cursor: "pointer"
  },
  image: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%"
  },

  dropbox: {
    border: 'unset',
  },
  button: {
    position: "absolute",
    bottom: 16,
    right: 16,
    border: 'unset',
    //color: "rgb(248, 248, 242)",
    //borderColor: "rgb(248, 248, 242)",
    //backgroundColor: "rgb(39, 40, 35)"
  },

  fadeOut: {
    visibility: "hidden",
    opacity: 0,
    transition: `visibility 0s linear 300ms, opacity 300ms`
  },

  fadeIn: {
    visibility: "visible",
    opacity: 1,
    transition: `visibility 0s linear 0s, opacity 300ms`
  },

  iconSmall: {
    fontSize: 18
  }
});

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "rgba(0,0,0,0.2)",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out"
};

const activeStyle = {
  borderColor: "#2196f3"
};

const acceptStyle = {
  borderColor: "#00e676"
};

const rejectStyle = {
  borderColor: "#ff1744"
};

class ImageUploader extends React.Component {
  imageRef;

  state = {
    imageData: this.props.imageData,
    src: null,
    crop: {
      unit: "%",
      x: 0,
      y: 0,
      width: 100,
      height: 100
    },
    isFileSizeAlertOpen: false,
    isMouseOverImage: false
  };

  onImageLoaded = image => {
    this.imageRef = image;
  };

  onSelectFile = e => {
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      () => this.setDialogState(reader.result),
      false
    );
    if (e.target.files[0].size < 10000000) {
      reader.readAsDataURL(e.target.files[0]);
    } else {
      this.setState({ isFileSizeAlertOpen: true });
    }
  };

  onCropComplete = (crop, percentCrop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    this.setState({ crop });
  };

  onDialogClose = () => {
    this.setDialogState(null);
  };

  setDialogState = state => {
    const { onDialogToggle } = this.props;
    this.setState({ src: state }, () => onDialogToggle && onDialogToggle(state != null));
  };

  onSubmit = () => {
    const { id, onImageSet } = this.props;
    const croppedImageData = this.getCroppedImg(
      this.imageRef,
      this.state.crop,
    );
    PhoenixApiManager.patchMainModel(
      id,
      JSON.stringify({
        pra_tree: croppedImageData
      })
    ).then(response => {
      if (response.status == 200) {
        this.setState({ imageData: croppedImageData }, () => {
          onImageSet(croppedImageData);
          this.setDialogState(null);
        });
      }
    });
  };

  onFileSizeAlertClose = () => {
    this.setState({ isFileSizeAlertOpen: false });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
      );
      this.setState({ croppedImageUrl });
    }
  }

  getCroppedImg(image, crop) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return canvas.toDataURL("image/jpeg"); // base 64
  }

  isBase64(str) {
    return str.startsWith("data:image/jpeg;base64");
  }

  onMouseOverImage = () => {
    this.setState({ isMouseOverImage: true });
  };

  onMouseOutImage = () => {
    this.setState({ isMouseOverImage: false });
  };

  render() {
    const { classes, intl } = this.props;
    const dialog = (
      <Dialog open={this.state.src !== null} onClose={this.onDialogClose}>
        <DialogTitle>Crop your new overview image</DialogTitle>
        <DialogContent>
          {
            this.state.src &&
            <ReactCrop
              src={this.state.src}
              crop={this.state.crop}
              onImageLoaded={this.onImageLoaded}
              onComplete={this.onCropComplete}
              onChange={this.onCropChange}
              // imageStyle={{ width: 420 }}
              keepSelection
            />
          }
          <div><br/></div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.onSubmit} color="primary" autoFocus>
            Set new overview image
          </Button>
        </DialogActions>
      </Dialog>
    );

    const alert = (
      <Dialog
        open={this.state.isFileSizeAlertOpen}
        onClose={this.onFileSizeAlertClose}
      >
        <DialogTitle>{intl.formatMessage({ id: 'imageUploader.errorTitle' })}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {intl.formatMessage({ id: 'imageUploader.errorDesc' })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.onFileSizeAlertClose} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );

    const editButton = (
      <Button
        size="small"
        variant="outlined"
        className={classNames({
          [classes.button]: true,
          [classes.fadeIn]: this.state.isMouseOverImage,
          [classes.fadeOut]: !this.state.isMouseOverImage
        })}
        onMouseOver={this.onMouseOverImage}
        onMouseOut={this.onMouseOutImage}
        component="label"
        id="edit"
      >
        Edit
        <EditIcon className={classes.iconSmall} />
        <input
          type="file"
          onChange={this.onSelectFile}
          onClick={event => (event.target.value = null)}
          accept="image/*"
          hidden
        />
      </Button>
    );

    const dropzone = (
      <Dropzone
        onDrop={acceptedFiles => {
          const reader = new FileReader();
          reader.addEventListener(
            "load",
            () => this.setDialogState(reader.result),
            false
          );

          if (acceptedFiles[0].size < 10000000) {
            reader.readAsDataURL(acceptedFiles[0]);
          } else {
            this.setState({ isFileSizeAlertOpen: true });
          }
        }}
      >
        {({
          getRootProps,
          getInputProps,
          isDragActive,
          isDragAccept,
          isDragReject
        }) => {
          const style = useMemo(
            () => ({
              ...baseStyle,
              ...(isDragActive ? activeStyle : {}),
              ...(isDragAccept ? acceptStyle : {}),
              ...(isDragReject ? rejectStyle : {})
            }),
            [isDragActive, isDragReject]
          );

          return (
            <Card>
              <section className={classes.cursorPointer}>
                <div {...getRootProps({ style })}>
                  <input {...getInputProps()} accept="image/*" className={classes.dropbox} />
                  <Typography>{intl.formatMessage({ id: 'imageUploader.desc' })}</Typography>
                </div>
              </section>
            </Card>
          );
        }}
      </Dropzone>
    );

    const imageContentWithContainer =
      this.state.imageData && this.isBase64(this.state.imageData) ? (
        <Paper className={classes.container}>
          <img className={classes.image} src={this.state.imageData} />
          {editButton}
        </Paper>
      ) : (
          <React.Fragment>{dropzone}</React.Fragment>
        );

    const imageContentWithoutContainer =
      this.state.imageData && this.isBase64(this.state.imageData) ? (
        <React.Fragment>
          <img
            onMouseOver={this.onMouseOverImage}
            onMouseOut={this.onMouseOutImage}
            className={classes.image}
            src={this.state.imageData}
            draggable={false}
          />
          {editButton}
        </React.Fragment>
      ) : (
          <React.Fragment>{dropzone}</React.Fragment>
        );

    const image = this.props.withContainer ? (
      <Grid container className={classes.root}>
        <Grid
          item
          xs={12}
          onMouseOver={this.onMouseOverImage}
          onMouseOut={this.onMouseOutImage}
        >
          {imageContentWithContainer}
        </Grid>
      </Grid>
    ) : (
        <React.Fragment>{imageContentWithoutContainer}</React.Fragment>
      );

    return (
      <React.Fragment>
        {!this.state.src && image}
        {this.state.src && dialog}
        {alert}
      </React.Fragment>
    );
  }
}

ImageUploader.propTypes = {
  id: PropTypes.number.isRequired,
  imageData: PropTypes.string,
  withContainer: PropTypes.bool,
  onImageSet: PropTypes.func,
  onDialogToggle: PropTypes.func
};

export default withStyles(styles)(injectIntl(ImageUploader));
