import BasicEventVertex from "hcl-web-editor/app/mxGraph/HCLTreeNode/FaultTreeNode/BasicEventNode/BasicEventVertex";
import HumanFailureEventTraveler from "../HumanFailureEventTraveler";
import React, {useState} from "react";
import {useIntl} from "react-intl";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import FormControl from "@material-ui/core/FormControl";
import ProbabilityInput
	from "hcl-web-editor/app/components/Model/SingleHCLModelView/SingleTreeView/Inputs/ProbabilityInput";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import InputRules from "hcl-web-editor/app/components/Model/SingleHCLModelView/SingleTreeView/Inputs/InputRules";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Theme} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
	form: {
		display: 'flex',
		flexDirection: 'column',
		margin: 'auto',
		width: 'fit-content',
	},
	formControl: {
		marginTop: theme.spacing(1) * 2,
		minWidth: 120,
	},
}));

type BasicEventEditingDialogProps = {
	targetBasicEvent: BasicEventVertex
	setTargetBasicEvent: (basicEvent: BasicEventVertex) => void,
	traveler: HumanFailureEventTraveler
}

const BasicEventEditingDialog = (props: BasicEventEditingDialogProps) => {

	const { targetBasicEvent, setTargetBasicEvent, traveler } = props;

	if (!targetBasicEvent) return <React.Fragment />;

	const intl = useIntl();
	const classes = useStyles();

	const [probability, setProbability] = useState(targetBasicEvent.getValue().getExpression().getValue());

	return (
		<Dialog
		open={!Boolean(targetBasicEvent.getKey().includes("CFM") || targetBasicEvent.getKey().split(" ").length == 1) }
		onClose={() => setTargetBasicEvent(null)}
		>
			<DialogTitle>{targetBasicEvent.getValue().label.name}</DialogTitle>
			<DialogContent>
				<DialogContentText>
					{intl.formatMessage({ id: 'FaultTreeMxGraphSelectionView.BasicEventSelectionDialog.description' })}
				</DialogContentText>
				<div className={classes.form}>
					<FormControl className={classes.formControl}>
						<ProbabilityInput
							value={probability}
							onChange={e => setProbability(e.target.value)}
						/>
					</FormControl>
				</div>
			</DialogContent>
			<DialogActions>
				<Button
					fullWidth
					variant="contained"
					color="primary"
					disabled={!InputRules.isProbabilityValid(probability)}
					onClick={() => {
						setTargetBasicEvent(null);
						traveler.setBasicEvent(targetBasicEvent.getKey(), { failure_probability: probability });
					}}
				>
					{intl.formatMessage({ id: 'FaultTreeMxGraphSelectionView.BasicEventSelectionDialog.action' })}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default BasicEventEditingDialog;