import React from 'react';
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Theme} from "@material-ui/core/styles";
import {useIntl} from "react-intl";
import {Card} from "@material-ui/core";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";

interface Props {
	basicEvents: any,
	isHFEEmpty: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
	expansionPanelExpanded: {
		margin: '0 !important',
		minHeight: '100%'
	},
	chipRoot: {
		marginRight: theme.spacing(1),
		marginBottom: theme.spacing(1)
	},
}));

const SelectedBasicEvents = (props: Props) => {
	const { basicEvents, isHFEEmpty } = props;

	const classes = useStyles();
	const intl = useIntl();

	var onlyCFMS = true;
	Object.keys(basicEvents).map(key => {
		if (!key.includes("CFM -")) { // determine if there are any events which are basic/not cfms
			onlyCFMS = false;
		}
	})
	let content: any;

	if (!isHFEEmpty && Object.keys(basicEvents).length !== 0 && !onlyCFMS) { // if contain non-cfm events, then do this
		content = (
			<Grid item xs={12}>
				{ Object.keys(basicEvents).map(key => {
					const label = basicEvents[key].label ? basicEvents[key].label.name : key;
					if (!key.includes("CFM -")) {
						return (
							<Chip className={classes.chipRoot} label={label} />
						);
					}
				}) }
			</Grid>
		)
	} else {
		content = (
			<Grid item xs={12}>
				<Typography variant={"caption"}>{intl.formatMessage({ id: 'noSelection.basicEvents' })}</Typography>
			</Grid>
		)
	}
	return (
		<ExpansionPanel component={Card} defaultExpanded classes={{ expanded: classes.expansionPanelExpanded }}>
			<ExpansionPanelSummary
				expandIcon={<ExpandMoreIcon />}
			>
				<Typography variant={"h5"}>{intl.formatMessage({ id: 'basicEvents' })}</Typography>
			</ExpansionPanelSummary>
			<ExpansionPanelDetails>
				<Grid container spacing={2}>
					{content}
				</Grid>
			</ExpansionPanelDetails>
		</ExpansionPanel>
	)
};

export default SelectedBasicEvents;