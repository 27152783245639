import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { injectIntl } from 'react-intl';

//@ts-ignore
import ViewNoMatch from 'hcla-web-frontend-primitives/app/components/primitives/Error/ViewNoMatch';

import { HFEType } from "../../../../Types";
import SinglePIFView from './SinglePIFView';

interface Props {
  match: { [key: string]: any }
  location: { [key: string]: any },
  depth: number,
  hfe: HFEType,
  intl: any
}

const PIFForCFMRouter = (props: Props) => {
  const {
    depth, match: {
      params: {
        hfeName, cfmName, pifName, id,cfid
      },
    },
    intl, hfe
  } = props;
  return (
    <React.Fragment>
      <BreadcrumbsItem to={`/model/${id}/main-analysis/${cfid}/hfe/${hfeName}/cfm`} depth={depth - 3}>CFMs</BreadcrumbsItem>
      <BreadcrumbsItem to={`/model/${id}/main-analysis/${cfid}/hfe/${hfeName}/cfm/${cfmName}`} depth={depth - 2}>
        {cfmName}
      </BreadcrumbsItem>
      <BreadcrumbsItem to={`/model/${id}/main-analysis/${cfid}/hfe/${hfeName}/cfm/${cfmName}/pif`} depth={depth - 1}>PIFs</BreadcrumbsItem>
      <BreadcrumbsItem to={`/model/${id}/main-analysis/${cfid}/hfe/${hfeName}/cfm/${cfmName}/pif/${pifName}`} depth={depth}>
        {pifName}
      </BreadcrumbsItem>
      <Switch>
        <Route
          exact
          path="/model/:id/main-analysis/:cfid/hfe/:hfeName/cfm/:cfmName/pif/:pifName/"
          render={prop => (
            <SinglePIFView
              {...prop}
              key={pifName}
              hfe={hfe}
              cfid={cfid}
            />
          )}
        />
        <Route
          render={prop => (
            <ViewNoMatch
              {...prop}
              message={intl.formatMessage({ id: '404.pageNotFound' })}
            />
          )}
        />
      </Switch>
    </React.Fragment>
  );
};

export default injectIntl(PIFForCFMRouter);
