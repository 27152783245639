import CustomGraphLayout from "./CustomGraphLayout";

const positions = {
  null: [0, 0],
  BP_A: [0, 1],
  BP_B: [0, 2],
  BP_C: [1, 4],
  BP_D: [0, 5],
  BP_E: [0, 6],
  BP_F: [0, 7],
  BP_G: [1, 8],
  BP_H: [1, 8],
  DP_1: [0, 3],
  DP_2: [0, 4],
  DP_3: [0, 8],
  DP_4: [1, 5],
  DP_5: [0, 7],
  DP_6: [0, 9],
  EF_1: [2, 1],
  EF_2: [2, 2],
  EF_3: [2, 4],
  EF_4: [2, 5],
  EF_5: [2, 6],
  EF_6: [2, 7],
  EF_7: [2, 9],
  ES_1: [2, 3],
  ES_2: [2, 8]
};

const edges = {
  "DP_1->BP_E": CustomGraphLayout.EDGE_STYLE.LEFT_DOWN_RIGHT_SHORT_WITH_JUMP,
  "DP_3->DP_2": CustomGraphLayout.EDGE_STYLE.LEFT_UP_RIGHT,
  "BP_C->ES_1": CustomGraphLayout.EDGE_STYLE.UP_RIGHT,
  "BP_G->EF_7": CustomGraphLayout.EDGE_STYLE.DOWN_RIGHT
};

class MaintenanceScenarioLayout extends CustomGraphLayout {
  constructor(graph) {
    super(graph, positions, edges);
  }
}

export default MaintenanceScenarioLayout;
