import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { injectIntl } from 'react-intl';

interface Props {
  title: string,
  content: any,
  open: boolean,
  onRequestClose: () => void,
  children: any,
  intl: { [key: string]: any }
}
class DependantPIFSelectionDialog extends React.Component<Props> {

  handleClose = () => {
    this.props.onRequestClose();
  };

  render() {
    const {
      open, content, intl, title, children,
    } = this.props;
    return (
      <Dialog
        open={open}
        maxWidth="xl"
        onClose={() => this.handleClose()}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          id="form-dialog-title"
        >
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {content}
          </DialogContentText>
          {children}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this.handleClose}
            color="primary"
            variant="outlined"
          >
            {intl.formatMessage({ id: 'cancel' })}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default injectIntl(DependantPIFSelectionDialog);
