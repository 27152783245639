import { mxConstants as MxConstants, mxGeometry as MxGeometry } from 'mxgraph-js';
import FunctionalEventVertex from 'hcl-web-editor/app/mxGraph/HCLTreeNode/EventTreeNode/FunctionalEventNode/FunctionalEventVertex';

class BranchPointVertex extends FunctionalEventVertex {
  static SHAPE = {
    WIDTH: 100,
    HEIGHT: 70,
  };

  static SCALING = 2.5;

  getMyGeometry() {
    const s = this.hasLabel() ? BranchPointVertex.SCALING : 1;
    return new MxGeometry(
      0,
      0,
      BranchPointVertex.SHAPE.WIDTH * s,
      BranchPointVertex.SHAPE.HEIGHT * s
    );
  }

  getMyStyle() {
    const style = {};
    style[MxConstants.STYLE_ROUNDED] = 1;
    style[MxConstants.STYLE_SPACING] = 18;
    style[MxConstants.STYLE_STROKEWIDTH] = '2';
    style[MxConstants.STYLE_FONTSIZE] = '12';
    style[MxConstants.STYLE_WHITE_SPACE] = 'wrap';
    style[MxConstants.STYLE_FONTCOLOR] = 'inherit';
    style[MxConstants.STYLE_SHAPE] = MxConstants.RECTANGLE_ROUNDING_FACTOR;
  style[MxConstants.STYLE_AUTOSIZE] = 1;
    style[MxConstants.STYLE_RESIZABLE] = 1;
    style[MxConstants.STYLE_ASPECT] = 'fixed';
    style[MxConstants.STYLE_OVERFLOW] = 'width';
    style[MxConstants.STYLE_FILL_OPACITY] = '60';
    style[MxConstants.STYLE_STROKE_OPACITY] = '60';
    return style;
  }

  hasLabel() {
    try {
      return this.getValue().getLabel().getName() !== this.getKey();
    } catch (e) {
      return false;
    }
  }

  static get inactiveStyle() {
    const style = {};
    style[MxConstants.STYLE_FILLCOLOR] = '#433d3a';
    style[MxConstants.STYLE_STROKECOLOR] = '#5f5858';
    style[MxConstants.STYLE_STROKE_OPACITY] = '60';
    style[MxConstants.STYLE_FILL_OPACITY] = '0';
    style[MxConstants.STYLE_STROKEWIDTH] = '1';
    return style;
  }

  static get activeStyle() {
    const style = {};
    style[MxConstants.STYLE_STROKEWIDTH] = '2';
    style[MxConstants.STYLE_STROKE_OPACITY] = '80';
    style[MxConstants.STYLE_FILL_OPACITY] = '40';
    style[MxConstants.STYLE_FILLCOLOR] = '#8abec1';
    style[MxConstants.STYLE_STROKECOLOR] = '#66a9c1';
    return style;
  }

  static get completedStyle() {
    const { activeStyle } = BranchPointVertex;
    activeStyle[MxConstants.STYLE_FILLCOLOR] = '#1d3080';
    activeStyle[MxConstants.STYLE_STROKECOLOR] = '#5a7497';
    activeStyle[MxConstants.STYLE_STROKEWIDTH] = '2';
    activeStyle[MxConstants.STYLE_STROKE_OPACITY] = '80';
    activeStyle[MxConstants.STYLE_FILL_OPACITY] = '40';
    return activeStyle;
  }


  /**
   * Define an return an array of source connection constraints for a functional/pivotal event. Constrains outgoing
   * edges to either the bottom,middle section or the middle,rightmost section of the vertex.
   * @return MxGraphType.mxConnectionConstraint[]
   */
  getSourceConnectionConstraints() {
    return [];
  }

  /**
   * Define an return a singleton array of target connection constraints for a functional/pivotal event. Constrains
   * incoming edges to the leftmost,middle section of the vertex.
   * @return MxGraphType.mxConnectionConstraint[]
   */
  getTargetConnectionConstraints() {
    return [];
  }

  onAddedToGraph(graph) {
    super.onAddedToGraph(graph);
    this.successPort.setVisible(false);
    this.failurePort.setVisible(false);
  }

  getType() {
    return "Branch Point";
  }

  clone() {
    return new BranchPointVertex(null, this.value.clone());
  }
}

export default BranchPointVertex;
