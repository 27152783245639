import React, {useEffect, useState} from 'react';
import { Typography, withStyles, Theme } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import {Link} from 'react-router-dom'
import { useIntl } from 'react-intl';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import InputRules from "hcl-web-editor/app/components/Model/SingleHCLModelView/SingleTreeView/Inputs/InputRules";
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import ExpressionInput
  from 'hcl-web-editor/app/components/Model/SingleHCLModelView/SingleTreeView/Inputs/ExpressionInput';
import { Expression, ExpressionJSON } from 'hcl-web-editor/app/mxGraph/HCLTreeNode/HCLTreeVertexValue/Expression';
import AnalysisReferencesHeader from "../AnalysisReferencesHeader";
import PhoenixApiManager from "../../../Api/PhoenixApiManager";
import ProbabilityInput
  from "hcl-web-editor/app/components/Model/SingleHCLModelView/SingleTreeView/Inputs/ProbabilityInput";
import TextField from '@material-ui/core/TextField';

const PIFScoreDialog = (props: PIFScoreDialogProps) => {
    const { header, handleClose, score, save, expression, isOverride, onResponseUpdated } = props;
  
    const intl = useIntl();
  
    const [value, setValue] = useState(score);
    const [expressionError, setExpressionError] = useState(true); // used for if justification is filled out or not
    const [expr, setExpr] = useState(new Expression(expression).toExtendedJSON());
    const [override, setOverride] = useState(isOverride ? isOverride : false);
    const [hasError, setHasError] = useState(!InputRules.isProbabilityValid(value));
    const [title, setTitle] = useState("");
  
    useEffect(() => {
      setHasError(!InputRules.isProbabilityValid(value));
    },[value]);

    useEffect(() => {
      if (title !== "") {
        setExpressionError(false);
      }
      else {
        setExpressionError(true);
      }
    },[title])
  
    const payload = JSON.stringify({
      score: value,
      override,
      score_justification: title,
      expression: new Expression(expression).toJSON(),
    });
  
    const handleChange = (e: any, name: string, multiple: boolean) => {
      e.persist();
      if (multiple) {
        setExpr({ ...expr, ...e.target.value });
      } else {
        setExpr({ ...expr, [e.target.name]: e.target.value });
      }
    };

    const setValueAndUpate = (value) => {
      setValue(value)
      onResponseUpdated()
     }

    return (
      <Dialog open={true} maxWidth="lg" fullWidth onClose={handleClose} aria-labelledby="form-dialog-title">
        {header}
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <DialogTitle id="form-dialog-title">
              {`${intl.formatMessage({ id: 'Input PIF Score' })}`}
            </DialogTitle>
          </Grid>
          <Grid item>
            {/* <Switch checked={override} onChange={() => setOverride(!override)} /> */}
          </Grid>
        </Grid>
        <DialogContent>
          <Typography>
          Manually add a score for this PIF (probability that this PIF is in a state that will degraded human performance). The manual input should be used only when the score of this PIF is already known because it was previously calculated in this model, or for sensitivity analysis. The calculation of the PIF score through the questions is preferable to a manual input.
          </Typography>
          <ProbabilityInput
            autoFocus={true}
            disabled={false}
            fullWidth
            margin="dense"
            label={intl.formatMessage({ id: 'analysis.pifScore' })}
            value={value}
            onChange={e => setValueAndUpate(e.target.value)}
            InputProps={{ inputProps: { min: 0, max: 1 } }}
          />
          {/* {expr &&
            <ExpressionInput
              expression={new Expression(expr)}
              onChange={(e, name, multiple) => handleChange(e, name, multiple)}
              onError={(e) => setExpressionError(e)}
              name="expression"
              disabled={!override}
            />
          } */}
        <TextField
        autoFocus={true}
        variant="outlined"
        fullWidth
        label="Justification"
        onChange={(event)=>{setTitle(event.target.value)}}
        required
        InputProps={{ inputProps: { min: 0, max: 1 } }}

        >
        </TextField>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {`${intl.formatMessage({ id: 'cancel' })}`}
          </Button>
          {/* <Link to={window.location.pathname} onClick={window.location.reload}>  */}
                   <Button
            variant="contained"
            onClick={() => save(payload)} // hasError & expressionError = true, want disabled = true
            color="primary"
            disabled={hasError || expressionError} // want disabled = true if expression error is true or hasError = true, so use or
          >
            {`${intl.formatMessage({ id: 'save' })}`}
          </Button>
          {/* </Link> */}

        </DialogActions>
      </Dialog>
    );
  };

export default PIFScoreDialog;