import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { Switch, Route } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';

import { HFEType } from "../../../Types";
import PhoenixApiManager from '../../../../../Api/PhoenixApiManager';
import PIFListView from './PIFListView';

const updatableDataInitStates: State = {
  items: [],
  label: {
    name: null,
    description: null,
  },
  isLoading: true,
  sort: (i: any) => i
};

interface Props {
  depth: number,
  match: { [key: string]: any },
  location: any,
  detailsProgress: any,
  hfe: HFEType
}

interface State {
  items: any[],
  label: {
    name: string,
    description: string
  },
  isLoading: boolean,
  sort: (i: any) => any
}

class PIFsForCFM extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      ...updatableDataInitStates,
      sort: (i: any) => i,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  adaptCFMItemsToPIFs = (hfeName: string, cfmName: string, data: any) => {
    const { pifs, label } = data;
    const pifEntries = [];
    const pifKeys = Object.keys(pifs);
    const output = pifKeys.map((pifKey) => {
      return {
        pif: pifKey,
        pifScore: ('score' in pifs[pifKey]) ? pifs[pifKey].score : null,
        cfm: cfmName,
        cfmLabel: label.name,
        hfe: hfeName,
        hfeLabel: hfeName,
        key: `${hfeName}.${cfmName}.${pifKey}`,
      };
    });
    pifEntries.push(...output);
    return pifEntries;
  };

  loadData = () => {
    const { match: { params: { hfeName, cfmName, id,cfid } } } = this.props;
    PhoenixApiManager.getPIFsForModelWithIdAndHFEAndCFM(cfid, hfeName, cfmName)
      .then(response => response.status === 200 && response.json())
      .then(data => this.setState({ items: this.adaptCFMItemsToPIFs(hfeName, cfmName, data), label: data.label, isLoading: false }));
  };

  handleSortChange = (sort: any) => {
    this.setState({ sort });
    // todo: fetch data if pagination is enabled
  };

  render() {
    const { items, isLoading, label: { name } } = this.state;
    const { depth, match: { params: { hfeName, cfmName, id,cfid } }, detailsProgress, hfe } = this.props;
    return (
      <React.Fragment>
        <BreadcrumbsItem to={`/model/${id}/main-analysis/${cfid}/hfe/${hfeName}/cfm`} depth={depth - 2}>CFMs</BreadcrumbsItem>
        <BreadcrumbsItem to={`/model/${id}/main-analysis/${cfid}/hfe/${hfeName}/cfm/${cfmName}`} depth={depth - 1}>
          {name}
        </BreadcrumbsItem>
        <BreadcrumbsItem to={`/model/${id}/main-analysis/${cfid}/hfe/${hfeName}/cfm/${cfmName}/pif`} depth={depth}>PIFs</BreadcrumbsItem>
        <Switch>
          <Route
            exact
            path="/model/:id/main-analysis/hfe/:hfeName/cfm/:cfmName/pif/"
            render={prop => (
              <PIFListView
                {...prop}
                items={items}
                isLoading={isLoading}
                title={<Typography variant="h5">{name}</Typography>}
                filterInput={null}
                handleFilterChange={null}
                handleFilterSubmit={null}
                handleSortChange={this.handleSortChange}
                detailsProgress={detailsProgress}
                hfeLabel={hfe.label.name}
              />
            )}
          />
        </Switch>

      </React.Fragment>
    );
  }
}

export default PIFsForCFM;
