import React, {useEffect, useState} from 'react';
import TreeReferenceButton from "../Model/SinglePhoenixModelView/Analysis/HFE/TreeReferenceButton";
import Button, { ButtonTypeMap } from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import {
	PhoenixTreeMxGraphView,
	PhoenixEventTreeMxGraph,
	PhoenixFaultTreeMxGraph
} from "../Model/SinglePhoenixModelView/PhoenixTreeMxGraphView";
import { injectIntl } from "react-intl";
import PhoenixApiManager from "../../Api/PhoenixApiManager";
import {createStyles, withStyles, Theme} from '@material-ui/core/styles';
import Paper from "@material-ui/core/Paper";
import classNames from "classnames";
import AvatarText from "./Icons/AvatarText";


const styles = (theme: Theme) => createStyles({
	root: {
		padding: theme.spacing(0.5),
		display: 'flex',
		flex: '0 0 auto',
		justifyContent: 'space-between',
		alignItems: 'center'
	},
	asLabel: {
		textTransform: 'none',
		justifyContent: 'flex-start',
		'&:hover' : {
			cursor: 'unset',
			background: 'initial'
		}
	},
	btnGroupRoot: {
		display: 'flex',
		flex: '1 0 auto',
	},
	btnBPRoot: {
		flexGrow: 1
	},
	fullWidth: {
		width: '100%',
	},
	avatarTextOverride: {
		width: theme.spacing(3.5),
		height: theme.spacing(3.5),
	},
	unsetMargin: {
		margin: theme.spacing(0)
	}
});

interface Props {
	crt?: {
		id: number,
	},
	bp?: string,
	hfe?: {
		id: number,
	},
	cfm?: {
		treeId: number,
		label: string
	},
	pif?: string,
	others?: any,
	inPaperContainer: boolean,
	buttonVariant?: ButtonTypeMap['props']['variant'],
	rootClassOverride?: any,
	intl: any,
	classes: any,
	match: { [key: string]: any }

}

interface buttonAsLabelProps {
	avatarText: string,
	title: string,
	classes: any,
	buttonVariant?: ButtonTypeMap['props']['variant'],
}

const ButtonAsLabel = (props: buttonAsLabelProps) => {
	const { avatarText, title, classes, buttonVariant } = props;
	return (
		<Button
			className={`${avatarText === 'BP' && classes.btnBPRoot} ${classes.asLabel}`}
			variant={buttonVariant ? buttonVariant : "outlined"}
			disableRipple
			disableTouchRipple
			disableFocusRipple
		>
			<AvatarText text={avatarText} rootClassOverride={classes.avatarTextOverride} />
			{title}
		</Button>
	)
};

const AnalysisReferencesHeader = (props: Props) => {
	const { crt, bp, hfe, cfm, pif, others, inPaperContainer, buttonVariant, rootClassOverride, intl, classes } = props;

	const [eventTreeGraph, setEventTreeGraph] = useState(null);
	const [faultTreeGraph, setFaultTreeGraph] = useState(null);
	const [cfmTreeGraph, setCFMTreeGraph] = useState(null);
	console.log(props, "crt quant")

	useEffect(() => {
		if (crt && crt.id) { // when rendered by HFEControllerView, crt props is id which is the number
			PhoenixApiManager.getCrewResponseTreeAnalysisEventTree(crt.id) // getting CRT, but crt value isn't found on call
			.then(response => { // simple change, crt.cfid doesn't exist. crt.id does exist
				setEventTreeGraph(new PhoenixEventTreeMxGraph(response.data));
			  })
			.catch(err => console.error(err));
		}
		if (crt && crt.cfid) { // when rendered by PIF viewer, crt props is cfid which is the number
			PhoenixApiManager.getCrewResponseTreeAnalysisEventTree(crt.cfid) // u
			.then(response => { 
				setEventTreeGraph(new PhoenixEventTreeMxGraph(response.data));
			  })
			.catch(err => console.error(err));
		}
		if (hfe) {
			PhoenixApiManager.getPhoenixTree(hfe.cfid || hfe.id || hfe.fault_tree_id || null)
				.then((response) => {
					setFaultTreeGraph(new PhoenixFaultTreeMxGraph(response.data))
				})
				.catch(err => console.error(err));
		}
		if (cfm) {
			PhoenixApiManager.getPhoenixTreeWithMetadata(cfm.treeId)
				.then((response: any) => response.json())
				.then((data) => setCFMTreeGraph(new PhoenixFaultTreeMxGraph(data.tree_data)))
				.catch(err => console.error(err));
		}
	}, []);

	const content = (
		<React.Fragment>
			<div className={classes.fullWidth}>
				<ButtonGroup className={classes.btnGroupRoot}>
					{ crt && (
			<TreeReferenceButton
			tooltipTitle={intl.formatMessage({ id: 'analysis.checkCRT' })}
			title="CRT"
			graphView={<PhoenixTreeMxGraphView graph={eventTreeGraph} />}
			/>  
					)} 
					{/* bp = ct from now on... */}
					{ bp && <ButtonAsLabel avatarText={'CT'} title={bp} classes={classes} buttonVariant={buttonVariant} /> }
					{ hfe && (
						<TreeReferenceButton
							tooltipTitle={intl.formatMessage({ id: 'analysis.checkHFE' })}
							title="HFE"
							graphView={
								<PhoenixTreeMxGraphView
									graph={faultTreeGraph}
								/>
							}
						/>
					)}
					{/* cfm && (
						<TreeReferenceButton
							icon={<AvatarText text={'CFM'} rootClassOverride={`${classes.avatarTextOverride} ${classes.unsetMargin}`}/>}
							title={cfm.label}
							tooltipTitle={'check CFM'}
							graphView={
								<PhoenixTreeMxGraphView
									graph={cfmTreeGraph}
								/>
							}
						/>
						) */}
					{ pif && <ButtonAsLabel avatarText={'PIF'} title={pif} classes={classes} buttonVariant={buttonVariant}/> }
				</ButtonGroup>
			</div>
			<div>
				{others}
			</div>
		</React.Fragment>
	);

	return inPaperContainer ? (
		<Paper className={classNames(classes.root, rootClassOverride !== null ? rootClassOverride : null)}>
			{content}
		</Paper>
	) : (
		<div className={classNames(classes.root, rootClassOverride !== null ? rootClassOverride : null)}>
			{content}
		</div>
	);
};

export default withStyles(styles)(injectIntl(AnalysisReferencesHeader));
