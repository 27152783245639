import React, { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Checkbox, FormControlLabel, Typography, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PhoenixApiManager from '../../../../../../Api/PhoenixApiManager';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import { Tooltip } from '@material-ui/core';



interface EventData {
  house_events: string[];
  basic_events: string[];
  cfms: {
    [key: string]: {
      label: {
        name: string;
        description: string;
      }
    };
  };
}


interface ExpertModeHFEEditorProps {
  data: EventData;
  match: {
    params: {
      id: string;
      cfid: string;
      hfeName: string;
    };
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  pageTitle: {
    variant: 'h4',
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    textAlign: 'center',
    margin: theme.spacing(2, 0),
    // textShadow: '1px 1px 2px gray',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    height: '100vh',
  },
  sendButton: {
    background: 'linear-gradient(45deg, #1565C0 30%, #0D47A1 90%)', 
    borderRadius: 3, 
    border: 0, 
    color: 'white',
    height: 48, 
    padding: '0 30px',
    boxShadow: '0 3px 5px 2px rgba(13, 71, 161, .3)', 
    margin: theme.spacing(2, 0),
    '&:hover': {
      background: 'linear-gradient(45deg, #21CBF3 30%, #2196F3 90%)', 
    },
  },
  eventPreview: {
    border: '1px solid #0D47A1',
    borderRadius: '5px',
    padding: theme.spacing(2),
    margin: theme.spacing(2, 0),
    background: 'rgba(13, 71, 161, 0.1)',
    width: '80%',
    alignSelf: 'center'
  },
  eventTitle: {
    color: '#0D47A1',
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  },
  eventItem: {
    marginLeft: theme.spacing(2),
    color: '#0D47A1',
  },
  basicEvent: {
    fontWeight: 'bold',
  },
  houseEvent: {
    fontStyle: 'italic',
  },
  cfmEvent: {
    textDecoration: 'underline',
  },
}));

const ExpertModeHFEEditor: React.FC<ExpertModeHFEEditorProps> = ({ data, match, history, onResponseUpdated, hfe }) => {
  const { id, cfid, hfeName } = match.params;
  const classes = useStyles();
  const [selectedEvents, setSelectedEvents] = useState({
    basic_events: {},
    house_events: {},
    excluded_gates: [],
    cfms: []
  });

  const [openDialog, setOpenDialog] = useState(false);
  const [currentEvent, setCurrentEvent] = useState('');
  const [probability, setProbability] = useState('');
  const [loading, setLoading] = useState(false);


  const handleToggle = (event: React.ChangeEvent<HTMLInputElement>, category: keyof EventData) => {
    const { name, checked } = event.target;
    const updatedEvents = { ...selectedEvents };

    if (category === 'basic_events' && checked) {
      setCurrentEvent(name);
      setOpenDialog(true);
      return;
    } else if (category === 'house_events') {
      if (checked) {
        updatedEvents.house_events[name] = { constant: true };
      } else {
        delete updatedEvents.house_events[name];
      }
    } else if (category === 'cfms') {
      const cfmKey = event.target.name;
      if (checked) {
        updatedEvents.cfms.push(cfmKey);
      } else {
        updatedEvents.cfms = updatedEvents.cfms.filter(cfm => cfm !== cfmKey);
      }
    }

    setSelectedEvents(updatedEvents);
  };

  const handleDialogClose = (accept: boolean) => {
    if (accept && currentEvent) {
      setSelectedEvents(prev => ({
        ...prev,
        basic_events: {
          ...prev.basic_events,
          [currentEvent]: {
            failure_probability: parseFloat(probability)
          }
        }
      }));
    }
    setOpenDialog(false);
    setProbability('');
  };

  const hasSelectedEvent = (): boolean => {
    for (let category in selectedEvents) {
      if (category === "basic_events" && Object.keys(selectedEvents[category]).length > 0) {
        return true;
      } else if (category === "house_events" && Object.keys(selectedEvents[category]).length > 0) {
        return true;
      } else if (category === "cfms" && selectedEvents[category].length > 0) {
        return true;
      }
    }
    return false;
};


  const sendDataToEndpoint = () => {
    setLoading(true);

    const payload = {
      name: hfeName,
      nodes: selectedEvents
    };

    PhoenixApiManager.postHFEForModelWithId(cfid, JSON.stringify(payload))
      .then(response => response.status === 201 && response.json())
      .then(() => 
						onResponseUpdated())
					
					.then(() => history.push({
						pathname: `/model/${id}/main-analysis/hfe/`
					}, {reload: true}
					))
      .finally(() => {
        setLoading(false); // Ensure loading is turned off regardless of success or error
      });
        };


        const EventPreview: React.FC<{ selectedEvents: typeof selectedEvents }> = ({ selectedEvents }) => {
          return (
            <div className={classes.eventPreview}>
              <Typography className={classes.eventTitle}>Selected Events Preview:</Typography>
              {Object.entries(selectedEvents).map(([category, events]) => {
                if (category === "basic_events") {
                  return Object.entries(events).map(([eventName, details]) => (
                    <Typography key={eventName} className={`${classes.eventItem} ${classes.basicEvent}`}>
                      {eventName} - {details.failure_probability}
                    </Typography>
                  ));
                } else if (category === "house_events") {
                  return Object.entries(events).map(([eventName]) => (
                    <Typography key={eventName} className={`${classes.eventItem} ${classes.houseEvent}`}>
                      {eventName}
                    </Typography>
                  ));
                } else if (category === "cfms") {
                  return selectedEvents[category].map((cfmKey) => (
                      <Typography key={cfmKey} className={`${classes.eventItem} ${classes.cfmEvent}`}>
                          {cfmKey}
                      </Typography>
                  ));
              }              
                return null;
              })}
            </div>
          );
      };
      

  return (
    <div>
      <Typography variant="h4" className={classes.pageTitle} gutterBottom>
      {hfe ? hfe.label.name : ''}
    </Typography>
{Object.keys(data).map((category) => (
    <Accordion key={category}>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel-content"
            id={category}
        >
            <Typography>{category.replace(/_/g, ' ').toUpperCase()}</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            { 
                category === 'cfms'
                ? Object.entries(data[category as keyof EventData]).map(([eventKey, eventData]) => (
                    <Tooltip key={eventKey} title={eventData.label.description}>
                        <FormControlLabel
                            control={<Checkbox name={eventKey} onChange={(e) => handleToggle(e, category as keyof EventData)} />}
                            label={eventData.label.name}
                        />
                    </Tooltip>
                ))
                : data[category as keyof EventData].map((event) => (
                    <FormControlLabel
                        key={event}
                        control={<Checkbox name={event} onChange={(e) => handleToggle(e, category as keyof EventData)} />}
                        label={event}
                    />
                ))
            }
            </div>
        </AccordionDetails>
    </Accordion>
))}

      
      <EventPreview selectedEvents={selectedEvents} />

      <div className={classes.container}>
          <Button 
            className={classes.sendButton} 
            onClick={sendDataToEndpoint} 
            disabled={loading || !hasSelectedEvent()}
          >
            {loading ? <CircularProgress size={24} color="inherit" /> : "Send Data"}
          </Button>
      </div>

      <Dialog open={openDialog} onClose={() => handleDialogClose(false)}>
        <DialogTitle>Enter Failure Probability</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please provide a failure probability for the selected event.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Probability"
            type="number"
            fullWidth
            onChange={(e) => setProbability(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDialogClose(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleDialogClose(true)} color="primary">
            Accept
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ExpertModeHFEEditor;
