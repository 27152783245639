import React, { useState, useEffect } from "react";
import { Typography } from "@material-ui/core";
import { injectIntl } from "react-intl";

//@ts-ignore
import MainContent from "hcla-web-frontend-primitives/app/components/primitives/Views/MainContent";
import UseFilterKey, {
  frontendFilter,
} from "hcla-web-frontend-primitives/app/Utils/UseFilterKey";
import PhoenixApiManager from "../../Api/PhoenixApiManager";
import ModelListView from "./ModelListView";
import MainModelListView from "./MainModelListView";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

const updatableDataInitStates: Partial<State> = {
  items: [],
  main_items: [],
  itemTotal: null,
  main_itemTotal: null,
  limit: null,
  main_limit: null,
  offset: null,
  main_offset: null,
  paginationEnabled: false,
  isLoading: true,
};

interface Props {
  intl: any;
  depth: number;
  history: { [key: string]: any };
  location: { [key: string]: any };
  match: { [key: string]: any };
}

interface State {
  items: any[];
  main_items: any[];
  itemTotal?: number | null;
  main_itemTotal?: number | null;
  limit?: number | null;
  main_limit?: number | null;
  offset?: number | null;
  main_offset?: number | null;
  paginationEnabled?: boolean | null;
  isLoading: boolean;
  sort: (i: any) => any;
  value: any;
}

class Models extends React.Component<Props, State> {
  localPagination = JSON.parse(localStorage.getItem("pagination"));
  isAutoPagination =
    !this.localPagination ||
    (this.localPagination &&
      this.localPagination.auto &&
      !this.localPagination.rowsPerPage);
  constructor(props: Props) {
    super(props);
    this.state = {
      ...updatableDataInitStates,
      limit: !this.isAutoPagination ? this.localPagination.rowsPerPage : null,
      main_limit: !this.isAutoPagination ? this.localPagination.rowsPerPage : null,
      sort: (i) => i,
      value: 1,
    };
  }


  loadData = (filterKey?: string) => {
    const { limit, offset } = this.state;
    if (filterKey) {
      return PhoenixApiManager.searchModels(filterKey, limit, offset).then(
        (response) => {
          const { data } = response;
          this.setState({
            items: data && data.results ? data.results : data,
            itemTotal: data && data.count ? data.count : null,
            paginationEnabled: !!(data && data.results),
            isLoading: false,
          });
        }
      );
    } else {
      return PhoenixApiManager.getModels(limit, offset)
        .then((response) => response.json())
        .then((data) =>
          this.setState({
            items: data && data.results ? data.results : data,
            itemTotal: data && data.count ? data.count : null,
            paginationEnabled: !!(data && data.results),
            isLoading: false,
          })
        );
    }
  };

  loadMainData = (filterKey?: string) => {
    const { main_limit, main_offset } = this.state;
    if (filterKey) {
      return PhoenixApiManager.searchMainModels(filterKey, main_limit, main_offset).then(
        (response) => {
          const { data } = response;
          this.setState({
            main_items: data && data.results ? data.results : data,
            main_itemTotal: data && data.count ? data.count : null,
            paginationEnabled: !!(data && data.results),
            isLoading: false,
          });
        }
      );
    } else {
      return PhoenixApiManager.getMainModels(main_limit, main_offset)
        .then((response) => response.json())
        .then((data) =>
          this.setState({
            main_items: data && data.results ? data.results : data,
            main_itemTotal: data && data.count ? data.count : null,
            paginationEnabled: !!(data && data.results),
            isLoading: false,
          })
        );
    }
  };

  loadAllData = () => {
    this.loadData();
    this.loadMainData();
  }

  searchModels = (filterKey?: string) => {
    const { limit, offset } = this.state;
    return this.setState(
      {
        offset: 0,
      },
      () => {
        return PhoenixApiManager.searchModels(filterKey, limit, offset).then(
          (response) => {
            const { data } = response;
            this.setState({
              items: data && data.results ? data.results : data,
              itemTotal: data && data.count ? data.count : null,
              paginationEnabled: !!(data && data.results),
              isLoading: false,
            });
          }
        );
      }
    );
  };

  searchMainModels = (filterKey?: string) => {
    const { main_limit, main_offset } = this.state;
    return this.setState(
      {
        main_offset: 0,
      },
      () => {
        return PhoenixApiManager.searchMainModels(
          filterKey,
          main_limit,
          main_offset
        ).then((response) => {
          const { data } = response;
          this.setState({
            main_items: data && data.results ? data.results : data,
            main_itemTotal: data && data.count ? data.count : null,
            paginationEnabled: !!(data && data.results),
            isLoading: false,
          });
        });
      }
    );
  };

  updateList = (filterKey?: string, limit?: number, offset?: number) => {
    this.setState({ limit, offset }, () => this.loadData(filterKey));
  };

  updateMainList = (filterKey?: string, main_limit?: number, main_offset?: number) => {
    this.setState({ main_limit, main_offset }, () => this.loadMainData(filterKey));
  };

  handleSortChange = (sort: any) => {
    this.setState({ ...this.state, sort });
    // todo: fetch data if pagination is enabled
  };

  setTabValue = (newValue) => {
    this.setState({ value: newValue });
  };

  render() {
    const {
      items,
      main_items,
      itemTotal,
      main_itemTotal,
      paginationEnabled,
      isLoading,
      sort,
    } = this.state;
    const { intl } = this.props;

    return (
      <UseFilterKey
        loadData={this.loadAllData}
        render={({ filterInput, handleFilterChange, handleFilterSubmit }) => (
          <MainContent>
            <AppBar position="static" color="default">
              <Tabs
                value={this.state.value}
                onChange={(event, val) => {
                  this.setTabValue(val);
                }}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                aria-label="full width tabs example"
              >
                <Tab value={0} label="model list"></Tab>

                <Tab value={1} label="main model list"></Tab>
              </Tabs>
            </AppBar>
            {/*console.log('models list', this.state.items)*/}
            {this.state.value == 0 && (
              <ModelListView
                items={sort(frontendFilter(items, filterInput))}
                itemTotal={itemTotal}
                paginationEnabled={paginationEnabled}
                updateList={(limit?: number, offset?: number) =>
                  this.updateList(filterInput, limit, offset)
                }
                isLoading={isLoading}
                title={
                  <Typography variant="h4" gutterBottom>
                    {intl.formatMessage({ id: "modelListTitle" })}
                  </Typography>
                }
                filterInput={filterInput}
                handleFilterChange={(e: any) => {
                  handleFilterChange(e);
                  this.searchModels(e.target.value);
                }}
                handleFilterSubmit={handleFilterSubmit}
                handleSortChange={this.handleSortChange}
              />
            )}
            {/*console.log('main models list', this.state.main_items)*/}
            {this.state.value == 1 && (
              <MainModelListView
                items={sort(frontendFilter(main_items, filterInput))}
                itemTotal={main_itemTotal}
                paginationEnabled={paginationEnabled}
                updateList={(main_limit?: number, main_offset?: number) =>
                  this.updateMainList(filterInput, main_limit, main_offset)
                }
                isLoading={isLoading}
                title={
                  <Typography variant="h4" gutterBottom>
                    {intl.formatMessage({ id: "modelListTitle" })}
                  </Typography>
                }
                filterInput={filterInput}
                handleFilterChange={(e: any) => {
                  handleFilterChange(e);
                  this.searchModels(e.target.value);
                }}
                handleFilterSubmit={handleFilterSubmit}
                handleSortChange={this.handleSortChange}
              />
            )}
          </MainContent>
        )}
      />
    );
  }
}

export default injectIntl(Models);
