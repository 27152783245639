import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { injectIntl } from 'react-intl';
import { Switch, Route } from 'react-router-dom';

//@ts-ignore
import ViewNoMatch from 'hcla-web-frontend-primitives/app/components/primitives/Error/ViewNoMatch';

import PhoenixApiManager from '../../../../../Api/PhoenixApiManager';
import CFMListView from './CFMListView';

const updatableDataInitStates: Partial<State> = {
  items: [],
  isLoading: true,
};

interface Props {
  depth: number,
  match: { [key: string]: any },
  detailsProgress: any,
  intl: any
}

interface State {
  items: any[],
  isLoading: boolean,
  sort: (i: any) => any,
}

class CFMsForHFE extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      ...updatableDataInitStates,
      sort: (i: any) => i,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  adaptCFMListToObject = (cfms: any[]) => {
    const { match: { params: { hfeName } } } = this.props;
    const data = {
      name: hfeName,
      label: {
        name: hfeName,
        description: hfeName,
      },
      nodes: {
        cfms: [],
      },
      cfms: {
      },
    };
    cfms.forEach((cfm) => {
      data.nodes.cfms.push(cfm.name);
      data.cfms = {
        ...data.cfms,
        [cfm.name]: cfm,
      };
      data.label.name = cfm.hfe.label.name;
    });
    return [data];
  };

  loadData = () => {
    const { match: { params: { hfeName, id,cfid } } } = this.props;
    PhoenixApiManager.getCFMsForModelWithIdAndHFE(cfid, hfeName)
      .then(response => response.status === 200 && response.json())
      .then(data => this.setState({ items: this.adaptCFMListToObject(data), isLoading: false }));
  };

  handleSortChange = (sort: any) => {
    this.setState({ sort });
    // todo: fetch data if pagination is enabled
  };

  render() {
    const { items, isLoading } = this.state;
    const { depth, match: { params: { hfeName, id,cfid } }, intl, detailsProgress } = this.props;
    return (
      <React.Fragment>
        <BreadcrumbsItem to={`/model/${id}/main-analysis/${cfid}/hfe/${hfeName}/cfm`} depth={depth}>{intl.formatMessage({ id: 'analysis.cfm' })}</BreadcrumbsItem>
        <Switch>
          <Route
            exact
            path="/model/:id/main-analysis/:cfid/hfe/:hfeName/cfm/"
            render={prop => (
              <CFMListView
                items={items}
                isLoading={isLoading}
                filterInput={null}
                handleFilterChange={null}
                handleFilterSubmit={null}
                handleSortChange={this.handleSortChange}
                detailsProgress={detailsProgress}
                {...prop}
              />
            )}
          />
          <Route
            render={prop => (
              <ViewNoMatch
                {...prop}
                message={intl.formatMessage({ id: '404.pageNotFound' })}
              />
            )}
          />
        </Switch>
      </React.Fragment>
    );
  }
}

export default injectIntl(CFMsForHFE);
