import React, {FunctionComponent, useEffect, useRef, useState} from 'react';
import PhoenixApiManager, {
  AnsweredDecisionPoint, NextDecisionPoint
} from '../../../../../Api/PhoenixApiManager';
import {PhoenixFlowChartMxGraph, PhoenixTreeMxGraphView} from "../../PhoenixTreeMxGraphView";
import Notepad from './Notepad/Notepad'


export const TEMPLATE_NAME_MAP: {[key: number]: string} = {
  7: 'Design Basis Scenarios',
  8: 'Maintenance Scenarios',
  11: 'Phoenix 2.0',
  1: 'Phoenix 2.0',
  3: 'Phoenix 2.1',
  12: 'Phoenix 2.1'
};

type CrewResponseTreeViewProps = {
  templateId: number,
  treeId: number,
  answeredDecisionPoints: AnsweredDecisionPoint[],
  nextDecisionPoint: NextDecisionPoint
}

const getAnsweredDecisionPointNames = (answeredDecisionPoints: AnsweredDecisionPoint[]) => {
  return {
    yes: answeredDecisionPoints.filter(dp => dp.response === "Yes").map(d => d.name),
    no: answeredDecisionPoints.filter(dp => dp.response === "No").map(d => d.name)
  }
};

const CrewResponseTreeView = (props: CrewResponseTreeViewProps) => {

  const { templateId, treeId, answeredDecisionPoints, nextDecisionPoint, cfId } = props;
  const answeredDecisionPointNames = getAnsweredDecisionPointNames(answeredDecisionPoints);
  const templateName = TEMPLATE_NAME_MAP[templateId];

  const [graph, setGraph] = useState(null);

  useEffect(() => {
    PhoenixApiManager.getPhoenixTree(treeId)
      .then(response => {

        setGraph(new PhoenixFlowChartMxGraph(response.data, templateName, answeredDecisionPoints))
      })
      .catch(err => console.error(err));
  }, []);

  useEffect(() => {
    if (graph) {
      graph.removeAllVertices()    

      graph.updateAnswerResponse(answeredDecisionPoints)
      // graph.applyDecisionPointStyles(answeredDecisionPointNames, nextDecisionPoint.name);
      graph.applyBranchPointStyles();
      // graph.applyInitEventStyles();
      graph.applyEndStateStyles();
      
      // graph.applyDefaultLayout()

    }
  }, [answeredDecisionPointNames]);

  return (
    <>
    <PhoenixTreeMxGraphView
      graph={graph}
      target={nextDecisionPoint.name}
      cfId={cfId}
      answeredDecisionPoints={answeredDecisionPoints}
    />
    {/* <div>
									<Notepad 
		         cfId={cfId}
		         className='App'/>
</div> */}
  </>
  );

};

export default CrewResponseTreeView;

