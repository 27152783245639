import React, { useEffect, useState } from "react";
import EventTreeMxGraphEditorView from "./SinglePhoenixMainModelView/MasterESD/EventTreeMxGraphEditorView";
import HCLApiManager from "hcl-web-editor/app/Api/HclApiManager";
import CircularIndeterminate from "hcla-web-frontend-primitives/app/components/primitives/Progress/CircularIndeterminate";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { useIntl } from "react-intl";
import { Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import PhoenixApiManager from "../../Api/PhoenixApiManager";
import Button from "@material-ui/core/Button";
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import CircularProgressIcon from '../primitives/Progress/CircularProgressIcon';


interface Props {
  details: any;
}

const MasterESD_main = (props: Props) => {
  const {
    details,
    history,
    match: {
      params: { id },
    },
  } = props;
  const [data, setData] = useState(null);
  const [criticalFunctions, setCriticalFunctions] = useState([]);
  const [activeNextButton, setActiveNextButton] = useState(false);
  // create a const for the status for criticle functions
  // const [cfStatus,setCfStatus]= useState([]);
  const [flag, setFlag] = useState(true);
  const intl = useIntl();

  const filterCriticalFunctions = (results) => {
    return results.filter((result) =>
      result.title.includes(`${id.toString()} - `)
    );
  };
  //Just get a bunch of critical functions
  useEffect(() => {
    PhoenixApiManager.getMasterEsd(id)
      .then((response) => setData(response.data))
      // .then((data) => setData(data))
      .then(() => {
        PhoenixApiManager.getMainCriticalFunctions(id).then((data) => {
          setCriticalFunctions(data.data);
          if (data.data.next) getNextCriticalFunctions(data.data.next);
        });
      });
  }, []);

  useEffect(() => {

    let dp_completed = criticalFunctions.filter(cf => cf.crt_model != null && cf.failure_probability == null).map(cf => (cf.progress.dp.summary.completed >= cf.progress.dp.summary.total));
    let bp_completed = criticalFunctions.filter(cf => cf.crt_model != null && cf.failure_probability == null).map(cf => (cf.progress.bp.summary.completed >= cf.progress.bp.summary.total));
    setActiveNextButton(dp_completed.every(v => Boolean(v)) && bp_completed.every(v => Boolean(v)))
  }, [criticalFunctions])

  const getNextCriticalFunctions = (next) => {
    PhoenixApiManager.getNextCriticalFunctions(next)
      .then((res) => res.json())
      .then((data) => {
        const newCriticalFunctions = [...criticalFunctions];
        newCriticalFunctions.concat(filterCriticalFunctions(data.results));
        if (data.next) getNextCriticalFunctions(data.next);
      });
  };

  //FUNCTIONS FOR CHECKING THE API RETURN
  // const checkTrue=(cf)=>{
  // 	return cf===true;
  // }
  // const getAllCFStatus=(cf_array)=>{
  // 	return cf_array.every(checkTrue)
  // }
  // const getCriticalFunctionStatus=()=>{
  // 	PhoenixApiManager.getCriticalFunctionStatus()
  // 		.then((res)=>res.json())
  // 		.then(data=>{
  // 			setCfStatus(data.results)
  // 		})
  // }

  //remember to map with index when going through the loop and get the states from
  //cfStatus

  //TEST ONLY:
  //const cfStatus=[true,false];

  //Need Jiale to update the backend to make sure all the thing is completed
  const renderGraph = () => {
    const getPercentageComplete = (complete: number, N: number) => {
      return (complete / N) * 100.0;
    };
    return (
      <Grid container spacing={2} wrap="wrap-reverse" justify="flex-start">
        <Grid item xs={9}>
          <EventTreeMxGraphEditorView
            modelId={id}
            data={data}
            hide={{ transferGate: true, quantification: true }}
            setMaxHeight
          />
        </Grid>
        <Grid
          item
          xs={3}
          direction="row"
          justify="flex-end"
          alignItems="flex-end"
        >
          <span>All critical functions</span>
          {criticalFunctions &&
    criticalFunctions.map((cf, index) => (
        <Button
            fullWidth
            disabled={!cf.crt_model}
            color="primary"
            variant="contained"
            style={{ borderBottom: "2px solid black", marginTop: "1em", minHeight: "40px" }}
            onClick={() =>
              cf.progress.bp?.summary.completed/cf.progress.bp?.summary.total ?  history.push(`/model/${id}/main-analysis/${cf.crt_model}/bp`) : history.push(`/model/${id}/main-analysis/${cf.crt_model}/crt`)
            }
            key={index}
        >
            <ListItemAvatar style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                {cf.crt_model && (
                    <div style={{ marginRight: '10px', display: "flex", alignItems: "center" }}>
                        <div style={{ marginRight: '5px' }}>
                            Progress:
                        </div>
                        <div style={{ width: '50px', height: '20px', border: '1px solid black', position: 'relative' }}>
                            <div style={{ width: `${(cf.progress.bp?.summary.completed/cf.progress.bp?.summary.total)*100}%`, height: '100%', backgroundColor: 'green' }}></div>
                        </div>
                        <div style={{ marginLeft: '5px' }}>
                            {cf.progress.bp?.summary.completed}/{cf.progress.bp?.summary.total}
                        </div>
                    </div>
                )}
                <div style={{ maxWidth: '200px', wordWrap: 'break-word' }}>
                    <span>{cf.title}</span>
                </div>
            </ListItemAvatar>
        </Button>
    ))}




          <Button
            fullWidth
            color={"primary"}
            variant={"outlined"}
            style={{ borderBottom: "2px solid black", marginTop: "2em" }}
            disabled={!activeNextButton}
            onClick={() =>
              history.push(`/model/${details.id}/main-analysis/hfe/`)
            }
          >
            Next
          </Button>
          <Button
            fullWidth
            color={"primary"}
            variant={"outlined"}
            style={{ borderBottom: "2px solid black", marginTop: "3em" }}
            onClick={() =>
              (window.location.href = `/model/${details.id}/main-overview`)
            }
          >
            Go back
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <React.Fragment>
      <BreadcrumbsItem to={`/model/${details.id}/main-master-esd`}>
        {intl.formatMessage({ id: "master-esd" })}
      </BreadcrumbsItem>
      {/* <Typography>Before proceeding: PLEASE connect critical functions to its corresponding endstate and be sure to double-click and states to re-label their names.</Typography> */}
        

      {data ? renderGraph() : <CircularIndeterminate size={20} />}
    </React.Fragment>
  );
};

export default MasterESD_main;
