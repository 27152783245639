import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import Assessment from '@material-ui/icons/AssessmentTwoTone';

const styles = theme => ({
  root: theme.buttonTypes != null ? {
    color: theme.buttonTypes.transfer.backgroundColor,
    '&:hover': {
      color: theme.buttonTypes.transfer['&:hover'].backgroundColor,
    },
  } : {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.getContrastText(theme.palette.primary.main),
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
});

const ResultsIcon = (props) => {
  const { disabled, drawer, classes, ...others } = props;

  if (disabled) {
    return <Assessment color="disabled" {...others} />;
  }

  if (drawer) return <Assessment {...others} />;
  
  return <Assessment classes={{ root: classes.root }} {...others} />;
};

ResultsIcon.defaultProps = {
  disabled: false,
};

ResultsIcon.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  disabled: PropTypes.bool,
};


export default withStyles(styles)(ResultsIcon);
