import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { injectIntl } from 'react-intl';
import { Switch, Route } from 'react-router-dom';
import Typography from '@material-ui/core/Typography/Typography';
import { withStyles, Theme } from '@material-ui/core';

//@ts-ignore
import CircularIndeterminate from 'hcla-web-frontend-primitives/app/components/primitives/Progress/CircularIndeterminate';
//@ts-ignore
import ViewNoMatch from 'hcla-web-frontend-primitives/app/components/primitives/Error/ViewNoMatch';

import { CFMDetailsProgress } from '../../../Types';
import CFMListView from './CFMListView';
import PhoenixApiManager from '../../../../../Api/PhoenixApiManager';

const styles = (theme: Theme) => ({
  message: {
    padding: theme.spacing(1) * 4,
    textAlign: 'center',
    color: '#888',
  },
});

const updatableDataInitStates: Partial<State> = {
  items: [],
  isLoading: true,
};

interface Props {
  classes: { [key: string]: any },
  match: { [key: string]: any },
  intl: { [key: string]: any },
  location: { [key: string]: any },
  depth: number,
  detailsProgress: CFMDetailsProgress,
}

interface State {
  items: any[],
  isLoading: boolean,
  sort: (i: any) => any
}

class CFMs extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      ...updatableDataInitStates,
      sort: i => i,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    const { match: { params: { id } } } = this.props;
    PhoenixApiManager.getHFEsForModelWithId(id)
      .then(response => response.json())
      .then(data => this.setState({ items: data, isLoading: false }));
  };

  isHFEEmpty = () => {
    try {
      const { items } = this.state;
      return (items.every(obj => Object.keys(obj.cfms).length === 0));
    } catch (e) {
      return true;
    }
  };

  handleSortChange = (sort: any) => {
    this.setState({ sort });
    // todo: fetch data if pagination is enabled
  };

  render() {
    const { items, isLoading } = this.state;
    const {
      classes, depth, match: { params: { id,cfid } }, intl, detailsProgress,
    } = this.props;

    let content = null;
    if (isLoading) {
      content = <CircularIndeterminate size={80} color="secondary" />;
    }
    else if (this.isHFEEmpty()) {
      content = <Typography align="center" variant="h6" className={classes.message}>{intl.formatMessage({ id: 'analysis.cfmListView.defaultText' })}</Typography>
    }
    else {
      content = (
        <Switch>
          <Route
            exact
            path="/model/:id/main-analysis/:cfid/hfe/cfm/"
            render={prop => (
              <CFMListView
                items={items}
                isLoading={isLoading}
                filterInput={null}
                handleFilterChange={null}
                handleFilterSubmit={null}
                handleSortChange={this.handleSortChange}
                detailsProgress={detailsProgress}
                {...prop}
              />
            )}
          />
          <Route
            render={prop => (
              <ViewNoMatch
                {...prop}
                message={intl.formatMessage({ id: '404.pageNotFound' })}
              />
            )}
          />
        </Switch>
      );
    }
    return (
      <React.Fragment>
        <BreadcrumbsItem to={`/model/${id}/main-analysis/${cfid}/hfe/cfm`} depth={depth}>{intl.formatMessage({ id: 'analysis.cfm' })}</BreadcrumbsItem>
        {content}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(injectIntl(CFMs));
