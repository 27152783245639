import React from 'react';
import PropTypes from 'prop-types';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import Typography from '@material-ui/core/Typography';
import StepLabel from '@material-ui/core/StepLabel';
import StepButton from '@material-ui/core/StepButton';
import StepContent from '@material-ui/core/StepContent';
import { withStyles } from '@material-ui/core/styles';
import StepConnector from '@material-ui/core/StepConnector';
import DecisionPointIcon from './DPIcon';
import DPVerticalStepContent from './DPVerticalStepContent';

const styles = theme => ({
  root: {
    height: "100%",
    overflowY: 'auto'
  },

  stepContentRoot: {
    marginLeft: '20px',
    borderLeft: `2px solid ${theme.palette.secondary.main}`,
  },

  resetContainer: {
    padding: theme.spacing(1) * 3,
  },

  stepLabelTypography: {
    fontSize: '1.2rem',
    lineHeight: '1em',
    textAlign: 'left',
  },

  stepLabelIconContainer: {
    paddingRight: theme.spacing(1) * 4,
  },

  connectorActive: {
    '& $connectorLine': {
      marginLeft: '8px',
    },
  },

  connectorLine: {
    borderColor: theme.palette.secondary.main,
    marginLeft: '8px',
    borderWidth: '2px',
  },

  responseSummary: {
    paddingLeft: theme.spacing(1) * 7,
    marginLeft: '20px',
    color: theme.palette.grey[theme.palette.type === 'light' ? 700 : 500],
    borderLeft: `2px solid ${theme.palette.secondary.main}`,
  },

  responseSummaryLast: {
    paddingLeft: theme.spacing(1) * 7,
    marginLeft: '20px',
    color: theme.palette.grey[theme.palette.type === 'light' ? 700 : 500],
    borderLeft: '1px solid rgba(0,0,0,0)',
  },
});

class DPVerticalStepper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
    };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const { answered } = nextProps;
    if (answered.length === this.props.answered.length) {
      return;
    }

    const isLastQuestionAnswered = answered[answered.length - 1].user_response.response;

    this.setState({
      activeStep: isLastQuestionAnswered ? null : answered.length - 1,
    });
  }

  getStepConnector = () => {
    const { classes } = this.props;
    return (
      <StepConnector
        classes={{
          active: classes.connectorActive,
          line: classes.connectorLine,
        }}
      />
    );
  };

  incrementActiveStep = (event) => {
    this.setState(state => ({
      activeStep: state.activeStep + 1,
    }));
  };

  setActiveStep = (event, index) => {
    this.setState(() => ({
      activeStep: index,
    }));
  };

  getStep = (dp, idx) => { // This function helps generate the different questions on the analysis CRT page
    const { activeStep } = this.state;
    const { classes, answered, next,cfid } = this.props;

    const getInactiveResponseSummary = () => {
      const myClass = idx === answered.length - 1 ? classes.responseSummaryLast : classes.responseSummary;
      if (idx !== activeStep) {
        return (
          <Typography variant="body2" classes={{ root: myClass }}>
            {dp.user_response.description}
          </Typography>
        );
      }
      return null;
    };

    const handleRequestAnswerUpdate = (event, updated) => {
      const { onAnswerUpdate } = this.props;
      this.incrementActiveStep(event);
      onAnswerUpdate(event, {
        name: dp.name,
        ...updated,
      });
    };

    return (
      <Step key={dp.name} completed={idx < activeStep}>
        <StepButton
          disableRipple
          onClick={event => this.setActiveStep(event, idx)}
          icon={(
            <DecisionPointIcon
              completed={idx !== activeStep}
              green={dp.user_response.response === 'Yes'}
              label={dp.name}
            />
          )}
        >
          <StepLabel
            classes={{
              label: classes.stepLabelTypography,
              iconContainer: classes.stepLabelIconContainer,
            }}
          >
            {/* this is where the question is layed out, right below */}
            {dp.question} 
          </StepLabel>
        </StepButton>
        <StepContent classes={{ root: classes.stepContentRoot }}>
          <DPVerticalStepContent
            explanation={dp.description}
            response={dp.user_response.description}
            requestAnswerUpdate={handleRequestAnswerUpdate}
            cfid={cfid}
          />
        </StepContent>
        {getInactiveResponseSummary()}
      </Step>
    );
  };

  render() {
    const { classes, answered } = this.props;
    const { activeStep } = this.state;
    return (
      <div className={classes.root}>
        {/* Setting the connector to null removes the extra white space between steps */}
        <Stepper
          nonLinear
          activeStep={activeStep}
          orientation="vertical"
          connector={this.getStepConnector()}
        >
          {/* below is calling the getStep method with dp */}
          {answered.map((dp, idx) => (this.getStep(dp, idx)))}
        </Stepper>
      </div>
    );
  }
}

DPVerticalStepper.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  answered: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    question: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    user_response: PropTypes.shape({
      response: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }).isRequired,
  })).isRequired,
  onAnswerUpdate: PropTypes.instanceOf(Function).isRequired,
};

export default withStyles(styles)(DPVerticalStepper);
