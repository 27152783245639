import HumanFailureEventTraveler from "../HumanFailureEventTraveler";
import React, { useEffect, useState } from "react";
import BasicEventVertex from "hcl-web-editor/app/mxGraph/HCLTreeNode/FaultTreeNode/BasicEventNode/BasicEventVertex";
import FaultTreeTransferGateVertex
	from "hcl-web-editor/app/mxGraph/HCLTreeNode/FaultTreeNode/FaultTreeTransferGateNode/FaultTreeTransferGateVertex";
import { useIntl } from "react-intl";
import ErrorIcon from "@material-ui/icons/Error";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Chip from "@material-ui/core/Chip";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Theme } from "@material-ui/core";
import HumanFailureEventNodeTable from "./HumanFailureEventNodeTable";
import InsufficientTimeDialog from "../Dialogs/InsufficientTimeDialog";

const useStyles = makeStyles((theme: Theme) => ({
	errorMsg: {
		display: 'flex',
		backgroundColor: `${theme.palette.error.main}`,
		color: `${theme.palette.common.white}`,
		padding: '20px'
	},
	errorIcon: {
		verticalAlign: 'bottom',
		marginRight: '5px'
	}

}));

type HumanFailureEventControllerContentViewProps = {
	currentSelection: any,
	setCurrentSelection: any,
	traveler: HumanFailureEventTraveler,
	onResponseUpdated: any,
}

const HumanFailureEventControllerContentView = (props: HumanFailureEventControllerContentViewProps) => {

	const { currentSelection, setCurrentSelection, traveler, onResponseUpdated } = props;
	const [basicEventQueue, setBasicEventQueue] = useState([]);
	const [cfmQueue, setCFMQueue] = useState([]);
	const [displayInsufficientTimeDialog, setDisplayInsufficientTimeDialog] = useState(null);
	// useEffect(() => {
		// let graphElement = document.querySelector('svg g')
		// graphElement.setAttribute('transform', 'translate(276,416)scale(0.48)')

		// console.log("graphElement", graphElement)
	// }, [])
	useEffect(() => {
		let basicEvents: any = [];
		let cfms: any = [];
		if (Array.isArray(currentSelection)) {
			currentSelection.forEach((selection: any) => {
				if (selection instanceof BasicEventVertex) basicEvents.push(selection);
				else if (selection instanceof FaultTreeTransferGateVertex) cfms.push(selection);
			});

		}

		setBasicEventQueue(basicEvents);
		setCFMQueue(cfms);

	}, [currentSelection]);

	// this useEffect will trigger the dialog to insufficianet time, need to use same logic for the feasibility chart


	useEffect(() => {
		setDisplayInsufficientTimeDialog(traveler.getDisplayInsufficientTimeDialog())
	}, [traveler.getDisplayInsufficientTimeDialog()]);

	const classes = useStyles();
	const intl = useIntl();

	const errorMsg = (text: any) => {
		return (
			<div className={classes.errorMsg}>
				<ErrorIcon className={classes.errorIcon} />
				<Typography variant={"body1"}>{text}</Typography>
			</div>
		)
	};

	const summary = () => {
		const saved = traveler.getSummary();
		const lgGridNumber = () => {
			let count = 0;
			saved["CFMs"].length > 0 && count++;
			saved["House Events"].length > 0 && count++;
			saved["Basic Events"].length > 0 && count++;
			return 12 / count;
		};
		const display = (title: any, list: string[]) => {
			return (
				<Grid item xs={12} lg={lgGridNumber()}>
					<Typography variant={"overline"}>{title}</Typography>
					<Divider />
					<List>
						{list.map((name: string) => <ListItem>{name}</ListItem>)}
					</List>
				</Grid>
			)
		};
		return (
			<React.Fragment>
				<Typography variant={"body1"}>{intl.formatMessage({ id: 'summary' })}</Typography>
				<Grid container spacing={1}>
					{saved["CFMs"].length > 0 && display("CFMs", saved["CFMs"])}
					{saved["Basic Events"].length > 0 && display(intl.formatMessage({ id: 'basicEvents' }), saved["Basic Events"])}
					{saved["House Events"].length > 0 && display(intl.formatMessage({ id: 'houseEvents' }), saved["House Events"])}
				</Grid>
			</React.Fragment>
		);
	};

	if (currentSelection) {
		if (Array.isArray(currentSelection)) {
			return (
				<React.Fragment>
					<Typography>
						{intl.formatMessage({ id: "analysis.question" })}
					</Typography>
					{basicEventQueue.length > 0 && (
						<HumanFailureEventNodeTable
							title={intl.formatMessage({ id: "basicEvents" })}
							nodes={basicEventQueue}
							traveler={traveler}
						/>
					)}
					{cfmQueue.length > 0 && (
						<HumanFailureEventNodeTable
							title="CFMs"
							nodes={cfmQueue}
							traveler={traveler}
						/>
					)}
				</React.Fragment>
			);
		}
		if (currentSelection.getValue().getLabel().getName() == 'The Time Required for Completing the Task is Higher than the Available Time for the Crew') {
			return (
				<HumanFailureEventNodeTable
					title={intl.formatMessage({ id: "basicEvents" })}
					nodes={[currentSelection]}
					traveler={traveler}
				/>
			);

		}
		else {
			return (
				<React.Fragment>
					<Typography>
						{intl.formatMessage({ id: "analysis.question" })}
					</Typography>
					<Chip label={currentSelection.getValue().getLabel().getName()} />
					{/* <InsufficientTimeDialog
						displayInsufficientTimeDialog={displayInsufficientTimeDialog}
						traveler={traveler}
						currentSelection={currentSelection}
						setCurrentSelection={setCurrentSelection}
						onResponseUpdated={onResponseUpdated}
					/> */}
				</React.Fragment>
			);
		}
	} else if (traveler.savedNodesAreEmpty()) {
		return errorMsg(`You must select at least one Basic Event, House Event or CFM to continue.`);
	} else {

		return summary();
	}
};

export default HumanFailureEventControllerContentView;
