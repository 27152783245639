import FaultTreeTransferGateVertex
	from "hcl-web-editor/app/mxGraph/HCLTreeNode/FaultTreeNode/FaultTreeTransferGateNode/FaultTreeTransferGateVertex";
import {useIntl} from "react-intl";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Theme} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
	closeIcon: {
		position: 'absolute',
		top: 0,
		right: 0
	},
}));

type CFMEditingDialogProps = {
	targetCFM: FaultTreeTransferGateVertex,
	setTargetCFM: (cfm: FaultTreeTransferGateVertex) => void,
}

const CFMEditingDialog = (props: CFMEditingDialogProps) => {

	const { targetCFM, setTargetCFM } = props;

	const intl = useIntl();
	const classes = useStyles();

	return targetCFM && (
		<Dialog
			open={Boolean(targetCFM)}
			onClose={() => setTargetCFM(null)}
			fullWidth={false}
			maxWidth="md"
		>
			<DialogTitle id="cfm-dialog-title" disableTypography>
				<Typography variant={"h6"}>{targetCFM.value.label.name}</Typography>
				<IconButton onClick={() => setTargetCFM(null)} className={classes.closeIcon}>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent dividers>
				<Typography variant={"body1"}>{targetCFM.value.label.description !== "" ? targetCFM.value.label.description : `${intl.formatMessage({ id: 'noDescriptionFor' })} ${targetCFM.value.label.name}`}</Typography>
			</DialogContent>
		</Dialog>
	);
};

export default CFMEditingDialog;