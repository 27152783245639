import React from 'react';
import {WithStyles, withStyles} from '@material-ui/core/styles/index';
import Toolbar from '@material-ui/core/Toolbar/index';
import ZoomIn from '@material-ui/icons/ZoomInTwoTone';
import ZoomOut from '@material-ui/icons/ZoomOutTwoTone';
import Center from '@material-ui/icons/CenterFocusStrongTwoTone';
import ControlCamera from '@material-ui/icons/ControlCameraTwoTone';
import createStyles from "@material-ui/core/styles/createStyles";

import Undo from '@material-ui/icons/UndoTwoTone';
import Redo from '@material-ui/icons/RedoTwoTone';

import Copy from '@material-ui/icons/FileCopyTwoTone';
import Delete from '@material-ui/icons/DeleteTwoTone';

//import ActionBarMenuButton from './ActionBarButton';
import ActionBarIconButton from 'hcl-web-editor/app/components/Model/SingleHCLModelView/SingleTreeView/Tools/ActionBar/ActionBarIconButton';

import Quantification from "hcl-web-editor/app/components/Model/SingleHCLModelView/SingleTreeView/Tools/Quantification";

import HCLTreeVertex from "hcl-web-editor/app/mxGraph/HCLTreeNode/HCLTreeVertex";
//import ActionBarFileMenuButton from "./ActionBarFileMenuButton";
//import ActionBarViewMenuButton from "./ActionBarViewMenuButton";

import { addClonedCells } from 'hcl-web-editor/app/components/Model/SingleHCLModelView/SingleTreeView/Tools/Utils';
import HCLTreeMxGraph from "hcl-web-editor/app/mxGraph/HCLTreeMxGraph/HCLTreeMxGraph";
import LayersMenuButton from "hcl-web-editor/app/components/Model/SingleHCLModelView/SingleTreeView/Tools/ActionBar/LayersMenuButton";
import {PreferencesConsumer} from'hcla-web-frontend-primitives/app/config/PreferencesProvider';

const styles = () => createStyles({
  root: {
    backgroundColor: 'rgba(133,133,133,0.15)',
    borderBottom: '1px solid #838796',
  },
  toolbarRoot: {
    height: '36px',
    minHeight: '36px',
    justifyContent: 'space-between',
    paddingRight: 'unset',
    paddingLeft: '4px',
    // '& div:last-child': {
    //   borderRight: 'unset',
    // },
  },
  toolbarOuter: {
    display: 'flex',
  },
  toolbarSection: {
    height: '36px',
    display: 'flex',
    alignItems: 'center',
    borderRight: '1px solid #838796',
    '& button:last-child': {
      marginRight: '12px',
    },
  },
});

interface Props extends WithStyles<typeof styles> {
  graph: HCLTreeMxGraph;
  quantifyDisabled: boolean;
  trashDisabled?: boolean;
  modelId: number;
  treeId: number;
  treeType: string;
  handleSave: () => void;
  cloneDisabled: boolean;
}

class ActionBar extends React.Component<Props> {

  constructor(props: Props) {
    super(props);
  }

  private handleEditClick = (e: React.MouseEvent<HTMLElement>) => {
    alert("Edit");
  };

  private handleHelpClick = (e: React.MouseEvent<HTMLElement>) => {
    alert("Help");
  };

  private handleZoomInClick = (e: React.MouseEvent<HTMLElement>) => {
    const { graph } = this.props;
    graph.zoomIn();
  };

  private handleZoomOutClick = (e: React.MouseEvent<HTMLElement>) => {
    const { graph } = this.props;
    graph.zoomOut();
  };

  private handleCenterClick = (e: React.MouseEvent<HTMLElement>) => {
    const { graph } = this.props;
    graph.center(null, null, null, null);
  };

  private handleAutoLayoutClick = (e: React.MouseEvent<HTMLElement>) => {
    const { graph } = this.props;
    graph.applyDefaultLayout();
  };

  private trashCell = (e: React.MouseEvent<HTMLElement>) => {
    const { graph } = this.props;
    const cells = graph.getSelectionCells();
    cells.forEach((cell) => {
      if (cell.edge) {
        graph.removeCells([cell]);
      } else if (cell instanceof HCLTreeVertex) {
        graph.removeVertex(cell);
      } else {
        console.error("Unable to remove cell");
      }
    });
  };

  private cloneCells = () => {
    const { graph } = this.props;
    const cells = graph.getSelectionCells();
    addClonedCells(cells, graph);
  };

  render() {
    const {
      classes,
      graph,
      quantifyDisabled,
      trashDisabled,
      handleSave,
      modelId,
      treeId,
      treeType,
      cloneDisabled,
      hide
    } = this.props;

    return (
      <div className={classes.root}>
        <Toolbar className={classes.toolbarRoot} variant="dense">
          <div className={classes.toolbarOuter}>
            <div className={classes.toolbarSection}>
              {/*<ActionBarFileMenuButton graph={graph} handleSave={handleSave} />
              <ActionBarMenuButton onClick={this.handleEditClick} disabled>Edit</ActionBarMenuButton>
              <ActionBarViewMenuButton graph={graph} />
              <ActionBarMenuButton onClick={this.handleHelpClick} disabled>Help</ActionBarMenuButton>
              <LayersMenuButton graph={graph} />*/}
            </div>
            <div className={classes.toolbarSection}>
              <ActionBarIconButton icon={<ZoomIn />} tooltip="Zoom In" onClick={this.handleZoomInClick} />
              <ActionBarIconButton icon={<ZoomOut />} tooltip="Zoom Out" onClick={this.handleZoomOutClick} />
              <ActionBarIconButton icon={<Center />} tooltip="Center" onClick={this.handleCenterClick} />
              <ActionBarIconButton icon={<ControlCamera />} tooltip="AutoLayout" onClick={this.handleAutoLayoutClick} />
            </div>
            <div className={classes.toolbarSection}>
              <ActionBarIconButton icon={<Undo />} tooltip="Undo" disabled />
              <ActionBarIconButton icon={<Redo />} tooltip="Redo" disabled />
            </div>
            <div className={classes.toolbarSection}>
              {/*<ActionBarIconButton icon={<Copy />} tooltip="Copy" onClick={this.cloneCells} disabled={cloneDisabled} />
              <ActionBarIconButton icon={<Delete />} tooltip="Delete" onClick={this.trashCell} disabled={trashDisabled} />*/}
            </div>
          </div>
          {hide && hide.quantification ? null : (
            <div className={classes.toolbarOuter}>
              <div className={classes.toolbarSection}>
                <PreferencesConsumer>
                  {({getPreference, setPreference}) => (
                    <Quantification
                      modelId={modelId}
                      treeId={treeId}
                      treeType={treeType}
                      quantifyDisabled={quantifyDisabled}
                      getPreference={getPreference}
                      setPreference={setPreference}
                    />
                  )}
                </PreferencesConsumer>
              </div>
            </div>
          )}
        </Toolbar>
      </div>
    );
  }
}

export default withStyles(styles)(ActionBar);
