import { mxConstants as MxConstants, mxGeometry as MxGeometry } from 'mxgraph-js';
import FunctionalEventVertex from 'hcl-web-editor/app/mxGraph/HCLTreeNode/EventTreeNode/FunctionalEventNode/FunctionalEventVertex';
import FunctionalEventVertexValue from 'hcl-web-editor/app/mxGraph/HCLTreeNode/EventTreeNode/FunctionalEventNode/FunctionalEventVertexValue';
import HCLTreeVertex from 'hcl-web-editor/app/mxGraph/HCLTreeNode/HCLTreeVertex';
import FunctionalEventSuccessPort
  from 'hcl-web-editor/app/mxGraph/HCLTreeNode/EventTreeNode/FunctionalEventNode/Port/FunctionalEventSuccessPort';
import FunctionalEventFailurePort
  from 'hcl-web-editor/app/mxGraph/HCLTreeNode/EventTreeNode/FunctionalEventNode/Port/FunctionalEventFailurePort';

class DecisionPointVertex extends FunctionalEventVertex {

  // successPort = this;
  // failurePort = this;

  static SHAPE = {
    HEIGHT: 56,
    WIDTH: 56,
  };

  /**
   * @param {string} key
   * @param {FunctionalEventVertexValue} value
   */
  constructor(key, value) {
    super(key, value);
  }

  /**
   * @override
   * @return MxGraphType.mxGeometry
   */
  getMyGeometry() {
    return new MxGeometry(0, 0, DecisionPointVertex.SHAPE.WIDTH, DecisionPointVertex.SHAPE.HEIGHT);
  }

  getType() {
    return "Decision Point";
  }

  getMyStyle() {
    const style = {};
    style[MxConstants.STYLE_ROUNDED] = 1;
    style[MxConstants.STYLE_ARCSIZE] = 10;
    style[MxConstants.STYLE_SPACING] = 4;
    style[MxConstants.STYLE_STROKEWIDTH] = '2';
    style[MxConstants.STYLE_FONTSIZE] = '12';
    style[MxConstants.STYLE_FONTCOLOR] = 'inherit';
    style[MxConstants.STYLE_SHAPE] = MxConstants.SHAPE_RHOMBUS;
    style[MxConstants.STYLE_WHITE_SPACE] = 'wrap';
    style[MxConstants.STYLE_AUTOSIZE] = 1;
    style[MxConstants.STYLE_RESIZABLE] = 0;
    style[MxConstants.STYLE_ASPECT] = 'fixed';
    style[MxConstants.STYLE_OVERFLOW] = 'hidden';
    return style;
  }

  static get inactiveStyle() {
    const style = {};
    style[MxConstants.STYLE_FILLCOLOR] = '#433d3a';
    style[MxConstants.STYLE_STROKECOLOR] = '#5f5858';
    style[MxConstants.STYLE_STROKE_OPACITY] = '60';
    style[MxConstants.STYLE_FILL_OPACITY] = '0';
    style[MxConstants.STYLE_STROKEWIDTH] = '1';
    return style;
  }

  static get activeStyle() {
    const style = {};
    style[MxConstants.STYLE_STROKE_OPACITY] = '80';
    style[MxConstants.STYLE_FILL_OPACITY] = '40';
    style[MxConstants.STYLE_STROKEWIDTH] = '2';
    style[MxConstants.STYLE_FILLCOLOR] = '#c17c15';
    style[MxConstants.STYLE_STROKECOLOR] = '#c9682c';
    return style;
  }

  static get yesStyle() {
    const { activeStyle } = DecisionPointVertex;
    activeStyle[MxConstants.STYLE_FILLCOLOR] = '#5ac1a4';
    activeStyle[MxConstants.STYLE_STROKECOLOR] = '#3b9780';
    return activeStyle;
  }

  static get noStyle() {
    const { activeStyle } = DecisionPointVertex;
    activeStyle[MxConstants.STYLE_FILLCOLOR] = '#ca4830';
    activeStyle[MxConstants.STYLE_STROKECOLOR] = '#ca4830';
    return activeStyle;
  }

  /**
   * Define an return an array of source connection constraints for a functional/pivotal event. Constrains outgoing
   * edges to either the bottom,middle section or the middle,rightmost section of the vertex.
   * @return MxGraphType.mxConnectionConstraint[]
   */
  getSourceConnectionConstraints() {
    return [];
  }

  /**
   * Define an return a singleton array of target connection constraints for a functional/pivotal event. Constrains
   * incoming edges to the leftmost,middle section of the vertex.
   * @return MxGraphType.mxConnectionConstraint[]
   */
  getTargetConnectionConstraints() {
    return [];
  }

  onAddedToGraph(graph) {
    super.onAddedToGraph(graph);
    this.successPort.setVisible(false);
    this.failurePort.setVisible(false);
  }

  clone() {
    return new DecisionPointVertex(null, this.value.clone());
  }
}

export default DecisionPointVertex;
