import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { injectIntl } from 'react-intl';

import { computeHEPFromData } from '../../../Quantification/QuantificationView';
import { HFEType } from "../../../../Types";
import PhoenixApiManager from '../../../../../../Api/PhoenixApiManager';
import QuantificationCard from '../../../Quantification/QuantificationCard';

interface Props {
  match: { [key: string]: any },
  depth: number,
  intl: any,
  hfe: HFEType
}

interface State {
  tree: {
    id: number,
    loading: boolean,
    loaded: boolean
  },
  quantificationResult: {
    data: { [key: string]: any },
    loaded: boolean,
    loading: boolean,
  },
}
class QuantifyHFE extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      tree: {
        id: null,
        loading: true,
        loaded: false,
      },
      quantificationResult: {
        data: {},
        loaded: false,
        loading: true,
      },
    };
  }

  componentDidMount() {
    const { match: { params: { hfeName, id,cfid } } } = this.props;
    PhoenixApiManager.getAnsweredBranchPointForModelWithId(cfid, hfeName)
      .then(response => response.status === 200 && response.json())
      .then((bp) => {
        if ('fault_tree_id' in bp) {
          this.setState({
            tree: {
              id: bp.fault_tree_id, // setting stuff fine
              loading: false,
              loaded: true,
            },
          }, () => this.fetchQuantificationResults(bp.fault_tree_id));
        } else {
          this.setState({
            tree: {
              id: null,
              loading: false,
              loaded: false,
            },
            quantificationResult: {
              data: {},
              loading: false,
              loaded: false,
            },
          });
        }
      });
  }

  fetchQuantificationResults = (treeId: number) => {
    const { match: { params: { cfid, id } } } = this.props;
    PhoenixApiManager.getQuantificationResultsForPhoenixTreeAndModelWithId(treeId, id)
      .then(response => response.status === 200 && response.json())
      .then((list) => {
        if (list.results ? list.results.length === 0 : list.length === 0) {
          this.setState({
            quantificationResult: {
              data: {},
              loading: false,
              loaded: true,
            },
          });
        } else {
          this.setState({
            quantificationResult: { // the api call turns up with nothing,
              data: list.results ? list.results[list.results.length - 1] : list[list.length - 1],
              loading: false,
              loaded: true,
            },
          });
        }
      });
  };

  isModelQuantifiable = () => {
    const { tree } = this.state;
    return tree.id !== null && tree.id !== undefined;
  };

  quantify = (configuration: any) => {
    const { match: { params: { id,cfid } } } = this.props;
    this.setState({
      quantificationResult: {
        data: {},
        loading: true,
        loaded: false,
      },
    }, () => {
      const { tree } = this.state;
      PhoenixApiManager.quantifyPhoenixTreeForModelWithId(tree.id, cfid, configuration)
        .then(response => response.status === 201 && response.json())
        .then((data) => {
          this.setState({
            quantificationResult: {
              data,
              loading: false,
              loaded: true,
            },
          });
        });
    });
  };

  render() {
    const { depth, match: { params: { hfeName, id,cfid } }, intl, hfe } = this.props;
    const { quantificationResult: { loaded, loading, data } } = this.state;
    console.log("fstate:", this.state, "PROPS:", this.props)
    return (
      <React.Fragment>
        <BreadcrumbsItem to={`/model/${id}/main-analysis/${cfid}/hfe/${hfeName}/quantify`} depth={depth}>
          {intl.formatMessage({ id: 'quantify' })}
        </BreadcrumbsItem>
        <QuantificationCard
          loaded={loaded}
          loading={loading}
          data={data}
          onRequestQuantify={(configuration: any) => this.quantify(configuration)}
          title={hfe ? hfe.label.name : intl.formatMessage({ id: 'results.quantificationHEP' })}
          computeErrorFunc={computeHEPFromData}
          quantifyDisabled={!this.isModelQuantifiable()}
          hfe={hfe}
        />
      </React.Fragment>
    );
  }
}

export default injectIntl(QuantifyHFE);
