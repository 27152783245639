import { mxConstants as MxConstants, mxGeometry as MxGeometry } from 'mxgraph-js';

import EndStateVertex from 'hcl-web-editor/app/mxGraph/HCLTreeNode/EventTreeNode/EndStateNode/EndStateVertex';
import HCLTreeEdge from 'hcl-web-editor/app/mxGraph/HCLTreeNode/HCLTreeEdge';

class PhoenixEndStateVertex extends EndStateVertex {
  static SHAPE = {
    HEIGHT: 40,
    WIDTH: 80,
  };

  /**
   * @override
   * @return {MxGraphType.mxGeometry}
   */
  getMyGeometry() {
    return new MxGeometry(0, 0, PhoenixEndStateVertex.SHAPE.WIDTH, PhoenixEndStateVertex.SHAPE.HEIGHT);
  }

  static get inactiveStyle() {
    const style = HCLTreeEdge.inactiveStyle;
    style[MxConstants.STYLE_FILL_OPACITY] = '0';
    return style;
  }

  static get activeStyle() {
    const style = {};
    style[MxConstants.STYLE_FILLCOLOR] = 'rgba(238,152,56,0.20)';
    style[MxConstants.STYLE_STROKECOLOR] = 'rgba(238,152,56,0.75)';
    style[MxConstants.STYLE_FILL_OPACITY] = '60';
    style[MxConstants.STYLE_STROKE_OPACITY] = '60';
    style[MxConstants.STYLE_STROKEWIDTH] = '2';
    return style;
  }

  clone() {
    return new PhoenixEndStateVertex(null, this.value.clone());
  }
}

export default PhoenixEndStateVertex;
