//PROBLEMATIC!!!!!! THE  ID REFERENCE IS A BIG PROBLEM--> IT REFERS TO THE MAIN MODE
import * as React from "react";
import {Link} from 'react-router-dom';
import withStyles from "@material-ui/core/styles/withStyles";
import {createStyles, Theme} from "@material-ui/core";

import ApiManager from "hcla-web-frontend-primitives/app/Api/ApiManager";
import CutsetItemChip, { CutsetItemChipProps } from "hcl-web-editor/app/components/Model/QuantificationResult/Chips/CutsetItemChip";
import NodeLabelChip from "hcl-web-editor/app/components/Model/QuantificationResult/NodeLabel/NodeLabelChip";

export const cutsetItemStyles = (theme: Theme) => createStyles({
  chipRoot: {
    margin: `0px ${theme.spacing(1) / 2}px`,
    flexDirection: 'row',
  },
});

const PhoenixCutsetItemChip = (props: CutsetItemChipProps): React.ReactElement  => {
  const { ...others } = props;
  const { map, name } = others;
  if (map[name] && 'phoenix_attrs' in map[name]) {
    const hfeName = map[name]['phoenix_attrs']['hfe']['name'];
    const cfmName = map[name]['phoenix_attrs']['cfm']['name'];
    const id = map[name]['phoenix_attrs']['model']['id'];
    const pifPathBasename = `${ApiManager.MODEL_PATH}/${id}/main-analysis/hfe/${hfeName}/cfm/${cfmName}/pif/`;
    return (
      <Link target="_blank" to={{ pathname: pifPathBasename }}>
        <NodeLabelChip {...others} clickable />
      </Link>
    );
  }

  return (
    <CutsetItemChip
      {...others}
    />
  );
};

export default withStyles(cutsetItemStyles)(PhoenixCutsetItemChip);

