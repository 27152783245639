import {
  mxGraphLayout as MxGraphLayout,
  mxGeometry as MxGeometry,
  mxConstants as MxConstants,
  mxEdgeStyle as MxEdgeStyle,
  mxStyleRegistry as MxStyleRegistry,
  mxPoint as MxPoint
} from "mxgraph-js";

const GAP_X = 200;
const GAP_Y = 150;
const START_X = 250;
const START_Y = 50;

const OFFSET_Y = 8;

const JUMP_RADIUS = 8;

const EDGE_STYLE = {
  STRAIGHT: "S",
  LEFT_DOWN_RIGHT: "LDR",
  LEFT_UP_RIGHT: "LUR",
  DOWN_RIGHT: "DR",
  UP_RIGHT: "UR",
  LEFT_DOWN_RIGHT_SHORT: "LDRS",
  LEFT_DOWN_RIGHT_WITH_OFFSET: "LDRWO",
  LEFT_UP_RIGHT_WITH_OFFSET: "LURWO",
  LEFT_DOWN_RIGHT_SHORT_WITH_JUMP: "LDRSWJ"
};

class CustomGraphLayout extends MxGraphLayout {
  constructor(graph, positions, edges) {
    super(graph);
    this.positions = this.objectMap(positions, position => {
      return [START_X + position[0] * GAP_X, START_Y + position[1] * GAP_Y];
    });

    this.edges = edges;
    this.registerCustomEdgeStyles();
  }

  objectMap = (object, mapFunction) => {
    return Object.keys(object).reduce((result, key) => {
      result[key] = mapFunction(object[key]);
      return result;
    }, {});
  };

  static get EDGE_STYLE() {
    return EDGE_STYLE;
  }

  registerCustomEdgeStyles = () => {
    MxEdgeStyle.Straight = this.straight;
    MxEdgeStyle.LeftDownRight = this.leftDownRight;
    MxEdgeStyle.LeftUpRight = this.leftUpRight;
    MxEdgeStyle.DownRight = this.downRight;
    MxEdgeStyle.LeftDownRightShort = this.leftDownRightShort;
    MxEdgeStyle.UpRight = this.upRight;
    MxEdgeStyle.LeftDownRightWithOffset = this.leftDownRightWithOffset;
    MxEdgeStyle.LeftUpRightWithOffset = this.leftUpRightWithOffset;
    MxEdgeStyle.LeftDownRightShortWithJump = this.leftDownRightShortWithJump;

    MxStyleRegistry.putValue(EDGE_STYLE.STRAIGHT, MxEdgeStyle.Straight);
    MxStyleRegistry.putValue(
      EDGE_STYLE.LEFT_DOWN_RIGHT,
      MxEdgeStyle.LeftDownRight
    );
    MxStyleRegistry.putValue(EDGE_STYLE.LEFT_UP_RIGHT, MxEdgeStyle.LeftUpRight);
    MxStyleRegistry.putValue(EDGE_STYLE.DOWN_RIGHT, MxEdgeStyle.DownRight);
    MxStyleRegistry.putValue(
      EDGE_STYLE.LEFT_DOWN_RIGHT_SHORT,
      MxEdgeStyle.LeftDownRightShort
    );
    MxStyleRegistry.putValue(EDGE_STYLE.UP_RIGHT, MxEdgeStyle.UpRight);
    MxStyleRegistry.putValue(
      EDGE_STYLE.LEFT_DOWN_RIGHT_WITH_OFFSET,
      MxEdgeStyle.LeftDownRightWithOffset
    );
    MxStyleRegistry.putValue(
      EDGE_STYLE.LEFT_UP_RIGHT_WITH_OFFSET,
      MxEdgeStyle.LeftUpRightWithOffset
    );
    MxStyleRegistry.putValue(
      EDGE_STYLE.LEFT_DOWN_RIGHT_SHORT_WITH_JUMP,
      MxEdgeStyle.LeftDownRightShortWithJump
    );
  };

  straight = (state, source, target, points, result) => {};

  vertical = (state, source, target, points, result) => {
    if (source != null && target != null) {
      const start = new MxPoint(source.getCenterX(), source.getCenterY());
      const vertical = new MxPoint(start.x, target.getCenterY());

      result.push(vertical);
    }
  };

  leftVerticalRight = (
    state,
    source,
    target,
    points,
    result,
    gap,
    yOffset = 0
  ) => {
    if (source != null && target != null) {
      const scale = state.view.scale;
      const start = new MxPoint(source.getCenterX(), source.getCenterY());
      const left = new MxPoint(start.x - gap * scale, start.y);
      const vertical = new MxPoint(left.x, target.getCenterY() + yOffset);
      if (yOffset) {
        state.setAbsoluteTerminalPoint(
          new MxPoint(
            target.origin.x,
            target.origin.y + target.height / 2 + yOffset
          ),
          false
        );
      } else {
        state.setAbsoluteTerminalPoint(null, false);
      }

      result.push(left);
      result.push(vertical);
    }
  };

  leftDownRightWithJump = (
    state,
    source,
    target,
    points,
    result,
    gap,
    jumpYPosition
  ) => {
    if (source != null && target != null) {
      const start = new MxPoint(source.getCenterX(), source.getCenterY());
      const left = new MxPoint(start.x - gap, start.y);
      result.push(left);

      const circleCenter = new MxPoint(left.x, jumpYPosition);
      for (
        let angle = Math.PI / 2;
        angle >= -Math.PI / 2;
        angle -= Math.PI / 8
      ) {
        result.push(
          new MxPoint(
            circleCenter.x + JUMP_RADIUS * Math.cos(angle),
            circleCenter.y - JUMP_RADIUS * Math.sin(angle)
          )
        );
      }

      const down = new MxPoint(left.x, target.getCenterY());
      result.push(down);

      state.setAbsoluteTerminalPoint(null, false);
    }
  };

  leftDownRightShortWithJump = (state, source, target, points, result) => {
    this.leftDownRightWithJump(
      state,
      source,
      target,
      points,
      result,
      GAP_X / 2,
      START_Y + 4 * GAP_Y
    );
  };

  leftDownRight = (state, source, target, points, result) => {
    this.leftVerticalRight(state, source, target, points, result, GAP_X);
  };

  leftDownRightWithOffset = (state, source, target, points, result) => {
    this.leftVerticalRight(
      state,
      source,
      target,
      points,
      result,
      GAP_X,
      -OFFSET_Y
    );
  };

  leftUpRight = (state, source, target, points, result) => {
    this.leftVerticalRight(state, source, target, points, result, GAP_X);
  };

  leftUpRightWithOffset = (state, source, target, points, result) => {
    this.leftVerticalRight(
      state,
      source,
      target,
      points,
      result,
      GAP_X,
      OFFSET_Y
    );
  };

  leftDownRightShort = (state, source, target, points, result) => {
    this.leftVerticalRight(state, source, target, points, result, GAP_X / 2);
  };

  downRight = (state, source, target, points, result) => {
    this.vertical(state, source, target, points, result);
  };

  upRight = (state, source, target, points, result) => {
    this.vertical(state, source, target, points, result);
  };

  execute() {
    const graph = this.getGraph();
    const graphModel = graph.model;

    const edges = Object.values(graphModel.cells).filter(cell => cell.edge);

    // edge layout
    edges.forEach(edge => {
      let key = edge.source.key + "->" + edge.target.key;
      if (!(key in this.edges)) return;
      const style = JSON.stringify(edge.style).replace(/"/g, "");
      graphModel.setStyle(edge, style.concat(";edgeStyle=" + this.edges[key]));
    });

    // vertex layout
    Object.entries(graph.verticesMap).forEach(entry => {
      const key = entry[0];
      const cellArray = entry[1];

      if (!(key in this.positions)) return;

      cellArray.forEach(cell => {
        const pos = this.positions[key];

        const { width, height } = cell.geometry;
        const geometry = new MxGeometry(
          pos[0] - width / 2,
          pos[1] - height / 2,
          width,
          height
        );

        graphModel.setGeometry(cell, geometry);
      });
    });
  }
}

export default CustomGraphLayout;
