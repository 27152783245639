import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import Button from '@material-ui/core/Button';
import ResultsIcon from '../../../primitives/Icons/ResultsIcon';
import { withStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import ApiManager from 'hcla-web-frontend-primitives/app/Api/ApiManager';
import { injectIntl } from 'react-intl';

const styles = theme => ({
  root: theme.buttonTypes != null ? {
    color: theme.buttonTypes.transfer.backgroundColor,
    border: `1px solid ${theme.buttonTypes.transfer.backgroundColor}`,
    '&:hover': {
      color: theme.buttonTypes.transfer['&:hover'].backgroundColor,
      border: `1px solid ${theme.buttonTypes.transfer['&:hover'].backgroundColor}`,
    },
  } : {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.getContrastText(theme.palette.primary.main),
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
});

const ModelEntryResultsIcon = (props) => {
  const { details, classes, complete, intl } = props;

  if (!complete) {
    return (
      <Button
        size="small"
        variant="outlined"
        disabled
        aria-label="ResultsIcon"
      >
        <ResultsIcon disabled={!complete} />
      </Button>
    );
  }
  return (
    <Tooltip title={intl.formatMessage({ id: 'results' })}>
      <Button
        component={Link}
        to={{
          pathname: `${ApiManager.MODEL_PATH}/${details.id}/quantification/`,
          state: { details },
        }}
        size="small"
        variant="outlined"
        disabled={!complete}
        className={classes.root}
        aria-label="ResultsIcon"
      >
        <ResultsIcon disabled={!complete} />
      </Button>
    </Tooltip>
  );
};

ModelEntryResultsIcon.defaultProps = {
  complete: false,
};

ModelEntryResultsIcon.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  details: PropTypes.instanceOf(Object).isRequired,
  complete: PropTypes.bool,
};

export default withStyles(styles)(injectIntl(ModelEntryResultsIcon));
