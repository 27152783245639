import React from 'react';
import { injectIntl } from 'react-intl';
import SingleSelect from 'hcla-web-frontend-primitives/app/components/primitives/Select/SingleSelect';
import PhoenixApiManager from '../../Api/PhoenixApiManager';
import ApiManager from "hcla-web-frontend-primitives/app/Api/ApiManager";

interface Props {
  handleUpdate: (event: any) => void,
  selected: string,
  placeholder: string,
  intl: { [key: string]: any }
}
interface State {
  paginationEnabled: boolean,
  selected: any[],
  suggestionTotal: number,
  suggestions: {[key: string]: any }[]
}

interface Item {
  label: string;
  value: number;
}

class ModelTemplateSelect extends React.Component<Props, State> {
  static  performMapping(data: { title: any; id: any; }[]) {
    return data.map((template: { title: any; id: any; }) => ({
      label: template.title,
      value: template.id,
    }));
  }

  static mapSelected(allMapped: any[], selected: any) {
    return allMapped.filter(entry => entry.value === selected);
  }

  constructor(props: Props) {
    super(props);
    this.state = {
      suggestions: [],
      selected: [],
      paginationEnabled: false,
      suggestionTotal: null
    };
  }

  /*
  When the component mounts, retrieve the list
   */
  componentDidMount() {
    const { selected } = this.props;
    PhoenixApiManager.getTemplates()
      .then((response: { json: () => any; }) => response.json())
      .then((data: any) => {
        const allMapped = ModelTemplateSelect.performMapping(data && data.results ? data.results : data);
        const filteredTemplate = this.filterByLabel(allMapped)
        this.setState({
          suggestions: filteredTemplate,
          selected: ModelTemplateSelect.mapSelected(filteredTemplate, selected),
          suggestionTotal: data && data.count ? data.count : null,
          paginationEnabled: !!(data && data.results)
        });
      });
  }

  filterByLabel(arr: Item[]): Item[] {
    return arr.filter(item => item.label === "Phoenix3" || item.label === "Phoenix2023");
}

  loadMoreSuggestions = (limit: number, offset: number) => {
    ApiManager.getUsers(limit, offset)
      .then(response => response.json())
      .then((data) => {
        this.setState(prevState => ({
          suggestions: [...prevState.suggestions],
          suggestionTotal: data && data.count ? data.count : null,
          paginationEnabled: !!(data && data.results),
        }));
      });
  };

  componentWillReceiveProps(nextProps: any, nextContext: any) {
    const { suggestions } = this.state;
    const { selected } = nextProps;
    this.setState({
      selected: ModelTemplateSelect.mapSelected(suggestions, selected),
    });
  }

  handleChange = (selected: { value: any; }) => {
    const { handleUpdate } = this.props;
    const event = {
      target: {
        value: selected.value,
      },
    };
    handleUpdate(event);
  };

  render() {
    const { suggestions, suggestionTotal, paginationEnabled, selected } = this.state;
    const { placeholder, intl } = this.props;
    return (
      <SingleSelect
        handleUpdate={this.handleChange}
        suggestions={suggestions}
        suggestionTotal={2}
        loadMoreSuggestions={this.loadMoreSuggestions}
        paginationEnabled={paginationEnabled}
        placeholder={placeholder || intl.formatMessage({ id: 'selectTemplate' })}
        selected={selected}
      />
    );
  }
}

export default injectIntl(ModelTemplateSelect);
