import React from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Button from "@material-ui/core/Button";
import Divider from '@material-ui/core/Divider';


const BasicEventAccordion = ({
  // basicEvent,
  cfs,
  expanded,
  focusOnGroup,
  groupIndex,
  labelMap
}) => {
  // console.log(basicEvent, expanded, groupIndex);
  // return (
  //   <div>
  //     <Button>New Group</Button>
  //   </div>
  // )

  console.log(expanded);

  return (
    <>
      <Accordion expanded={expanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          onClick={() => focusOnGroup(groupIndex)}
        >
          <Typography>Group {groupIndex}</Typography>
          <Divider />
        </AccordionSummary>
        <AccordionDetails>
          {Object.keys(cfs).map((cf) => (
            <div style={{ paddingLeft: "2%" }}>
              <Typography>{labelMap[`${cf}`]}</Typography>
              <Divider />
              {Object.keys(cfs[cf]).map((basicEvent) => (
                <div style={{ paddingLeft: "2%" }}>
                  {/* {console.log(basicEvent)} */}
                  {Object.keys(cfs[cf][basicEvent]).map((basic) => (
                    <div>
                      <Typography>{basic}</Typography>
                      {/* <div style={{ paddingLeft: "10%" }}>
                        {Object.keys(cfs[cf][basicEvent][basic]).map((cfm) => (
                          <Typography>{cfs[cf][basicEvent][basic][cfm]}</Typography>
                        ))}
                      </div> */}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          ))}
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default BasicEventAccordion;
