import React, {useEffect, useState} from 'react';
import { withRouter, Link } from 'react-router-dom';
import {Theme} from '@material-ui/core/styles';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import {useIntl} from 'react-intl';

import ApiManager from 'hcla-web-frontend-primitives/app/Api/ApiManager';

import CircularProgressIcon from '../../../../primitives/Progress/CircularProgressIcon';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import BubbleChartIcon from '@material-ui/icons/BubbleChart';
import makeStyles from "@material-ui/core/styles/makeStyles";
import {
  PhoenixEventTreeMxGraph,
  PhoenixFaultTreeMxGraph,
  PhoenixFlowChartMxGraph,
} from "../../PhoenixTreeMxGraphView";
import PhoenixApiManager from "../../../../../Api/PhoenixApiManager";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Accordion } from '@material-ui/core';
import { AccordionSummary } from '@material-ui/core';
import { AccordionDetails } from '@material-ui/core'
import Grid from "@material-ui/core/Grid";
import {Typography} from "@material-ui/core";
import { injectIntl } from "react-intl";
import SelectedBasicEvents from "./SelectedBasicEvents";
import SelectedCFMs from "./SelectedCFMs";
import ActionButtons from "../../../../primitives/Button/ActionButtons";

const useStyles = makeStyles((theme: Theme) => ({
  listTitle: {
    marginLeft: theme.spacing(1)
  },
  graphViewContainer: {
    width: "72vw",
    height: '72vh'
  },
  alignBottom: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
}));

type HumanFailureEventListItemProps = {
  detail: any,
  modelId: any,
  cfId:any,
  key: any,
  hfeProgress: any,
  cfmProgress: any,
  pifProgress: any,
  disableEditing: boolean,
  history: any,
  onResponseUpdated: () => void,
  updateScore: () => void,
  expertmodeactive: boolean
}

const HumanFailureEventListItem = (props: HumanFailureEventListItemProps) => {
  const {getProgress, expertmodeactive, detail, modelId, key, hfeProgress, cfmProgress, pifProgress, disableEditing, history, onResponseUpdated,cfId } = props;
  const primaryText = (
    <Typography variant="h6" color="textPrimary">{detail.label.name}</Typography>
  );

  const classes = useStyles();
  const intl = useIntl();
  
  const [faultTreeId, setFaultTreeId] = useState(null);
  const [faultTree, setFaultTree] = useState(null);
  const [graph, setGraph] = useState<PhoenixFaultTreeMxGraph | PhoenixEventTreeMxGraph | PhoenixFlowChartMxGraph>(null);

  const isHFEEmpty = () => {
    try {
      const {
        nodes: {
          basic_events, house_events, cfms, excluded_gates,
        },
      } = props.detail;
      return Object.keys(basic_events).length === 0
        && Object.keys(house_events).length === 0
        && excluded_gates.length === 0
        && cfms.length === 0;
    } catch (e) {
      return true;
    }
  };



  useEffect(() => {
    
    if (!detail.name) return;
    PhoenixApiManager.getAnsweredHumanFailureEvent(cfId, detail.name)
      .then(response => {
        setFaultTreeId(response.data.fault_tree_id);
      });


  }, []);

  useEffect(() => {
    if (faultTreeId) {
      PhoenixApiManager.getPhoenixTree(faultTreeId)
        .then(response => {
          setFaultTree(response.data);
          setGraph(new PhoenixFaultTreeMxGraph(response.data));
        })
        .catch(err => console.error(err));
    }
  }, [faultTreeId]);

  const hfePathBasename = `${ApiManager.MODEL_PATH}/${modelId}/main-analysis/${cfId}/hfe/${detail.name}/`;

  const hfeProgressAvatar = () => 
    (
    <Grid item xs={1}>
      <ListItemAvatar>
        <CircularProgressIcon progress={hfeProgress} checkmark />
      </ListItemAvatar>
    </Grid>
  );

  const panelTitle = () => (
    <Grid item xs={7}>
      <Typography>{primaryText}</Typography>
    </Grid>
  );

  const panelActions = () => (
    <Grid item xs={4} align={"right"}>
      <ActionButtons
        graphView={{
          graph: graph,
          toolTipTitle: intl.formatMessage({id: 'checkHFE'}),
          disabled: isHFEEmpty()
        }}
        editLink={ expertmodeactive ? {
          url: `${hfePathBasename}expertedit/`,
          disabled: false
        } : {
          url: `${hfePathBasename}edit/`,
          disabled: false
        }}
        quantifyLink={{ // this link isn't working for me? (the grey quantify icon button)
          url: `${hfePathBasename}quantify/`,
          disabled: isHFEEmpty()
        }}
//         others={
//           <Tooltip title={intl.formatMessage({ id: 'analysis.cfm' })} >

// <Link to={`${hfePathBasename}/cfm`}>
//               <IconButton disabled={isHFEEmpty()}>
//                 <BubbleChartIcon />
//               </IconButton>
// </Link>
//           </Tooltip>
//         }
      />
    </Grid>
  );

  const cfclicked = () => {

  }

  const hfeDetails = () => (
    <Grid container>
      <Grid item xs={1} />
      <Grid item xs={11}>
        <Grid container>
          <Grid xs={12}>
            <SelectedCFMs
              basePath={hfePathBasename}
              cfId={cfId}
              detail={detail}
              cfmProgress={cfmProgress}
              pifProgress={pifProgress}
              onResponseUpdated={onResponseUpdated}
              updateScore={props.updateScore}
              getProgress={getProgress}
            />
          </Grid>
          <Grid xs={12}>
            <SelectedBasicEvents
              basicEvents={faultTree ? faultTree.basic_events : {}}
              isHFEEmpty={isHFEEmpty()}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <Accordion
      id={detail.name}
      defaultExpanded={hfeProgress.completed === hfeProgress.total}
      expanded={true}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Grid container alignItems={'center'}>
          {hfeProgressAvatar()}
          {panelTitle()}
          {panelActions()}
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        {hfeDetails()}
      </AccordionDetails>
    </Accordion>
  );
};

export default withRouter(injectIntl(HumanFailureEventListItem));
