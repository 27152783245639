import React from 'react';
import { withStyles, Theme } from '@material-ui/core';
import ButtonWithTooltip from './ButtonWithTooltip';

const styles = (theme: Theme) => ({
  root: theme.buttonTypes != null ? {
    color: theme.buttonTypes.create.backgroundColor,
    border: `1px solid ${theme.buttonTypes.create.backgroundColor}`,
    '&:hover': {
      color: theme.buttonTypes.create['&:hover'].backgroundColor,
      border: `1px solid ${theme.buttonTypes.create['&:hover'].backgroundColor}`,
    },
  } : {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.getContrastText(theme.palette.primary.main),
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
});

interface Props {
  classes: { [key: string]: any },
  children: any,
  tooltipTitle: string,
}

const GreenButton = (props: Props) => {
  const {
    classes, tooltipTitle, children, ...others
  } = props;
  return (
    <ButtonWithTooltip classes={classes} tooltipTitle={tooltipTitle} {...others}>
      {children}
    </ButtonWithTooltip>
  );
};

export default withStyles(styles)(GreenButton);
