import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { injectIntl } from 'react-intl';
import NewTrackableCard from 'hcla-web-frontend-primitives/app/components/primitives/Card/NewTrackableCard';
import ViewHeader from 'hcla-web-frontend-primitives/app/components/primitives/Views/ViewHeader';
import MultiUserSelectChip from 'hcla-web-frontend-primitives/app/components/primitives/Chips/MultiUserSelectChip';
import CreateButton from 'hcla-web-frontend-primitives/app/components/primitives/Buttons/CreateButton';
import CancelButton from 'hcla-web-frontend-primitives/app/components/primitives/Buttons/CancelButton';
import PhoenixApiManager from '../../Api/PhoenixApiManager';
import ModelTemplateSelect from './ModelTemplateSelect';
import AuthService from "hcla-web-frontend-primitives/app/Api/AuthService";
import Validators from "hcla-web-frontend-primitives/app/Utils/Validators";

const styles = () => ({
  tableRoot: {
    border: 'none',
    borderCollapse: 'collapse',
    width: '100%',
    verticalAlign: 'center',
    '& tr': {
    },
    '& td': {
      paddingTop: '12px',
      paddingBottom: '12px',
    },
    '& td:first-child': {
      paddingRight: '16px',
      borderRight: '1px solid #ccc',
      textAlign: 'right',
    },
    '& td:last-child': {
      paddingLeft: '16px',
    },
  },
});

interface Props {
  classes: { [key: string]: any },
  intl: { [key: string]: any }
}

interface State {
  createdId: number | null,
  title: {
    text: string,
    hasError: boolean
  },
  description: {
    text: string,
    hasError: boolean
  },
  template: string,
  assignedUsers: any[]
}

class NewModel_main extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      createdId: null,
      title: {
        text: '',
        hasError: !Validators.isTitleValid('')
      },
      description: {
        text: '',
        hasError: !Validators.isDescriptionValid('')
      },
      template: '',
      assignedUsers: [AuthService.getProfile().user_id]
    };
  }

  private handleChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const text = event.target.value;
    const hasError = () => {
      if (name === 'title') return !Validators.isTitleValid(text);
      if (name === 'description') return !Validators.isDescriptionValid(text);
    }
    if (name === 'template') {
      this.setState({
        [name]: text
      })
    } else {
      //@ts-ignore
      this.setState({
        [name]: {
          text,
          hasError: hasError()
        },
      });
    }
  };

  handleMultiChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      [name]: event,
    });
  };

  handleSubmit = () => {
    const {
      title, description, template, assignedUsers,
    } = this.state;
    const payload = {
      title: title.text,
      description: description.text,
      assigned_users: assignedUsers,
      template:template
    };

    // PhoenixApiManager.createNewMainModel(payload)
    //   .then((response: { status: number; json: () => any; }) => response.status === 201 && response.json())
    //   .then((newModel: { id: any; }) => this.setState({ createdId: newModel.id }));
    // console.log(this.state.createdId)
    PhoenixApiManager.createNewMainModel(payload)
      .then((response)=>{
        this.setState({ createdId: response.data.id })
        
      }).catch((err)=>{console.log(err)})
  };

  isCreateButtonDisabled = () => {
    const { title, template, assignedUsers } = this.state;
    if (title === null || title === undefined || title === '') {
      return true;
    }
    if (template === null || template === undefined || template === '') {
      return true;
    }
    return assignedUsers === null || assignedUsers === undefined || assignedUsers === [];
  };

  render() {
    const { classes, intl, ...others } = this.props;
    const {
      createdId, title, template, description, assignedUsers,
    } = this.state;

    if (createdId != null) {
      return <Redirect to={`/model/${createdId}/main-overview/`} />;
    }

    return (
      <ViewHeader
        title={intl.formatMessage({ id: 'newModel' })}
        caption={intl.formatMessage({ id: 'mainContent.newModel.newModelDesc' })}
      >
        <BreadcrumbsItem to="/model/new">{intl.formatMessage({ id: 'newModel' })}</BreadcrumbsItem>
        <NewTrackableCard {...others}>
          <CardContent>
            <table className={classes.tableRoot}>
              <tbody>
                <tr>
                  <td>
                    <Typography variant="subtitle1">{intl.formatMessage({ id: 'title' })}</Typography>
                  </td>
                  <td>
                    <TextField
                      id="title"
                      value={title.text}
                      onChange={this.handleChange('title')}
                      fullWidth
                      error={!Validators.isEmpty(title.text) ? title.hasError : false}
                      helperText={!Validators.isEmpty(title.text) && title.hasError ? Validators.ERROR_DESCRIPTIONS.TITLE : undefined}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <Typography variant="subtitle1">{intl.formatMessage({ id: 'description' })}</Typography>
                  </td>
                  <td>
                    <TextField
                      id="description"
                      value={description.text}
                      onChange={this.handleChange('description')}
                      multiline
                      fullWidth
                      error={description.hasError}
                      helperText={description.hasError ? Validators.ERROR_DESCRIPTIONS.DESCRIPTION : undefined}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <Typography variant="subtitle1">{intl.formatMessage({ id: 'template' })}</Typography>
                  </td>
                  <td>
                    <ModelTemplateSelect
                      handleUpdate={this.handleChange('template')}
                      selected={template}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <Typography variant="subtitle1">{intl.formatMessage({ id: 'mainContent.newModel.assignedUsers' })}</Typography>
                  </td>
                  <td>
                    <MultiUserSelectChip
                      selected={assignedUsers}
                      handleUpdate={this.handleMultiChange('assignedUsers')}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </CardContent>
          <CardActions className={classes.buttonWrapper}>
            <Link to="/"><CancelButton variant="outlined" size="large">{intl.formatMessage({ id: 'cancel' })}</CancelButton></Link>
            <CreateButton
              onClick={this.handleSubmit}
              variant="outlined"
              size="large"
              disabled={this.isCreateButtonDisabled()}
            >
              {intl.formatMessage({ id: 'create' })}
            </CreateButton>
          </CardActions>
        </NewTrackableCard>
      </ViewHeader>
    );
  }
}

export default withStyles(styles)(injectIntl(NewModel_main));
